import { Dictionary } from 'lodash';
import { POLICY_ENUMS } from '../constants';

type KeyLists = string[] | undefined[];

/**
 * Unapproved Properties per policy type
 */
const gl_keys: KeyLists = [
  'professionalLiability',
  'bppDeductible',
  'restaurantCoverage',
  'equipmentBreakdownEndorsement',
  'bppLimit',
  'buildingLimit',
  'locationType',
];
const bop_keys: KeyLists = ['professionalLiability'];
const pl_keys: KeyLists = [
  'restaurantCoverage',
  'equipmentBreakdownEndorsement',
  'hiredAutoAndNonOwnedAuto',
  'liquorLiabilityEndorsement',
  'coverage',
  'premierPlatformDiscount',
  'bopClassCode',
  'cglClassCode',
  'glAggregateLimit',
  'glAggregatePcoLimit',
  'propertyDamageLiabilityDeductible',
  'glLimit',
  'bppDeductible',
  'bppLimit',
  'buildingLimit',
  'locationType',
  'lossControl',
];

interface Collection {
  [key: string]: KeyLists;
}

const keyCollections: Collection = {
  BOP: bop_keys,
  GL: gl_keys,
  PL: pl_keys,
};

const transformer = (collection: Dictionary<any>, selectedApplications: string[]) => {
  const newCollection: Dictionary<any> = {};

  Object.keys(collection).forEach((key: string) => {
    const timesKeyIsUnapproved = selectedApplications.reduce((accum: number, type: string) => {
      const isKeyUnapproved = (keyCollections[type] as string[]).includes(key);
      if (isKeyUnapproved) {
        accum += 1;
      }

      return accum;
    }, 0);

    /**
     * We'll add the property to the new application object unless the property is on the
     * unapproved list for each of the selected policy types
     */
    if (timesKeyIsUnapproved !== selectedApplications.length) {
      if (key === 'endorsements') {
        newCollection[key] = transformer(collection[key], selectedApplications);
      } else if (key === 'locations') {
        newCollection[key] = collection[key].map((item: any) => transformer(item, selectedApplications));
      } else {
        newCollection[key] = collection[key];
      }
    }

    /**
     * The `endorsementsPackage` property needs a default value if not "approved"
     */
    if (!selectedApplications.includes(POLICY_ENUMS.BOP) && key === 'endorsementPackage') {
      newCollection.endorsementPackage = 'None';
    }

    /**
     * The `lossControl` property needs a default value if not "approved"
     */
    if (
      !(selectedApplications.includes(POLICY_ENUMS.BOP) || selectedApplications.includes(POLICY_ENUMS.GL)) &&
      key === 'lossControl'
    ) {
      newCollection.lossControl = 'NotSet';
    }
  });

  return newCollection;
};

/**
 * Returns a new application to be sent with the POST request with any fields not required
 * based on the selected policy types.
 *
 * @param application Application to be transformed
 * @returns A copy of the application without any fields not required for the selected policies
 */
const applicationDataTransformer = (application: any) => {
  const selectedApplications = application.applicationTypes || [];

  if (selectedApplications.length) {
    return transformer(application, application.applicationTypes);
  } else {
    return application;
  }
};

export default applicationDataTransformer;
