import { Dictionary } from 'lodash';
import React from 'react';
import styled from 'styled-components';

const Text = styled.p`
  font-family: ${(props) => props.theme.font.typeface.secondary};
  font-size: 15px;
  line-height: 23px;
  letter-spacing: -0.2px;
  color: ${(props) => props.theme.pageComponents.policySelection.text.textColor};
  margin: 0;
`;

const List = styled.ul`
  padding-left: 32px;
`;

const Bullet = styled.li`
  font-family: ${(props) => props.theme.font.typeface.secondary};
  font-size: 15px;
  line-height: 23px;
  margin: 5px 0;
  letter-spacing: -0.2px;
  color: ${(props) => props.theme.pageComponents.policySelection.bullet.textColor};

  &::marker {
    color: ${(props) => props.theme.pageComponents.globals.listBullet};
  }
`;

const policies: Dictionary<any> = {
  GL: {
    subtitle: 'You should get a General Liability Policy at a minimum if your business interacts with any clients.',
    description: (
      <React.Fragment>
        <Text>A General Liability Policy covers you when other parties sue you over:</Text>
        <List>
          <Bullet>Bodily Injury, e.g., slip-and-falls at your business</Bullet>
          <Bullet>Property Damage, e.g., your business broke a client's computer.</Bullet>
          <Bullet>
            Advertising and Personal Injury, e.g., you infringed on a copyright or wrote something that damaged
            someone's reputation.
          </Bullet>
        </List>
        <Text>You should get a General Liability Policy at a minimum if your business interacts with any clients.</Text>
      </React.Fragment>
    ),
  },
  BOP: {
    subtitle:
      'Typically used for service-based professions, and may cover you if a client sues due to a business mistake.',
    description: (
      <React.Fragment>
        <Text>
          A Business Owner's Policy (BOP) is a General Liability Policy and a Commercial Property Policy combined into
          one. You should get a BOP if your business:
        </Text>
        <List>
          <Bullet>Has assets (your businesses' or your clients') that can be damaged or stolen.</Bullet>
          <Bullet>
            Has a physical location that you rent or own (e.g., offices, shared workspace, apartment building).
          </Bullet>
          <Bullet>Has less than $5 million in sales and 50 employees.</Bullet>
        </List>
      </React.Fragment>
    ),
  },
  PL: {
    subtitle:
      'Typically used for service-based professions, and may cover you if a client sues a business due to a mistake.',
    description: (
      <React.Fragment>
        <Text>
          A Professional Liability Policy (also known as Errors & Omissions insurance) covers you when other parties sue
          over a failure to perform the service that your business was expected to complete. You should get a
          Professional Liability Policy if:
        </Text>
        <List>
          <Bullet>You're a freelancer.</Bullet>
          <Bullet>You're a service-based business, e.g., attorneys, consulting firm, accountants, barbers.</Bullet>
          <Bullet>You regularly give advice to clients.</Bullet>
        </List>
      </React.Fragment>
    ),
  },
};

export default policies;
