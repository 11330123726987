import { getRequestContext, reportToSentry } from '../helpers/sentry';
import { bindQuote } from '../api';

export const POLICY_PURCHASED = 'POLICY_PURCHASED';
export const QUOTE_PURCHASE_PENDING = 'QUOTE_PURCHASE_PENDING';
export const APPLICATION_ERROR = 'APPLICATION_ERROR';
export const POLICY_PURCHASE_FAILED = 'POLICY_PURCHASE_FAILED';
export const SET_PAYMENT_INTERVAL = 'SET_PAYMENT_INTERVAL';
export const SET_QUOTE_IS_LOADING = 'SET_QUOTE_IS_LOADING';
export const MAKE_RATE_CALL = 'MAKE_RATE_CALL';
export const CLEAR_APPLICATION_ERROR = 'CLEAR_APPLICATION_ERROR';
export const SET_EXCLUSION_DATA = 'SET_EXCLUSION_DATA';
export const SET_DECLINATION_MESSAGES = 'SET_DECLINATION_MESSAGES';

export function policyPurchased(data) {
  return {
    type: POLICY_PURCHASED,
    data,
  };
}

export function quotePurchasePending() {
  return {
    type: QUOTE_PURCHASE_PENDING,
  };
}

export function applicationError(data) {
  return {
    type: APPLICATION_ERROR,
    data,
  };
}

export function clearApplicationError() {
  return {
    type: CLEAR_APPLICATION_ERROR,
  };
}

export function policyPurchaseFailed(data) {
  return {
    type: POLICY_PURCHASE_FAILED,
    data,
  };
}

export function setPaymentInterval() {
  return {
    type: SET_PAYMENT_INTERVAL,
  };
}

export function setDeclinationMessages(data) {
  return {
    type: SET_DECLINATION_MESSAGES,
    data,
  };
}

export function handlePurchasePolicy(data) {
  return (dispatch) => {
    return bindQuote(data)
      .then((res) => {
        if (res.data.isSuccess) {
          dispatch(policyPurchased(res.data));
        } else {
          dispatch(policyPurchaseFailed(res.data.errors));
          reportToSentry(res.data.errors);
        }
      })
      .catch((error) => reportToSentry(error, getRequestContext({ requestData: data })));
  };
}

export function setQuoteIsLoading(data) {
  return {
    type: SET_QUOTE_IS_LOADING,
    data,
  };
}

export function makeRateCall(data) {
  return {
    type: MAKE_RATE_CALL,
    data,
  };
}

export function setExclusions(data) {
  return {
    type: SET_EXCLUSION_DATA,
    data,
  };
}
