import styled from 'styled-components';

export const LocationFormCont = styled.div<{ expanded: boolean }>`
  border-radius: 5px;
  display: ${(props) => (props.expanded ? '' : 'none')};
  margin: 32px 0 10px 0;
  @media (max-width: 767px) {
    padding: 10px 0;
    margin: 0;
  }
`;

export const LocationTypeCont = styled.div`
  justify-content: flex-start;
  display: inline-flex;
  flex-wrap: wrap;
  padding-top: 16px;

  //grid-gap emulation for unsupported browsers
  margin: -12px 0 32px -12px;
  width: calc(100% + 12px);
  button {
    margin: 12px 0 0 12px;
  }
`;

export const LocationTypeButton = styled.button<{ active: boolean }>`
  height: 48px;
  justify-content: center;
  align-items: center;
  background: ${(props) =>
    props.active
      ? props.theme.selectors.dynamicSelectors.backgroundSelected
      : props.theme.selectors.dynamicSelectors.backgroundUnselected};
  border-radius: 23px;
  border: none;
  text-align: center;
  font-family: ${(props) => props.theme.font.typeface.primary};
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
  color: ${(props) =>
    props.active
      ? props.theme.selectors.dynamicSelectors.textColorSelected
      : props.theme.selectors.dynamicSelectors.textColorUnselected};
  width: 192px;

  @media (max-width: 767px) {
    width: 100%;
  }

  :hover {
    cursor: pointer;
    background: ${(props) => props.theme.selectors.dynamicSelectors.backgroundOnHover};
    color: ${(props) => props.theme.selectors.dynamicSelectors.textColorOnHover};
  }
`;
