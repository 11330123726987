import React from 'react';
import Locations from '../Components/Shared/Locations';
import { Container } from '../elements';

const LocationsPage = () => (
  <Container>
    <Locations />
  </Container>
);

export default LocationsPage;
