import React from 'react';
import styled, { css } from 'styled-components';

interface RadioBtnProps {
  onChange: (value: any) => void;
  id: string | number;
  labelText: string;
  isChecked: boolean;
  name: string;
  dataCy?: string;
}

const RadioBtn = ({ onChange, id, labelText, isChecked, name, dataCy }: RadioBtnProps) => {
  return (
    <Container>
      <CustomInput
        aria-label={labelText}
        type="radio"
        id={id.toString()}
        name={name}
        checked={isChecked}
        onChange={onChange}
        value={id}
        data-cy={dataCy}
      />
      <RadioButtonFill />
      <LabelText htmlFor={id.toString()} isChecked={isChecked}>
        {labelText}
      </LabelText>
    </Container>
  );
};

export default RadioBtn;

const Container = styled.li`
  display: flex;
  align-items: center;
  position: relative;
  margin: 0 0 16px 0;

  @media (max-width: 768px) {
    margin: 0 0 32px 0;
  }
`;

const LabelText = styled.label<{ isChecked: boolean }>`
  font-size: 15px;
  line-height: 23px;
  color: ${({ theme, isChecked }) =>
    isChecked ? theme.colors.primary.zero : theme.formElements.radioButton.labelColor};
  font-family: ${({ theme }) => theme.font.typeface.secondary};

  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.primary.zero};
  }
`;

const RadioButtonFill = styled.div`
  position: absolute;
  left: 4px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: ${({ theme }) => theme.formElements.radioButton.backgroundUnselected};
  border: 1px solid ${({ theme }) => theme.colors.neutral.medium};
`;

const innerBorder = css`
  content: '';
  width: 24px;
  height: 24px;
  position: absolute;
  outline: 5px solid rgb(255, 255, 255);
  border-radius: 50%;
  transform: scale(0.5);
`;

const CustomInput = styled.input`
  opacity: 0;
  z-index: 1;
  cursor: pointer;
  width: 25px;
  height: 25px;
  margin-right: 10px;
  &:hover ~ ${RadioButtonFill} {
    border: 1px solid ${({ theme }) => theme.formElements.radioButton.backgroundSelected};
  }
  &:checked + ${Container} {
    background: ${({ theme }) => theme.formElements.radioButton.backgroundSelected};
    border: 2px solid ${({ theme }) => theme.formElements.radioButton.backgroundSelected};
  }
  &:checked + ${RadioButtonFill} {
    background: ${({ theme }) => theme.formElements.radioButton.backgroundSelected};
    border: 1px solid ${({ theme }) => theme.formElements.radioButton.backgroundSelected};
    &::after {
      ${innerBorder}
    }
  }
  &:focus + ${RadioButtonFill} {
    outline: 1px solid ${({ theme }) => theme.formElements.radioButton.backgroundSelected};
  }
`;
