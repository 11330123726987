import thunk from 'redux-thunk';
import navigation from './navigation';
import token from './token';
import rateCalls from './rateCalls';
import availableEndorsements from './availableEndorsements';
import errorInterceptor from './errorInterceptor';
import setEndorsementDefaults from './setEndorsementDefaults';
import sendMDALeadEmail from './sendMDALeadEmail';

let middleware = [
  thunk,
  token,
  navigation,
  rateCalls,
  availableEndorsements,
  setEndorsementDefaults,
  errorInterceptor,
  sendMDALeadEmail,
];

if (process.env.NODE_ENV === 'development') {
  middleware = [...middleware];
}
export default middleware;
