export const SET_AGENT = 'SET_AGENT';
export const SET_IS_MDA = 'SET_IS_MDA';
export const SET_AGENT_PRODUCER_EXTERNAL_ID = 'SET_AGENT_PRODUCER_EXTERNAL_ID';
export const SET_MDA_LEAD_EMAIL_SENT = 'SET_MDA_LEAD_EMAIL_SENT';

export const setAgent = (agent: Agent) => ({
  type: SET_AGENT,
  data: agent,
});

export const setIsMDA = () => ({
  type: SET_IS_MDA,
});

export const setMDALeadEmailSent = (data: boolean) => ({
  type: SET_MDA_LEAD_EMAIL_SENT,
  data,
});

export const setAgentProducerExternalId = (producerExternalId: string) => ({
  type: SET_AGENT_PRODUCER_EXTERNAL_ID,
  data: producerExternalId,
});
