import { SET_REFERRAL_PARTNER } from '../actions/referralPartner';

const initialState = {};

export function referralPartner(state: PartnerInfo = initialState, action: Action) {
  switch (action.type) {
    case SET_REFERRAL_PARTNER:
      return Object.assign({}, state, action.data);
    default:
      return state;
  }
}
