import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Dictionary, isEmpty } from 'lodash';
import styled from 'styled-components';
import { selectUserHasInvalidatedAddOns, selectAkHash, selectGrossAnnualSales } from '../../selectors/application';
import { EndorsementSubQuestions } from './addOnQuestionMap';
import ValidationQuestions from './ValidationQuestions';
import { AddOnQuestionCont, AddOnQuestionDesc, InvalidMessage, QuestionRow } from './styles/addons';

import UnderWritingQuestions from './UnderwritingQuestions';
import { useThemeContext } from '../../elements/theme/CustomThemeProvider';
import { liquorClasses100 } from '../../constants';
import { useFeatureFlags } from '../../toggle_tools/featureFlagTools';
import Checkbox from '../../elements/Checkbox';
import { MoreInfoCaret } from '../Summary/AddOns/styles';
import SmoothCollapse from 'react-smooth-collapse';
import { EndorsementEnum } from '../../types/enums';

interface AddonQuestionProps {
  endorsement: string;
  label: string;
  description: string | JSX.Element;
  value: boolean | Dictionary<any> | undefined;
  action: (value: any) => Action;
  subQuestions?: EndorsementSubQuestions;
  isLoading: boolean;
  validationData: Dictionary<number>;
  disabled?: boolean;
  akHash?: string;
  grossAnnualSales?: number;
}

interface ConnectedAddonQuestionProps extends AddonQuestionProps {
  isAddonInvalid: boolean;
}

export const invalidMessageCopy =
  'Based on your answers, we’re unable to offer this coverage. For help, please call our customer service at (855) 566 - 1011 or use our chat tool.';

const RenderInvalidMessage = () => <InvalidMessage>{invalidMessageCopy}</InvalidMessage>;

export const AddOnQuestion = ({
  endorsement,
  label,
  description,
  value,
  action,
  subQuestions,
  isLoading,
  validationData,
  isAddonInvalid,
  disabled = false,
  akHash,
  grossAnnualSales,
}: ConnectedAddonQuestionProps) => {
  const { theme } = useThemeContext();
  const { underwritingQuestions, validation } = subQuestions || {};
  const initialExpandedValue = typeof value === 'boolean' || value == null ? false : isEmpty(value);
  const hasHNOABeenSelectedByDefault =
    endorsement === 'hiredAutoAndNonOwnedAuto' && value && typeof value === 'object' && Object.keys(value).length === 0;

  const [isExpanded, setExpanded] = useState(initialExpandedValue);
  const [isChecked, setIsChecked] = useState(hasHNOABeenSelectedByDefault);

  const { useNewLiquorLiabilityRules } = useFeatureFlags();
  const isLiquorException =
    useNewLiquorLiabilityRules &&
    endorsement === 'liquorLiabilityEndorsement' &&
    liquorClasses100.includes(akHash || '');

  const getIsSelected = (stateValue: boolean | Dictionary<any> | undefined) => {
    const isBoolean = typeof stateValue === 'boolean';
    const hasValue = isBoolean
      ? stateValue
      : Boolean(stateValue && Object.values(stateValue).some((value) => Boolean(value)));
    if (subQuestions && !isLiquorException) {
      if (isChecked) {
        return true;
      } else {
        return hasValue;
      }
    } else {
      return hasValue;
    }
  };

  const isSelected = getIsSelected(value);

  const underwritingQuestionValue = typeof value === 'boolean' || value == null ? {} : value;

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.checked) {
      if (!isLiquorException && (subQuestions?.underwritingQuestions || subQuestions?.validation)) {
        setExpanded(true);
        setIsChecked(true);
        action({});
      } else if (isLiquorException) {
        action({ alcoholicBeverageSales: grossAnnualSales });
      } else {
        action(true);
      }
    } else {
      setExpanded(false);
      setIsChecked(false);
      action(null);
    }
  };

  const handleToggleDetails = () => {
    setExpanded(!isExpanded);
  };

  const hasMoreInfo = !isLiquorException && subQuestions;
  const isHNOA = endorsement !== EndorsementEnum.hiredAutoAndNonOwnedAuto;
  const shouldShowMoreInfoCaret = isSelected && hasMoreInfo && !disabled && !isAddonInvalid && isHNOA;

  const renderAddOnHeader = () => {
    return (
      <>
        <QuestionRow data-testid="AddOn-question" data-cy={`${endorsement}-addOn`}>
          {/* if add on is allowed render checkbox + label, otherwise render just the title of the add on */}
          {!isAddonInvalid && !disabled ? (
            <Checkbox
              onChange={handleCheckboxChange}
              selected={isSelected}
              label={label}
              id={endorsement}
              showActiveLabel={!(disabled || isAddonInvalid)}
            />
          ) : (
            <AddOnTitle>{label}</AddOnTitle>
          )}

          {shouldShowMoreInfoCaret && (
            <MoreInfoButton
              onClick={handleToggleDetails}
              type="button"
              aria-label={`${isExpanded ? 'hide' : 'show'} ${endorsement} info`}
            >
              <MoreInfoCaret src={theme.icons.caret} expanded={isExpanded} />
            </MoreInfoButton>
          )}
        </QuestionRow>
        {isAddonInvalid ? (
          <RenderInvalidMessage />
        ) : (
          <AddOnQuestionDesc disabled={disabled}>{description}</AddOnQuestionDesc>
        )}
      </>
    );
  };

  const renderAddOnMoreInfo = () => {
    return (
      <SmoothCollapse heightTransition=".1s ease-out" expanded={isExpanded} allowOverflowWhenOpen={true}>
        <StyledHeaderRow />
        {underwritingQuestions && (
          <UnderWritingQuestions
            underwritingQuestions={underwritingQuestions}
            value={underwritingQuestionValue}
            validationData={validationData}
            action={action}
            isLoading={isLoading}
            setExpanded={setExpanded}
          />
        )}
        {validation && (
          <ValidationQuestions
            validation={validation}
            endorsement={endorsement}
            action={action}
            setExpanded={setExpanded}
          />
        )}
      </SmoothCollapse>
    );
  };

  return (
    <AddOnQuestionCont
      key={endorsement}
      isSelected={isSelected}
      disabled={disabled || isAddonInvalid}
      data-testid={`${endorsement}-container`}
    >
      {renderAddOnHeader()}
      {hasMoreInfo && renderAddOnMoreInfo()}
    </AddOnQuestionCont>
  );
};

const mapStateToProps = (state: ReduxState, props: AddonQuestionProps) => ({
  isAddonInvalid: Boolean(selectUserHasInvalidatedAddOns(state)?.includes(props.endorsement)),
  akHash: selectAkHash(state),
  grossAnnualSales: selectGrossAnnualSales(state),
});

const AddOnTitle = styled.h3`
  font-size: 20px;
  font-weight: 600;
  font-family: ${(props) => props.theme.font.typeface.primary};
  color: ${(props) => props.theme.colors.tertiary.zero};
  margin-top: 0;
  margin-bottom: 8px;
`;

const MoreInfoButton = styled.button`
  background: transparent;
  border: none;
  height: 25px;
`;

const StyledHeaderRow = styled.hr`
  border: 0.5px solid #e7e4e4;
  margin-bottom: 16px;
`;

export default connect(mapStateToProps)(AddOnQuestion);
