import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateEndDate } from '../../actions/application';
import { selectPolicyEndDate, selectPolicyStartDate } from '../../selectors/application';
import { addDays, addYears } from 'date-fns';
import styled from 'styled-components';
import { LabelLarge } from '../../elements';
import DatePicker from '../../elements/DatePicker';
import { selectPartnerInfo } from '../../selectors';
import { IES_PARTNER_IDS } from '../../constants';
import { getEnvironment } from '../../helpers/getEnvironment';
import { Dispatch } from 'redux';

type DatePickerDate = Date | [Date | null, Date | null] | null;

interface EndDateProps {
  endDate: Date;
  startDate: Date;
  isIESPartner: boolean;
  disabled: boolean;
  updateEndDate: (date: DatePickerDate) => void;
}

class EndDate extends Component<EndDateProps> {
  setEndDate = (date: DatePickerDate) => this.props.updateEndDate(date);

  render() {
    const { endDate, startDate, disabled, isIESPartner } = this.props;

    return isIESPartner ? (
      <Container>
        <LabelLarge>Policy End Date</LabelLarge>
        <DatePicker
          fullWidth
          setDate={this.setEndDate}
          minDate={addDays(startDate, 30)}
          maxDate={addYears(startDate, 1)}
          date={endDate as Date}
          disabled={disabled}
        ></DatePicker>
      </Container>
    ) : null;
  }
}

const mapStateToProps = (state: ReduxState) => {
  const isIESPartner = Boolean(selectPartnerInfo(state)?.id === IES_PARTNER_IDS[getEnvironment()]);
  return {
    endDate: selectPolicyEndDate(state),
    startDate: selectPolicyStartDate(state),
    isIESPartner,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateEndDate: (date: DatePickerDate) => dispatch(updateEndDate({ policyEndDate: date })),
});

export default connect(mapStateToProps, mapDispatchToProps)(EndDate);

const Container = styled.div`
  z-index: 5;
  margin-bottom: 30px;
`;
