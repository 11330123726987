import styled from 'styled-components';
import CaretIcon from '../../Shared/CaretIcon';
import { CheckboxAndLabel } from '../../../elements/Checkbox';

export const SectionHeader = styled.h2`
  font-size: 20px;
  color: ${({ theme }) => theme.pageComponents.summary.styles.sectionHeader.textColor};
  font-weight: 600;
  margin: 40px 0 8px;
`;

export const SectionHeaderCont = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-items: center;

  position: relative;
  gap: 16px;

  img {
    width: 36px;
    height: 24px;
    margin-right: 0px;
  }

  ${CheckboxAndLabel} {
    margin-bottom: 0px;
  }
`;

export const Property = styled.div<{ center?: boolean; isReadOnly?: boolean }>`
  font-size: 16px;
  color: ${({ theme }) => theme.pageComponents.summary.styles.property.textColor};
  font-weight: 600;
  text-align: ${({ center }) => (center ? 'center' : 'left')};
  display: flex;
  align-items: center;
  cursor: ${({ isReadOnly }) => (isReadOnly ? 'default' : 'pointer')};
  display: flex;
  ${({ isReadOnly }) => isReadOnly && `padding-right: 32px`};

  @media (max-width: 767px) {
    font-size: 14px;
  }
`;

export const PropertyName = styled.div`
  @media (min-width: 768px) and (max-width: 1024px) {
    margin-right: 1em;
  }
`;

export const PropertySubtitle = styled.span`
  font-size: 15px;
  font-family: ${({ theme }) => theme.font.typeface.secondary};
  font-weight: 400;
`;

export const MobilePropertyValue = styled.div`
  color: ${({ theme }) => theme.pageComponents.summary.styles.mobilePropertyValue.textColor};
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  display: inline;
`;

export const Row = styled.div<{ borderColor?: string; backgroundColor?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: 52px;
  width: 100%;
  @media (max-width: 767px) {
    min-height: 0;
  }
  ${({ borderColor }) =>
    borderColor &&
    `
    margin-left: -20px;
    padding-left: 20px;
    padding-right: 20px;
    border-top: 4px solid ${borderColor};
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
`};
  ${({ backgroundColor }) =>
    backgroundColor &&
    `
    background: ${backgroundColor};
  `};
`;

export const MobileContainer = styled.div`
  width: 100%;
  padding: 10px 0;
`;

export const CollapseRow = styled(Row)`
  flex-direction: column;
  align-items: flex-start;
  div {
    width: 100%;
    padding: 0 25px 10px;
  }

  @media (max-width: 767px) {
    div {
      padding: 0;
    }
  }
`;

export const StyledCaret = styled(CaretIcon)`
  margin-left: 0;
`;

export const CaretButton = styled.button`
  background: none;
  border: none;
  margin-left: 10px;
  @media (max-width: 767px) {
    margin: 0 10px 0 0;
  }
`;

export const SectionDescription = styled.p`
  color: ${({ theme }) => theme.pageComponents.summary.styles.sectionDescription.textColor};
  font-family: ${({ theme }) => theme.font.typeface.secondary};
  font-size: 15px;
  letter-spacing: 0px;
  line-height: 24px;
  margin: 0 0 8px;
`;
