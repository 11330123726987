import React, { useState } from 'react';
import { LabelLarge, FormSection, SectionTitle, NumberInput, Paragraph, LabelWithToolTipCont } from '../../elements';
import styled from 'styled-components';
import ToolTipIcon from './ToolTipIcon';
import MultiRow from './MultiRow';
import YesNoQuestion from './YesNoQuestion';
import { updateProfessionalLiability } from '../../actions/application';
import handleFormSubmit from '../../actions/handleFormSubmit';
import {
  selectPlDeductible,
  selectPlLimit,
  selectYearsOfPriorActs,
  selectCertificationsRequired,
  selectCertificationsMaintained,
  selectPeriodLoading,
  selectYearsOfProfessionalExperience,
  selectGrossAnnualSales,
} from '../../selectors/application';
import { getCurrentPage } from '../../selectors/routing';
import SectionDivider from './SectionDivider';
import {
  PL_LIMIT_OCC_AGG,
  POLICY_OPTIONS,
  TOOL_TIP_CONTENT_KEY,
  VALUE_OPTIONS,
  POLICY_ARIA_LABELS,
  PAGES,
} from '../../constants';
import GhostButton from './GhostButton';
import SubmitButton from './SubmitButton';
import { isPageComplete } from '../../selectors/pageComplete';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { isPLComplete } from '../../selectors/progress';
import toolTipContent from '../../helpers/toolTipContent';
import { NumberFormatValues } from 'react-number-format';

interface ProfessionalLiabilityProps {
  certificationsRequired?: boolean;
  certificationsMaintained?: boolean;
  yearsOfPriorActs?: number;
  periodLoading?: number;
  yearsOfProfessionalExperience?: number;
  occurrenceLimit?: number;
  deductibleAmount?: number;
  isSummaryPage?: boolean;
  grossAnnualSales?: number;
  onBundleSubmit?: () => void;
  handleFormSubmit: () => void;
  updateProfessionalLiability: (value: any) => void;
  isPageComplete: boolean;
}

const ProfessionalLiability = ({
  certificationsRequired,
  certificationsMaintained,
  yearsOfPriorActs,
  periodLoading,
  yearsOfProfessionalExperience,
  occurrenceLimit,
  deductibleAmount,
  isSummaryPage,
  grossAnnualSales,
  onBundleSubmit,
  handleFormSubmit,
  updateProfessionalLiability,
  isPageComplete,
}: ProfessionalLiabilityProps) => {
  const updateSection = (field: string, value: number | boolean) => {
    updateProfessionalLiability({
      grossRevenue: grossAnnualSales || 0,
      [field]: value,
      ...(field === 'occurrenceLimit' ? { aggregateLimit: PL_LIMIT_OCC_AGG[Number(value)] } : {}),
    });
  };

  const adjustYearsExp = debounce((years: NumberFormatValues) => {
    if (years.floatValue) {
      updateSection('yearsOfProfessionalExperience', years.floatValue);
    }
  }, 500);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onBundleSubmit ? onBundleSubmit() : handleFormSubmit();
  };

  const [disabledDeductibleOptions, setDisabledDeductibleOptions] = useState<number[]>([]);

  const adjustDeductibleOptions = (limit: number) => {
    const disabledOptions = VALUE_OPTIONS[POLICY_OPTIONS.DEDUCTIBLE].filter((deductible: number) => {
      return limit < deductible;
    });
    setDisabledDeductibleOptions(disabledOptions);
    if (deductibleAmount && disabledOptions.includes(deductibleAmount)) {
      const deductible = [...VALUE_OPTIONS[POLICY_OPTIONS.DEDUCTIBLE]].reverse().filter((option: number) => {
        return !disabledOptions.includes(option);
      })[0];
      updateSection('deductibleAmount', deductible);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <FormSection available={true}>
          <LabelWithToolTipCont id="PLClaims">
            <LabelLarge display="inline">
              For professional liability claims, I want my business to be covered up to the amount of:
            </LabelLarge>
            <ToolTipIcon
              content={toolTipContent[TOOL_TIP_CONTENT_KEY[POLICY_OPTIONS.PROFESSIONAL_LIABILITY_LIMIT]]}
              toolTipName="Professional_Liability_Limit"
              labelElementId="PLClaims"
            />
          </LabelWithToolTipCont>
          <MultiRow
            money={true}
            options={VALUE_OPTIONS[POLICY_OPTIONS.PROFESSIONAL_LIABILITY_LIMIT]}
            value={occurrenceLimit}
            updateValue={(value) => {
              adjustDeductibleOptions(value);
              updateSection('occurrenceLimit', value);
            }}
            label={POLICY_ARIA_LABELS[POLICY_OPTIONS.PROFESSIONAL_LIABILITY_LIMIT]}
          />
        </FormSection>
        {isSummaryPage && <SectionDivider height={20} />}
        <FormSection available={true}>
          <LabelWithToolTipCont id="PLDeductible">
            <LabelLarge display="inline">For professional liability claims, I want to pay a deductible of</LabelLarge>
            <ToolTipIcon
              content={toolTipContent[TOOL_TIP_CONTENT_KEY[POLICY_OPTIONS.DEDUCTIBLE]]}
              toolTipName="Deductible"
              labelElementId="PLDeductible"
            />
          </LabelWithToolTipCont>
          <MultiRow
            money={true}
            options={VALUE_OPTIONS[POLICY_OPTIONS.DEDUCTIBLE]}
            value={deductibleAmount}
            updateValue={(value) => updateSection('deductibleAmount', value)}
            label={POLICY_ARIA_LABELS[POLICY_OPTIONS.DEDUCTIBLE]}
            disabledOptions={disabledDeductibleOptions}
          />
        </FormSection>
        {isSummaryPage && <SectionDivider height={20} />}
        <FormSection available={true}>
          <LabelLarge display="inline">
            How long would you like to be covered for work that your business performed in the past?
          </LabelLarge>
          <MultiRow
            options={VALUE_OPTIONS[POLICY_OPTIONS.YEARS_OF_PRIOR_ACTS]}
            value={yearsOfPriorActs}
            updateValue={(value) => updateSection('yearsOfPriorActs', value)}
            label={POLICY_ARIA_LABELS[POLICY_OPTIONS.YEARS_OF_PRIOR_ACTS]}
          />
        </FormSection>
        {isSummaryPage && <SectionDivider height={20} />}
        <FormSection available={true}>
          <LabelLarge display="inline">
            How long after the policy ends would you like to be able to file claims against this policy?
          </LabelLarge>
          <MultiRow
            options={VALUE_OPTIONS[POLICY_OPTIONS.PERIOD_LOADING]}
            value={periodLoading}
            updateValue={(value) => updateSection('periodLoading', value)}
            label={POLICY_ARIA_LABELS[POLICY_OPTIONS.PERIOD_LOADING]}
            days={true}
          />
        </FormSection>
        <SectionDivider height={20} />
        {!isSummaryPage && (
          <>
            <StyledSectionTitle>Professional Experience</StyledSectionTitle>
            <StyledParagraph>
              These questions are designed to gauge your qualification and experience in your industry.
            </StyledParagraph>
          </>
        )}
        <YesNoQuestion
          question="Does your industry require professional certifications?"
          value={certificationsRequired}
          selectYes={() => updateSection('certificationsRequired', true)}
          selectNo={() => updateSection('certificationsRequired', false)}
          cyLabel="certificationsRequired"
        />
        {isSummaryPage && <SectionDivider height={20} />}
        <YesNoQuestion
          question="Do you maintain professional certifications?"
          value={certificationsMaintained}
          selectYes={() => updateSection('certificationsMaintained', true)}
          selectNo={() => updateSection('certificationsMaintained', false)}
          cyLabel="certificationsMaintained"
        />
        {isSummaryPage && <SectionDivider height={20} />}
        <FormSection available={true}>
          <LabelLarge display="inline">How many years of professional experience do you have?</LabelLarge>
          <NumberInput
            value={yearsOfProfessionalExperience}
            onValueChange={adjustYearsExp}
            customWidth="quarter"
            data-cy={'years-of-professional-experience'}
            aria-label="Years of professional experience"
          />
        </FormSection>
        {isSummaryPage ? (
          <>
            <SectionDivider height={20} />
            <SubmitButton active={isPageComplete} arrowLinks={false}>
              Add Policy
            </SubmitButton>
          </>
        ) : (
          <GhostButton />
        )}
      </form>
    </>
  );
};

const mapStateToProps = (state: ReduxState) => {
  const page = getCurrentPage(state);
  const isSummaryPage = page === PAGES.SUMMARY;

  return {
    deductibleAmount: selectPlDeductible(state),
    occurrenceLimit: selectPlLimit(state),
    yearsOfPriorActs: selectYearsOfPriorActs(state),
    certificationsRequired: selectCertificationsRequired(state),
    certificationsMaintained: selectCertificationsMaintained(state),
    periodLoading: selectPeriodLoading(state),
    yearsOfProfessionalExperience: selectYearsOfProfessionalExperience(state),
    grossAnnualSales: selectGrossAnnualSales(state),
    isSummaryPage,
    isPageComplete: isSummaryPage ? isPLComplete(state, { ignoreDefaults: true }) : isPageComplete(state),
  };
};

const StyledSectionTitle = styled(SectionTitle)`
  margin-bottom: 8px;
  padding-top: 20px;
`;

const StyledParagraph = styled(Paragraph)`
  margin-bottom: 32px;
`;

export default connect(mapStateToProps, { updateProfessionalLiability, handleFormSubmit })(ProfessionalLiability);
