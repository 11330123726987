import React from 'react';
import GeneralLiabilityQuestions from '../Components/Shared/GeneralLiability';
import { Container } from '../elements';

const GeneralLiabilityPage = () => {
  return (
    <Container>
      <GeneralLiabilityQuestions />
    </Container>
  );
};

export default GeneralLiabilityPage;
