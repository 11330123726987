import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { useThemeContext } from '../../../elements/theme/CustomThemeProvider';
import { clearAllData } from '../../../actions/application';
import { isLocal, isTest } from '../../../helpers/environment';

interface LogoProps {
  clearAllData: () => void;
}

const LogoComp = ({ clearAllData }: LogoProps) => {
  const handleClick = () => {
    if (isLocal() || isTest()) {
      clearAllData();
    }
  };
  const { logo } = useThemeContext();
  return <Logo height={logo.height} src={logo.imgSrc} alt={logo.altText} onClick={handleClick} />;
};

const Logo = styled.img`
  margin-top: 40px;
  margin-bottom: 60px;
`;

const mapDispatchToProps = { clearAllData };

export default connect(null, mapDispatchToProps)(LogoComp);
