import styled from 'styled-components';

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;

  thead tr {
    background: ${({ theme }) => theme.tables.header.background};
    border-style: ${({ theme }) => theme.tables.header.border};
    color: ${({ theme }) => theme.tables.header.textColor};
    font-size: 12px;
    text-align: left;
    height: 32px;
  }

  tbody tr td {
    font-size: 16px;
    color: ${({ theme }) => theme.tables.body.textColor};
    font-weight: 600;
  }

  th {
    padding: 5px 16px;
    border: none;
  }

  td {
    padding: 16px;
    border: none;
  }

  tr:not(:last-child) {
    border-bottom: 2px solid ${({ theme }) => theme.tables.body.borderBottom};
  }

  tr {
    height: 50px;
  }
`;
