import React from 'react';
import styled from 'styled-components';
import BOPicon from '../icons/BOPicon.svg';
import GLicon from '../icons/GLicon.svg';
import PLicon from '../icons/PLicon.svg';

const getIcon = (name: string) => {
  switch (name) {
    case 'BOP':
      return BOPicon;
    case 'GL':
      return GLicon;
    case 'PL':
    default:
      return PLicon;
  }
};

export const StyledIcon = styled.img<{ onClick?: Function }>`
  height: 37px;
  width: 54px;
  margin-right: 20px;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
`;

export const PolicyIcon = ({ icon, onClick }: { icon: string; onClick?: any }) => (
  <StyledIcon alt={`${icon}-icon`} src={getIcon(icon)} onClick={onClick} />
);
