import { ERROR_MESSAGE_MAP } from './errorMessageRemapper';

type error = { message: string };

export const getHasInvalidPolicyError = (errors: Array<error>) => {
  for (let i in errors) {
    const errorId = errors[i].message?.slice(0, 5);
    if (Boolean(ERROR_MESSAGE_MAP[errorId])) return true;
  }

  return false;
};
