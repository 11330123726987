import React, { FunctionComponent, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { selectGLLimit, selectTwoFourLimitsAvailable, selectLiquorLiability } from '../../selectors/application';
import { getCurrentPage } from '../../selectors/routing';
import { isPageComplete } from '../../selectors/pageComplete';
import { setGlLimit } from '../../actions/application';
import handleFormSubmit from '../../actions/handleFormSubmit';
import { LabelLarge, FormSection, LabelWithToolTipCont } from '../../elements';
import { POLICY_OPTIONS, VALUE_OPTIONS, TOOL_TIP_CONTENT_KEY, POLICY_ARIA_LABELS, PAGES } from '../../constants';
import MultiRow from './MultiRow';
import ToolTipIcon from './ToolTipIcon';
import SectionDivider from './SectionDivider';
import GhostButton from './GhostButton';
import SubmitButton from './SubmitButton';
import toolTipContent from '../../helpers/toolTipContent';

interface PropTypes {
  glLimit?: number;
  twoFourLimitsAvailable?: boolean;
  onBundleSubmit?: () => void;
  handleFormSubmit: () => void;
  setGlLimit: (glLimit: number) => void;
  isPageComplete: boolean;
  isSummaryPage: boolean;
  hasLiquorLiability: boolean;
}

const GeneralLiability: FunctionComponent<PropTypes> = ({
  glLimit,
  twoFourLimitsAvailable,
  onBundleSubmit,
  handleFormSubmit,
  setGlLimit,
  isPageComplete,
  isSummaryPage,
  hasLiquorLiability,
}) => {
  useEffect(() => {
    document.title = 'Coterie - Coverage';
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!glLimit) {
      setGlLimit(twoFourLimitsAvailable ? 2_000_000 : 1_000_000);
    }
  }, [glLimit, twoFourLimitsAvailable]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onBundleSubmit ? onBundleSubmit() : handleFormSubmit();
  };

  const isTwoFourLimitDisabled = !twoFourLimitsAvailable || hasLiquorLiability;
  const disabledGlLimitOptions = isTwoFourLimitDisabled ? [2_000_000] : [];

  let twoFourUnavailableText;
  if (!twoFourLimitsAvailable) {
    twoFourUnavailableText = '$2M limit is unavailable for selected industry and location';
  } else if (hasLiquorLiability) {
    twoFourUnavailableText = '$2M limit is unavailable when Liquor Liability is added';
  }

  return (
    <>
      <form onSubmit={handleSubmit} data-cy="general-liability-section">
        <FormSection available={true}>
          <LabelWithToolTipCont id="GlLimit">
            <LabelLarge display="inline">
              For general liability claims, I want my business to be covered up to the amount of
            </LabelLarge>
            <ToolTipIcon
              content={toolTipContent[TOOL_TIP_CONTENT_KEY[POLICY_OPTIONS.GENERAL_LIABILITY_LIMIT]]}
              toolTipName="General_Liability_Limit"
              labelElementId="GlLimit"
            />
          </LabelWithToolTipCont>
          <MultiRow
            money={true}
            options={VALUE_OPTIONS[POLICY_OPTIONS.GENERAL_LIABILITY_LIMIT]}
            disabledOptions={disabledGlLimitOptions}
            value={glLimit}
            updateValue={(amount: number) => setGlLimit(amount)}
            label={POLICY_ARIA_LABELS[POLICY_OPTIONS.GENERAL_LIABILITY_LIMIT]}
          />
          {twoFourUnavailableText && <Message>{twoFourUnavailableText}</Message>}
        </FormSection>
        {isSummaryPage ? (
          <>
            <SectionDivider height={20} />
            <SubmitButton active={isPageComplete} arrowLinks={false}>
              Add Policy
            </SubmitButton>
          </>
        ) : (
          <GhostButton />
        )}
      </form>
    </>
  );
};

const Message = styled.div`
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 20px;
  color: #626266;
  @media (max-width: 850px) {
    text-align: center;
  }
`;

const mapStateToProps = (state: ReduxState) => {
  const isSummaryPage = getCurrentPage(state) === PAGES.SUMMARY;

  return {
    glLimit: selectGLLimit(state),
    twoFourLimitsAvailable: selectTwoFourLimitsAvailable(state),
    isSummaryPage,
    isPageComplete: isSummaryPage || isPageComplete(state),
    hasLiquorLiability: Boolean(selectLiquorLiability(state)),
  };
};

const mapDispatchToProps = {
  setGlLimit,
  handleFormSubmit,
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneralLiability);
