import { updateApplicationOnServer } from '../api';
import { addSource } from '../helpers/addSource';
import { getRequestContext, reportToSentry } from '../helpers/sentry';
import { selectIsAgentMDA } from '../selectors/agent';
import { getIsDropin } from '../selectors/routing';
import { setAvailablePolicyTypes } from './application';
import { applicationError } from './quote';

export const handleGetAvailablePolicyTypes = (errors: Array<string>) => (dispatch: Function, getState: Function) => {
  const { application: existingApplication } = getState();

  const sourceData = addSource({ isDropin: getIsDropin(getState()), isMDA: selectIsAgentMDA(getState()) });
  const appToSendToServer = {
    ...existingApplication,
    ...sourceData,
  };
  delete appToSendToServer.applicationTypes;

  return updateApplicationOnServer(appToSendToServer)
    .then((res: any) => {
      dispatch(applicationError(errors));
      dispatch(setAvailablePolicyTypes(res.data.availablePolicyTypes));
    })
    .catch((error: any) => {
      dispatch(applicationError(errors));
      reportToSentry(error, getRequestContext({ appToSendToServer }));
    });
};
