import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  PackagesCont,
  Options,
  Column,
  PackageContainer,
  Title,
  Description,
  SelectedCheck,
  Icon,
  ModalIcon,
  ModalSubHeaderContainer,
  ModalSubHeaderCharge,
  ModalSubHeaderTitle,
  ModalSubHeaderTitleCont,
  MobileRow,
  UnSelectedCheck,
  Table,
  SelectPlanBtn,
  CoveragesButton,
} from './PackagesStyledComponents';
import handleUpdateApplication from '../../../actions/handleUpdateApplication';
import { setEndorsementPackage } from '../../../actions/application';
import { selectEndorsements, selectEndorsementPackage } from '../../../selectors/application';
import { HeaderWithToolTipCont } from '../../../elements';
import Modal from '../../../elements/Modal';
import MoreDetailsTable from './MoreDetailsTable';
import ToolTipIcon from '../../Shared/ToolTipIcon';
import { coverages, transformCoveragesForDetails, packages } from './endorsementsData';
import { SectionHeader } from '../styles';
import { Desktop } from '../../../helpers/responsive';
import { useThemeContext } from '../../../elements/theme/CustomThemeProvider';
import toolTipContent from '../../../helpers/toolTipContent';
interface PackagesProps {
  selectedPackage?: string;
  setEndorsementPackage: (endorsement: string) => void;
  handleUpdateApplication: () => void;
}

const Packages = ({ selectedPackage, setEndorsementPackage, handleUpdateApplication }: PackagesProps) => {
  const { coveragePackageIcons } = useThemeContext();
  const [modalOption, setModalOption] = useState<string | null>(null);

  const toggleCoveragesModal = (option: string) => {
    setModalOption(option);
  };

  const closeCoveragesModal = () => {
    setModalOption(null);
  };

  const handleSelectPackage = (option: string) => {
    const selection = option === selectedPackage ? 'None' : option;
    setEndorsementPackage(selection);
    handleUpdateApplication();
  };

  type Coverage = {
    option: string;
    flat_premium_charge: string;
    description: string;
  };

  const getModalDetailsBar = (coverage: Coverage) => (
    <>
      <ModalSubHeaderContainer>
        <ModalSubHeaderTitleCont>
          <ModalIcon src={coveragePackageIcons[coverage.option]} alt={coverage.option} />
          <ModalSubHeaderTitle>{coverage.option} Coverage Package</ModalSubHeaderTitle>
        </ModalSubHeaderTitleCont>
        <ModalSubHeaderCharge>{coverage.flat_premium_charge}</ModalSubHeaderCharge>
      </ModalSubHeaderContainer>
      <Table>
        <thead>
          <tr>
            <th>COVERAGE</th>
            <th>LIMIT</th>
          </tr>
        </thead>
      </Table>
    </>
  );

  return (
    <PackagesCont data-cy="packages">
      <HeaderWithToolTipCont id="coveragePackages">
        <SectionHeader>Recommended Coverage Packages</SectionHeader>
        <ToolTipIcon
          content={toolTipContent['ENDORSEMENTS']}
          toolTipName="Endorsements"
          labelElementId="coveragePackages"
        />
      </HeaderWithToolTipCont>
      <Options>
        {packages.map((coverage) => (
          <Column key={coverage.option}>
            <PackageContainer active={selectedPackage === coverage.option}>
              {selectedPackage === coverage.option ? (
                <SelectedCheck
                  onClick={() => handleSelectPackage(coverage.option)}
                  aria-label={`Remove ${coverage.option} coverage from plan`}
                />
              ) : (
                <UnSelectedCheck
                  onClick={() => handleSelectPackage(coverage.option)}
                  aria-label={`Add ${coverage.option} coverage to plan`}
                />
              )}
              <MobileRow>
                <Icon src={coveragePackageIcons[coverage.option]} alt={coverage.option} />
                <div>
                  <Title>{coverage.option}</Title>
                  <Description>{coverage.description}</Description>
                </div>
              </MobileRow>
              <div>
                <SelectPlanBtn
                  onClick={() => handleSelectPackage(coverage.option)}
                  disabled={selectedPackage === coverage.option}
                  data-cy={coverage.option}
                  type="button"
                  aria-label={`Add ${coverage.option} coverage to plan`}
                  full
                >
                  {selectedPackage === coverage.option ? (
                    'Selected'
                  ) : (
                    <>
                      Select <Desktop>Plan</Desktop>
                    </>
                  )}
                </SelectPlanBtn>
                <CoveragesButton
                  onClick={() => toggleCoveragesModal(coverage.option)}
                  aria-label={`See ${coverage.option} coverages`}
                >
                  Coverages
                </CoveragesButton>
              </div>
            </PackageContainer>
            <Modal
              isOpen={modalOption === coverage.option}
              onRequestClose={closeCoveragesModal}
              label={`more details on ${coverage.option} endorsement modal`}
              title="Coverages"
              detailsBar={getModalDetailsBar(coverage)}
              options={{ noContainerPadding: true }}
            >
              <MoreDetailsTable rows={transformCoveragesForDetails(coverages, coverage.option)} hideTableHeader />
            </Modal>
          </Column>
        ))}
      </Options>
    </PackagesCont>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  endorsements: selectEndorsements(state),
  selectedPackage: selectEndorsementPackage(state),
});

const mapDispatchToProps = { setEndorsementPackage, handleUpdateApplication };

export default connect(mapStateToProps, mapDispatchToProps)(Packages);
