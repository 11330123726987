import { useMediaQuery } from 'react-responsive';

export const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1025 });
  return isDesktop ? children : null;
};

export const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};

export const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1024 });
  return isTablet ? children : null;
};

export const MobileAndTablet = ({ children }) => {
  const isMobileOrTablet = useMediaQuery({ maxWidth: 1024 });
  return isMobileOrTablet ? children : null;
};

export const DesktopAndTablet = ({ children }) => {
  const isDeskTopOrTablet = useMediaQuery({ minWidth: 768 });
  return isDeskTopOrTablet ? children : null;
};

export const DesktopOrMobile = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1025 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isDesktop || isMobile ? children : null;
};
