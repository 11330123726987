import CoterieLogoSVG from '../../assets/img/coterie_logo.svg';
import CresLogoSVG from '../../assets/img/cres_logo.svg';
import coterieTheme, { createCresTheme, createDefaultWhiteLabelTheme } from './';
import { EndorsementOptions as options } from '../../constants';
import silver_icon from '../../icons/Packages/silver.svg';
import gold_icon from '../../icons/Packages/gold.svg';
import platinum_icon from '../../icons/Packages/platinum.svg';
import white_label_silver_icon from '../../icons/Packages/white_label_silver.svg';
import white_label_gold_icon from '../../icons/Packages/white_label_gold.svg';
import white_label_platinum_icon from '../../icons/Packages/white_label_platinum.svg';
import { ThemeState } from './types';
import { white_label_partners } from '../../helpers/whitelabel';
interface ThemeDetails {
  [key: string]: ThemeState;
}

export const coterieIconSrc = {
  [options.SILVER]: silver_icon,
  [options.GOLD]: gold_icon,
  [options.PLATINUM]: platinum_icon,
};

export const whiteLabelIconSrc = {
  [options.SILVER]: white_label_silver_icon,
  [options.GOLD]: white_label_gold_icon,
  [options.PLATINUM]: white_label_platinum_icon,
};

export const themeDetails: ThemeDetails = {
  coterie: {
    theme: coterieTheme,
    shouldRenderIcon: true,
    shouldRenderHubSpotWidget: true,
    logo: {
      imgSrc: CoterieLogoSVG,
      altText: 'Coterie Logo',
      mobileHeight: '24px',
    },
    coveragePackageIcons: {
      ...coterieIconSrc,
    },
  },
  defaultWhiteLabel: {
    theme: createDefaultWhiteLabelTheme(),
    updateTheme: createDefaultWhiteLabelTheme,
    shouldRenderIcon: true,
    shouldRenderHubSpotWidget: false,
    logo: { imgSrc: '', height: '70px', mobileHeight: '50px', altText: 'Logo' },
    coveragePackageIcons: {
      ...whiteLabelIconSrc,
    },
  },
  [white_label_partners.CRES]: {
    theme: createCresTheme(),
    updateTheme: createCresTheme,
    shouldRenderIcon: false,
    shouldRenderHubSpotWidget: false,
    logo: { imgSrc: CresLogoSVG, altText: 'CRES Logo', height: '59px', mobileHeight: '32px' },
    coveragePackageIcons: {
      ...whiteLabelIconSrc,
    },
  },
};
