import React from 'react';
import ToolTipIcon from '../Shared/ToolTipIcon';
import styled from 'styled-components';
import { DesktopAndTablet, Mobile } from '../../helpers/responsive';
import toolTipContent from '../../helpers/toolTipContent';

interface CreateRowProps {
  coverage: string;
  coverageToolTip?: string;
  limit: string;
  deductible?: string;
  deductibleToolTip?: string;
}

const CreateRow = ({ coverage, coverageToolTip, limit, deductible, deductibleToolTip }: CreateRowProps) => (
  <tr key={coverage}>
    <td>
      <CellTitle>{coverage}</CellTitle>
      {coverageToolTip && (
        <DesktopAndTablet>
          <ToolTipIcon content={toolTipContent[coverageToolTip]} toolTipName={coverageToolTip} />
        </DesktopAndTablet>
      )}
    </td>
    <FlexCell>
      <CellTitle>{limit}</CellTitle>
      {coverageToolTip && (
        <Mobile>
          <ToolTipIcon content={toolTipContent[coverageToolTip]} toolTipName={coverage} />
        </Mobile>
      )}
    </FlexCell>
    {deductible && (
      <td>
        <CellTitle>{deductible}</CellTitle>
        {deductibleToolTip && <ToolTipIcon content={toolTipContent[deductibleToolTip]} toolTipName={coverage} />}
      </td>
    )}
  </tr>
);

const CellTitle = styled.span`
  margin-right: 8px;
`;

const FlexCell = styled.td`
  @media (max-width: 767px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export default CreateRow;
