import { endorsementsMap } from '../Components/AddOns/addOnQuestionMap';
import { isObjectLiteral } from './functionalHelpers';

const checkIfAddonsAreComplete = (
  availableEndorsements: AvailableEndorsementsState,
  endorsementsState?: EndorsementsInterface
) => {
  return (
    Boolean(availableEndorsements) &&
    Object.keys(availableEndorsements).reduce((accum: boolean, availableEndorsementKey: string) => {
      if (!accum) return false;
      if (endorsementsMap[availableEndorsementKey] === undefined) return true;

      const underwritingSubQuestions = endorsementsMap?.[availableEndorsementKey]?.subQuestions?.underwritingQuestions;
      const validationQuestions =
        endorsementsMap?.[availableEndorsementKey]?.subQuestions?.validation?.validationQuestions;
      const questionMarkedYes = isObjectLiteral(endorsementsState?.[availableEndorsementKey]);

      if (underwritingSubQuestions && questionMarkedYes) {
        return Object.keys(underwritingSubQuestions).reduce((accum: boolean, subQuestionKey: string) => {
          if (!accum) return false;
          return endorsementsState?.[availableEndorsementKey][subQuestionKey] != null;
        }, true);
      } else if (validationQuestions && questionMarkedYes) {
        return Boolean(Object.keys(endorsementsState?.[availableEndorsementKey]).length);
      } else {
        return true;
      }
    }, true)
  );
};

export default checkIfAddonsAreComplete;
