import React, { useState } from 'react';
import { connect } from 'react-redux';
import LocationInput from './LocationInput';
import styled from 'styled-components';
import { LabelLarge, TextButton } from '../../elements';
import { setExistingMailingAddress, addPotentialAddress } from '../../actions/application';
import { selectPolicyTypes, selectMailingAddressInfo, selectFirstLocation } from '../../selectors/application';
import { selectPotentialMailingAddresses } from '../../selectors/index';
import { PolicyType } from '../../types/enums';
import Checkbox from '../../elements/Checkbox';

interface PropTypes {
  applicationTypes: PolicyType[];
  locations: ApplicationLocation[];
  mailingAddressInfo: Address;
  addInsAddress?: Address;
  setAddInsAddress?: Function;
  setExistingMailingAddress: (address: Address) => void;
  addPotentialAddress: (address: Address) => void;
  firstLocation?: ApplicationLocation;
}

const AddressInput = ({
  applicationTypes,
  locations,
  mailingAddressInfo,
  addInsAddress,
  setAddInsAddress,
  setExistingMailingAddress,
  addPotentialAddress,
  firstLocation,
}: PropTypes) => {
  const [showInputBox, setShowInputBox] = useState(
    !applicationTypes.includes(PolicyType.BOP) && !locations[0]?.street ? true : false
  );

  if (applicationTypes.includes(PolicyType.BOP) && locations.length < 1 && firstLocation) {
    if (firstLocation.street && firstLocation.city && firstLocation.state && firstLocation.zip) {
      const newLocation = {
        street: firstLocation.street,
        city: firstLocation.city,
        state: firstLocation.state,
        zip: firstLocation.zip,
      };
      addPotentialAddress(newLocation);
    }
  }

  const toggleInputBox = () => {
    setShowInputBox(!showInputBox);
  };

  const addNewAddress = (address: Address) => {
    const { street, city, state, zip } = address;
    setShowInputBox((p) => {
      p = false;
      addPotentialAddress({ city, state, zip, street });
      return p;
    });
  };

  const setExistingAddress = (address: Address) => {
    if (address.street === mailingAddressInfo.street) {
      setExistingMailingAddress({
        city: '',
        state: '',
        zip: '',
        street: '',
      });
    } else {
      setExistingMailingAddress({
        city: address.city,
        state: address.state,
        zip: address.zip,
        street: address.street,
      });
    }
  };

  if (!locations.length && applicationTypes.includes(PolicyType.BOP)) return null;

  return (
    <React.Fragment>
      <LabelLarge>Mailing Address</LabelLarge>
      {showInputBox && (
        <ExistingAddressButton data-cy="select-existing-address" type="button" onClick={toggleInputBox}>
          Select existing address
        </ExistingAddressButton>
      )}
      {showInputBox ? (
        <LocationInput addLocation={addNewAddress} showInputBox={showInputBox} isMailingAddress />
      ) : (
        <React.Fragment>
          {locations.map(({ street, city, state, zip }) => {
            if (!street || !city || !state || !zip) return null;
            const selected = mailingAddressInfo.street === street;
            return (
              <QuestionCont key={street}>
                <Checkbox
                  onChange={() => {
                    setExistingAddress({
                      city: city,
                      state: state,
                      zip: zip,
                      street: street,
                    });
                  }}
                  selected={selected}
                  label={`${street} ${city}, ${state} ${zip}`}
                />
              </QuestionCont>
            );
          })}
        </React.Fragment>
      )}
      {!showInputBox && (
        <TextButton data-cy="enter-different-address" type="button" onClick={toggleInputBox}>
          Enter a different address
        </TextButton>
      )}
    </React.Fragment>
  );
};
interface OwnPropTypes {
  addInsAddress?: Address;
  setAddInsAddress?: Function;
}

const mapStateToProps = (state: ReduxState, ownProps: OwnPropTypes) => ({
  locations: selectPotentialMailingAddresses(state),
  mailingAddressInfo: ownProps.addInsAddress || selectMailingAddressInfo(state),
  applicationTypes: selectPolicyTypes(state),
  firstLocation: selectFirstLocation(state),
});

const mapDispatchToProps = (dispatch: Function, ownProps: OwnPropTypes) => {
  return {
    addPotentialAddress: (address: Address) => dispatch(addPotentialAddress(address)),
    setExistingMailingAddress: (address: Address) =>
      ownProps.setAddInsAddress ? ownProps.setAddInsAddress(address) : dispatch(setExistingMailingAddress(address)),
  };
};

const QuestionCont = styled.div`
  width: 100%;
  display: flex;
  align-content: flex-start;
  justify-content: space-between;
  padding: 10px 0;
  margin-bottom: 16px;
  :first-child {
    padding: 10px 0;
  }
`;

const ExistingAddressButton = styled(TextButton)`
  margin-top: 8px;
  margin-bottom: 8px;
`;

export default connect(mapStateToProps, mapDispatchToProps)(AddressInput);
