export const formatString = (value?: number, days: boolean = false) => {
  if (value === 0 && days === true) {
    return '60 days (included)'; // shown for periodLoading for PL also known as Extended Reporting Period
  } else if (value === 0 && days === false) {
    return 'None';
  } else if (value === 1) {
    return '1 year';
  } else {
    return `${value} years`;
  }
};

export const formatNumberLong = (value?: number) => value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || '';

export const formatNumber = (amount: any) => {
  try {
    return parseInt(amount);
  } catch {
    return amount;
  }
};

export const formatPriceToNumber = (value: string = '') => Number(value.replace(/[^0-9.]/g, ''));

export const formatCurrency = (value: number | undefined) => {
  if (value === undefined) return '';
  if (value % 1 === 0) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  } else
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(value);
};
