import React from 'react';
import styled, { css, StyledComponent } from 'styled-components';
import { TextProps } from '.';
import { NumericFormat, NumericFormatProps, PatternFormat } from 'react-number-format';
import { TextSharedStyles } from './text';
import ToolTipIcon from '../Components/Shared/ToolTipIcon';
import { Mobile } from '../helpers/responsive';
import toolTipContent from '../helpers/toolTipContent';

export const inputStyles = css`
  appearance: none;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.formElements.input.background};
  color: ${({ theme }) => theme.formElements.input.textColor};
  font-family: ${({ theme }) => theme.font.typeface.secondary};
  font-size: ${({ theme }) => theme.formElements.input.fontSize};
  letter-spacing: 0.2px;
  line-height: 15px;
  border: ${({ theme, hasError }: FormProps) =>
    hasError ? theme.formElements.input.borderOnError : theme.formElements.input.border};
  padding: 15px 16px;
  transition: all 0.1s;
  max-width: 600px;
  box-sizing: border-box;

  width: ${(props: FormProps) => {
    switch (props.customWidth) {
      case 'full':
        return '100%';
      case 'half':
        return '50%';
      case 'halfWithMargin':
        return '47%';
      case 'third':
        return '33%';
      case 'quarter':
        return '192px';
      default:
        return '100%';
    }
  }};

  margin: ${(props: FormProps) => {
    switch (props.marginBottom) {
      case 'smallest':
        return '8px 0 16px';
      case 'smaller':
        return '8px 0 24px';
      case 'larger':
        return '8px 0 48px';
      case 'none':
        return '8px 0 0';
      default:
        return '8px 0 32px';
    }
  }};

  :placeholder {
    color: ${(props) => props.theme.formElements.input.placeholderTextColor};
  }

  :focus {
    box-shadow: ${({ theme }) => theme.formElements.input.boxShadowOnFocus};
    outline: ${({ theme }) => theme.formElements.input.outlineOnFocus} !important;
  }

  @media (max-width: 767px) {
    width: 100%;
  }
`;

export interface FormProps extends TextProps {
  customWidth?: string;
  hasError?: boolean;
  children?: any;
  display?: string;
  isModal?: boolean;
  theme: ThemeInterface;
  center?: boolean;
  marginBottom?: string;
  name?: string;
}

export const Input: StyledComponent<'input', any, FormProps, never> = styled.input`
  ${inputStyles}
`;

interface NumberInputProps extends NumericFormatProps {
  customWidth: string;
}

export const NumberInput = styled(NumericFormat)<NumberInputProps & FormProps>`
  ${inputStyles}
  display: block;
`;
export const PatternInput = styled(PatternFormat)<FormProps>`
  ${inputStyles}
  display: block;
`;

export const LabelLarge = styled.label<FormProps>`
  ${TextSharedStyles}
  margin-right: 8px;
  margin-bottom: 8px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 10px 20px 20px;
  @media (min-width: 767px) {
    flex-direction: ${(isModal: FormProps) => (isModal ? 'column' : 'row')};
  }
`;

export const InputLabel = styled(LabelLarge)<{ size?: string }>`
  font-size: ${({ size }) => {
    switch (size) {
      case 'xl':
        return '20px';
      default:
        return '1rem';
    }
  }};
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputWithTooltipCont = styled.div<{ marginSmall: boolean }>`
  display: flex;
  align-items: center;
  margin-bottom: ${(props) => (props.marginSmall ? `16px` : `32px`)};
  position: relative;
  input {
    margin-bottom: 0;
    padding-right: 45px;
  }
`;

const inputToolTipButton = css`
  position: absolute;
  right: 10px;
  top: 15px;
`;

interface InputWithToolTipProps {
  children: React.ReactNode;
  toolTipContentKey: string;
  toolTipName: string;
  labelElementId: string;
  marginSmall?: boolean;
}

export const InputWithToolTip = ({
  children,
  toolTipContentKey,
  toolTipName,
  labelElementId,
  marginSmall = false,
}: InputWithToolTipProps) => {
  return (
    <InputWithTooltipCont marginSmall={marginSmall}>
      {children}
      <Mobile>
        <ToolTipIcon
          content={toolTipContent[toolTipContentKey]}
          css={inputToolTipButton}
          toolTipName={toolTipName}
          labelElementId={labelElementId}
          alignToLabel
        />
      </Mobile>
    </InputWithTooltipCont>
  );
};

export const LabelWithToolTipCont = styled.div`
  @media (max-width: 767px) {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 10px;
    label {
      width: 85%;
      margin-top: 0px;
    }
  }
`;

export const HeaderWithToolTipCont = styled.div`
  display: flex;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 20px;
  h2 {
    margin: 0 8px 0 0;
  }
  @media (max-width: 767px) {
    justify-content: space-between;
  }
`;
