import { Dictionary, isArray } from 'lodash';

export const pipe = (...fns: Array<Function>) => (x: any) => fns.reduce((v: any, f: Function) => f(v), x);

export const compose = (...fns: Array<Function>) => (x: any) => fns.reduceRight((v: any, f: Function) => f(v), x);

export const isObjectLiteral = (obj: any) =>
  Boolean(typeof obj === 'object' && obj !== null && typeof Object.keys(obj).length === 'number' && !isArray(obj));

export const formatKeysFromPascalCaseToCamelCase = (obj: Dictionary<any>): Object => {
  return Object.keys(obj).reduce((accum: Dictionary<any>, key) => {
    const value = obj[key];
    const formattedKey = key.charAt(0).toLowerCase() + key.slice(1);
    accum[formattedKey] = isObjectLiteral(value) ? formatKeysFromPascalCaseToCamelCase(value) : value;

    return accum;
  }, {});
};
