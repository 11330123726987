import { SET_ZIP_STATE_VERIFIED } from '../actions/businessInfo';

export const initialState = {
  isZipStateVerified: false,
};

export function businessInfo(state = initialState, action: Action) {
  switch (action.type) {
    case SET_ZIP_STATE_VERIFIED:
      return Object.assign({}, state, { isZipStateVerified: action.data });
    default:
      return state;
  }
}
