import React from 'react';
import { useThemeContext } from '../../../elements/theme/CustomThemeProvider';

interface SuccessCheckmarkProps {
  width: string;
  height: string;
}
const SuccessCheckmark = ({ width, height }: SuccessCheckmarkProps) => {
  const { theme } = useThemeContext();
  return (
    <svg
      data-testid="successCheckmark"
      aria-hidden="true"
      width={width}
      height={height}
      viewBox="0 0 26 26"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>8CA637E7-D45C-4D2A-A06A-7DB7390F2316</title>
      <g id="May-2020" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Style-Guide-Rebrand-Update" transform="translate(-1141.000000, -1053.000000)">
          <g id="Group-4" transform="translate(987.000000, 1054.000000)">
            <g id="Group-11" transform="translate(155.000000, 0.000000)">
              <circle
                id="Oval"
                stroke={theme.icons.successCheckmark.strokeColor}
                fill={theme.icons.successCheckmark.fillColor}
                fill-rule="nonzero"
                cx="12"
                cy="12"
                r="12"
              ></circle>
              <path
                d="M17.043375,8.606625 C17.455725,8.156925 18.111975,8.813175 17.699625,9.262875 C15.4123125,11.8498125 13.105875,14.4 10.8001875,16.96875 C10.687395,17.0998538 10.5753375,17.21265 10.4625375,17.34375 C10.29408,17.53125 9.9747375,17.5122075 9.8062875,17.34375 C8.6439375,16.2378 7.4816625,15.112875 6.3184125,14.0060625 C5.8870125,13.5937125 6.5432625,12.9184125 6.9746625,13.3498125 C8.0249625,14.3437125 9.0562875,15.3376875 10.105725,16.3498125 C12.4311,13.762875 14.7375375,11.1935625 17.043225,8.606625 L17.043375,8.606625 Z"
                id="Fill-1"
                fill="#0D0D46"
              ></path>
              <path
                d="M17.043375,7.95084905 C17.455725,7.50114905 18.111975,8.15739905 17.699625,8.60709905 C15.4123125,11.1940366 13.105875,13.7442241 10.8001875,16.3129741 C10.687395,16.4440778 10.5753375,16.5568741 10.4625375,16.6879741 C10.29408,16.8754741 9.9747375,16.8564316 9.8062875,16.6879741 C8.6439375,15.5820241 7.4816625,14.4570991 6.3184125,13.3502866 C5.8870125,12.9379366 6.5432625,12.2626366 6.9746625,12.6940366 C8.0249625,13.6879366 9.0562875,14.6819116 10.105725,15.6940366 C12.4311,13.1070991 14.7375375,10.5377866 17.043225,7.95084905 L17.043375,7.95084905 Z"
                id="Stroke-2"
                stroke="#0D0D46"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SuccessCheckmark;
