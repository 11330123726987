import React from 'react';
import styled, { keyframes } from 'styled-components';

export const FormSection = styled.div`
  opacity: ${(props: FormSectionProps) => (props.available ? 1 : 0.25)};
  @media (min-width: 1025px) {
    min-height: ${(props: FormSectionProps) => (props.smaller ? '110px' : '120px')};
  }
  @media (max-width: 1024px) {
    margin-bottom: 32px;
  }

  :hover {
    cursor: ${(props: FormSectionProps) => (props.available ? 'default' : 'not-allowed')};
  }
`;

interface FormSectionProps {
  available?: boolean;
  smaller?: boolean;
}

export const Container = styled.div`
  max-width: 600px;
  display: flex;
  flex-direction: column;
  margin: 0 auto 0 auto;
  box-sizing: border-box;
  @media (min-width: 1025px) {
    margin: 0;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    max-width: calc(100vw - 290px);
    width: 100%;
    padding: 0 40px;
  }
  @media (max-width: 767px) {
    width: 80%;
  }
`;

const LoadingContainer = styled.div`
  max-width: 100%;
`;

export const ContainerRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;

const LoadingDualRing = styled.div`
  display: inline-block;
  max-width: 64px;
  height: 34px;

  :after {
    content: ' ';
    display: block;
    width: 24px;
    height: 24px;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid ${({ theme }) => theme.pageComponents.elements.containers.border};
    border-color: ${({ theme }) => theme.pageComponents.elements.containers.borderColor};
    animation: ${spin} 1.2s linear infinite;
  }
`;

export const LoadingSpinner = () => (
  <LoadingContainer>
    <LoadingDualRing />
  </LoadingContainer>
);

export const ToolTipHeader = styled.div`
  display: flex;
  align-items: center;
`;

export const LocationQuestionContainer = styled.div`
  width: 75%;
  @media (min-width: 768px) and (max-width: 1024px) {
    &:first-child {
      margin-right: 20px;
    }
  }
`;

export const InputCont = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (min-width: 1025px) {
    flex-direction: row;
    column-gap: 8px;
  }
`;

export const InputBox = styled.div`
  flex: 1;
  max-width: 292px;
  width: 100%;
`;
