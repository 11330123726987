import React from 'react';
import ProfessionalLiabilityQuestions from '../Components/Shared/ProfessionalLiability';
import { Container } from '../elements';

const ProfessionalLiabilityPage = () => {
  return (
    <Container>
      <ProfessionalLiabilityQuestions />
    </Container>
  );
};

export default ProfessionalLiabilityPage;
