import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Link from 'redux-first-router-link';
import styled, { keyframes, css } from 'styled-components';
import { selectLocationType } from '../../selectors/index';
import { selectCompletedPages, pagesWithUserInfo } from '../../selectors/routing';
import Logo from '../WhiteLabel/Components/Logo';
import FinePrint from './FinePrint';
import { DesktopAndTablet, Mobile } from '../../helpers/responsive';
import { selectAvailablePages } from '../../selectors/progress';
import { getAvailableEndorsements } from '../../selectors/availableEndorsements';
import { PAGES } from '../../constants';

export const ApplicationProgress = ({
  completedPages,
  pagesWithInfo,
  location,
  availablePages,
  shouldShowQuote,
  forceReRender,
}) => {
  const formatPageTitle = (page) => page.replace('_', ' ').toLowerCase();

  const appProgress = useMemo(() => {
    return availablePages.map((page, index) => {
      if (completedPages.includes(page)) {
        if (location === page) {
          return (
            <LinkCont key={page}>
              <DesktopAndTablet>
                <BarDotCont>
                  <Circle active={true} />
                  {index !== availablePages.length - 1 && <Connector active={true} />}
                </BarDotCont>
              </DesktopAndTablet>
              <LinkRow>
                <ActiveLink className="activeLink">
                  <span>{formatPageTitle(page)}</span>
                </ActiveLink>
                {pagesWithInfo.includes(page)}
              </LinkRow>
              <Mobile>
                <BarDotCont>
                  <Circle active={true} />
                  {index !== availablePages.length - 1 && <Connector active={true} />}
                </BarDotCont>
              </Mobile>
            </LinkCont>
          );
        } else {
          return (
            <LinkCont key={page}>
              <DesktopAndTablet>
                <BarDotCont>
                  <Circle completed={true} />
                  {index !== availablePages.length - 1 && <Connector completed={true} />}
                </BarDotCont>
              </DesktopAndTablet>
              <LinkRow>
                <React.Fragment>
                  <StyledLink to={{ type: page }} id={page} className="availableLink" data-cy={`sidebar-to-${page}`}>
                    {formatPageTitle(page)}
                  </StyledLink>
                  <React.Fragment>{pagesWithInfo.includes(page)}</React.Fragment>
                </React.Fragment>
              </LinkRow>
              <Mobile>
                <BarDotCont>
                  <Circle completed={true} />
                  {index !== availablePages.length - 1 && <Connector completed={true} />}
                </BarDotCont>
              </Mobile>
            </LinkCont>
          );
        }
      } else {
        return (
          <LinkCont key={page}>
            <DesktopAndTablet>
              <BarDotCont>
                <Circle />
                {index !== availablePages.length - 1 && <Connector />}
              </BarDotCont>
            </DesktopAndTablet>
            <LinkRow>
              <UnavailLink>{formatPageTitle(page)}</UnavailLink>
            </LinkRow>
            <Mobile>
              <BarDotCont>
                <Circle />
                {index !== availablePages.length - 1 && <Connector />}
              </BarDotCont>
            </Mobile>
          </LinkCont>
        );
      }
    });
    /**
     * * We don't want to add all deps to the array because we don't want this to re-render unless
     * * the location changes, or the user loads the app on the summary page and the available endorsements
     * * async request resolves with options
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, forceReRender]);

  return (
    <Container data-cy="app-progress" shouldShowQuote={shouldShowQuote}>
      <DesktopAndTablet>
        <Logo />
      </DesktopAndTablet>
      <FlexContainer>
        <TextCont>{completedPages && appProgress}</TextCont>
        <DesktopAndTablet>
          <FinePrint />
        </DesktopAndTablet>
      </FlexContainer>
    </Container>
  );
};

ApplicationProgress.propTypes = {
  location: PropTypes.string,
  pages: PropTypes.arrayOf(PropTypes.string),
  pagesWithInfo: PropTypes.arrayOf(PropTypes.string),
  shouldShowQuote: PropTypes.bool,
};

const shouldForceReRender = (state) => {
  const location = selectLocationType(state);
  const hasEndorsements = Object.keys(getAvailableEndorsements(state) || {}).length;

  return location === PAGES.SUMMARY ? hasEndorsements : null;
};

const mapStateToProps = (state) => ({
  completedPages: selectCompletedPages(state),
  pagesWithInfo: pagesWithUserInfo(state),
  location: selectLocationType(state),
  availablePages: selectAvailablePages(state),
  forceReRender: shouldForceReRender(state),
});

const progressbarKeyframes = keyframes`
0% {
  opacity: 0;
}
50% {
  opacity:0;
}
100% {
  opacity:1;
}
`;

const Container = styled.div`
  animation-name: ${progressbarKeyframes};
  top: 0;
  background-color: ${({ theme }) => theme.pageComponents.applicationProgress.container.background};
  transition: all 0.2s;
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    position: fixed;
    height: ${({ theme }) => `calc(100vh - ${theme.pageComponents.globalComponents.footerContainer.height})`};
    align-items: center;
  }
  @media (min-width: 1025px) {
    width: 320px;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    width: 290px;
  }
  @media (max-width: 767px) {
    overflow: auto;
    max-height: calc(100vh - ${({ shouldShowQuote }) => (shouldShowQuote ? '210px' : '174px')});
  }
`;

const TextCont = styled.div`
  @media (min-width: 768px) {
    min-width: 160px;
    max-width: 240px;
    flex: 1;
  }
`;
const StyledLink = styled(Link)`
  background-color: ${(props) => (props.active === true ? 'green' : 'transparent')};
  color: ${(props) => props.theme.pageComponents.applicationProgress.styledLink.textColor} !important;
  text-decoration: none;
  font-family: ${(props) => props.theme.font.typeface.primary};
  margin: 0;
  padding: 0;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 15px;
  text-transform: capitalize;
  :hover {
    cursor: pointer;
  }
`;

const UnavailLink = styled.p`
  font-family: ${(props) => props.theme.font.typeface.primary};
  margin: 0;
  color: ${(props) => props.theme.pageComponents.applicationProgress.unavailLink.textColor};
  display: inline;
  font-weight: 600;
  font-size: 13px;
  margin: 0;
  letter-spacing: 1px;
  line-height: 15px;
  text-transform: capitalize;
`;

const ActiveLink = styled(UnavailLink)`
  font-family: ${(props) => props.theme.font.typeface.primary};
  color: ${(props) => props.theme.pageComponents.applicationProgress.activeLink.textColor};
  opacity: 1;
  text-transform: capitalize;
  align-self: flex-start;

  @media (min-width: 769px) {
    border-bottom: ${(props) => props.theme.pageComponents.applicationProgress.activeLink.borderBottom};
  }
`;

const LinkRow = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 8px 0 15.5px;
  padding-bottom: 20px;
`;

const LinkCont = styled.div`
  display: flex;
  min-height: 40px;
  justify-content: flex-start;
  @media (max-width: 767px) {
    justify-content: flex-end;
  }
`;

const BarDotCont = styled.div`
  display: flex;
  flex-direction: column;
`;

const Connector = styled.div`
  flex: 1;
  margin: 0 0 -3px 6px;
  border-left: ${(props) => props.theme.pageComponents.applicationProgress.connector.borderLeft};

  ${(props) =>
    props.completed &&
    css`
      border-left: ${(props) => props.theme.pageComponents.applicationProgress.connector.borderLeftOnActive};
    `}

  ${(props) =>
    props.active &&
    css`
      border-left: ${(props) => props.theme.pageComponents.applicationProgress.connector.borderLeftOnActive};
    `}
`;

const Circle = styled.div`
  border-radius: 20px;
  border: ${(props) => props.theme.pageComponents.applicationProgress.circle.border};
  background-color: ${(props) => props.theme.pageComponents.globals.background};
  width: 8px;
  height: 8px;
  margin-top: 1px;
  margin-left: 2px;
  padding: 0;
  background-clip: content-box;
  position: relative;

  ${(props) =>
    props.active &&
    css`
      border: ${props.theme.pageComponents.applicationProgress.circle.borderOnActive};
      background-color: ${(props) => props.theme.pageComponents.applicationProgress.circle.backgroundOnActive};
    `}

  ${(props) =>
    props.completed &&
    css`
      border: ${props.theme.pageComponents.applicationProgress.circle.borderOnActive};
      background-color: ${(props) => props.theme.pageComponents.applicationProgress.circle.backgroundOnActive};
    `}
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  padding-bottom: 24px;
`;

export default connect(mapStateToProps)(ApplicationProgress);
