import React, { FunctionComponent, useEffect } from 'react';
import { connect } from 'react-redux';
import ToolTipIcon from '../Components/Shared/ToolTipIcon';
import { fatalError, setAnnualPayroll, setGrossAnnualSales } from '../actions/application';
import handleFormSubmit from '../actions/handleFormSubmit';
import { selectAnnualPayroll, selectGrossAnnualSales, selectPolicyTypes } from '../selectors/application';
import { Container, LabelLarge, FormSection, NumberInput, InputWithToolTip } from '../elements';
import GhostButton from '../Components/Shared/GhostButton';
import { DesktopAndTablet } from '../helpers/responsive';
import toolTipContent from '../helpers/toolTipContent';
import { NumberFormatValues } from 'react-number-format';

interface FinancePropTypes {
  grossAnnualSales?: number;
  annualPayroll?: number;
  handleFormSubmit: () => void;
  fatalError: any;
  setGrossAnnualSales: (sales: number) => void;
  setAnnualPayroll: (payroll: number) => void;
}

const FinanceBasics: FunctionComponent<FinancePropTypes> = ({
  grossAnnualSales,
  annualPayroll,
  handleFormSubmit,
  setGrossAnnualSales,
  setAnnualPayroll,
}) => {
  useEffect(() => {
    document.title = 'Coterie - Finance Basics';
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    handleFormSubmit();
  };

  const onSalesChanged = (values: NumberFormatValues) => {
    if (values.floatValue) {
      setGrossAnnualSales(values.floatValue);
    }
  };

  const onPayrollChanged = (values: NumberFormatValues) => {
    if (values.floatValue) {
      setAnnualPayroll(values.floatValue);
    }
  };

  return (
    <Container>
      <form onSubmit={handleSubmit}>
        <FormSection available={true}>
          <LabelLarge display="inline">Annually, my business earns (before taxes)</LabelLarge>
          <DesktopAndTablet>
            <ToolTipIcon content={toolTipContent['GROSS_ANNUAL_SALES']} toolTipName="Gross_Annual_Sales" />
          </DesktopAndTablet>
          <InputWithToolTip
            toolTipContentKey="GROSS_ANNUAL_SALES"
            toolTipName="Gross_Annual_Sales"
            labelElementId="annualSalesInput"
          >
            <NumberInput
              value={grossAnnualSales}
              onValueChange={onSalesChanged}
              customWidth="quarter"
              thousandSeparator={true}
              prefix={'$'}
              data-cy={'sales'}
              aria-label="Annual Sales"
              placeholder="e.g., $500,000"
              id="annualSalesInput"
            />
          </InputWithToolTip>
        </FormSection>
        <FormSection available={Boolean(grossAnnualSales)}>
          <LabelLarge display="inline">Annually, I pay myself and my employees a total of</LabelLarge>
          <DesktopAndTablet>
            <ToolTipIcon content={toolTipContent['ANNUAL_PAYROLL']} toolTipName="Annual_Payroll" />
          </DesktopAndTablet>
          <InputWithToolTip
            toolTipContentKey="ANNUAL_PAYROLL"
            toolTipName="Annual_Payroll"
            labelElementId="payrollInput"
          >
            <NumberInput
              value={annualPayroll}
              onValueChange={onPayrollChanged}
              customWidth="quarter"
              thousandSeparator={true}
              prefix={'$'}
              data-cy={'payroll'}
              aria-label="Annual Employee Salaries"
              disabled={!Boolean(grossAnnualSales)}
              placeholder="e.g., $500,000"
              id="payrollInput"
            />
          </InputWithToolTip>
        </FormSection>
        <GhostButton />
      </form>
    </Container>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  annualPayroll: selectAnnualPayroll(state),
  grossAnnualSales: selectGrossAnnualSales(state),
  applicationTypes: selectPolicyTypes(state),
});

const mapDispatchToProps = {
  fatalError: fatalError,
  handleFormSubmit,
  setAnnualPayroll,
  setGrossAnnualSales,
};

export default connect(mapStateToProps, mapDispatchToProps)(FinanceBasics);
