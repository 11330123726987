import { combineReducers } from 'redux';
import {
  application,
  applicationIsLoading,
  fatalError,
  availablePolicyTypes,
  potentialMailingAddresses,
  partner,
  industryName,
  acknowledgements,
  partnerFromApplication,
  existingApplicationLoaded,
  userHas,
  dbaIsValid,
} from './application';

import {
  quote,
  purchasedPolicy,
  quoteBindPending,
  reasonabilityErrors,
  policyPurchaseErrors,
  paymentInterval,
  quoteIsLoading,
  declinations,
} from './quote';
import dropin from './dropIn';
import { paymentTransaction } from './payment';
import availableEndorsements from './availableEndorsements';
import { connectRoutes } from 'redux-first-router';
import { routePaths } from '../routing/routes';
import { agent } from './agent';
import { referralPartner } from './referralPartner';
import { businessInfo } from './businessInfo';

const { reducer: location } = connectRoutes(routePaths);

export default combineReducers({
  location,
  application,
  agent,
  applicationIsLoading,
  fatalError,
  quote,
  availablePolicyTypes,
  purchasedPolicy,
  quoteBindPending,
  potentialMailingAddresses,
  partner,
  reasonabilityErrors,
  declinations,
  industryName,
  policyPurchaseErrors,
  acknowledgements,
  dropin,
  paymentInterval,
  partnerFromApplication,
  referralPartner,
  quoteIsLoading,
  existingApplicationLoaded,
  userHas,
  paymentTransaction,
  availableEndorsements,
  dbaIsValid,
  businessInfo,
});
