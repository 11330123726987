import { isEmpty } from 'lodash';
import { useReducer, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { selectEndorsements, selectGLLimit, selectLimitDamagePremRented } from '../../../selectors/application';
import { endorsementsMap } from '../../AddOns/addOnQuestionMap';
import AddOnsSummaryCard from './AddOnSummaryCard';
import { SectionHeader, SectionDescription } from '../styles';
import { EndorsementEnum } from '../../../types/enums';

interface AddOnSummaryProps {
  endorsements: EndorsementsInterface;
  glLimit?: number;
  limitDamagePremRented?: number;
}

const AddOnsSummary = ({ endorsements, glLimit, limitDamagePremRented }: AddOnSummaryProps) => {
  const [cachedEndorsements] = useReducer((state: any, action: any) => (action != null ? action : state), endorsements);
  const cachedEndorsementKeys = Object.keys(cachedEndorsements);
  const limitDamagePremRentedRef = useRef(limitDamagePremRented);

  useEffect(() => {
    if (glLimit === 2_000_000) {
      delete endorsements[EndorsementEnum.liquorLiabilityEndorsement];
      delete cachedEndorsements[EndorsementEnum.liquorLiabilityEndorsement];
    }
  }, [glLimit]);

  const shouldRenderAddOns =
    !isEmpty(cachedEndorsements) &&
    cachedEndorsementKeys.reduce((accum: boolean, addedEndorsement: string) => {
      if (accum) return true;

      return Boolean(endorsementsMap[addedEndorsement] && cachedEndorsements[addedEndorsement]);
    }, false);

  return shouldRenderAddOns || limitDamagePremRented ? (
    <>
      <SectionHeader>Your Add Ons</SectionHeader>
      <SectionDescription>You’ve added these coverages on top of your existing policy</SectionDescription>
      {cachedEndorsementKeys.map((endorsement: string) =>
        endorsementsMap[endorsement] && cachedEndorsements[endorsement] ? (
          <AddOnsSummaryCard key={endorsement} endorsement={endorsement} value={cachedEndorsements[endorsement]} />
        ) : null
      )}
      {limitDamagePremRentedRef.current && (
        <AddOnsSummaryCard endorsement="limitDamagePremRented" value={limitDamagePremRentedRef.current} />
      )}
    </>
  ) : null;
};

const mapStateToProps = (state: ReduxState) => ({
  endorsements: selectEndorsements(state) || { additionalInsureds: [] },
  glLimit: selectGLLimit(state),
  limitDamagePremRented: selectLimitDamagePremRented(state),
});

export default connect(mapStateToProps)(AddOnsSummary);
