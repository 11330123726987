import edit_icon from '../../icons/edit_icon_black.svg';
import trash_can from '../../icons/trash_can_black.svg';
import check_mark from '../../icons/add_on_checkmark.svg';
import caret from '../../icons/caret_black.svg';
import calendar from '../../icons/calendar_black.svg';
import phone from '../../icons/phone_black.svg';
import { CustomColors } from './types';

const createDefaultWhiteLabelTheme = (customColors?: CustomColors) => {
  const colors = {
    primary: {
      zero: '',
      twentyFive: '',
    },
    secondary: {
      zero: '',
    },
    neutral: {
      one: '#808080',
      two: '#BFBFBF',
      three: '#F2F2F2',
      black: '#000000',
      white: '#FFFFFF',
    },
    alert: {
      zero: '#FF0000',
      fifteen: '#FFD8D8',
    },
    ...customColors,
  };

  const typefaces = {
    gilroy: 'Roboto',
    questrial: 'Roboto',
  };

  const fontColors = {
    primary: colors.neutral.black,
    secondary: colors.secondary.zero,
    tertiary: colors.neutral.one,
    quaternary: colors.neutral.black,
    darkThemePrimary: colors.neutral.white,
    subdued: colors.neutral.black,
    subduedDark: colors.neutral.black,
    error: colors.alert.zero,
    link: colors.neutral.black,
  };

  const fontSizes = {
    extraLarge: '30px',
    large: '24px',
    medium: '20px',
    small: '16px',
    extraSmall: '13px',
    labelLarge: '16px',
    labelSmall: '12px',
    labelError: '12px',
    bodyLarge: '18px',
    bodyRegular: '15px',
    bodySmall: '13px',
  };

  const font = {
    typeface: {
      primary: typefaces.gilroy,
      secondary: typefaces.questrial,
    },
  };

  const buttonSizes = {
    height: {
      large: '46px', // subtracted 2px from style-guide to account for border
      small: '30px',
    },
    width: {
      max: '232px',
      min: '120px',
      small: {
        max: '132px',
        min: '80px',
      },
    },
  };

  const buttons = {
    solid: {
      textColor: colors.neutral.black,
      textColorOnHover: colors.neutral.black,
      fontSize: fontSizes.bodyRegular,
      fontSizeSmall: fontSizes.extraSmall,
      background: colors.primary.zero,
      backgroundOnHover: colors.primary.twentyFive,
      borderOnFocus: `1px dashed ${colors.primary.zero}`,
      darkTheme: {
        borderOnFocus: `1px dashed ${colors.primary.zero}`,
      },
      ...buttonSizes,
    },
    ghost: {
      textColor: colors.neutral.black,
      background: colors.neutral.white,
      backgroundOnHover: colors.primary.zero,
      border: `1px solid ${colors.neutral.two}`,
      borderOnFocus: colors.primary.zero,
      darkTheme: {
        textColor: colors.neutral.white,
        textColorOnDisabled: colors.neutral.three,
        background: colors.neutral.black,
        backgroundOnHover: colors.primary.zero,
        border: `1px solid ${colors.neutral.white}`,
        borderOnFocus: colors.primary.zero,
      },
      ...buttonSizes,
    },
    disabled: {
      textColor: colors.neutral.three,
      background: colors.neutral.one,
      darkTheme: {
        textColor: colors.neutral.three,
        opacity: 1,
        background: colors.neutral.one,
      },
      ...buttonSizes,
    },
    warning: {
      textColor: colors.neutral.white,
      background: colors.alert.zero,
      backgroundOnFocus: colors.alert.fifteen,
      borderOnFocus: `2px dotted ${colors.alert.zero}`,
      ...buttonSizes,
    },
    textOnly: {
      textColor: colors.secondary.zero,
      borderOnHover: `2px dotted ${colors.secondary.zero}`,
      fontSize: fontSizes.bodyRegular,
      darkTheme: {
        textColor: colors.primary.zero,
        borderOnHover: `2px dotted ${colors.primary.zero}`,
      },
    },
  };

  const formElements = {
    input: {
      textColor: fontColors.primary,
      placeholderTextColor: colors.neutral.one,
      background: colors.neutral.white,
      backgroundDisabled: colors.neutral.one,
      border: `1px solid ${colors.neutral.one}`,
      outlineOnFocus: `1px solid ${colors.primary.zero}`,
      borderOnHover: colors.neutral.one,
      borderOnError: `1px solid ${colors.alert.zero}`,
      boxShadowOnFocus: `0 0 0 1px ${colors.primary.zero}`,
      subTextColor: fontColors.primary,
      errorTextColor: colors.alert.zero,
      fontSize: fontSizes.bodyRegular,
      fontSizeSmall: fontSizes.bodySmall,
      height: '48px',
      heightSmall: '32px',
    },
    label: {
      textColor: colors.neutral.black,
      size: {
        large: {
          fontSize: fontSizes.labelLarge,
        },
        small: {
          fontSize: fontSizes.labelSmall,
        },
      },
    },
    dropdown: {
      background: colors.neutral.white,
      backgroundSelected: colors.primary.twentyFive,
      linkTextColor: fontColors.secondary,
      dropdownIndicator: colors.primary.zero,
    },
    checkbox: {
      backgroundUnselected: colors.neutral.white,
      backgroundSelected: colors.primary.zero,
      borderUnselected: `1px solid ${colors.neutral.one}`,
      borderSelected: `1px solid ${colors.primary.zero}`,
    },
    radioButton: {
      backgroundSelected: colors.primary.zero,
      backgroundUnselected: colors.neutral.white,
      backgroundOnHover: colors.neutral.two,
      labelColor: colors.neutral.black,
    },
    switch: {
      background: colors.neutral.white,
      backgroundAlt: colors.neutral.white,
      selected: colors.primary.zero,
      unselected: colors.neutral.one,
      boxShadowOnActive: `0 0 2px 3px ${colors.primary.zero}`,
    },
    visualCheckbox: {
      background: colors.neutral.three,
      backgroundWhite: colors.neutral.white,
      backgroundActive: colors.primary.zero,
      border: colors.neutral.two,
      borderOnActive: `1px solid ${colors.primary.zero}`,
      expandedSection: {
        background: colors.neutral.white,
      },
      textLinkColor: colors.neutral.black,
      vertical: {
        background: colors.neutral.three,
        borderOnActive: `1px solid ${colors.primary.zero}`,
        darkTheme: {
          background: colors.neutral.white,
          borderOnActive: `1px solid ${colors.primary.zero}`,
        },
      },
    },
    visualSwitchbox: {
      background: colors.neutral.three,
      backgroundOnActive: colors.primary.twentyFive,
      border: `1px solid ${colors.neutral.three}`,
      borderOnFocus: `1px solid ${colors.primary.zero}`,
      borderOnActive: `1px solid ${colors.primary.zero}`,
      expandedSection: {
        background: colors.neutral.white,
        border: colors.primary.zero,
      },
    },
    detailCards: {
      background: colors.neutral.three,
    },
  };

  const selectors = {
    multiSelect: {
      textColorUnselected: fontColors.primary,
      textColorSelected: colors.neutral.black,
      backgroundUnselected: colors.neutral.white,
      backgroundSelected: colors.primary.zero,
      backgroundOnHover: colors.primary.zero,
      textColorOnHover: colors.neutral.white,
      borderUnselected: `1px solid ${colors.neutral.two}`,
    },
    dynamicSelectors: {
      textColorUnselected: fontColors.primary,
      textColorSelected: fontColors.primary,
      textColorOnHover: fontColors.primary,
      backgroundUnselected: colors.neutral.three,
      backgroundSelected: colors.primary.zero,
      backgroundOnHover: colors.primary.zero,
      backButton: colors.primary.zero,
    },
    yesNoButton: {
      textColorUnselected: colors.neutral.black,
      textColorSelected: colors.neutral.black,
      fontSize: fontSizes.small,
      fontSizeSmall: fontSizes.extraSmall,
      backgroundUnselected: colors.neutral.three,
      backgroundSelected: colors.primary.zero,
      height: '48px',
      heightSmall: '32px',
      width: '70px',
      widthSmall: '48px',
    },
    datePicker: {
      background: colors.neutral.white,
      border: `1px solid ${colors.neutral.two}`,
      borderMobile: `1px solid ${colors.primary.zero}`,
      textColor: colors.neutral.black,
      textColorOnSelect: colors.primary.zero,
      backgroundOnHover: colors.neutral.three,
      selectionBackgroundOnActive: colors.primary.zero,
    },
  };

  const modals = {
    overlay: {
      background: `${colors.neutral.black}b3`,
      zIndex: 20,
    },
    titleBar: {
      background: colors.neutral.black,
      textColor: colors.neutral.white,
      height: '64px',
      fontSize: fontSizes.medium,
    },
    detailBar: {
      background: colors.neutral.two,
      height: '64px',
      fontSize: fontSizes.medium,
      prefilledApp: {
        background: colors.neutral.two,
      },
    },
    content: {
      background: colors.neutral.white,
    },
    buttonBar: {
      background: colors.neutral.white,
      borderTop: colors.neutral.three,
    },
    tabs: {
      background: colors.neutral.three,
    },
    pageIndicator: {
      current: {
        border: `1px solid ${colors.primary.zero}`,
        background: colors.primary.zero,
      },
      notCurrent: {
        background: colors.neutral.three,
      },
    },
  };

  const tables = {
    header: {
      textColor: fontColors.primary,
      background: colors.neutral.three,
      border: colors.primary.zero,
    },
    body: {
      borderBottom: colors.neutral.three,
      textColor: fontColors.primary,
    },
  };

  const icons = {
    toolTip: {
      textColor: fontColors.primary,
      textColorOnHover: fontColors.darkThemePrimary,
      background: colors.primary.twentyFive,
      backgroundOnHover: colors.primary.zero,
      content: {
        border: `1px solid ${colors.primary.zero}`,
        borderRadius: '3px',
        fontColor: fontColors.primary,
        background: colors.primary.twentyFive,
      },
    },
    editIcon: edit_icon,
    trashCan: trash_can,
    checkMark: check_mark,
    caret: caret,
    calendar: calendar,
    successCheckmark: {
      fillColor: colors.primary.zero,
      strokeColor: colors.primary.zero,
    },
    phone: phone,
  };

  const pageComponents = {
    globals: {
      background: colors.neutral.white,
      errorTextColor: colors.alert.zero,
      errorBackground: colors.alert.fifteen,
      errorBorder: colors.alert.zero,
      listBullet: colors.primary.zero,
      loadingSpinnerColor: colors.primary.zero,
      loadingSpinnerAltColor: colors.primary.zero,
      outlineOnFocus: `1px solid ${colors.primary.zero}`,
      linkColor: colors.secondary.zero,
    },
    products: {
      gl: {
        color: colors.primary.zero,
        background: colors.primary.twentyFive,
      },
      pl: {
        color: colors.primary.zero,
        background: colors.primary.twentyFive,
      },
      bop: {
        color: colors.primary.zero,
        background: colors.primary.twentyFive,
      },
    },
    elements: {
      containers: {
        border: colors.neutral.white,
        borderColor: `${colors.neutral.white} transparent ${colors.neutral.white} transparent`,
      },
      text: {
        paragraph: {
          textColor: fontColors.quaternary,
        },
        textSharedStyles: {
          textColor: fontColors.primary,
          textColorDarkTheme: fontColors.darkThemePrimary,
        },
        pageTitle: {
          textColor: fontColors.tertiary,
        },
      },
    },
    errorModal: {
      background: colors.alert.fifteen,
      textColor: colors.alert.zero,
      border: colors.alert.zero,
      textColorAlt: fontColors.primary,
      backgroundAlt: colors.neutral.three,
      invalidPolicy: {
        background: colors.neutral.three,
      },
    },
    businessInfo: {
      previousLosses: {
        addEditLossButton: {
          textColor: fontColors.primary,
        },
        previousLossTitle: {
          textColor: fontColors.primary,
        },
        lossAmount: {
          textColor: fontColors.primary,
        },
        lossContainer: {
          border: colors.primary.zero,
        },
        lossDescription: {
          textColor: fontColors.primary,
        },
        subTitle: {
          textColor: fontColors.primary,
          fontSize: fontSizes.small,
        },
        addClaimsTitle: {
          textColor: fontColors.primary,
        },
      },
    },
    applicationProgress: {
      container: {
        background: colors.neutral.three,
      },
      activeLink: {
        borderBottom: colors.neutral.black,
        textColor: colors.neutral.black,
      },
      connector: {
        borderLeft: `1px dashed ${colors.neutral.one}`,
        borderLeftOnActive: `1px solid ${colors.neutral.black}`,
      },
      circle: {
        border: `1px solid ${colors.neutral.one}`,
        borderOnActive: `1px solid ${colors.neutral.black}`,
        backgroundOnActive: colors.primary.zero,
      },
      styledLink: {
        textColor: fontColors.primary,
      },
      unavailLink: {
        textColor: fontColors.subdued,
      },
      finePrintLink: {
        textColor: colors.neutral.black,
      },
      finePrintText: {
        textColor: fontColors.subdued,
      },
    },
    addOns: {
      invalidMessage: {
        textColor: fontColors.error,
      },
    },
    additionalInsureds: {
      existingAddIns: {
        border: `1px solid ${colors.primary.zero}`,
      },
    },
    globalComponents: {
      footerContainer: {
        background: colors.neutral.black,
      },
    },
    shared: {
      yesNoQuestion: {
        questionText: {
          textColor: fontColors.primary,
        },
      },
      infoSideBar: {
        premiumHeader: {
          textColor: fontColors.primary,
        },
        priceText: {
          textColor: colors.neutral.one,
        },
        price: {
          textColor: fontColors.primary,
        },
        priceCont: {
          border: colors.primary.zero,
        },
        toggleButton: {
          colorSelected: colors.neutral.black,
          colorUnselected: colors.neutral.black,
          backgroundSelected: colors.primary.zero,
          backgroundUnselected: colors.neutral.three,
        },
      },
      multiButton: {
        incrementButton: {
          background: colors.neutral.white,
          border: `1px solid ${colors.primary.zero}`,
        },
        clearText: {
          textColor: fontColors.primary,
        },
        value: {
          textColor: fontColors.primary,
        },
      },
      mobileHeader: {
        progressBar: {
          backgroundColor: colors.neutral.three,
          valueColor: colors.primary.zero,
        },
        headerContainer: {
          background: colors.neutral.three,
        },
        quoteContainer: {
          h3: {
            textColor: fontColors.primary,
          },
        },
        textContainer: {
          p: {
            textColor: fontColors.subduedDark,
          },
        },
      },
      prefilledSummary: {
        row: {
          borderTop: `2px solid ${colors.neutral.three}`,
        },
        field: {
          textColor: fontColors.primary,
        },
        value: {
          textColor: fontColors.quaternary,
        },
        p: {
          textColor: fontColors.quaternary,
        },
      },
      sectionDivider: {
        border: `1px solid ${colors.neutral.three}`,
      },
      locations: {
        existingAddressButton: {
          border: `1px solid ${colors.neutral.white}`,
          borderOnActive: `1px solid ${colors.primary.zero}`,
          background: colors.neutral.white,
        },
      },
      locationInput: {
        statusMessage: {
          textColor: colors.primary.zero,
        },
        addressSuggestion: {
          backgroundOnHover: colors.primary.zero,
        },
      },
      addressInput: {
        questionTitle: {
          textColor: fontColors.primary,
          textColorOnActive: fontColors.tertiary,
        },
      },
    },
    policySelection: {
      text: {
        textColor: fontColors.quaternary,
      },
      bullet: {
        textColor: fontColors.quaternary,
      },
      policyTitle: {
        textColor: fontColors.primary,
      },
      policyDesc: {
        textColor: fontColors.quaternary,
      },
      learnMoreButton: {
        textColor: colors.neutral.black,
        borderOnHover: `2px dotted ${colors.neutral.black}`,
      },
    },
    summary: {
      businessNameHeader: {
        textColor: fontColors.primary,
        fontFamily: font.typeface.primary,
      },
      policies: {
        policyTitle: {
          textColor: fontColors.primary,
        },
      },
      checkoutForm: {
        fraudLabel: {
          textColor: fontColors.primary,
        },
        darkLabel: {
          textColor: fontColors.quaternary,
        },
        link: {
          textColor: fontColors.tertiary,
        },
        sectionTitle: {
          textColor: fontColors.primary,
        },
        switch: {
          background: colors.neutral.three,
        },
      },
      addOns: {
        card: {
          textColor: fontColors.primary,
        },
      },
      addPolicy: {
        textColor: fontColors.primary,
      },
      plusSymbol: {
        textColor: fontColors.primary,
      },
      title: {
        textColor: fontColors.primary,
      },
      content: {
        textColor: fontColors.quaternary,
      },
      link: {
        textColor: fontColors.link,
      },
      packages: {
        title: {
          textColor: fontColors.primary,
        },
        description: {
          textColor: fontColors.quaternary,
        },
        coverages: {
          textColor: fontColors.tertiary,
        },
      },
      styles: {
        sectionHeader: {
          textColor: fontColors.primary,
        },
        property: {
          textColor: fontColors.primary,
        },
        mobilePropertyValue: {
          textColor: fontColors.subduedDark,
        },
        styledCaret: {
          textColor: fontColors.tertiary,
        },
        sectionDescription: {
          textColor: fontColors.primary,
        },
      },
    },
    payment: {
      overlay: colors.neutral.black,
    },
    quoteHelp: {
      title: {
        textColor: fontColors.primary,
      },
      centeredLabel: {
        textColor: fontColors.subdued,
        fontSize: fontSizes.bodyRegular,
      },
      phoneNumber: {
        textColor: fontColors.primary,
      },
      button: {
        textColor: fontColors.primary,
      },
    },
  };

  return {
    colors,
    font,
    buttons,
    formElements,
    selectors,
    modals,
    tables,
    icons,
    pageComponents,
  };
};

export default createDefaultWhiteLabelTheme;
