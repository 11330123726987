import { ENVIRONMENT_URL } from '../constants';

export const getEnvironment = () => {
  if (process.env.REACT_APP_SANDBOX_API === 'true' && process.env.NODE_ENV === 'development') {
    return 'SANDBOX';
  }

  switch (window.location.hostname.toLowerCase()) {
    // Production
    case ENVIRONMENT_URL.PRODUCTION:
    case 'coterieclient.azurewebsites.net':
      return 'PRODUCTION';

    // Sandbox
    case ENVIRONMENT_URL.SANDBOX:
    case 'coterieclient-sandbox.azurewebsites.net':
      return 'SANDBOX';

    // Test
    case ENVIRONMENT_URL.TEST:
    case ENVIRONMENT_URL.DEV_1:
    case ENVIRONMENT_URL.DEV_2:
    case ENVIRONMENT_URL.DEV_3:
    case 'coterieclient-test.azurewebsites.net':
      return 'TEST';

    // Default (Test)
    default:
      return 'TEST';
  }
};
