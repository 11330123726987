import styled from 'styled-components';
import checkmark from '../icons/endorsementsCheckmark.svg';

interface CheckboxProps extends React.HTMLAttributes<HTMLInputElement> {
  label?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  selected: boolean;
  showActiveLabel?: boolean;
  ariaLabel?: string;
}

const CheckboxInput = styled.input`
  cursor: pointer;
  appearance: none;
  margin-left: 0px;
  width: 24px;
  height: 24px;
  border: 1px solid ${({ theme }) => theme.formElements.visualCheckbox.border};
  border-radius: 4px;
  background: ${({ theme }) => theme.formElements.visualCheckbox.backgroundWhite};
  &:focus {
    outline-color: ${({ theme }) => theme.formElements.visualCheckbox.backgroundActive};
    outline-style: dotted;
    outline-width: thin;
  }
  &:checked {
    border: ${({ theme }) => theme.formElements.visualCheckbox.borderOnActive};
    background-color: ${({ theme }) => theme.formElements.visualCheckbox.backgroundActive};
    background-repeat: no-repeat;
    background-image: url(${checkmark});
    background-position: center;
  }
`;
export const CheckboxAndLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 8px;
`;
export const CheckboxLabel = styled.label<{ active?: boolean }>`
  :hover {
    cursor: pointer;
  }
  font-size: 15px;
  font-family: ${(props) => props.theme.font.typeface.secondary};
  color: ${(props) =>
    props.active
      ? props.theme.colors.tertiary.zero
      : props.theme.pageComponents.shared.addressInput.questionTitle.textColor};
`;

export default function Checkbox({ label, onChange, selected, showActiveLabel, id, ariaLabel }: CheckboxProps) {
  const addAriaLabel = !label && ariaLabel ? { 'aria-label': ariaLabel } : {};
  return (
    <CheckboxAndLabel>
      <CheckboxInput
        type="checkbox"
        onChange={onChange}
        checked={selected}
        data-cy={selected ? 'selected' : 'unselected'}
        data-testid={selected ? 'selected' : 'unselected'}
        id={id ? id : 'checkbox-component'}
        {...addAriaLabel}
      />
      {label && (
        <CheckboxLabel active={showActiveLabel && selected} htmlFor={id ? id : 'checkbox-component'}>
          {label}
        </CheckboxLabel>
      )}
    </CheckboxAndLabel>
  );
}
