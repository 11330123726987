import { createSelector } from 'reselect';
import { selectReasonabilityErrors, selectAvailablePolicyTypes } from '.';
import { messageRemapperUtility } from '../helpers/errorMessageRemapper';
import { uniq } from 'lodash';

export const messageRemapperUtilitySelector = createSelector(
  selectReasonabilityErrors,
  selectAvailablePolicyTypes,
  (reasonabilityErrors, availablePolicyTypes) =>
    messageRemapperUtility(uniq(reasonabilityErrors), { validPolicy: availablePolicyTypes[0] })
);

export const selectFatalError = (state) => state.fatalError;
