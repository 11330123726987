import React, { FunctionComponent } from 'react';
import ReactModal from 'react-modal';
import styled, { css } from 'styled-components';
import lightCloseButton from '../icons/Modal/lightCloseButton.svg';
import { LoadingSpinner } from '.';
import { GhostButton, PrimaryButton } from './Buttons';
import { Text } from './text';
import { useMediaQuery } from 'react-responsive';
import { useThemeContext } from './theme/CustomThemeProvider';
import SuccessCheckmark from '../Components/WhiteLabel/Components/SuccessCheckmark';

const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  padding: 0;
  top: 0px;
  right: 0px;
  width: 16px;
  height: 16px;
  margin: 16px;
`;

const TitleBar = styled.h1`
  height: 64px;
  padding-left: 24px;
  margin: 0;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.modals.titleBar.background};
  font-size: ${({ theme }) => theme.modals.titleBar.fontSize};
  font-family: ${({ theme }) => theme.font.typeface.secondary};
  color: ${({ theme }) => theme.modals.titleBar.textColor};
  @media screen and (max-width: 768px) {
    padding-right: 56px;
  }
`;

/**
 * Div element to wrap the content for the optional Details Bar on the Modal.
 * Has a fixed height of 64px. This is important for mobile.
 */
export const ModalDetailBar = styled.div`
  min-height: 64px;
  display: flex;
  background: ${({ theme }) => theme.modals.detailBar.background};
  padding: 5px;
  @media screen and (max-width: 768px) {
    padding: 16px;
  }
`;

/**
 * Div element to wrap the content for the optional Details Bar on the Modal.
 * Has a fixed height of 176px on mobile. This is important for mobile.
 */
export const PrefilledAppDetailsBar = styled.div<{ premierPlatform: boolean }>`
  padding: ${({ premierPlatform }) => (premierPlatform ? '24px 24px 0px 24px' : '24px')};
  background-color: ${({ theme }) => theme.modals.detailBar.prefilledApp.background};

  @media screen and (max-width: 768px) {
    height: 176px;
  }
`;

type ContentContainerProps = {
  hasButtons: boolean;
  hasDetailsBar: boolean;
  hasPrefilledAppDetailsBar: boolean;
  options?: ModalOptions;
};

const ContentContainer = styled.div<ContentContainerProps>`
  box-sizing: border-box;
  overflow: auto;
  max-height: 536px;
  padding: ${({ options }) => (options?.noContainerPadding ? '0' : '32px')};
  form > div:not(:last-of-type),
  form > section,
  form > h3,
  form > a,
  form > p {
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
  }
  form > p {
    display: inline-block;
  }

  @media screen and (max-width: 768px) {
    flex-grow: 1;
  }
`;

const ModalButtonCont = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 2px solid ${({ theme }) => theme.modals.buttonBar.borderTop};
  background-color: ${({ theme }) => theme.modals.buttonBar.background};
  @media screen and (min-width: 767px) {
    min-height: 96px;
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
`;

const ModalButtonTemplate = css`
  min-width: 156px;
  margin: 0 8px;
`;

const ModalButton = styled(PrimaryButton)`
  ${ModalButtonTemplate}
  @media screen and (max-width: 767px) {
    order: -1;
    margin: 24px 0 16px;
  }
`;

const ModalGhostButton = styled(GhostButton)`
  ${ModalButtonTemplate}
  @media screen and (max-width: 767px) {
    margin: 0 0 24px;
  }
`;

const StyledModal = styled(ReactModal)`
  width: 600px;
  background: ${({ theme }) => theme.modals.content.background};
  border-radius: 4px;
  outline: currentColor none medium;
  /* overflow: auto; */
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  @media screen and (max-width: 768px) {
    max-height: 90%;
    max-width: 90%;
    display: flex;
    flex-direction: column;
  }
`;

interface SuccessMessageProps {
  children: string | React.ElementType;
}

/**
 * Displays a success message with green check mark.
 */
export const SuccessMessage = ({ children }: SuccessMessageProps) => {
  return (
    <ModalContentContainer>
      <SuccessCheckmark width="80px" height="80px" />
      <Text center>{children}</Text>
    </ModalContentContainer>
  );
};

const ModalContentContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  height: 281px;
`;

type ModalOptions = {
  noContainerPadding: boolean;
};
interface ModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  id?: string;
  title: string;
  label?: string;
  cancelButtonText?: string;
  buttonOnClick?: (...args: any[]) => void;
  buttonCyLabel?: string;
  buttonTestId?: string;
  buttonIsDisabled?: boolean;
  buttonText?: string;
  buttonIsLoading?: boolean;
  buttonsAreHidden?: boolean;
  detailsBar?: JSX.Element;
  prefilledAppDetailsBar?: JSX.Element;
  options?: ModalOptions;
}

/**
 * React Modal element that conforms to Coterie design standards and style guide
 * @param {boolean} isOpen-  determines if the modal is rendered
 * @callback onRequestClose - callback function for close and cancel buttons
 * @param {string} id - unique id prop that is passed along to data-cy and data-testid attributes
 * @param {string} title - header
 * @param {string} label - aria-label attribute
 * @param {string} cancelButtonText - text rendered for the optional cancel button; Cancel button is not rendered if this param is not present
 * @callback buttonOnClick - callback function for primary button; Primary button is not rendered if this param and buttonText param are no present
 * @param {string} buttonCyLabel - data-cy attribute for primary button
 * @param {string} buttonTestId - data-testid attribute for primary button
 * @param {boolean} buttonIsDisabled - disables the primary button
 * @param {string} buttonText - primary button text
 * @param {boolean} buttonIsLoading - renders loading spinner in primary button and triggers disabled attribute
 * @param {boolean} buttonsAreHidden - optional boolean to hide the buttons container
 * @param {React.ElementType} detailsBar - React JSX element that must use ModalDetailsBar container to render beneath the header element
 * @param {React.ElementType} prefilledAppDetailsBar - React JSX element that must use the PrefilledAppDetailsBar container to render beneath the header element
 * @param {Object} options - custom modal options that use the ModalOptions type
 * @returns {React.FunctionComponent} React Modal
 */
const Modal: FunctionComponent<ModalProps> = ({
  isOpen,
  onRequestClose,
  id,
  title,
  label,
  cancelButtonText,
  buttonOnClick,
  buttonCyLabel,
  buttonTestId,
  buttonIsDisabled,
  buttonText,
  buttonIsLoading,
  buttonsAreHidden,
  detailsBar = null,
  prefilledAppDetailsBar = null,
  options,
  children,
}) => {
  const { theme } = useThemeContext();
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <StyledModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={{ overlay: theme.modals.overlay }}
      contentLabel={label}
    >
      <div>
        <CloseButton onClick={onRequestClose} aria-label="Close Modal">
          <img alt="close icon" src={lightCloseButton} />
        </CloseButton>
        <TitleBar data-testid="modal-header">{title}</TitleBar>
      </div>
      {detailsBar}
      {prefilledAppDetailsBar}
      <ContentContainer
        hasButtons={Boolean(buttonText && buttonOnClick)}
        hasDetailsBar={Boolean(detailsBar)}
        hasPrefilledAppDetailsBar={Boolean(prefilledAppDetailsBar)}
        options={options}
        data-testid={id}
        data-cy={id}
      >
        {children}
      </ContentContainer>
      {!buttonsAreHidden && (Boolean(buttonText && buttonOnClick) || Boolean(cancelButtonText)) && (
        <ModalButtonCont>
          {Boolean(cancelButtonText) && (
            <ModalGhostButton onClick={onRequestClose} full={isMobile}>
              {cancelButtonText}
            </ModalGhostButton>
          )}
          <ModalButton
            onClick={buttonOnClick}
            data-cy={buttonCyLabel}
            data-testid={buttonTestId}
            disabled={buttonIsDisabled || buttonIsLoading}
            full={isMobile}
          >
            {buttonIsLoading ? <LoadingSpinner data-cy="loading-spinner" /> : buttonText}
          </ModalButton>
        </ModalButtonCont>
      )}
    </StyledModal>
  );
};

ReactModal.setAppElement('*');

export default Modal;
