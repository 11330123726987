import React, { Component } from 'react';
import './App.css';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { initialApplicationLoad, clearAllData } from './actions/application';
import {
  setDataIdsFromDropin,
  setDropin,
  setChannelPartnerGuidDropIn,
  setCustomStylesFromDropin,
} from './actions/dropIn';
import { Desktop, Mobile, DesktopAndTablet } from './helpers/responsive';
import { isProd, isSandbox } from './helpers/environment';
import QuoteHelp from './Pages/QuoteHelp';
import ApplicationProgress from './Components/Global/ApplicationProgress';
import InfoSidebar from './Components/Shared/InfoSidebar';
import PolicySelection from './Pages/PolicySelection';
import Industry from './Pages/Industry';
import BusinessInfo from './Pages/BusinessInfo';
import FinanceBasics from './Pages/FinanceBasics';
import GeneralLiability from './Pages/GeneralLiability';
import Locations from './Pages/Locations';
import Summary from './Pages/Summary';
import Footer from './Components/Global/Footer';
import ProfessionalLiability from './Pages/ProfessionalLiability';
import AdditionalInsureds from './Pages/AdditionalInsureds';
import Payment from './Pages/Payment';
import Contact from './Pages/Contact';
import LoadingApplication from './Pages/LoadingApplication';
import PrefilledSummaryModal from './Components/Shared/PrefilledSummary';
import ErrorModal from './Components/Shared/ErrorModal';
import { PAGES } from './constants';
import MobileHeader from './Components/Shared/MobileHeader';
import PageHeader from './Components/Shared/PageHeader';
import AddOns from './Pages/AddOns';
import CenteredLoadingSpinner from './Components/AddOns/shared/CenteredLoadingSpinner';
import { isEmpty } from 'lodash';
import LogRocket from 'logrocket';
import { persistor } from './configureStore';

interface AppProps {
  location: LocationState;
  setDropInWhiteLabelPartner: (token: string) => void;
  setDataIdsFromDropin: (ids: string[]) => void;
  setDropin: () => void;
  clearAllData: () => void;
  shouldRenderHubSpotWidget: boolean;
  initialApplicationLoad: () => void;
  setCustomStylesFromDropin: (customStyles: CustomStyles) => void;
}

interface AppState {
  iFrameMsgReceived: boolean;
  isIframe: boolean;
}

interface Pages {
  [key: string]: React.ReactNode;
}

export class App extends Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);
    this.state = {
      iFrameMsgReceived: false,
      isIframe: window !== window.parent || window.CYPRESS_DROPIN,
    };
  }

  leftSidebar = () => {
    switch (this.props.location.type) {
      case PAGES.QUOTEHELP:
        return null;
      case PAGES.POLICIES:
      case PAGES.INDUSTRY:
      case PAGES.BUSINESS_INFORMATION:
      case PAGES.FINANCE_BASICS:
      case PAGES.GENERAL_LIABILITY:
      case PAGES.PROFESSIONAL_LIABILITY:
      case PAGES.LOCATIONS:
      case PAGES.CONTACT_INFORMATION:
      case PAGES.ADD_ONS:
      case PAGES.SUMMARY:
      case PAGES.PAYMENT:
      case PAGES.ADDITIONAL_INSUREDS:
        return (
          <SidebarCont data-testid="application_progress_sidebar">
            <ApplicationProgress />
          </SidebarCont>
        );
      default:
        return null;
    }
  };

  handleMessage = ({ data }: WindowMessage) => {
    const { ids, token, customStyles } = data;
    if (token) {
      this.props.setDropInWhiteLabelPartner(token);
    }
    if (ids && Array.isArray(ids)) {
      this.props.setDataIdsFromDropin(ids);
    }
    if (!isEmpty(customStyles)) {
      this.props.setCustomStylesFromDropin(customStyles);
    }
    this.setState({ ...this.state, iFrameMsgReceived: true });
  };

  newQuote = (e: KeyboardEvent) => {
    // shift + ctrl + r
    if (e.shiftKey && e.ctrlKey && (e.key === 'R' || e.key === 'r')) {
      const response = window.confirm('Are you should you want to start a new quote?');
      if (response) {
        // https://github.com/rt2zz/redux-persist?tab=readme-ov-file#persistor-object
        persistor.purge().then(() => {
          window.location.reload();
        });
      }
    }
  };

  componentDidMount() {
    if (this.state.isIframe) {
      this.props.setDropin();
      window.addEventListener('message', this.handleMessage);
    } else if (!window.Cypress && this.props.shouldRenderHubSpotWidget) {
      // hubspot widget script
      const script = document.createElement('script');
      script.src = '//js.hs-scripts.com/8588529.js';
      script.async = true;
      script.id = 'hs-script-loader';
      script.type = 'text/javascript';
      document.head.appendChild(script);
    }

    if (this.props.location.type !== 'APPLICATION') {
      this.props.initialApplicationLoad();
    }

    window.addEventListener('keydown', this.newQuote);

    // pendo init - https://support.pendo.io/hc/en-us/articles/360046272771-Developer-s-Guide-To-Installing-Pendo

    if (isProd() || isSandbox()) {
      window.pendo.initialize();
    }

    // log rocket init - logrocket.com
    if (isProd()) {
      LogRocket.init('jkmawz/quote-flow');
    }
    if (isSandbox()) {
      LogRocket.init('jkmawz/sandbox-quoteflow');
    }
  }

  componentWillUnmount() {
    if (this.state.isIframe) {
      window.removeEventListener('message', this.handleMessage);
    }
    window.removeEventListener('keydown', this.newQuote);
  }
  pages: Pages = {
    INDUSTRY: <Industry key={PAGES.INDUSTRY} />,
    BUSINESS_INFORMATION: <BusinessInfo key={PAGES.BUSINESS_INFORMATION} />,
    POLICIES: <PolicySelection key={PAGES.POLICIES} />,
    FINANCE_BASICS: <FinanceBasics key={PAGES.FINANCE_BASICS} />,
    PROFESSIONAL_LIABILITY: <ProfessionalLiability key={PAGES.PROFESSIONAL_LIABILITY} />,
    GENERAL_LIABILITY: <GeneralLiability key={PAGES.GENERAL_LIABILITY} />,
    LOCATIONS: <Locations key={PAGES.LOCATIONS} />,
    ADDITIONAL_INSUREDS: <AdditionalInsureds key={PAGES.ADDITIONAL_INSUREDS} />,
    CONTACT_INFORMATION: <Contact key={PAGES.CONTACT_INFORMATION} />,
    ADD_ONS: <AddOns key={PAGES.ADD_ONS} />,
    SUMMARY: <Summary key={PAGES.SUMMARY} />,
    PAYMENT: <Payment key={PAGES.PAYMENT} />,
    QUOTEHELP: <QuoteHelp key={PAGES.QUOTEHELP} />,
    APPLICATION: <LoadingApplication key={PAGES.APPLICATION} />,
  };

  rightSidebar = () => {
    switch (this.props.location.type) {
      case PAGES.POLICIES:
      case PAGES.INDUSTRY:
      case PAGES.BUSINESS_INFORMATION:
      case PAGES.FINANCE_BASICS:
      case PAGES.GENERAL_LIABILITY:
      case PAGES.PROFESSIONAL_LIABILITY:
      case PAGES.LOCATIONS:
      case PAGES.CONTACT_INFORMATION:
      case PAGES.ADD_ONS:
      case PAGES.SUMMARY:
      case PAGES.PAYMENT:
      case PAGES.ADDITIONAL_INSUREDS:
        return (
          <PriceCont data-testid="info_sidebar">
            <InfoSidebar />
          </PriceCont>
        );
      default:
        return null;
    }
  };

  render() {
    if (this.state.isIframe && !this.state.iFrameMsgReceived) {
      return <CenteredLoadingSpinner />;
    }

    return (
      <AppContainer data-testid="main-app">
        <PrefilledSummaryModal />
        <ErrorModal />
        <ContentContainer>
          <DesktopAndTablet>
            {this.leftSidebar()}
            <PageContainer>
              <PageHeader />
              {[this.pages[this.props.location.type]]}
            </PageContainer>
            <Desktop>{this.rightSidebar()}</Desktop>
          </DesktopAndTablet>
          <Mobile>
            <MobileHeader />
            <MobileMainContent>
              <PageHeader />
              {[this.pages[this.props.location.type]]}
            </MobileMainContent>
          </Mobile>
        </ContentContainer>
        <ChatFooter id="zendesk" />
        <Footer />
      </AppContainer>
    );
  }
}

const AppContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  a {
    color: ${({ theme }) => theme.pageComponents.globals.linkColor};
  }

  input,
  button,
  a {
    :focus {
      outline: ${({ theme }) => theme.pageComponents.globals.outlineOnFocus};
    }
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex: 4;
  @media (max-width: 767px) {
    justify-content: flex-start;
    flex-direction: column;
  }
`;

const SidebarCont = styled.div`
  margin-right: 425px;
  @media (max-width: 767px) {
    display: none;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    margin-right: 290px;
  }
`;

const PriceCont = styled.div`
  flex: 2;
  @media (max-width: 767px) {
    flex: 0;
  }
  @media (max-width: 1320px) {
    margin-left: 16px;
  }
`;

const MobileMainContent = styled.div`
  padding-top: 40px;
  @media (max-width: 767px) {
    padding-top: 0;
  }
`;

const PageContainer = styled.div`
  width: 100%;
  @media (max-width: 1023px) {
    overflow-x: hidden;
  }
`;

const ChatFooter = styled.div``;

const mapStateToProps = (state: ReduxState) => ({
  location: state.location,
});

const mapDispatchToProps = {
  setDropInWhiteLabelPartner: setChannelPartnerGuidDropIn,
  setDataIdsFromDropin,
  clearAllData,
  setDropin,
  initialApplicationLoad,
  setCustomStylesFromDropin,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
