import React, { useState } from 'react';
import { ReactComponent as AlertIcon } from '../../icons/alert.svg';
import { ReactComponent as Caret } from '../../icons/caret_black.svg';
import styled from 'styled-components';

interface Props {
  collapsedMessage: string;
  message: string;
}

const ValidationWarning = ({ collapsedMessage, message }: Props) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <Warning>
      <WarningIcon />
      <WarningText>{isCollapsed ? collapsedMessage : message}</WarningText>
      <Button
        data-testid="toggle-warning"
        onClick={(e: React.MouseEvent) => {
          e.preventDefault();
          setIsCollapsed(!isCollapsed);
        }}
      >
        <WarningCaret className={isCollapsed ? 'closed' : ''} />
      </Button>
    </Warning>
  );
};

const Warning = styled.div`
  margin: -8px 0 8px;
  color: ${(props) => props.theme.pageComponents.validationWarning.textColor};
  background-color: ${(props) => props.theme.pageComponents.validationWarning.backgroundColor};
  display: flex;
  flex-direction: row;
  padding: 8px;
`;

const WarningIcon = styled(AlertIcon)`
  width: 16px;
  min-width: 16px;
  height: 16px;
  display: block;

  g {
    fill: ${(props) => props.theme.pageComponents.validationWarning.iconColor};
  }
`;

const WarningCaret = styled(Caret)`
  min-width: 16px;
  cursor: pointer;
  transform: rotate(180deg);

  &.closed {
    transform: rotate(0);
  }

  g {
    fill: ${(props) => props.theme.pageComponents.validationWarning.iconColor};
  }
`;

const WarningText = styled.p`
  color: ${(props) => props.theme.pageComponents.validationWarning.textColor};
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.25px;
  margin: 0 8px 0 8px;
`;

const Button = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  outline: none;
  margin-left: auto;
`;

export default ValidationWarning;
