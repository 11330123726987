import { ERRORS, PAGES, QUOTE_COVERAGE_DEFAULTS } from '../constants';
import isApplicationBindable from '../helpers/isApplicationBindable';
import { previousLossesOk } from '../helpers/previousLoss';
import { convertNumberToString } from '../helpers/valueConversion';
import { selectLocationType } from '../selectors';
import {
  selectAnnualPayroll,
  selectBPPDeductible,
  selectGrossAnnualSales,
  selectIndustryState,
  selectNumEmployees,
  selectPreviousLosses,
  selectPolicyTypes,
  selectTwoFourLimitsAvailable,
  selectLocationBPPLimit,
} from '../selectors/application';
import {
  didViewAddOnsPage,
  didViewGLPage,
  didViewSummaryPage,
  fatalError,
  setBppDeductible,
  setGlLimit,
  setUserHasSubmittedCheckoutForm,
  showNextPage,
  setLossControl,
  setBlanketAdditionalInsureds,
  didViewAdditionalInsureds,
  setBppLimit,
} from './application';
import handleFailFastCheck from './handleFailFastCheck';
import handleUpdateApplication from './handleUpdateApplication';

const handleFormSubmit = () => (dispatch: Function, getState: () => ReduxState) => {
  const state = getState();
  const locationType = selectLocationType(state);
  const industryId = convertNumberToString(selectIndustryState(state));
  const annualPayroll = selectAnnualPayroll(state);
  const grossAnnualSales = selectGrossAnnualSales(state);
  const numEmployees = selectNumEmployees(state);
  const previousLosses = selectPreviousLosses(state);
  const bppDeductible = selectBPPDeductible(state);
  const applicationTypes = selectPolicyTypes(state);
  const twoFourLimitsAvailable = selectTwoFourLimitsAvailable(state);
  const bppLimit = selectLocationBPPLimit(state);

  switch (locationType) {
    case PAGES.INDUSTRY:
      if (industryId === 'INVALID_ID') {
        dispatch(fatalError(ERRORS.CAT_1.INVALID_INDUSTRY_ID));
      } else {
        dispatch(handleUpdateApplication());
      }
      break;
    case PAGES.BUSINESS_INFORMATION:
      if (!previousLossesOk(previousLosses)) {
        dispatch(fatalError(ERRORS.CAT_1.PREVIOUS_LOSSES_OUTSIDE_BOUNDS));
      } else if (numEmployees && numEmployees > 50) {
        dispatch(fatalError(ERRORS.CAT_1.NUMBER_OF_EMPLOYEES_OUTSIDE_BOUNDS));
      } else {
        if (isApplicationBindable(state.application)) {
          dispatch(handleUpdateApplication());
        } else {
          dispatch(handleFailFastCheck());
        }
      }
      break;
    case PAGES.POLICIES:
      if (applicationTypes.includes('BOP') || applicationTypes.includes('GL')) {
        dispatch(setLossControl('OptIn'));
        if (twoFourLimitsAvailable) {
          dispatch(setGlLimit(2_000_000));
        } else {
          dispatch(setGlLimit(1_000_000));
        }

        if (!bppLimit) {
          dispatch(setBppLimit(QUOTE_COVERAGE_DEFAULTS.BPP_LIMIT));
        }
      }

      // add blanket AI endorsement by default
      dispatch(setBlanketAdditionalInsureds());

      dispatch(handleUpdateApplication());
      break;
    case PAGES.FINANCE_BASICS:
      if (grossAnnualSales! > 10000000 || grossAnnualSales! < 1000 || annualPayroll! < 1000) {
        dispatch(fatalError(ERRORS.CAT_1.REVENUE_OUTSIDE_BOUNDS));
      } else {
        dispatch(handleUpdateApplication());
      }
      break;
    case PAGES.LOCATIONS:
      if (applicationTypes.includes('BOP') && !bppDeductible) {
        dispatch(setBppDeductible(2500));
      }
      dispatch(handleUpdateApplication());
      break;
    case PAGES.GENERAL_LIABILITY:
      dispatch(didViewGLPage());
      dispatch(handleUpdateApplication());
      break;
    case PAGES.ADDITIONAL_INSUREDS:
      dispatch(didViewAdditionalInsureds());
      dispatch(handleUpdateApplication());
      break;
    case PAGES.ADD_ONS:
      dispatch(didViewAddOnsPage());
      dispatch(handleUpdateApplication());
      break;
    case PAGES.SUMMARY:
      dispatch(didViewSummaryPage(true));
      dispatch(showNextPage());
      dispatch(handleUpdateApplication());
      break;
    case PAGES.PAYMENT:
      dispatch(setUserHasSubmittedCheckoutForm());
      break;
    default:
      dispatch(handleUpdateApplication());
  }
};

export default handleFormSubmit;
