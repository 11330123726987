import React, { FunctionComponent, useEffect } from 'react';
import { connect } from 'react-redux';
import styled, { createGlobalStyle } from 'styled-components';
import {
  removeAdditionalInsured,
  clearAdditionalInsured,
  didViewAdditionalInsureds,
  setUserSelectedHasAdditionalInsureds,
} from '../actions/application';
import {
  selectAdditionalInsureds,
  selectUserHasSelectedAdditionalInsureds,
  selectUserHasViewedAdditionalInsuredsPage,
} from '../selectors/application';
import AdditionalInsuredsForm from '../Components/Shared/AdditionalInsuredsForm';
import DeleteOrUpdateButton, { Button } from '../Components/Shared/DeleteOrUpdateButton';
import { Paragraph, Container, FormSection } from '../elements';
import SmoothCollapse from 'react-smooth-collapse';
import GhostButton from '../Components/Shared/GhostButton';
import handleFormSubmit from '../actions/handleFormSubmit';
import RadioBtn from '../elements/RadioBtn';
import BlanketAdditionalInsureds from '../Components/AdditionalInsureds/BlanketAdditionalInsureds';

interface AdditionalInsuredsProps {
  additionalInsureds: AdditionalInsured[];
  additionalInsuredsScreenViewed: boolean;
  removeAdditionalInsured: (name: string) => void;
  didViewAdditionalInsureds: () => void;
  clearAdditionalInsured: () => void;
  setShowAdditionalInsuredsForm: (bool: boolean) => void;
  shouldShowAdditionalInsuredsForm: boolean;
}

export const AdditionalInsureds: FunctionComponent<AdditionalInsuredsProps> = ({
  additionalInsureds,
  additionalInsuredsScreenViewed,
  removeAdditionalInsured,
  didViewAdditionalInsureds,
  clearAdditionalInsured,
  setShowAdditionalInsuredsForm,
  shouldShowAdditionalInsuredsForm,
}) => {
  useEffect(() => {
    if (additionalInsuredsScreenViewed && Array.isArray(additionalInsureds)) setShowAdditionalInsuredsForm(false);
    if (additionalInsureds && additionalInsureds.length) setShowAdditionalInsuredsForm(true);
    // TODO refactor this logic to not depend on redux; we shouldn't need this useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteAddlIns = (name: string) => {
    removeAdditionalInsured(name);
  };

  const submitAdditionalInsured = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleFormSubmit();
  };

  const showAdditionalInsuredsForm = () => {
    didViewAdditionalInsureds();
    setShowAdditionalInsuredsForm(true);
  };

  const hideAdditionalInsuredsForm = () => {
    clearAdditionalInsured();
    didViewAdditionalInsureds();
    setShowAdditionalInsuredsForm(false);
  };

  return (
    <Container>
      <FormSection available={true}>
        <StyledParagraph>
          Additional insureds are anyone other than the policyholder covered by the policy. You can add multiple
          additional insureds to your policy.
        </StyledParagraph>
        <BlanketAdditionalInsureds />
        <RadioContainer data-cy="individual-additional-insureds" data-testid="individual-additional-insureds">
          <RadioText>Would you like to add an individual additional insured?</RadioText>

          <RadioBtn
            id="yes-individual"
            dataCy="yes-additional-insureds"
            labelText="Yes"
            isChecked={shouldShowAdditionalInsuredsForm}
            onChange={showAdditionalInsuredsForm}
            name="individual-additional-insured"
          />
          <RadioBtn
            id="no-individual"
            dataCy="no-additional-insureds"
            labelText="No"
            isChecked={typeof shouldShowAdditionalInsuredsForm === 'boolean' && !shouldShowAdditionalInsuredsForm}
            onChange={hideAdditionalInsuredsForm}
            name="individual-additional-insured"
          />
        </RadioContainer>
      </FormSection>

      <Form onSubmit={submitAdditionalInsured} data-cy="additional-insureds-section">
        <AddInsContainer>
          {additionalInsureds.map((insured) => (
            <ExistingAddlIns data-cy="additional-insured" key={insured.name}>
              <AddlInsContent>
                <p>{insured.name}</p>
                <p>{insured.email}</p>
                <p>
                  {insured.street}, {insured.city} {insured.state}
                </p>
              </AddlInsContent>
              <OverrideStyle />
              <DeleteOrUpdateButton
                handleClick={() => deleteAddlIns(insured.name)}
                type="delete"
                name="additional-insureds"
              />
            </ExistingAddlIns>
          ))}
        </AddInsContainer>
        <SmoothCollapse expanded={Boolean(shouldShowAdditionalInsuredsForm)} allowOverflowWhenOpen={true}>
          <AdditionalInsuredsForm />
        </SmoothCollapse>
        <GhostButton />
      </Form>
    </Container>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  additionalInsureds: selectAdditionalInsureds(state),
  additionalInsuredsScreenViewed: selectUserHasViewedAdditionalInsuredsPage(state),
  shouldShowAdditionalInsuredsForm: selectUserHasSelectedAdditionalInsureds(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  removeAdditionalInsured: (name: string) => dispatch(removeAdditionalInsured(name)),
  clearAdditionalInsured: () => dispatch(clearAdditionalInsured()),
  didViewAdditionalInsureds: () => dispatch(didViewAdditionalInsureds()),
  setShowAdditionalInsuredsForm: (bool: boolean) => dispatch(setUserSelectedHasAdditionalInsureds(bool)),
});

export const RadioContainer = styled.fieldset`
  border: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 30px;

  color: ${({ theme }) => theme.colors.tertiary.zero};
  font-family: ${({ theme }) => theme.font.typeface.primary};
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
`;

const Form = styled.form`
  margin-bottom: 72px;
`;

export const RadioText = styled.legend`
  margin-bottom: 16px;
`;

const ExistingAddlIns = styled.div`
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  border: ${(props) => props.theme.pageComponents.additionalInsureds.existingAddIns.border};
  border-radius: 4px;
  margin-bottom: 16px;
`;

const AddlInsContent = styled.div`
  padding: 13px 16px;

  p {
    font-weight: normal;
    margin: 0;
    margin-bottom: 3px;
    font-size: 15px;
    font-family: ${(props) => props.theme.font.typeface.secondary};
  }
`;

const OverrideStyle = createGlobalStyle`
  button${Button} {
    margin-top: -20px;
    margin-right: 15px;
  }
`;

const AddInsContainer = styled.div`
  padding: 8px 0;
`;

const StyledParagraph = styled(Paragraph)`
  margin-bottom: 32px;
  font-size: 16px;
  font-family: ${(props) => props.theme.font.typeface.secondary};
`;

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalInsureds);
