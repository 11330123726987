import styled, { css } from 'styled-components';

export const Paragraph = styled.p<{ display?: string }>`
  font-size: 15px;
  font-family: ${(props) => props.theme.font.typeface.secondary};
  color: ${(props) => props.theme.pageComponents.elements.text.paragraph.textColor};
  font-weight: 300;
  margin: 0;
  display: ${(props) => props.display || 'block'};
  letter-spacing: 0;
  line-height: 24px;
`;

export interface TextProps {
  display?: string;
  center?: boolean;
  isDarkTheme?: string;
}

const TextDefaultStyles = css`
  font-family: ${({ theme }) => theme.font.typeface.primary};
  font-weight: 600;
  line-height: 1.3;
`;

export const TextSharedStyles = css<TextProps>`
  ${TextDefaultStyles}
  color: ${(props) =>
    props.isDarkTheme // TODO update white prop to 'isWhite' or something similar; also determine if we even need this
      ? props.theme.pageComponents.elements.text.textSharedStyles.textColorDarkTheme
      : props.theme.pageComponents.elements.text.textSharedStyles.textColor};
  margin: ${(props) => {
    switch (props.display) {
      case 'inline':
        return '10px 0 5px 0';
      case 'title':
        return '20px 0 5px 0';
      default:
        return '0';
    }
  }};
  display: ${(props) => props.display || 'block'};
  text-align: ${(props) => (props.center ? 'center' : 'left')};
`;

export const Text = styled.p<TextProps>`
  font-size: 16px;
  ${TextDefaultStyles}
`;

export const PageTitle = styled.h1`
  font-weight: 600;
  font-size: 30px;
  color: ${({ theme }) => theme.pageComponents.elements.text.pageTitle.textColor};
  margin: 0;
  font-family: ${({ theme }) => theme.font.typeface.primary};
  @media (min-width: 768px) and (max-width: 1024px) {
    max-width: 60%;
  }
`;

export const SectionTitle = styled.h2`
  font-weight: 600;
  font-size: 24px;
`;

export const InputErrorMessage = styled.p`
  font-family: ${({ theme }) => theme.font.typeface.primary};
  font-size: 12px;
  color: ${({ theme }) => theme.pageComponents.globals.errorTextColor};
  margin: 8px 0 32px;
  font-weight: 600;
  line-height: 16px;
`;
