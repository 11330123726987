import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import Link from 'redux-first-router-link';
import styled from 'styled-components';
import { selectLoadingState } from '../../selectors';
import {
  selectEndorsements,
  selectGLLimit,
  selectGrossAnnualSales,
  selectLimitDamagePremRented,
} from '../../selectors/application';
import AddOnQuestion from './AddOnQuestions';
import { endorsementsMap } from './addOnQuestionMap';
import { EndorsementEnum } from '../../types/enums';
import { PAGES } from '../../constants';

interface AddOnsProps {
  endorsements: string[];
  endorsementsState?: EndorsementsInterface;
  isLoading: boolean;
  dispatch: Dispatch;
  grossAnnualSales: number;
  glLimit?: number;
  limitDamagePremRented?: number;
}

const AddOns = ({
  endorsements,
  endorsementsState,
  isLoading,
  dispatch,
  grossAnnualSales,
  glLimit,
  limitDamagePremRented,
}: AddOnsProps) => {
  return (
    <>
      {endorsements.map((endorsement: string) => {
        if (!endorsementsMap[endorsement]) return null;
        const { LABEL, QUESTION_COPY, subQuestions, action } = endorsementsMap[endorsement];

        const isLiquorAndHasTwoFourGlLimit =
          endorsement === EndorsementEnum.liquorLiabilityEndorsement && glLimit === 2_000_000;

        const liquorNotAvailableDescription = (
          <>
            This coverage is not available with a $2M liability limit. To add Liquor Liability Coverage, please{' '}
            {
              <StyledLink
                to={{ type: PAGES.GENERAL_LIABILITY }}
                id={PAGES.GENERAL_LIABILITY}
                className="availableLink"
                data-cy={`sidebar-to-${PAGES.GENERAL_LIABILITY}`}
              >
                go back
              </StyledLink>
            }{' '}
            and change your limits.
          </>
        );

        const description = isLiquorAndHasTwoFourGlLimit ? liquorNotAvailableDescription : QUESTION_COPY;

        const isLimitDamagePremRented = endorsement === EndorsementEnum.limitDamagePremRented;
        const hasLimitDamagePremRentedValue = limitDamagePremRented
          ? { limitDamagePremRented }
          : { limitDamagePremRented: undefined };
        const value = isLimitDamagePremRented ? hasLimitDamagePremRentedValue : endorsementsState?.[endorsement];

        return (
          <AddOnQuestion
            endorsement={endorsement}
            label={LABEL}
            description={description}
            subQuestions={subQuestions}
            value={value}
            action={(value: any) => dispatch(action(value))}
            key={endorsement}
            isLoading={isLoading}
            validationData={{ grossAnnualSales }}
            disabled={isLiquorAndHasTwoFourGlLimit}
          />
        );
      })}
    </>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  endorsementsState: selectEndorsements(state),
  isLoading: selectLoadingState(state),
  grossAnnualSales: selectGrossAnnualSales(state) || Infinity,
  glLimit: selectGLLimit(state),
  limitDamagePremRented: selectLimitDamagePremRented(state),
});

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.neutral.medium} !important;
  text-decoration-skip-ink: none;
`;

export default connect(mapStateToProps)(AddOns);
