import loadExistingApplication from './loadExistingApplication';
import { Dispatch } from 'redux';
import { setApiToken } from '../api';
import { COTERIE_PARTNER_ID } from '../constants';

const handleExistingApplication = (dispatch: Dispatch, getState: () => ReduxState) => {
  setApiToken(COTERIE_PARTNER_ID);
  const { id: incomingApplicationId } = getState().location.payload;
  const { existingApplicationLoaded } = getState();

  if (existingApplicationLoaded === incomingApplicationId) {
    return;
  }

  return loadExistingApplication(dispatch, getState, incomingApplicationId);
};

export default handleExistingApplication;
