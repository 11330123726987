import { createSelector } from 'reselect';
import { POLICY_NAMES } from '../constants';
import {
  selectPartnerInfo,
  selectChannelPartnerGuid,
  selectPartnerAgencyExternalId,
  selectPremierPlatform,
  getPartnerIdFromApplication,
  getPartnerFromApplication,
  selectCombinedPartnerInfo,
  selectIsPremierPlatformPartner,
} from './partnerInfo';
import { selectAgentProducerExternalId } from './agent';

export const selectAvailablePolicyTypes = (state) => state.availablePolicyTypes || [];
const selectAllowablePolicyTypes = (state) => state.partner && state.partner.allowablePolicyTypes;

export const policiesWithLabels = createSelector(
  selectAvailablePolicyTypes,
  selectAllowablePolicyTypes,
  (availablePolicyTypes, allowablePolicyTypes) => {
    let finalPolicyTypes = [];
    if (allowablePolicyTypes) {
      availablePolicyTypes.filter((policy) => {
        if (allowablePolicyTypes.includes(policy)) {
          return finalPolicyTypes.push(policy);
        } else {
          return undefined;
        }
      });
    } else {
      finalPolicyTypes = availablePolicyTypes;
    }

    return finalPolicyTypes.map((policy) => {
      switch (policy) {
        case 'BOP':
          return {
            name: policy,
            label: POLICY_NAMES.BOP,
            unavailableMessage:
              'BOP is not available when GL is selected. Select BOP if both liability and property coverage are needed.',
          };
        case 'PL':
          return { name: policy, label: POLICY_NAMES.PL };
        case 'GL':
          return {
            name: policy,
            label: POLICY_NAMES.GL,
            unavailableMessage: 'Standalone General Liability is not available when BOP is selected.',
          };
        default:
          return {};
      }
    });
  }
);

export const selectLoadingState = (state) => state.applicationIsLoading || state.quoteIsLoading;
export const selectQuoteBindPending = (state) => state.quoteBindPending;
export const selectAcknowledgements = (state) => state.acknowledgements;
export const selectIndustryName = (state) => state.industryName;
export const selectReasonabilityErrors = (state) => state.reasonabilityErrors;
export const selectDeclinationMessages = (state) => state.declinations || [];
export const selectPurchasedPolicy = (state) => state.purchasedPolicy ?? {};
export const selectPurchasedPolicies = (state) => state.purchasedPolicy?.policies ?? [];
export const selectTransactionFees = (state) => state.purchasedPolicy?.transactionFees;
export const selectPotentialMailingAddresses = (state) => state.potentialMailingAddresses || [];
export const selectLocationType = (state) => state.location.type;
export const selectPayloadId = (state) => state.location.payload.id;
export const selectDBAIsValid = (state) => state.dbaIsValid;

// Drop In
const selectDropIn = (state) => state.dropin;
export const selectDataIds = (state) => selectDropIn(state).dataIds;
export const selectDropInChannelPartnerGUID = (state) => selectDropIn(state).channelPartnerGUID;
export const selectCustomStyles = (state) => selectDropIn(state).customStyles;

// Partner Info
export {
  selectPartnerInfo,
  selectChannelPartnerGuid,
  selectPartnerAgencyExternalId,
  selectPremierPlatform,
  getPartnerFromApplication,
  getPartnerIdFromApplication,
  selectCombinedPartnerInfo,
  selectIsPremierPlatformPartner,
};

// Agent Info
export { selectAgentProducerExternalId };
