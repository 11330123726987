import React from 'react';
import styled from 'styled-components';

interface PropTypes {
  height?: number;
}

const StyledDivider = styled.hr`
  margin: ${(props: PropTypes) => (props.height ? `${props.height}px` : '8px 0 40px')} 0;
  border: ${(props) => props.theme.pageComponents.shared.sectionDivider.border};
`;

const SectionDivider = ({ height = 0 }: PropTypes) => <StyledDivider height={height} />;

export default SectionDivider;
