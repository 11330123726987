import { AnyAction } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { fetchAvailableEndorsements } from '../api';
import { getRequestContext, reportToSentry } from '../helpers/sentry';
import { fatalError } from './application';
import { setAvailableEndorsements, setIsLoading } from './availableEndorsements';

const handleFetchEndorsements = (): ThunkAction<void, ReduxState, unknown, AnyAction> => {
  return (dispatch: ThunkDispatch<ReduxState, unknown, AnyAction>, getState: () => ReduxState) => {
    dispatch(setIsLoading(true));
    const { application } = getState();
    return fetchAvailableEndorsements(application)
      .then((res) => {
        if (!res.data?.availableEndorsements) {
          dispatch(setAvailableEndorsements(null));
        } else if (res.data.availableEndorsements) {
          const availableEndorsements = res.data.availableEndorsements;

          // the endorsementsMap is expecting the endorsement key to be the same for available endorsements and added endorsements on an application
          // for contractors tools those two keys differ between each request, so we need to remap the key name to be consistent between available endorsement and added endorsements in the application
          if (availableEndorsements.contractorsToolsEndorsement) {
            availableEndorsements.contractorsInstallationAndTools = availableEndorsements.contractorsToolsEndorsement;
            delete availableEndorsements.contractorsToolsEndorsement;
          }

          dispatch(setAvailableEndorsements(availableEndorsements));
        }
        dispatch(setIsLoading(false));
      })
      .catch((err: any) => {
        dispatch(fatalError(err));
        dispatch(setIsLoading(false));
        reportToSentry(err, getRequestContext({ application }));
      });
  };
};

export default handleFetchEndorsements;
