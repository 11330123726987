const formatPhone = (phone: string | undefined = '') => {
  const x = phone
    .replace('+1', '')
    .replace(/\D/g, '')
    .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  const phoneNumber = x ? (!x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '')) : '';

  return phoneNumber;
};

export default formatPhone;
