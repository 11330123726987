import * as Sentry from '@sentry/react';
import { cloneDeep, Dictionary } from 'lodash';
import { convertStringToNumber } from './valueConversion';
import { isProd, isSandbox, isTest } from './environment';
import { StripeError } from '@stripe/stripe-js';
import { AxiosError } from 'axios';

export const sentryDsn = 'https://6076f3f9776b4bff88b80ded8623000d@sentry.io/1417172';

export const filteredErrorCodes: Array<number | never> = [];

const shouldReport = (error: any): Boolean => {
  const isValidEnvironment = isProd() || isSandbox() || isTest();

  const errorCode = convertStringToNumber(error?.response?.status);
  const isFilteredErrorCode = errorCode && filteredErrorCodes.includes(errorCode);

  return isValidEnvironment && !isFilteredErrorCode;
};

export const TAGS = {
  INDUSTRY_SEARCH: { key: 'component', value: 'industry-select' },
  STRIPE: { key: 'component', value: 'credit card stripe' },
  AUTHORIZE_NET: { key: 'component', value: 'credit card authorize.net' },
  ERROR_BOUNDARY: { key: 'component', value: 'error_boundary' },
};

export const CONTEXT_NAMES = {
  REQUEST: 'request data',
  RESPONSE: 'response data',
};

export const getRequestContext = (data: Dictionary<any>) => ({ name: CONTEXT_NAMES.REQUEST, data });

export const getResponseContext = (data: Dictionary<any>) => ({ name: CONTEXT_NAMES.RESPONSE, data });

// list of properties that contain personally identifiable information
export const stateDenyList = [
  'contactFirstName',
  'contactLastName',
  'contactEmail',
  'contactPhone',
  'mailingAddressStreet',
  'mailingAddressCity',
  'mailingAddressState',
  'mailingAddressZip',
  'name',
  'email',
  'street',
  'city',
  'state',
  'zip',
  'metadata',
  'legalBusinessName',
  'dba',
];

export type SentryContext = {
  name: string;
  data: Dictionary<any>;
};

export type SentryTag = {
  key: string;
  value: string;
};

export type ErrorToReport = string | Error | StripeError | AxiosError;

/**
 * Logs an event to Sentry.io
 * @arg error - Captures error events in Sentry.io https://docs.sentry.io/platforms/javascript/usage/
 * @arg context - Adds additional context to events https://docs.sentry.io/platforms/javascript/enriching-events/context/
 * @arg tag - Adds a indexed and searchable tag to events https://docs.sentry.io/platforms/javascript/enriching-events/tags/
 */
export function reportToSentry(error: ErrorToReport, context?: SentryContext, tag?: SentryTag | SentryTag[]) {
  if (context) {
    let contextData = cloneDeep(context.data);

    try {
      getSanitizedData(contextData, stateDenyList);
    } catch {
      contextData = {};
    }

    Sentry.configureScope((scope) => scope.setContext(context.name, contextData));
  }

  if (tag) {
    Sentry.configureScope((scope) => {
      if (Array.isArray(tag)) {
        tag.forEach((tagElem) => scope.setTag(tagElem.key, tagElem.value));
      } else {
        scope.setTag(tag.key, tag.value);
      }
    });
  }

  if ((error as AxiosError)?.response?.status) {
    Sentry.configureScope((scope) => {
      scope.setTag('http status', (error as AxiosError)?.response?.status);
    });
  }

  if ((error as AxiosError)?.response) {
    Sentry.configureScope((scope) => {
      scope.setContext('response data', { response: (error as AxiosError).response });
    });
  }

  if ((error as AxiosError)?.request) {
    Sentry.configureScope((scope) => {
      scope.setContext('request data', { request: (error as AxiosError).request });
    });
  }

  if (shouldReport(error)) {
    Sentry.captureException(error);
  }
}

export function sentrySetup() {
  if (isSandbox()) {
    Sentry.init({
      dsn: sentryDsn,
      normalizeDepth: 10,
      environment: 'sandbox',
    });
  }
  if (isProd()) {
    Sentry.init({
      dsn: sentryDsn,
      normalizeDepth: 10,
      environment: 'production',
    });
  }
  if (isTest()) {
    Sentry.init({
      dsn: sentryDsn,
      normalizeDepth: 10,
      environment: 'test',
    });
  }
}

export const transformToMetaData = (value: any) => {
  if (value == null) {
    return value;
  } else if (Array.isArray(value)) {
    return [
      'sanitized_data',
      {
        type: 'array',
        length: value.length,
      },
    ];
  } else if (typeof value === 'object') {
    return [
      'sanitized_data',
      {
        type: 'object',
        properties: Object.keys(value).length,
      },
    ];
  } else if (typeof value === 'string' || typeof value === 'number') {
    return [
      'sanitized_data',
      {
        type: typeof value,
        length: value.toString().length,
      },
    ];
  } else {
    return ['sanitized_data', { type: typeof value }];
  }
};

const sanitizeProperty = (state: any, property: string) => {
  state[property] = transformToMetaData(state[property]);
};

export const getSanitizedData = (state: any, denyList: string[]): void => {
  if (!state || !Object.keys(state).length) return;

  Object.keys(state).forEach((property) => {
    const isDenied = denyList.includes(property);
    const isArray = Array.isArray(state[property]);
    const isObject = state[property] && typeof state[property] === 'object' && Object.keys(state[property]).length;

    if (isDenied) {
      sanitizeProperty(state, property);
    } else if (isArray) {
      state[property].forEach((item: any) => {
        getSanitizedData(item, denyList);
      });
    } else if (isObject) {
      getSanitizedData(state[property], denyList);
    }
  });
};

/**
 * Generates the Sentry redux enhancer options for what actions and state values need to be sanitized before being logged.
 * @param actionsDenyList - array of action types to be sanitized before being logged to Sentry
 * @param stateDenyList - array of keys names to be sanitized from state before being logged to Sentry
 */
export const generateSentryReduxOptions = (actionsDenyList: string[], stateDenyList: string[]) => ({
  actionTransformer: (action: { type: string; data: any }) => {
    return actionsDenyList.includes(action.type)
      ? {
          type: action.type,
          data: transformToMetaData(action.data),
        }
      : action;
  },
  stateTransformer: (state: ReduxState) => {
    const transformedState = cloneDeep(state);
    try {
      getSanitizedData(transformedState, stateDenyList);
      return transformedState;
    } catch {
      return {};
    }
  },
});
