import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { Paragraph, Input, ButtonContainer, SelectButton } from '../../elements';

interface MultiButtonOptionType {
  title: string;
  values: any[];
  customInput?: string | null;
  placeholder?: string | null;
}

interface PropTypes {
  value: any;
  updateValue: (value: any) => void;
  available: boolean;
  directLabel: string;
  money?: boolean;
  options: MultiButtonOptionType[];
}

interface StateTypes {
  activeButtonTitle: string;
  showDirectInput: boolean;
  selectedIndex: number;
  triggerDirectInputFocus: boolean;
  directInputText: string | number;
}

class MultiButton extends Component<PropTypes, StateTypes> {
  private directInput: any;

  constructor(props: PropTypes) {
    super(props);
    this.state = {
      activeButtonTitle: '',
      showDirectInput: false,
      selectedIndex: 0,
      triggerDirectInputFocus: false,
      directInputText: this.props.value || '',
    };
    this.directInput = React.createRef();
  }

  componentDidMount() {
    // If value is set find the right detail button to show
    if (this.props.value && (!this.state.activeButtonTitle || this.state.showDirectInput)) {
      const activeButton = this.props.options.find((option) => option.values.includes(this.props.value));
      if (activeButton) {
        this.setState({ activeButtonTitle: activeButton.title });
      } else {
        this.setState({ showDirectInput: true });
      }
    }
  }

  changeRange = () => {
    this.setState({ activeButtonTitle: '', showDirectInput: false }, () => {
      this.props.updateValue(null);
    });
  };

  incrementSelection = () => {
    const activeOption = this.props.options.find((option) => option.title === this.state.activeButtonTitle);
    if (activeOption === undefined) {
      return;
    }
    const currentIndex = activeOption.values.findIndex((value) => value === this.props.value);

    if (currentIndex === activeOption.values.length - 1) {
      return;
    }

    this.props.updateValue(activeOption.values[currentIndex + 1]);
  };

  decrementSelection = () => {
    const activeOption = this.props.options.find((option) => option.title === this.state.activeButtonTitle);
    if (activeOption === undefined) {
      return;
    }
    const currentIndex = activeOption.values.findIndex((value) => value === this.props.value);
    this.props.updateValue(activeOption.values[currentIndex - 1]);
  };

  showDirect = () => {
    this.setState({ showDirectInput: true, triggerDirectInputFocus: true });
  };

  componentDidUpdate(prevProps: PropTypes, prevState: StateTypes) {
    if (this.state.showDirectInput && !prevState.showDirectInput && this.state.triggerDirectInputFocus) {
      if (this.props.options.find((item) => item.values.includes(this.props.value))) {
        this.directInput.value = '';
      } else this.directInput.value = this.props.value;
      this.directInput.focus();
    }
    if (typeof prevProps.value === 'number' && this.props.value === null) {
      this.setState({
        directInputText: '',
        activeButtonTitle: '',
        showDirectInput: false,
      });
    }

    if (typeof this.props.value === 'number' && prevProps.value === null && !this.state.showDirectInput) {
      const { options, value } = this.props;
      // looks to see if a pre selected option matches with the inputed value
      const selectedOption = options.find((item) => item.values.includes(value));

      // get the highest option title for the direct input
      const directInputTitle = options[options.length - 1]?.title;
      this.setState({
        // if no pre selected options match the given value we know we should show the direct value input
        activeButtonTitle: selectedOption?.title || directInputTitle,
        showDirectInput: !selectedOption,
        directInputText: !selectedOption ? value : '',
      });
    }
  }

  handleDirectInputChange = (value: string | number) => {
    this.setState({
      directInputText: value,
    });
  };

  renderTopLevelButtons = () => (
    <ButtonContainer>
      {this.props.options.map((option) => (
        <SelectButton
          aria-label={`${this.props.directLabel}: ${option.title}`}
          type="button"
          key={option.title}
          onClick={() => this.setState({ activeButtonTitle: option.title })}
          disabled={!this.props.available}
          data-cy={`multi-button-${option.title.replace(/\s/g, '-').replace('$', '').replace(',', '')}`}
        >
          {option.title}
        </SelectButton>
      ))}
    </ButtonContainer>
  );

  renderDrillDownRow = () => {
    const activeOption = this.props.options.find((option) => option.title === this.state.activeButtonTitle);
    const buttonList = activeOption?.values.map((value) => {
      if (value === this.props.value) {
        return <ActiveOption key={value.toString()}>{value}</ActiveOption>;
      } else {
        return (
          <Option
            type="button"
            key={value.toString()}
            onClick={() => this.props.updateValue(value)}
            active={value === this.props.value}
            data-cy={`multi-button-${value}`}
            data-testid={`multi-button-${value}`}
            aria-label={`${this.props.directLabel}: ${value}`}
            aria-pressed={value === this.props.value}
          >
            {value}
          </Option>
        );
      }
    });

    if (window.innerWidth < 767) {
      // TODO: Use ReactMedia for this
      if (!this.props.value && activeOption) {
        this.props.updateValue(activeOption.values[0]);
      }

      const currentIndex = activeOption?.values.findIndex((value) => value === this.props.value);

      return (
        <Fragment>
          <ClearText onClick={this.changeRange}>&#8592;{this.state.activeButtonTitle}</ClearText>
          <ButtonRow>
            <IncrementButton onClick={this.decrementSelection} type="button">
              -
            </IncrementButton>
            <Value>{this.props.value!}</Value>
            {activeOption && currentIndex === activeOption.values.length - 1 ? (
              <IncrementButton onClick={this.showDirect} type="button">
                +
              </IncrementButton>
            ) : (
              <IncrementButton onClick={this.incrementSelection} type="button">
                +
              </IncrementButton>
            )}
          </ButtonRow>
        </Fragment>
      );
    }

    if (activeOption?.customInput) {
      buttonList?.push(
        <Option
          key={activeOption.customInput}
          onClick={this.showDirect}
          data-cy={`multi-button-custom`}
          aria-label={`${this.props.directLabel}: ${activeOption.customInput}`}
        >
          {activeOption.customInput}
        </Option>
      );
    }

    return (
      <Fragment>
        <ClearText onClick={this.changeRange}>&#8592;{this.state.activeButtonTitle}</ClearText>
        <OptionRow>{buttonList}</OptionRow>
      </Fragment>
    );
  };

  render() {
    const { value, updateValue, directLabel } = this.props;
    const { showDirectInput, activeButtonTitle, directInputText } = this.state;

    if (showDirectInput) {
      return (
        <DirectContainer>
          <ClearText onClick={this.changeRange} data-testid="direct-back-button">
            &#8592;{this.state.activeButtonTitle}
          </ClearText>
          <Paragraph>{directLabel}</Paragraph>
          <Input
            type="text"
            value={this.state.directInputText || ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleDirectInputChange(e.target.value)}
            onBlur={(e: React.FormEvent<HTMLInputElement>) => {
              updateValue(Number(directInputText));
            }}
            customWidth="quarter"
            ref={(el: any) => (this.directInput = el)}
            aria-label={`${this.props.directLabel} input`}
            data-cy="multi-button-direct-input"
          />
        </DirectContainer>
      );
    }
    if (!value && !activeButtonTitle) {
      return this.renderTopLevelButtons();
    }
    if (activeButtonTitle) {
      return this.renderDrillDownRow();
    }

    return this.renderTopLevelButtons();
  }
}

export const OptionRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 64px;
  width: 100%;
  flex-direction: row;
  box-sizing: border-box;
`;

export const Option = styled.button<{ options?: any; active?: boolean; items?: number }>`
  text-align: center;
  border: ${(props) => props.theme.selectors.multiSelect.borderUnselected};
  color: ${(props) => props.theme.selectors.multiSelect.textColorUnselected};
  display: inline-flex;
  box-sizing: border-box;
  justify-content: center;
  flex: 1;
  align-items: center;
  padding: 16px 12px;
  height: 20px;
  background-color: ${(props) => props.theme.selectors.multiSelect.backgroundUnselected};
  :last-child {
    border-top-right-radius: 23px;
    border-bottom-right-radius: 23px;
  }
  :first-child {
    border-top-left-radius: 23px;
    border-bottom-left-radius: 23px;
  }
  font-family: ${(props) => props.theme.font.typeface.primary};
  font-size: 12px;
  font-weight: 600;
  letter-spacing: -0.2px;
  :hover {
    cursor: pointer;
  }
`;

const ActiveOption = styled(Option)`
  color: ${(props) => props.theme.selectors.multiSelect.textColorSelected};
  background-color: ${(props) => props.theme.selectors.multiSelect.backgroundSelected};
  min-width: 72px;
  width: 100%;
  height: 48px;
  border-radius: 23px;
  margin: 0 -10px;
  font-size: 16px;
  z-index: 2;
  position: relative;
  border: none;
`;

const ClearText = styled.a`
  display: block;
  font-family: ${(props) => props.theme.font.typeface.primary};
  color: ${(props) => props.theme.pageComponents.shared.multiButton.clearText.textColor};
  font-size: 13px;
  font-weight: 600;
  margin-top: 5px;
  justify-self: flex-start;
  align-self: flex-start;
  :hover {
    cursor: pointer;
  }
`;

const DirectContainer = styled.div`
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 0px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const ButtonRow = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  max-width: 300px;
  margin: auto;
  align-items: center;
`;

export const IncrementButton = styled(SelectButton)`
  font-weight: bold;
  font-size: 20px;
  margin: 0 5px;
  height: 50px;
  min-width: 52px;

  :disabled {
    color: #6D6D8F;
    background-color: #EBEDF2;
  }
`;

export const Value = styled.h3`
  color: ${(props) => props.theme.pageComponents.shared.multiButton.value.textColor};
  font-family: ${(props) => props.theme.font.typeface.primary};
  min-width: 70px;
  text-align: center;
`;

export default MultiButton;
