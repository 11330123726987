import get from 'lodash/get';

const minimumApplicationFieldsRequired = (application: Application) => {
  const isGL = application.applicationTypes?.includes('GL');
  const isBOP = application.applicationTypes?.includes('BOP');
  const checkForMissing = (property: string) => !(typeof get(application, property) !== 'undefined');
  const addEmployees = isGL || isBOP ? ['numEmployees'] : [];

  return [...['locations[0].zip', 'industryId'], ...addEmployees].filter(checkForMissing).length === 0;
};

export default minimumApplicationFieldsRequired;
