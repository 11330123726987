import { setApiToken, fetchPartnerInfo } from '../api';
import { getPartnerInfo, getAdditionalInsured } from '../helpers/whitelabel';
import { clearApplication, addAdditionalInsured, setPartnerInfo, fatalError } from './application';
import ReactGA from 'react-ga';
import { reportToSentry } from '../helpers/sentry';
import { ENVIRONMENT_URL } from '../constants';
import { Dispatch } from 'redux';

const handlePartnerInbound = async (dispatch: Dispatch, getState: () => ReduxState) => {
  try {
    const { query, payload } = getState().location;
    setApiToken(payload.id);

    const {
      data: { name, allowablePolicyTypes, premierPlatform },
    } = await fetchPartnerInfo(payload.id);

    const partnerInfo = getPartnerInfo({ ...payload, ...query });
    dispatch(clearApplication());

    // Hardcoded additional insureds for IES and Leidos
    const additionalInsureds = getAdditionalInsured(payload.id);
    if (additionalInsureds.name) {
      dispatch(addAdditionalInsured(additionalInsureds));
    }

    const useIvidAsLeadId = query && query.ivid ? { lead_id: query.ivid } : {};
    dispatch(setPartnerInfo({ ...partnerInfo, name, allowablePolicyTypes, premierPlatform, ...useIvidAsLeadId }));

    // TODO: Improve this tracking
    if (window.location.hostname.toLowerCase() === ENVIRONMENT_URL.PRODUCTION) {
      ReactGA.initialize('UA-127203748-3');
      ReactGA.event({
        category: 'Partner',
        action: `${payload.id}`,
        label: `${query && query.userId ? query.userId : name}`,
        nonInteraction: true,
      });
    }
  } catch (error) {
    reportToSentry(error as any);
    dispatch(fatalError(error));
  }
};

export default handlePartnerInbound;
