import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Paragraph } from '../../elements';
import handleGetAcknowledgements from '../../actions/handleGetAcknowledgements';
import { handlePaymentTransaction, clearPaymentTransactionErrors } from '../../actions/payment';
import { selectPaymentTransactionErrors } from '../../selectors/payment';
import {
  selectPolicyTypes,
  selectUserHasSubmittedCheckoutForm,
  selectUserHasCompletedCheckoutForm,
} from '../../selectors/application';
import {
  selectQuoteBindPending,
  selectAcknowledgements,
  selectPartnerAgencyExternalId,
  selectAgentProducerExternalId,
} from '../../selectors/index';
import { selectQuoteId, selectStateNoticeText } from '../../selectors/quote';
import AuthNetCheckout from './AuthNetCheckout';
import FraudSwitch from '../../elements/Switch';
import { ENVIRONMENT_URL } from '../../constants';
import {
  fatalError,
  resetUserHasCompletedCheckoutForm,
  resetUserHasSubmittedCheckoutForm,
  setUserHasCompletedCheckoutForm,
} from '../../actions/application';
import GhostButton from '../Shared/GhostButton';
import SectionDivider from '../Shared/SectionDivider';
import { PolicyType } from '../../types/enums';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { ThemeContext } from '../../elements/theme/CustomThemeProvider';
import StripeCheckout from './StripeCheckout';
import { Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { getResponseContext, reportToSentry, TAGS } from '../../helpers/sentry';
import { usePostBindQuote } from '../../hooks/usePostBindQuote';
import { getPaymentInterval } from '../../selectors/routing';
import { policyPurchased, policyPurchaseFailed, quotePurchasePending } from '../../actions/quote';
import { isProd } from '../../helpers/environment';
import { useFeatureFlags } from '../../toggle_tools/featureFlagTools';
import { MobileAndTablet } from '../../helpers/responsive';
import InfoSidebar from '../../Components/Shared/InfoSidebar';

export const processingErrorMessage = 'Error processing credit card, please try again';

interface CheckoutFormProps {
  quoteId: string;
  quoteBindPending: boolean;
  stateNoticeText: string;
  acknowledgements: Acknowledgements;
  applicationTypes: PolicyType[];
  hasPaymentTransactionError: boolean;
  userHasSubmittedCheckoutForm: boolean;
  userHasCompletedCheckoutForm: boolean;
  paymentInterval?: string;
  agencyExternalId?: string;
  producerExternalId?: string;
  handleGetAcknowledgements: () => void;
  resetUserHasCompletedCheckoutForm: () => void;
  resetUserHasSubmittedCheckoutForm: () => void;
  clearPaymentTransactionErrors: () => void;
  handlePaymentTransaction: (cardExp: string, cardNumber: string, cardCode: string) => void;
  setUserHasCompletedCheckoutForm: () => void;
  fatalError: (error: any) => void;
  policyPurchased: (response: any) => void;
  policyPurchaseFailed: (errors: any) => void;
  quotePurchasePending: () => void;
}

const CheckoutForm = ({
  quoteId,
  quoteBindPending,
  stateNoticeText,
  acknowledgements,
  applicationTypes,
  hasPaymentTransactionError,
  userHasSubmittedCheckoutForm,
  userHasCompletedCheckoutForm,
  paymentInterval,
  agencyExternalId,
  producerExternalId,
  handleGetAcknowledgements,
  resetUserHasCompletedCheckoutForm,
  resetUserHasSubmittedCheckoutForm,
  clearPaymentTransactionErrors,
  handlePaymentTransaction,
  setUserHasCompletedCheckoutForm,
  fatalError,
  policyPurchased,
  policyPurchaseFailed,
  quotePurchasePending,
}: CheckoutFormProps) => {
  const { theme } = useContext(ThemeContext);
  const [cardNumber, setCardNumber] = useState('');
  const [cardCode, setCardCode] = useState('');
  const [cardExp, setCardExp] = useState('');
  const [statusMessage, setStatusMessage] = useState(hasPaymentTransactionError ? processingErrorMessage : '');
  const [acceptedFraudNotice, setAcceptedFraudNotice] = useState(false);
  const [acceptedTermsAndConditions, setAcceptedTermsAndConditions] = useState(false);
  const [acceptedStateRequirements, setAcceptedStateRequirements] = useState(false);
  const [readAcknowledgements, setReadAcknowledgements] = useState(false);
  const [reasonabilityError, setReasonabilityError] = useState('');
  const { useStripeCheckout } = useFeatureFlags();
  const stripe = useStripe();
  const elements = useElements();

  const { mutate: postBindRequest, isLoading } = usePostBindQuote({
    onSuccess: (response: any) => {
      if (response.data.isSuccess) {
        policyPurchased(response.data);
      } else {
        policyPurchaseFailed(response.data.errors);
        reportToSentry(response.data.errors, getResponseContext(response));
      }
    },
    onError: (error: any) => {
      const tag = useStripeCheckout ? TAGS.STRIPE : TAGS.AUTHORIZE_NET;
      reportToSentry(error, undefined, tag);
      fatalError(error);
    },
  });

  const readyToSubmit = useCallback(() => {
    if (quoteBindPending || !quoteId) {
      userHasCompletedCheckoutForm && resetUserHasCompletedCheckoutForm();
      return false;
    }

    const hasReadAcknowledgements = applicationTypes.includes(PolicyType.PL) ? readAcknowledgements : true;
    const formIsCompleted = cardNumber.length === 19 && cardExp.length === 5 && cardCode.length > 2;

    const hasAcceptedAndReadAck =
      acceptedFraudNotice && acceptedTermsAndConditions && hasReadAcknowledgements && acceptedStateRequirements;

    if ((formIsCompleted || useStripeCheckout) && hasAcceptedAndReadAck) {
      setUserHasCompletedCheckoutForm();
      return true;
    }

    userHasCompletedCheckoutForm && resetUserHasCompletedCheckoutForm();
    return false;
  }, [
    quoteBindPending,
    quoteId,
    applicationTypes,
    readAcknowledgements,
    cardNumber,
    cardExp,
    cardCode,
    acceptedFraudNotice,
    acceptedTermsAndConditions,
    acceptedStateRequirements,
    userHasCompletedCheckoutForm,
    useStripeCheckout,
    setUserHasCompletedCheckoutForm,
    resetUserHasCompletedCheckoutForm,
  ]);

  const handleSubmit = useCallback(
    async (e?: any) => {
      e?.preventDefault();

      if (readyToSubmit()) {
        resetUserHasSubmittedCheckoutForm();
        if (useStripeCheckout) {
          if (isLoading) return;

          const cardElement = elements && elements.getElement('cardNumber');

          if (stripe && cardElement) {
            const { token, error } = await stripe.createToken(cardElement);
            if (error) {
              const hasStripeReasonabilityError = error.type === 'card_error' || error.type === 'validation_error';
              const stripeErrorMsg = error.message;
              if (hasStripeReasonabilityError && stripeErrorMsg) {
                setReasonabilityError(stripeErrorMsg);
              } else {
                reportToSentry(error, undefined, TAGS.STRIPE);
                fatalError(error);
              }
            } else if (token) {
              setReasonabilityError('');
              quotePurchasePending();
              postBindRequest({
                quoteId,
                tokenId: token.id,
                paymentInterval,
                useStripeCheckout,
                agencyExternalId,
                producerExternalId,
              });
            }
          } else {
            reportToSentry('Stripe Failed To initialize', undefined, TAGS.STRIPE);
            fatalError('Stripe Failed To initialize');
          }
        } else {
          handlePaymentTransaction(cardExp, cardNumber, cardCode);
        }
      }
    },
    [
      cardExp,
      cardNumber,
      cardCode,
      elements,
      stripe,
      useStripeCheckout,
      paymentInterval,
      quoteId,
      agencyExternalId,
      producerExternalId,
      isLoading,
      postBindRequest,
      resetUserHasSubmittedCheckoutForm,
      handlePaymentTransaction,
      readyToSubmit,
      fatalError,
      quotePurchasePending,
    ]
  );

  useEffect(() => {
    if (!useStripeCheckout) {
      const script = document.createElement('script');

      if (window.location.hostname === ENVIRONMENT_URL.PRODUCTION) {
        script.src = 'https://js.authorize.net/v1/Accept.js';
      } else {
        script.src = 'https://jstest.authorize.net/v1/Accept.js';
      }
      document.head.appendChild(script);
    }
  }, [useStripeCheckout]);

  useEffect(() => {
    if (applicationTypes.includes(PolicyType.PL) && !acknowledgements.pl) {
      handleGetAcknowledgements();
    }
  }, [applicationTypes, acknowledgements, handleGetAcknowledgements]);

  useEffect(() => {
    if (hasPaymentTransactionError) {
      setCardNumber('');
      setCardCode('');
      setCardExp('');
      setStatusMessage(processingErrorMessage);
      resetUserHasSubmittedCheckoutForm();
    }

    if (readyToSubmit() && userHasSubmittedCheckoutForm) {
      handleSubmit();
    }
  }, [
    hasPaymentTransactionError,
    userHasSubmittedCheckoutForm,
    handleSubmit,
    readyToSubmit,
    resetUserHasSubmittedCheckoutForm,
  ]);

  const updateCard = (e: React.ChangeEvent) => {
    clearPaymentTransactionErrors();
    resetUserHasCompletedCheckoutForm();
    switch ((e.target as HTMLInputElement).name) {
      case 'cardNumber':
        setCardNumber((e.target as HTMLInputElement).value);
        break;
      case 'cardCode':
        setCardCode((e.target as HTMLInputElement).value);
        break;
      case 'cardExp':
        setCardExp((e.target as HTMLInputElement).value);
        break;
    }
  };

  return (
    <CheckoutFormCont data-testid="checkout_form" onSubmit={handleSubmit}>
      <MobileAndTablet>
        <InfoSidebar />
      </MobileAndTablet>
      <PaymentCont>
        <SectionTitle>Credit card information</SectionTitle>
        {useStripeCheckout ? (
          <StripeCheckout reasonabilityError={reasonabilityError} setReasonabilityError={setReasonabilityError} />
        ) : (
          <AuthNetCheckout
            updateCard={updateCard}
            cardNumber={cardNumber}
            cardCode={cardCode}
            cardExp={cardExp}
            statusMessage={statusMessage}
          />
        )}
      </PaymentCont>
      <SectionDivider />
      <FraudNoticeCont>
        <FraudNoticeRow>
          <FraudLabel htmlFor="fraudNoticeSwitch">I acknowledge the state fraud notice</FraudLabel>
          <FraudSwitch
            onChange={() => setAcceptedFraudNotice((acceptedFraudNotice) => !acceptedFraudNotice)}
            checked={acceptedFraudNotice}
            dataCy="fraudNoticeButton"
            id="fraudNoticeSwitch"
            options={{ backgroundColor: theme.pageComponents.summary.checkoutForm.switch.background }}
          />
        </FraudNoticeRow>
        <DarkLabel>{stateNoticeText}</DarkLabel>
      </FraudNoticeCont>
      <FraudNoticeCont>
        <FraudNoticeRow>
          <FraudLabel htmlFor="stateRequirementsSwitch">
            All policy premiums, taxes, surcharges, and applicable fees have been reviewed prior to purchase in
            accordance with state requirements.
          </FraudLabel>
          <FraudSwitch
            onChange={() => setAcceptedStateRequirements((acceptedStateRequirements) => !acceptedStateRequirements)}
            checked={acceptedStateRequirements}
            dataCy="stateRequirementsButton"
            id="stateRequirementsSwitch"
            options={{ backgroundColor: theme.pageComponents.summary.checkoutForm.switch.background }}
          />
        </FraudNoticeRow>
      </FraudNoticeCont>
      <FraudNoticeCont>
        <FraudNoticeRow>
          <FraudLabel htmlFor="termsConditionsNoticeSwitch">
            I have reviewed Coterie's{' '}
            <Link href={`https://${process.env.REACT_APP_DOMAIN}/terms-conditions`} target="_blank" rel="noopener">
              Terms & Conditions
            </Link>{' '}
            and{' '}
            <Link href={`https://${process.env.REACT_APP_DOMAIN}/privacy-policy`} target="_blank" rel="noopener">
              Privacy Policy
            </Link>
            .
          </FraudLabel>
          <FraudSwitch
            onChange={() => setAcceptedTermsAndConditions((acceptedTermsAndConditions) => !acceptedTermsAndConditions)}
            checked={acceptedTermsAndConditions}
            dataCy="termsConditionsNoticeButton"
            id="termsConditionsNoticeSwitch"
            options={{ backgroundColor: theme.pageComponents.summary.checkoutForm.switch.background }}
          />
        </FraudNoticeRow>
      </FraudNoticeCont>
      {applicationTypes.includes(PolicyType.PL) && (
        <FraudNoticeCont>
          <FraudNoticeRow>
            <FraudLabel htmlFor="plAcknowledgementsSwitch">
              I have read the{' '}
              <Link href={acknowledgements.pl ? acknowledgements.pl.url : ''} target="_blank" rel="noopener">
                Professional Liability terms and exclusions
              </Link>
            </FraudLabel>
            <FraudSwitch
              onChange={() => setReadAcknowledgements((readAcknowledgements) => !readAcknowledgements)}
              checked={readAcknowledgements}
              dataCy="plAcknowledgements"
              id="plAcknowledgementsSwitch"
              options={{ backgroundColor: theme.pageComponents.summary.checkoutForm.switch.background }}
            />
          </FraudNoticeRow>
        </FraudNoticeCont>
      )}
      <GhostButton ariaLabel="submit payment" />
    </CheckoutFormCont>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  quoteId: selectQuoteId(state),
  quoteBindPending: selectQuoteBindPending(state),
  stateNoticeText: selectStateNoticeText(state),
  acknowledgements: selectAcknowledgements(state),
  applicationTypes: selectPolicyTypes(state),
  hasPaymentTransactionError: selectPaymentTransactionErrors(state)?.length > 0,
  userHasSubmittedCheckoutForm: selectUserHasSubmittedCheckoutForm(state),
  userHasCompletedCheckoutForm: selectUserHasCompletedCheckoutForm(state),
  paymentInterval: getPaymentInterval(state),
  agencyExternalId: selectPartnerAgencyExternalId(state),
  producerExternalId: selectAgentProducerExternalId(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch<ReduxState, unknown, AnyAction>) => ({
  clearPaymentTransactionErrors: () => dispatch(clearPaymentTransactionErrors()),
  resetUserHasCompletedCheckoutForm: () => dispatch(resetUserHasCompletedCheckoutForm()),
  resetUserHasSubmittedCheckoutForm: () => dispatch(resetUserHasSubmittedCheckoutForm()),
  handlePaymentTransaction: (cardExp: string, cardNumber: string, cardCode: string) =>
    dispatch(handlePaymentTransaction(cardExp, cardNumber, cardCode)),
  handleGetAcknowledgements: () => dispatch(handleGetAcknowledgements()),
  setUserHasCompletedCheckoutForm: () => dispatch(setUserHasCompletedCheckoutForm()),
  fatalError: (error: any) => dispatch(fatalError(error)),
  policyPurchased: (response: any) => dispatch(policyPurchased(response)),
  policyPurchaseFailed: (errors: any) => dispatch(policyPurchaseFailed(errors)),
  quotePurchasePending: () => dispatch(quotePurchasePending()),
});

const CheckoutFormCont = styled.form`
  position: relative;
`;

const PaymentCont = styled.div`
  position: relative;
  @media print {
    display: none;
  }
`;

const FraudNoticeRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

const FraudNoticeCont = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0;
  padding: 0 0 32px 0;

  @media print {
    display: none;
  }
`;

const FraudLabel = styled.label`
  color: ${(props) => props.theme.pageComponents.summary.checkoutForm.fraudLabel.textColor};
  font-family: ${(props) => props.theme.font.typeface.primary};
  font-weight: 600;
  font-size: 16px;
  :hover {
    cursor: pointer;
  }
`;

const DarkLabel = styled(Paragraph)`
  color: ${(props) => props.theme.pageComponents.summary.checkoutForm.darkLabel.textColor};
  font-family: ${(props) => props.theme.font.typeface.secondary};
  font-weight: 400;
  max-width: 512px;
  font-size: 15px;
  letter-spacing: 0.27px;
  line-height: 20px;
`;

const Link = styled.a`
  color: ${(props) => props.theme.pageComponents.summary.checkoutForm.link.textColor};
  font-weight: bold;
  text-decoration: none;
`;

const SectionTitle = styled.h2`
  color: ${(props) => props.theme.pageComponents.summary.checkoutForm.sectionTitle.textColor};
  font-size: 24px;
  font-family: ${(props) => props.theme.font.typeface.primary};
  font-weight: 600;
  margin: 0 0 16px 0;
`;

const ConnectedCheckoutForm = connect(mapStateToProps, mapDispatchToProps)(CheckoutForm);

const WrappedCheckoutForm: React.FC = (props) => {
  const elementOptions = {
    fonts: [{ cssSrc: 'https://fonts.googleapis.com/css?family=Questrial' }],
  };

  // TODO move this to env variable after env refactor
  const publishableKey = isProd()
    ? 'pk_live_51IF1byI0JrvxM1u0ITfcQnT1Xrr6wFh0L4P8rb7zro62HyXxJnUS8Kuo4504s1OdEkO7KiD9epkZ7kzaBjHTkjMo00YfeuGBkE'
    : 'pk_test_51IF1byI0JrvxM1u0rQYl5GKl4jkLGEk6PRVZJrXJ07Rc3tyOlUQPL9aPgusa1fQtRl5NtrTyX7XXuq1jKhevPJNY00vZsNngBX';
  const stripePromise = loadStripe(publishableKey);

  return (
    <Elements options={elementOptions} stripe={stripePromise}>
      <ConnectedCheckoutForm {...props} />
    </Elements>
  );
};

export default WrappedCheckoutForm;
