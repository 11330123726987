import React from 'react';
import { ThreeDots } from 'react-loader-spinner';
import { useThemeContext } from '../../elements/theme/CustomThemeProvider';

interface SpinnerProps {
  height?: number;
  width?: number;
  useAltColor?: boolean;
}

const Spinner = ({ height = 60, width = 100, useAltColor }: SpinnerProps) => {
  const { theme } = useThemeContext();
  const loadingSpinnerColor = useAltColor
    ? theme.pageComponents.globals.loadingSpinnerAltColor
    : theme.pageComponents.globals.loadingSpinnerColor;

  return <ThreeDots color={loadingSpinnerColor} height={height} width={width} />;
};

export default Spinner;
