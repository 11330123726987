import React from 'react';
import GhostButton from '../Components/Shared/GhostButton';
import { Container, Paragraph } from '../elements';
import AddOnsComponent from '../Components/AddOns';
import handleFormSubmit from '../actions/handleFormSubmit';
import { connect } from 'react-redux';
import { getAvailableEndorsements } from '../selectors/availableEndorsements';
import { endorsementsMap } from '../Components/AddOns/addOnQuestionMap';
import styled from 'styled-components';
import sparkles from '../icons/sparkles.svg';
import shieldCheck from '../icons/shield-check.svg';

const pageDescription =
  'Based on your choices, we think you should consider ' +
  'the following additional coverages. ' +
  'Select the ones you want to add to your policy.';

interface AddOnsProps {
  handleFormSubmit: () => void;
  availableEndorsements: AvailableEndorsementsState;
}

const AddOns = ({ handleFormSubmit, availableEndorsements }: AddOnsProps) => {
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleFormSubmit();
  };

  const endorsements = Object.keys({ ...availableEndorsements, limitDamagePremRented: 50000 });

  const recommendedEndorsements = endorsements.filter(
    (endorsement: string) => endorsementsMap[endorsement] && endorsementsMap[endorsement].recommended
  );

  const otherEndorsements = endorsements.filter(
    (endorsement: string) => endorsementsMap[endorsement] && !endorsementsMap[endorsement].recommended
  );

  return (
    <Container data-cy="Add-Ons-Page">
      <Paragraph>{pageDescription}</Paragraph>
      <Form onSubmit={handleSubmit}>
        <AddOnHeader>
          <AddOnHeaderImage src={sparkles} /> Recommended
        </AddOnHeader>
        <AddOnsComponent endorsements={recommendedEndorsements} />
        <AddOnHeader>
          <AddOnHeaderImage src={shieldCheck} />
          Additional Add Ons
        </AddOnHeader>
        <AddOnsComponent endorsements={otherEndorsements} />
        <GhostButton />
      </Form>
    </Container>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  availableEndorsements: getAvailableEndorsements(state),
});

const AddOnHeader = styled.h2`
  text-transform: uppercase;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 16px;
  margin-top: 0px;
  display: flex;
  align-items: center;

  &:last-of-type {
    margin-top: 56px;
  }
`;

const Form = styled.form`
  margin-top: 32px;
`;

const AddOnHeaderImage = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 16px;
`;

export default connect(mapStateToProps, { handleFormSubmit })(AddOns);
