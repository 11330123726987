import React from 'react';
import { PolicyType } from '../../../types/enums';

interface AddPolicyProps {
  application: Application;
  availablePoliciesToAdd: PolicyType[];
}

const shouldshowAddPolicy = (BaseComponent: React.ComponentClass<any>) => (props: AddPolicyProps) => {
  const selectedPolicies = props.application.applicationTypes;
  const availablePolicies = props.availablePoliciesToAdd;

  const plPolicyAlreadyAdded = selectedPolicies?.includes('PL');
  const glPolicyAlreadyAdded = selectedPolicies?.includes('GL');
  const bopPolicyAlreadyAdded = selectedPolicies?.includes('BOP');

  const ableToAddPL = availablePolicies?.length > 0 && availablePolicies?.includes(PolicyType.PL);
  const ableToAddGL = availablePolicies?.length > 0 && availablePolicies?.includes(PolicyType.GL);
  const ableToAddBOP = availablePolicies?.length > 0 && availablePolicies?.includes(PolicyType.BOP);

  const onlyPLPolicyAdded = plPolicyAlreadyAdded && selectedPolicies?.length === 1;
  const onlyGLPolicyAdded = glPolicyAlreadyAdded && selectedPolicies?.length === 1;
  const onlyGLPLPolicyAdded = glPolicyAlreadyAdded && plPolicyAlreadyAdded && selectedPolicies?.length === 2;

  const canAddPL = ableToAddPL && bopPolicyAlreadyAdded && selectedPolicies?.length === 1;
  const canAddGL = ableToAddGL && onlyPLPolicyAdded;

  const onlyPLNotAbleToAddGL = plPolicyAlreadyAdded && !ableToAddGL;

  const canAddBOP = ableToAddBOP && (onlyGLPolicyAdded || onlyGLPLPolicyAdded || onlyPLNotAbleToAddGL);

  const policyToAdd = () => {
    if (canAddPL) {
      return 'PL';
    } else if (canAddGL) {
      return 'GL';
    } else if (canAddBOP) {
      return 'BOP';
    }
  };

  return (canAddPL || canAddGL || canAddBOP) && <BaseComponent {...props} policy={policyToAdd()} />;
};

export default shouldshowAddPolicy;
