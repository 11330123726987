import styled from 'styled-components';

interface PrimaryButtonProps {
  theme?: ThemeInterface;
  full?: boolean;
  small?: boolean;
}

export const ButtonTemplate = styled.button<PrimaryButtonProps>`
  ${({ full }) => full && 'width: 100%'};
  max-width: ${({ small, theme }) => (small ? theme.buttons.solid.width.small.max : theme.buttons.solid.width.max)};
  min-width: ${({ small, theme }) => (small ? theme.buttons.solid.width.small.min : theme.buttons.solid.width.min)};
  height: ${({ small, theme }) => (small ? theme.buttons.solid.height.small : theme.buttons.solid.height.large)};
  padding: ${({ small }) => (small ? '0 16px' : '0 24px')};
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  font-family: ${({ theme }) => theme.font.typeface.primary};
  font-size: ${({ small, theme }) => (small ? theme.buttons.solid.fontSizeSmall : theme.buttons.solid.fontSize)};
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
  transition: all 0.2s;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);

  :focus {
    outline: ${({ theme }) => theme.buttons.solid.backgroundOnFocus} !important;
  }

  @media (hover: hover) {
    :hover {
      cursor: pointer;
      background-color: ${({ theme }) => theme.buttons.solid.backgroundOnHover};
      color: ${({ theme }) => theme.buttons.solid.textColorOnHover};
    }
  }

  :disabled {
    pointer-events: none;
    color: ${({ theme }) => theme.buttons.disabled.textColor};
    background-color: ${({ theme }) => theme.buttons.disabled.background};
  }
`;

export const PrimaryButton = styled(ButtonTemplate)`
  color: ${({ theme }) => theme.buttons.solid.textColor};
  background-color: ${({ theme }) => theme.buttons.solid.background};

  :focus {
    outline: ${({ theme }) => theme.buttons.solid.borderOnFocus} !important;
  }
`;

export const PrimaryButtonDarkTheme = styled(ButtonTemplate)`
  color: ${({ theme }) => theme.buttons.solid.textColor};
  background-color: ${({ theme }) => theme.buttons.solid.background};

  :focus {
    outline: ${({ theme }) => theme.buttons.solid.darkTheme.borderOnFocus} !important;
  }

  :disabled {
    color: ${({ theme }) => theme.buttons.disabled.darkTheme.textColor};
    background-color: ${({ theme }) => theme.buttons.disabled.darkTheme.background};
    opacity: ${({ theme }) => theme.buttons.disabled.darkTheme.opacity};
  }
`;

export const GhostButton = styled(ButtonTemplate)`
  color: ${({ theme }) => theme.buttons.ghost.textColor};
  border: ${({ theme }) => theme.buttons.ghost.border};
  background-color: ${({ theme }) => theme.buttons.ghost.background};
  box-shadow: none;
`;

export const GhostButtonDarkTheme = styled(ButtonTemplate)`
  color: ${({ theme }) => theme.buttons.ghost.darkTheme.textColor};
  border: ${({ theme }) => theme.buttons.ghost.darkTheme.border};
  background-color: ${({ theme }) => theme.buttons.ghost.darkTheme.background};

  :focus {
    outline: ${({ theme }) => theme.buttons.ghost.borderOnFocus} !important;
  }

  :disabled {
    color: ${({ theme }) => theme.buttons.ghost.darkTheme.textColorOnDisabled};
    border: ${({ theme }) => `1px solid ${theme.buttons.disabled.darkTheme.background}`};
    background-color: ${({ theme }) => theme.buttons.ghost.darkTheme.background};
    opacity: ${({ theme }) => theme.buttons.disabled.darkTheme.opacity};
  }
`;

interface LinkButtonProps {
  theme?: ThemeInterface;
  shouldUseAltColor?: boolean;
}

// TODO replace LinkButton with TextButton for appropriate use cases (e.g. actual buttons and not links)
export const LinkButton = styled.a<LinkButtonProps>`
  display: block;
  font-family: ${({ theme }) => theme.font.typeface.primary};
  font-size: ${({ theme }) => theme.buttons.textOnly.fontSize};
  letter-spacing: 0;
  color: ${({ theme, shouldUseAltColor }) =>
    shouldUseAltColor ? theme.buttons.textOnly.darkTheme.textColor : theme.buttons.textOnly.textColor} !important;
  font-weight: bold;
  line-height: 36px;
  :hover {
    cursor: pointer;
  }
`;

export const SelectButton = styled.button<{ active?: boolean }>`
  height: 48px;
  justify-content: center;
  align-items: center;
  background: ${({ theme, active }) =>
    active
      ? theme.selectors.dynamicSelectors.backgroundSelected
      : theme.selectors.dynamicSelectors.backgroundUnselected};
  border-radius: 23px;
  border: none;
  text-align: center;
  font-family: ${({ theme }) => theme.font.typeface.primary};
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
  color: ${({ theme, active }) =>
    active ? theme.selectors.dynamicSelectors.textColorSelected : theme.selectors.dynamicSelectors.textColorUnselected};
  min-width: 184px;
  :hover {
    cursor: pointer;
    background: ${({ theme }) => theme.selectors.dynamicSelectors.backgroundOnHover};
    color: ${({ theme }) => theme.selectors.dynamicSelectors.textColorOnHover};
  }
  :disabled {
    cursor: not-allowed;
    pointer-events: none;
  }
`;

export const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 12px;
  margin: 8px 0 32px;
  @media (max-width: 767px) {
    grid-template-columns: none;
  }
`;

export const TextButton = styled.button<{ fontSize?: string; shouldUseAltColor?: boolean }>`
  display: block;
  font-family: ${({ theme }) => theme.font.typeface.primary};
  color: ${({ theme }) => theme.buttons.textOnly.textColor};
  font-size: ${({ fontSize }) => fontSize || '15px'};
  font-weight: bold;
  border: none;
  background: none;
  padding: 0;
  border-bottom: 2px solid transparent;

  :hover {
    cursor: pointer;
    outline: none !important;
    border-bottom: ${({ theme, shouldUseAltColor }) =>
      shouldUseAltColor ? theme.buttons.textOnly.borderOnHoverAlt : theme.buttons.textOnly.borderOnHover};
  }
  :focus {
    outline: none !important;
  }
  :focus-visible {
    cursor: pointer;
    outline: none !important;
    border-bottom: ${({ theme, shouldUseAltColor }) =>
      shouldUseAltColor ? theme.buttons.textOnly.borderOnHoverAlt : theme.buttons.textOnly.borderOnHover};
  }
  :disabled {
    opacity: 0.2;
    pointer-events: none;
  }
`;

export const SecondaryButton = styled(ButtonTemplate)`
  text-decoration: none;
  border: 1px solid ${({ theme }) => theme.colors.primary.zero};
  background-color: ${({ theme }) => theme.colors.primary.ten};

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary.zero}
    color: #fff;
  }
`;
