import React, { Component } from 'react';

import styled from 'styled-components';
import { InputCont, InputBox, LabelLarge, PatternInput } from '../../elements';

interface CheckoutProps {
  cardNumber: string;
  cardCode: string;
  cardExp: string;
  statusMessage?: string;
  updateCard: (e: React.ChangeEvent) => void;
}

class Checkout extends Component<CheckoutProps> {
  handleChange = (e: React.ChangeEvent) => {
    this.props.updateCard(e);
  };

  render() {
    const { cardNumber, cardCode, cardExp, statusMessage } = this.props;
    return (
      <CreditCardInput data-testid="authorize-checkout">
        <StyledInputContainer>
          <CardNumberContainer>
            <LabelLarge>Credit card number</LabelLarge>
            <PatternInput
              value={cardNumber}
              onChange={this.handleChange}
              name="cardNumber"
              placeholder="XXXX-XXXX-XXXX-XXXX"
              format="#### #### #### ####"
              type="tel"
              data-cy="cardNumber"
              customWidth={'full'}
              aria-label="credit card number"
              marginBottom="smaller"
            />
          </CardNumberContainer>
          <SmallInputContainer>
            <SmallInputBox>
              <LabelLarge>Expiration</LabelLarge>
              <PatternInput
                value={cardExp}
                onChange={this.handleChange}
                name="cardExp"
                format="##/##"
                placeholder="MM/YY"
                mask={['M', 'M', 'Y', 'Y']}
                customWidth="small"
                type="tel"
                data-cy="cardExp"
                aria-label="credit card expiration date"
                marginBottom="none"
              />
            </SmallInputBox>
            <SmallInputBox>
              <LabelLarge>CVC</LabelLarge>
              <PatternInput
                value={cardCode}
                onChange={this.handleChange}
                name="cardCode"
                placeholder="CVC"
                customWidth="small"
                format="####"
                type="tel"
                data-cy="cardCode"
                aria-label="credit card security code"
                marginBottom="none"
              />
            </SmallInputBox>
          </SmallInputContainer>
        </StyledInputContainer>
        {statusMessage ? <StatusMessage>{statusMessage}</StatusMessage> : <StatusMessage>&nbsp;</StatusMessage>}
      </CreditCardInput>
    );
  }
}

const StatusMessage = styled.p`
  margin: 10px auto 2px;
  color: red;
  font-family: ${(props) => props.theme.font.typeface.secondary};
  font-size: 1.2rem;
  font-weight: bold;
`;

const SmallInputBox = styled(InputBox)`
  max-width: 138px;
  margin-right: 1em;
  @media (min-width: 1025px) {
    max-width: 130px;
  }
`;

const SmallInputContainer = styled.div`
  display: flex;
`;

const CardNumberContainer = styled.div`
  flex: 1;
  @media (min-width: 1025px) {
    margin-right: 1em;
  }
  @media (max-width: 1150px) {
    width: 100%;
    flex: none;
  }
`;

const StyledInputContainer = styled(InputCont)`
  flex-wrap: wrap;
`;

const CreditCardInput = styled.div`
  @media print {
    display: none;
  }
`;

export default Checkout;
