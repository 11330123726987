import React from 'react';
import styled from 'styled-components';
import { useThemeContext } from '../../elements/theme/CustomThemeProvider';
import { connect } from 'react-redux';

const Button = styled.button`
  border: none;
  background: none;
  padding: 0;
  margin-left: 16px;
`;

const EditIcon = styled.img`
  width: 20px;
  height: 20px;
  margin: auto;
  :hover {
    cursor: pointer;
  }
`;

interface IconProps {
  redirectTo: string;
  ariaLabel: string;
  dispatch: Function;
}

export const Icon = ({ redirectTo, ariaLabel, dispatch }: IconProps) => {
  const { theme } = useThemeContext();
  return (
    <Button onClick={() => dispatch({ type: redirectTo })} aria-label={`edit ${ariaLabel}`}>
      <EditIcon data-cy={`${ariaLabel}-edit`} src={theme.icons.editIcon} alt="" />
    </Button>
  );
};

export default connect()(Icon);
