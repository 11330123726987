const addApplicationAggregate = (application: Application) => {
  if (!application.glLimit || typeof application.glLimit !== 'number') return application;

  return {
    ...application,
    glAggregateLimit: application.glLimit * 2,
    glAggregatePcoLimit: application.glLimit * 2,
  };
};

export default addApplicationAggregate;
