import React, { useState } from 'react';
import { CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import { LabelLarge } from '../../elements';
import { useThemeContext } from '../../elements/theme/CustomThemeProvider';
import styled from 'styled-components';

interface StripeCheckoutProps {
  reasonabilityError: string;
  setReasonabilityError: (error: string) => void;
}

const StripeCheckout = ({ reasonabilityError, setReasonabilityError }: StripeCheckoutProps) => {
  const [focusedInput, setFocusedInput] = useState('');
  const { theme } = useThemeContext();

  const inputStyle = {
    fontWeight: '400',
    fontFamily: theme.font.typeface.secondary,
    fontSize: '16px',
    lineHeight: '25px',
  };

  const options = {
    style: {
      base: inputStyle,
    },
  };

  const handleChange = () => {
    if (reasonabilityError) {
      setReasonabilityError('');
    }
  };

  const handleBlur = () => setFocusedInput('');

  return (
    <div data-testid="stripe-checkout">
      <LabelLarge htmlFor="card_number">Credit card number</LabelLarge>
      <CardInputWrapper hasFocus={focusedInput === 'card_number'}>
        <CardNumberElement
          id="card_number"
          options={{ ...options, placeholder: 'XXXX-XXXX-XXXX-XXXX' }}
          onChange={handleChange}
          onFocus={() => setFocusedInput('card_number')}
          onBlur={handleBlur}
        />
      </CardInputWrapper>
      <InfoRow>
        <SubRow>
          <LabelLarge htmlFor="card_exp">Expiration</LabelLarge>
          <CardInputWrapper hasFocus={focusedInput === 'card_exp'}>
            <CardExpiryElement
              id="card_exp"
              options={{ ...options, placeholder: 'MM/YY' }}
              onChange={handleChange}
              onFocus={() => setFocusedInput('card_exp')}
              onBlur={handleBlur}
            />
          </CardInputWrapper>
        </SubRow>
        <SubRow>
          <LabelLarge htmlFor="card_cvc">CVC</LabelLarge>
          <CardInputWrapper hasFocus={focusedInput === 'card_cvc'}>
            <CardCvcElement
              id="card_cvc"
              options={{ ...options, placeholder: 'CVC' }}
              onChange={handleChange}
              onFocus={() => setFocusedInput('card_cvc')}
              onBlur={handleBlur}
            />
          </CardInputWrapper>
        </SubRow>
      </InfoRow>
      {reasonabilityError ? <ErrorText>{reasonabilityError}</ErrorText> : null}
    </div>
  );
};

export default StripeCheckout;

const CardInputWrapper = styled.div<{ hasFocus: boolean }>`
  background-color: ${({ theme }) => theme.formElements.input.background};
  border: ${({ theme, hasFocus }) =>
    hasFocus ? theme.formElements.input.outlineOnFocus : theme.formElements.input.border};
  padding: 11px 16px;
  border-radius: 4px;
  margin: 8px 0 24px;
`;

const InfoRow = styled.div`
  display: flex;
`;

const SubRow = styled.div`
  flex: 1 1 0%;
  width: 100%;
  max-width: 138px;
  margin-right: 1em;
`;

const ErrorText = styled.p`
  color: ${({ theme }) => theme.pageComponents.globals.errorTextColor};
  font-weight: 600;
  margin-top: 0;
`;
