import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {
  addPreviousLoss,
  editPreviousLoss,
  removePreviousLoss,
  clearPreviousLosses,
  resetPreviousLosses,
} from '../../actions/application';
import { selectPreviousLosses } from '../../selectors/application';
import { inputStyles, FormSection, TextButton, FormProps } from '../../elements';
import { formatCurrency } from '../../helpers/formatNumber';
import YesNoQuestion from '../Shared/YesNoQuestion';
import SmoothCollapse from 'react-smooth-collapse';
import DeleteOrUpdateButton from '../Shared/DeleteOrUpdateButton';
import { NumericFormat, NumberFormatValues } from 'react-number-format';
import { DesktopAndTablet, Mobile } from '../../helpers/responsive';
import { Dispatch } from 'redux';

const uuidv1 = require('uuid/v1');

interface PreviousLossesProps {
  previousLosses: PreviousLoss[] | null | undefined;
  disabled: boolean;
  resetPreviousLosses: () => void;
  addPreviousLoss: (data: PreviousLoss) => void;
  editPreviousLoss: (data: PreviousLossEdit) => void;
  removePreviousLoss: (index: number) => void;
  clearPreviousLosses: () => void;
}

interface PreviousLossEdit {
  claim: PreviousLoss;
  index: number;
}

interface PreviousLossesState {
  lossValue?: number | null;
  lossDescription: string;
  showClaimsInputs: boolean | null;
  editLossIndex: number | null;
}

interface ShowLossProps extends PreviousLoss {
  index: number;
}

class PreviousLosses extends Component<PreviousLossesProps, PreviousLossesState> {
  state: PreviousLossesState = {
    lossValue: 0,
    lossDescription: '',
    showClaimsInputs: null,
    editLossIndex: null,
  };

  componentDidMount() {
    const { previousLosses } = this.props;
    if (Array.isArray(previousLosses)) this.setState({ showClaimsInputs: false });
    if (previousLosses && previousLosses.length) this.setState({ showClaimsInputs: true });
  }

  componentDidUpdate(prevProps: PreviousLossesProps, prevState: PreviousLossesState) {
    const { previousLosses } = this.props;
    const { showClaimsInputs } = this.state;
    if (previousLosses === prevProps.previousLosses) return;
    if (prevProps.previousLosses?.length && !previousLosses?.length && prevState.showClaimsInputs && showClaimsInputs) {
      this.props.resetPreviousLosses();
    }
  }

  adjustLossValue = (value: NumberFormatValues) => {
    if (value.floatValue && value.floatValue > 9999999) return;
    this.setState({ lossValue: value.floatValue });
  };

  addEditLoss = (e: React.MouseEvent) => {
    e.preventDefault();
    const claim: PreviousLoss = {
      amount: this.state.lossValue ?? 0,
      description: this.state.lossDescription,
    };
    const index = this.state.editLossIndex;
    index === null ? this.props.addPreviousLoss(claim) : this.props.editPreviousLoss({ claim, index });
    this.setState({
      lossValue: null,
      lossDescription: '',
      editLossIndex: null,
    });
  };

  removeLoss = (lossIndex: number) => {
    this.props.removePreviousLoss(lossIndex);
  };

  editLoss = (lossIndex: number) => {
    if (typeof this.state.editLossIndex === 'number') {
      this.setState({ lossValue: null, lossDescription: '', editLossIndex: null });
    } else {
      const loss = this.props.previousLosses?.[lossIndex];
      this.setState({
        lossValue: loss?.amount,
        lossDescription: loss?.description ?? '',
        editLossIndex: lossIndex,
      });
    }
  };

  showClaimsInputs = () => {
    this.props.resetPreviousLosses();
    this.setState({ showClaimsInputs: true });
  };

  hideClaimsInputs = () => {
    this.props.clearPreviousLosses();
    this.setState({ showClaimsInputs: false });
  };

  render() {
    const editMode = typeof this.state.editLossIndex === 'number';

    const ShowLoss = ({ amount, description, index }: ShowLossProps) => (
      <LossContainer key={uuidv1()}>
        <DesktopAndTablet>
          <InfoContainer>
            <LossAmount>{formatCurrency(amount)}</LossAmount>
            <LossDescription data-cy="loss-description">{description}</LossDescription>
          </InfoContainer>
          <IconContainer>
            <DeleteOrUpdateButton handleClick={() => this.editLoss(index)} type="update" name="loss" />
            <DeleteOrUpdateButton handleClick={() => this.removeLoss(index)} type="delete" name="loss" />
          </IconContainer>
        </DesktopAndTablet>
        <Mobile>
          <MobileLossHeaderContainer>
            <LossAmount>{formatCurrency(amount)}</LossAmount>
            <IconContainer>
              <DeleteOrUpdateButton handleClick={() => this.editLoss(index)} type="update" name="loss" />
              <DeleteOrUpdateButton handleClick={() => this.removeLoss(index)} type="delete" name="loss" />
            </IconContainer>
          </MobileLossHeaderContainer>
          <InfoContainer>
            <LossDescription data-cy="loss-description">{description}</LossDescription>
          </InfoContainer>
        </Mobile>
      </LossContainer>
    );

    return (
      <FormSection available={!this.props.disabled} data-testid="previous-losses">
        <PreviousLossTitle>Claims History</PreviousLossTitle>
        <YesNoQuestion
          question="Have you had any insurance claims in the past 5 years?"
          value={this.state.showClaimsInputs}
          selectYes={this.showClaimsInputs}
          selectNo={this.hideClaimsInputs}
          cyLabel="previous-loss"
          disabled={this.props.disabled}
        />
        <SmoothCollapse expanded={Boolean(this.state.showClaimsInputs)}>
          <AddEditLossContainer data-cy="previous-loss-input">
            <AddClaimsTitle>
              {editMode ? 'Edit this claim' : 'Please add all claims from the past 5 years.'}
            </AddClaimsTitle>
            {Array.isArray(this.props.previousLosses) &&
              this.props.previousLosses
                .filter((loss, index) => (editMode ? this.state.editLossIndex === index : true))
                .map((loss, index) => <ShowLoss key={index} index={index} {...loss} />)}
            <InputContainer>
              <Subtitle>Claim amount</Subtitle>
              <Input
                data-cy="claim-amount"
                value={this.state.lossValue}
                onValueChange={this.adjustLossValue}
                customWidth="half"
                aria-label="claim amount"
                placeholder="e.g., $1,000"
                thousandSeparator={true}
                prefix={'$'}
              />
            </InputContainer>
            <Subtitle htmlFor="claim-description">Describe the claim</Subtitle>
            <InputContainer>
              <LossDescriptionInput
                id="claim-description"
                data-cy="claim-description-input"
                value={this.state.lossDescription}
                onChange={(e) => this.setState({ lossDescription: e.target.value })}
                placeholder="e.g., computer crashed, lost data"
                aria-label="Describe the claim"
              />
            </InputContainer>
            <TextButton
              onClick={this.addEditLoss}
              data-cy={editMode ? 'update-claim-button' : 'save-claim-button'}
              aria-label={editMode ? 'Update Claim' : 'Save Claim'}
              disabled={!this.state.lossDescription || !this.state.lossValue}
            >
              {editMode ? 'Update Claim' : 'Save Claim'}
            </TextButton>
          </AddEditLossContainer>
        </SmoothCollapse>
      </FormSection>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  previousLosses: selectPreviousLosses(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  resetPreviousLosses: () => dispatch(resetPreviousLosses()),
  addPreviousLoss: (data: PreviousLoss) => dispatch(addPreviousLoss(data)),
  clearPreviousLosses: () => dispatch(clearPreviousLosses()),
  removePreviousLoss: (index: number) => dispatch(removePreviousLoss(index)),
  editPreviousLoss: (data: PreviousLossEdit) => dispatch(editPreviousLoss(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PreviousLosses);

const Subtitle = styled.label<{ display?: string }>`
  margin: 0;
  padding: 0;
  font-size: ${(props) => props.theme.pageComponents.businessInfo.previousLosses.subTitle.fontSize};
  font-family: ${(props) => props.theme.font.typeface.primary};
  color: ${(props) => props.theme.pageComponents.businessInfo.previousLosses.subTitle.textColor};
  margin: 0;
  font-weight: 600;
  display: ${(props) => props.display || 'block'};
`;

const LossContainer = styled.div`
  margin: 0 0 32px 2px;
  border-radius: 4px;
  padding: 16px;
  background-color: ${(props) => props.theme.pageComponents.globals.background};
  border: 1px solid ${(props) => props.theme.pageComponents.businessInfo.previousLosses.lossContainer.border};
  display: flex;
  justify-content: space-between;

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

const MobileLossHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 12px;
`;

const LossAmount = styled.p`
  font-weight: bold;
  font-family: ${(props) => props.theme.font.typeface.primary};
  color: ${(props) => props.theme.pageComponents.businessInfo.previousLosses.lossAmount.textColor};
  margin: 0;
`;

const LossDescription = styled.p`
  font-family: ${(props) => props.theme.font.typeface.secondary};
  color: ${(props) => props.theme.pageComponents.businessInfo.previousLosses.lossDescription.textColor};
  margin: 0 0 0 16px;
  @media (max-width: 767px) {
    margin: 0;
  }
`;

const AddEditLossContainer = styled.div`
  background-color: ${(props) => props.theme.pageComponents.globals.background};
`;

const InputContainer = styled.div`
  margin: 0 2px;
`;

const LossDescriptionInput = styled.textarea`
  ${inputStyles}
  min-height: 50px;
  border-radius: 4px;
  margin-bottom: 16px;

  :focus {
    outline: none !important;
    box-shadow: ${({ theme }) => theme.formElements.input.boxShadowOnFocus};
  }
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 50px;
  justify-content: space-between;
  margin-left: 12px;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const PreviousLossTitle = styled.h2`
  color: ${(props) => props.theme.pageComponents.businessInfo.previousLosses.previousLossTitle.textColor};
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 12px;
  font-family: ${(props) => props.theme.font.typeface.primary};
`;

const AddClaimsTitle = styled.h3`
  color: ${(props) => props.theme.pageComponents.businessInfo.previousLosses.addClaimsTitle.textColor};
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 32px;
  font-family: ${(props) => props.theme.font.typeface.primary};
`;

const Input = styled(NumericFormat)<FormProps>`
  ${inputStyles}
`;
