import { AnyAction } from 'redux';
import {
  DATA_IDS_FROM_DROPIN,
  SET_CHANNEL_PARTNER_GUID,
  SET_DROPIN,
  SET_CUSTOM_STYLES_FROM_DROPIN,
} from '../actions/dropIn';

const initialState = {
  dataIds: [],
  isDropin: false,
  channelPartnerGUID: null,
  customStyles: {},
};

const dropin = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case DATA_IDS_FROM_DROPIN:
      return Object.assign({}, state, {
        dataIds: action.data.map((i: string) => i.trim()),
      });
    case SET_DROPIN:
      return Object.assign({}, state, { isDropin: true });
    case SET_CHANNEL_PARTNER_GUID:
      return Object.assign({}, state, { channelPartnerGUID: action.data });
    case SET_CUSTOM_STYLES_FROM_DROPIN:
      return Object.assign({}, state, { customStyles: action.data });
    default:
      return state;
  }
};

export default dropin;
