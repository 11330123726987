import React from 'react';
import styled from 'styled-components';
import { ModalDetailBar } from '../../../elements';
import checkmark from '../../../icons/endorsementsCheckmark.svg';
import { PrimaryButton, TextButton } from '../../../elements';

export const Package = styled.div`
  position: relative;
  box-sizing: border-box;
  background: ${({ theme }) => theme.formElements.visualCheckbox.vertical.background};
  margin-right: 15px;
  @media (min-width: 767px) {
    flex: 1;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    &:last-of-type {
      margin-right: 0px;
    }
  }
  @media (max-width: 767px) {
    max-width: 261px;
    margin: 0 auto 15px;
  }
`;

export const PackagesCont = styled.div`
  position: relative;
  padding-bottom: 10px;
`;

export const Options = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 15px;
  @media (min-width: 767px) {
    &:last-of-type {
      margin-right: 0px;
    }
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    width: 31%;
    flex: none;
    margin-right: 0px;
  }
`;

interface PackageContainerProps {
  active: Boolean;
  theme: ThemeInterface;
}

export const PackageContainer = styled(Package)`
  border: ${({ active, theme }: PackageContainerProps) =>
    active
      ? theme.formElements.visualCheckbox.vertical.borderOnActive
      : theme.formElements.visualCheckbox.vertical.border};
  border-radius: 4px;
  padding: 0 14px;
`;

export const Title = styled.h3`
  color: ${({ theme }) => theme.pageComponents.summary.packages.title.textColor};
  font-family: ${({ theme }) => theme.font.typeface.primary};
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 30px;
  text-align: center;
  margin: 10px 17px 3px 17px;
  @media (max-width: 767px) {
    text-align: left;
  }
`;

export const Description = styled.p`
  color: ${({ theme }) => theme.pageComponents.summary.packages.description.textColor};
  font-family: ${({ theme }) => theme.font.typeface.secondary};
  font-size: 15px;
  letter-spacing: 0px;
  line-height: 24px;
  text-align: center;
  margin: 0 0 16px;
  @media (max-width: 767px) {
    text-align: left;
  }
`;

export const CoveragesButton = styled(TextButton)`
  margin: 22px auto;
  color: ${({ theme }) => theme.pageComponents.summary.packages.coverages.textColor};
  margin-top: 22px;
  padding: 0;
  font-size: 13px;
  font-weight: 600;
  font-family: ${({ theme }) => theme.font.typeface.primary};
  letter-spacing: 0.5px;
  line-height: 13px;
  text-align: center;
`;

const SelectedCheckContainer = styled.button`
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;
  height: 27px;
  width: 26px;
  padding: 4px 7px 11px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.formElements.checkbox.backgroundSelected};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  border: none;

  :hover {
    cursor: pointer;
  }
`;

export const SelectedCheck = (props: { onClick: React.MouseEventHandler<HTMLButtonElement> }) => (
  <SelectedCheckContainer {...props}>
    <img src={checkmark} alt="checkmark" />
  </SelectedCheckContainer>
);

export const UnSelectedCheck = styled.button`
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;
  padding: 3px;
  height: 26px;
  width: 26px;
  padding: 6px;
  border: ${({ theme }) => theme.formElements.checkbox.borderUnselected};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.formElements.checkbox.backgroundUnselected};

  :hover {
    cursor: pointer;
  }
`;

export const Icon = styled.img`
  display: flex;
  margin: auto;
  padding-top: 24px;
  max-width: 50px;
`;

export const ModalIcon = styled.img`
  padding: 14px 16px 8px;
  @media screen and (max-width: 768px) {
    padding: 0 16px 0 0;
  }
`;

export const ModalSubHeaderContainer = styled(ModalDetailBar)`
  justify-content: space-between;
  padding: 5px;
  @media screen and (max-width: 768px) {
    padding: 16px;
  }
`;

export const ModalSubHeaderTitleCont = styled.div`
  display: flex;
`;

export const ModalSubHeaderTitle = styled.h3`
  font-weight: 600;
  @media screen and (max-width: 768px) {
    margin: auto;
  }
`;

export const ModalSubHeaderCharge = styled.h3`
  font-weight: 600;
  width: 30%;
`;

export const MobileRow = styled.div`
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    justify-content: center;

    h3 {
      margin: auto;
      text-align: center;
    }
  }
`;

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;

  thead tr {
    background: ${({ theme }) => theme.tables.header.background};
    border-style: ${({ theme }) => theme.tables.header.border};
    color: ${({ theme }) => theme.tables.header.textColor};
    font-size: 12px;
    text-align: left;
    height: 32px;
  }

  tbody tr td {
    border-bottom: 2px solid ${({ theme }) => theme.tables.body.borderBottom};
    font-size: 14px;
    color: ${({ theme }) => theme.tables.body.textColor};
    font-weight: 600;
  }

  th {
    padding: 5px 15px;
    border: none;
  }

  td {
    padding: 10px 15px;
    border: none;
  }

  tr {
    height: 50px;
  }
`;

export const SelectPlanBtn = styled(PrimaryButton)`
  @media (max-width: 1024px) {
    min-width: 100px;
  }
`;
