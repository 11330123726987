import React from 'react';
import styled from 'styled-components';
import { ComponentMap } from '../../../toggle_tools/ComponentMap';
import PoweredByLogoSVG from '../../../assets/img/powered_by_coterie.svg';
import { white_label_partners } from '../../../helpers/whitelabel';

const PoweredByLogoImg = styled.img`
  position: absolute;
  left: 76px;
  top: 56px;

  @media (max-width: 767px) {
    left: 24px;
    bottom: 16px;
    top: initial;
    height: 29px;
  }
`;

const map = {
  [white_label_partners.CRES]: PoweredByLogoImg,
};

const PoweredByLogoComp = ComponentMap('channelPartnerGUID', map, null);

const PoweredByLogo = () => <PoweredByLogoComp src={PoweredByLogoSVG} alt="Powered By Coterie Insurance" />;

export default PoweredByLogo;
