// Async Action Results
export const IS_LOADING = 'IS_LOADING';
export const FATAL_ERROR = 'FATAL_ERROR';
export const INITIAL_APPLICATION_LOAD = 'INITIAL_APPLICATION_LOAD';
export const EXISTING_APPLICATION_LOADED = 'EXISTING_APPLICATION_LOADED';

// Navigation
export const QUOTEHELP = 'QUOTEHELP';
export const SHOW_NEXT_PAGE = 'SHOW_NEXT_PAGE';

// Update Application
export const UPDATE_APPLICATION = 'UPDATE_APPLICATION';
export const UPDATE_APPLICATION_LOCALLY = 'UPDATE_APPLICATION_LOCALLY';
export const UPDATE_APPLICATION_FROM_SERVER = 'UPDATE_APPLICATION_FROM_SERVER';
export const SET_APPLICATION_FROM_SERVER = 'SET_APPLICATION_FROM_SERVER';

// Quote Actions
export const QUOTE_RECEIVED = 'QUOTE_RECEIVED';
export const BINDABLE_QUOTE_RECEIVED = 'BINDABLE_QUOTE_RECEIVED';

// Clear Data
export const CLEAR_ALL_DATA = 'CLEAR_ALL_DATA';
export const CLEAR_APPLICATION = 'CLEAR_APPLICATION';
export const CLEAR_QUOTE = 'CLEAR_QUOTE';

// Industry Page
export const SET_INDUSTRY_NAME = 'SET_INDUSTRY_NAME';
export const SET_INDUSTRY_ID = 'SET_INDUSTRY_ID';
export const SET_AK_HASH = 'SET_AK_HASH';
export const CLEAR_INDUSTRY_STATE = 'CLEAR_INDUSTRY_STATE';

// Business Info Page
export const SET_LOCATIONS = 'SET_LOCATIONS';
export const SET_NUM_EMPLOYEES = 'SET_NUM_EMPLOYEES';
export const SET_LEGAL_BUSINESS_NAME = 'SET_LEGAL_BUSINESS_NAME';
export const SET_DBA = 'SET_DBA';
export const DBA_IS_VALID = 'DBA_IS_VALID';
export const SET_BUSINESS_AGE_IN_MONTHS = 'SET_BUSINESS_AGE_IN_MONTHS';
export const ADD_PREVIOUS_LOSS = 'ADD_PREVIOUS_LOSS';
export const EDIT_PREVIOUS_LOSS = 'EDIT_PREVIOUS_LOSS';
export const REMOVE_PREVIOUS_LOSS = 'REMOVE_PREVIOUS_LOSS';
export const CLEAR_PREVIOUS_LOSSES = 'CLEAR_PREVIOUS_LOSSES';
export const RESET_PREVIOUS_LOSSES = 'RESET_PREVIOUS_LOSSES';

// Policies Page
export const SET_APPLICATION_TYPES = 'SET_APPLICATION_TYPES';
export const SET_AVAILABLE_POLICY_TYPES = 'SET_AVAILABLE_POLICY_TYPES';

// Finance Page
export const SET_ANNUAL_PAYROLL = 'SET_ANNUAL_PAYROLL';
export const SET_GROSS_ANNUAL_SALES = 'SET_GROSS_ANNUAL_SALES';

// Locations Page
export const ADD_LOCATION = 'ADD_LOCATION';
export const DELETE_LOCATION = 'DELETE_LOCATION';
export const UPDATE_LOCATION = 'UPDATE_LOCATION';
export const ADD_POTENTIAL_ADDRESS = 'ADD_POTENTIAL_ADDRESS';
export const SET_EXISTING_MAILING_ADDRESS = 'SET_EXISTING_MAILING_ADDRESS';
export const ADD_EXISTING_MAILING_TO_LOCATIONS = 'ADD_EXISTING_MAILING_TO_LOCATIONS';
export const SET_BPP_DEDUCTIBLE = 'SET_BPP_DEDUCTIBLE';
export const SET_BPP_LIMIT = 'SET_BPP_LIMIT';

// General Liability Page
export const SET_GL_LIMIT = 'SET_GL_LIMIT';
export const SET_TWO_FOUR_LIMITS_AVAILABLE = 'SET_TWO_FOUR_LIMITS_AVAILABLE';

// Professional Liability Page
export const UPDATE_PROFESSIONAL_LIABILITY = 'UPDATE_PROFESSIONAL_LIABILITY';

// Additional Insureds Page
export const ADD_ADDITIONAL_INSURED = 'ADD_ADDITIONAL_INSURED';
export const REMOVE_ADDITIONAL_INSURED = 'REMOVE_ADDITIONAL_INSURED';
export const CLEAR_ADDITIONAL_INSUREDS = 'CLEAR_ADDITIONAL_INSUREDS';
export const SET_BLANKET_ADDITIONAL_INSUREDS = 'SET_BLANKET_ADDITIONAL_INSUREDS';
export const REMOVE_BLANKET_ADDITIONAL_INSUREDS = 'REMOVE_BLANKET_ADDITIONAL_INSUREDS';
export const TOGGLE_BLANKET_AI_ITEM = 'TOGGLE_BLANKET_AI_ITEM';

// Contact Page
export const SET_CONTACT_FIRST_NAME = 'SET_CONTACT_FIRST_NAME';
export const SET_CONTACT_LAST_NAME = 'SET_CONTACT_LAST_NAME';
export const SET_CONTACT_EMAIL = 'SET_CONTACT_EMAIL';
export const SET_CONTACT_PHONE = 'SET_CONTACT_PHONE';
export const SET_CONTACT_EMAIL_IS_VALID = 'SET_CONTACT_EMAIL_IS_VALID';

// Summary Page
export const UPDATE_START_DATE = 'UPDATE_START_DATE';
export const UPDATE_END_DATE = 'UPDATE_END_DATE';
export const SET_ENDORSEMENT_PACKAGE = 'SET_ENDORSEMENT_PACKAGE';
export const SET_LOSS_CONTROL = 'SET_LOSS_CONTROL';

// Payment Page
export const SET_ACKNOWLEDGEMENTS = 'SET_ACKNOWLEDGEMENTS';

// Endorsements Page
export const SET_HIRED_AND_NON_OWNED_AUTO = 'SET_HIRED_AND_NON_OWNED_AUTO';

// User Interaction
export const DID_VIEW_ADDITIONAL_INSUREDS = 'DID_VIEW_ADDITIONAL_INSUREDS';
export const DID_VIEW_SUMMARY_PAGE = 'DID_VIEW_SUMMARY_PAGE';
export const DID_VIEW_GL_PAGE = 'DID_VIEW_GL_PAGE';
export const DID_VIEW_ADD_ONS_PAGE = 'DID_VIEW_ADD_ONS_PAGE';
export const PARTNER_INTRO_VIEWED = 'PARTNER_INTRO_VIEWED';
export const USER_SELECTED_HAS_ADDITIONAL_INSUREDS = 'USER_SELECTED_HAS_ADDITIONAL_INSUREDS';
export const USER_COMPLETED_CHECKOUT_FORM = 'USER_COMPLETED_CHECKOUT_FORM';
export const USER_SUBMITTED_CHECKOUT_FORM = 'USER_SUBMITTED_CHECKOUT_FORM';
export const RESET_USER_COMPLETED_CHECKOUT_FORM = 'RESET_USER_COMPLETED_CHECKOUT_FORM';
export const RESET_USER_SUBMITTED_CHECKOUT_FORM = 'RESET_USER_SUBMITTED_CHECKOUT_FORM';
export const INVALIDATED_ADDON_ENDORSEMENT = 'INVALIDATED_ADDON_ENDORSEMENT';

// Add Ons
export const SET_LIQUOR_LIABILITY_COVERAGE = 'SET_LIQUOR_LIABILITY_COVERAGE';
export const SET_RESTAURANT_COVERAGE = 'SET_RESTAURANT_COVERAGE';
export const SET_EQUIPMENT_BREAKDOWN_COVERAGE = 'SET_EQUIPMENT_BREAKDOWN_COVERAGE';
export const SET_LIMIT_DAMAGE_PREM_RENTED = 'SET_LIMIT_DAMAGE_PREM_RENTED';
export const SET_CONTRACTOR_TOOLS_COVERAGE = 'SET_CONTRACTOR_TOOLS_COVERAGE';
export const SET_CYBER_COVERAGE = 'SET_CYBER_COVERAGE';
export const SET_WORKPLACE_VIOLENCE_COVERAGE = 'SET_WORKPLACE_VIOLENCE_COVERAGE';

// Misc
export const SET_PARTNER_INFO = 'SET_PARTNER_INFO';
export const SET_PARTNER_FROM_APPLICATION = 'SET_PARTNER_FROM_APPLICATION';

export const setUserHasInvalidatedAddon = (data) => ({
  type: INVALIDATED_ADDON_ENDORSEMENT,
  data,
});

export const setLiquorLiabilityCoverage = (data) => ({
  type: SET_LIQUOR_LIABILITY_COVERAGE,
  data,
});

export const setLimitDamagePremRented = (data) => ({
  type: SET_LIMIT_DAMAGE_PREM_RENTED,
  data,
});

export const setContractorToolsCoverage = (data) => ({
  type: SET_CONTRACTOR_TOOLS_COVERAGE,
  data,
});

export const setRestaurantCoverage = (data) => ({
  type: SET_RESTAURANT_COVERAGE,
  data,
});

export const setEquipmentBreakdownCoverage = (data) => ({
  type: SET_EQUIPMENT_BREAKDOWN_COVERAGE,
  data,
});

export const setCyberCoverage = (data) => ({
  type: SET_CYBER_COVERAGE,
  data,
});

export const setWorkplaceViolenceCoverage = (data) => ({
  type: SET_WORKPLACE_VIOLENCE_COVERAGE,
  data,
});

export const setUserHasCompletedCheckoutForm = () => ({ type: USER_COMPLETED_CHECKOUT_FORM });

export const setUserHasSubmittedCheckoutForm = () => ({ type: USER_SUBMITTED_CHECKOUT_FORM });

export const resetUserHasCompletedCheckoutForm = () => ({ type: RESET_USER_COMPLETED_CHECKOUT_FORM });

export const resetUserHasSubmittedCheckoutForm = () => ({ type: RESET_USER_SUBMITTED_CHECKOUT_FORM });

export const setUserSelectedHasAdditionalInsureds = (data) => ({ type: USER_SELECTED_HAS_ADDITIONAL_INSUREDS, data });

export function setEndorsementPackage(data) {
  return {
    type: SET_ENDORSEMENT_PACKAGE,
    data,
  };
}

export function setApplicationTypes(data) {
  return {
    type: SET_APPLICATION_TYPES,
    data,
  };
}

export function setNumEmployees(data) {
  return {
    type: SET_NUM_EMPLOYEES,
    data,
  };
}

export function setLegalBusinessName(data) {
  return {
    type: SET_LEGAL_BUSINESS_NAME,
    data,
  };
}

export function setDBA(data) {
  return {
    type: SET_DBA,
    data,
  };
}

export function setBusinessAgeInMonths(data) {
  return {
    type: SET_BUSINESS_AGE_IN_MONTHS,
    data,
  };
}

export function setContactFirstName(data) {
  return {
    type: SET_CONTACT_FIRST_NAME,
    data,
  };
}

export function setContactLastName(data) {
  return {
    type: SET_CONTACT_LAST_NAME,
    data,
  };
}

export function setContactEmail(data) {
  return {
    type: SET_CONTACT_EMAIL,
    data,
  };
}

export function setContactPhone(data) {
  return {
    type: SET_CONTACT_PHONE,
    data,
  };
}

export function setGlLimit(data) {
  return {
    type: SET_GL_LIMIT,
    data,
  };
}

export function setTwoFourLimitsAvailable(data) {
  return {
    type: SET_TWO_FOUR_LIMITS_AVAILABLE,
    data,
  };
}

export function setBppDeductible(data) {
  return {
    type: SET_BPP_DEDUCTIBLE,
    data,
  };
}

export function setBppLimit(data) {
  return {
    type: SET_BPP_LIMIT,
    data,
  };
}

export function setAnnualPayroll(data) {
  return {
    type: SET_ANNUAL_PAYROLL,
    data,
  };
}

export function setGrossAnnualSales(data) {
  return {
    type: SET_GROSS_ANNUAL_SALES,
    data,
  };
}

export function clearAllData() {
  return {
    type: CLEAR_ALL_DATA,
  };
}
export function clearQuote() {
  return {
    type: CLEAR_QUOTE,
  };
}
export function clearApplication() {
  return {
    type: CLEAR_APPLICATION,
  };
}

export function updateApplicationLocally(data) {
  return {
    type: UPDATE_APPLICATION_LOCALLY,
    data,
  };
}

export function setApplicationIsLoading(data) {
  return {
    type: IS_LOADING,
    data,
  };
}

export function fatalError(data) {
  return {
    type: FATAL_ERROR,
    data,
  };
}

export function initialApplicationLoad() {
  return {
    type: INITIAL_APPLICATION_LOAD,
  };
}

export function quoteReceived(data) {
  return {
    type: QUOTE_RECEIVED,
    data,
  };
}

export function quoteHelp() {
  return {
    type: QUOTEHELP,
  };
}

export function didViewAdditionalInsureds() {
  return {
    type: DID_VIEW_ADDITIONAL_INSUREDS,
  };
}

export function didViewSummaryPage(data) {
  return {
    type: DID_VIEW_SUMMARY_PAGE,
    data,
  };
}

export function didViewGLPage() {
  return {
    type: DID_VIEW_GL_PAGE,
  };
}

export function didViewAddOnsPage() {
  return {
    type: DID_VIEW_ADD_ONS_PAGE,
  };
}

export function addLocation(data) {
  return {
    type: ADD_LOCATION,
    data,
  };
}

export function deleteLocation(data) {
  return {
    type: DELETE_LOCATION,
    data,
  };
}

export function updateLocation(data) {
  return {
    type: UPDATE_LOCATION,
    data,
  };
}

export function setLocations(data) {
  return {
    type: SET_LOCATIONS,
    data,
  };
}

export function setAvailablePolicyTypes(data) {
  return {
    type: SET_AVAILABLE_POLICY_TYPES,
    data,
  };
}

export function bindableQuoteReceived(data) {
  return {
    type: BINDABLE_QUOTE_RECEIVED,
    data,
  };
}

export function setExistingMailingAddress(data) {
  return {
    type: SET_EXISTING_MAILING_ADDRESS,
    data,
  };
}

export function addPotentialAddress(data) {
  return {
    type: ADD_POTENTIAL_ADDRESS,
    data,
  };
}

export function addAdditionalInsured(data) {
  return {
    type: ADD_ADDITIONAL_INSURED,
    data,
  };
}

export function removeAdditionalInsured(data) {
  return {
    type: REMOVE_ADDITIONAL_INSURED,
    data,
  };
}

export function clearAdditionalInsured() {
  return {
    type: CLEAR_ADDITIONAL_INSUREDS,
  };
}

export function setBlanketAdditionalInsureds() {
  return {
    type: SET_BLANKET_ADDITIONAL_INSUREDS,
  };
}

export function removeBlanketAdditionalInsureds() {
  return {
    type: REMOVE_BLANKET_ADDITIONAL_INSUREDS,
  };
}

export function toggleBlanketAIItem(data) {
  return {
    type: TOGGLE_BLANKET_AI_ITEM,
    data,
  };
}

export function setHiredAndNonOwnedAuto(data) {
  return {
    type: SET_HIRED_AND_NON_OWNED_AUTO,
    data,
  };
}

export function addPreviousLoss(data) {
  return {
    type: ADD_PREVIOUS_LOSS,
    data,
  };
}

export function editPreviousLoss(data) {
  return {
    type: EDIT_PREVIOUS_LOSS,
    data,
  };
}

export function removePreviousLoss(data) {
  return {
    type: REMOVE_PREVIOUS_LOSS,
    data,
  };
}

export function clearPreviousLosses() {
  return {
    type: CLEAR_PREVIOUS_LOSSES,
  };
}

export function resetPreviousLosses() {
  return {
    type: RESET_PREVIOUS_LOSSES,
  };
}

export function setPartnerInfo(data) {
  return {
    type: SET_PARTNER_INFO,
    data,
  };
}

export function setApplicationFromServer(data) {
  return {
    type: SET_APPLICATION_FROM_SERVER,
    data,
  };
}

export function setIndustryName(data) {
  return {
    type: SET_INDUSTRY_NAME,
    data,
  };
}

export function setIndustryId(data) {
  return {
    type: SET_INDUSTRY_ID,
    data,
  };
}

export function showNextPage() {
  return {
    type: SHOW_NEXT_PAGE,
  };
}

export function updateApplicationFromServer(data) {
  return {
    type: UPDATE_APPLICATION_FROM_SERVER,
    data,
  };
}

export function setAcknowledgements(data) {
  return {
    type: SET_ACKNOWLEDGEMENTS,
    data,
  };
}

export function updateProfessionalLiability(data) {
  return {
    type: UPDATE_PROFESSIONAL_LIABILITY,
    data,
  };
}

export function updateStartDate(data) {
  return {
    type: UPDATE_START_DATE,
    data,
  };
}

export function updateEndDate(data) {
  return {
    type: UPDATE_END_DATE,
    data,
  };
}

export function setPartnerFromApplication(data) {
  return {
    type: SET_PARTNER_FROM_APPLICATION,
    data,
  };
}

export function setPartnerIntroViewed(data) {
  return {
    type: PARTNER_INTRO_VIEWED,
    data,
  };
}

export function setExistingApplicationLoaded(data) {
  return {
    type: EXISTING_APPLICATION_LOADED,
    data,
  };
}

export function setExistingAddressAsLocation() {
  return {
    type: ADD_EXISTING_MAILING_TO_LOCATIONS,
  };
}

export function setAkHash(data) {
  return {
    type: SET_AK_HASH,
    data,
  };
}

export function clearIndustryState() {
  return { type: CLEAR_INDUSTRY_STATE };
}

export function setDBAIsValid(data) {
  return {
    type: DBA_IS_VALID,
    data,
  };
}

export function setContactEmailIsValid(data) {
  return { type: SET_CONTACT_EMAIL_IS_VALID, data };
}

export function setLossControl(data) {
  return {
    type: SET_LOSS_CONTROL,
    data,
  };
}
