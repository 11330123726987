import { CLEAR_ALL_DATA, CLEAR_APPLICATION } from '../actions/application';
import { CLEAR_PAYMENT_TRANSACTION_ERRORS, PAYMENT_TRANSACTION_FAILED } from '../actions/payment';

const defaultState = {
  errors: null,
};

export function paymentTransaction(state = defaultState, action: Action) {
  switch (action.type) {
    case PAYMENT_TRANSACTION_FAILED:
      return { ...state, errors: action.data };
    case CLEAR_PAYMENT_TRANSACTION_ERRORS:
    case CLEAR_ALL_DATA:
    case CLEAR_APPLICATION:
      return { ...defaultState };
    default:
      return state;
  }
}
