import { isPast, isValid, parseISO } from 'date-fns';

export const validStartDate = (dateToCheck: Date | string | undefined) => {
  const dateInput = typeof dateToCheck === 'string' ? parseISO(dateToCheck) : dateToCheck;

  if (!dateInput || !isValid(dateInput) || isPast(dateInput)) {
    const now = new Date();
    now.setMilliseconds(0);

    return now.toISOString();
  } else {
    const date = new Date(dateInput);

    return date.toISOString();
  }
};
