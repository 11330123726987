import React, { useState, useEffect, useCallback } from 'react';
import styled, { FlattenSimpleInterpolation } from 'styled-components';
import Floater from 'react-floater';
import { useMediaQuery } from 'react-responsive';
import { useThemeContext } from '../../elements/theme/CustomThemeProvider';

interface ToolTipProps {
  content: string | React.ReactNode;
  override?: React.ElementType;
  toolTipName: string;
  css?: FlattenSimpleInterpolation;
  labelElementId?: string;
  alignToLabel?: boolean;
}

interface ToolTipButtonProps {
  css?: FlattenSimpleInterpolation;
}

const ToolTipIcon = ({
  override: OverrideComponent,
  toolTipName,
  css,
  labelElementId,
  alignToLabel,
  content,
}: ToolTipProps) => {
  const { theme } = useThemeContext();
  const getContentBoxWidth = useCallback(() => {
    if (labelElementId && document.getElementById(labelElementId)) {
      return `${document.getElementById(labelElementId)?.offsetWidth}px`;
    } else {
      return '100%';
    }
  }, [labelElementId]);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [contentBoxWidth, setContentBoxWidth] = useState<string>(getContentBoxWidth);
  const isDesktopOrTablet = useMediaQuery({ minWidth: 768 });
  const isDesktop = useMediaQuery({ minWidth: 1025 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  useEffect(() => {
    const updateContentBoxWidth = () => {
      setContentBoxWidth(getContentBoxWidth());
    };
    window.addEventListener('resize', updateContentBoxWidth);
    return () => {
      window.removeEventListener('resize', updateContentBoxWidth);
    };
  }, [getContentBoxWidth]);

  return (
    <Floater
      content={content}
      open={isOpen}
      placement={isMobile ? 'bottom-end' : 'bottom'}
      hideArrow
      offset={alignToLabel ? 10 : 16}
      target={alignToLabel ? `#${labelElementId}` : `#${toolTipName.toLowerCase().replace(' ', '-')}-tooltip-icon`}
      styles={{
        floater: {
          maxWidth: '90%',
          filter: 'none',
        },
        container: {
          backgroundColor: theme.icons.toolTip.content.background,
          border: theme.icons.toolTip.content.border,
          borderRadius: theme.icons.toolTip.content.borderRadius,
          color: theme.icons.toolTip.content.fontColor,
          filter: 'none',
          minHeight: 'none',
          boxSizing: 'border-box',
          width: isDesktopOrTablet ? '320px' : contentBoxWidth,
          padding: 10,
        },
      }}
    >
      <ToolTipButton
        type="button"
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(!isOpen);
        }}
        onMouseEnter={() => {
          if (isDesktop) {
            setIsOpen(true);
          }
        }}
        onMouseLeave={() => {
          setIsOpen(false);
        }}
        aria-label={`Toggle tooltip ${toolTipName.toLowerCase().replace(' ', '-')}`}
        aria-expanded={isOpen}
        aria-describedby={`${toolTipName.toLowerCase().replace(' ', '-')}-sr-tooltip`}
        css={css}
      >
        <IconContainer id={`${toolTipName.toLowerCase().replace(' ', '-')}-tooltip-icon`}>
          {OverrideComponent ? <OverrideComponent /> : <Question>?</Question>}
        </IconContainer>
      </ToolTipButton>
      <SRTooltip id={`${toolTipName.toLowerCase().replace(' ', '-')}-sr-tooltip`}>{content}</SRTooltip>
    </Floater>
  );
};

const ToolTipButton = styled.button<ToolTipButtonProps>`
  width: 16px;
  height: 16px;
  display: inline-flex;
  background: transparent;
  border-radius: 20px;
  border: none;
  padding: 0;
  cursor: help;
  @media (max-width: 767px) {
    width: 32px;
    height: 32px;
  }
  :hover {
    span {
      color: ${(props) => props.theme.icons.toolTip.textColorOnHover};
    }
  }
  ${(props) => props.css};
`;

const Question = styled.span`
  color: ${(props) => props.theme.icons.toolTip.textColor};
  border-radius: 50%;
  padding: 2px 3px 2px 3px;
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-family: ${(props) => props.theme.font.typeface.primary};
  font-size: 11px;
  background: ${(props) => props.theme.icons.toolTip.background};
  @media (max-width: 767px) {
    align-self: center;
    font-size: 25px;
  }
  :hover {
    background: ${(props) => props.theme.icons.toolTip.backgroundOnHover};
    color: ${(props) => props.theme.icons.toolTip.textColorOnHover};
  }
`;

const IconContainer = styled.span`
  display: flex;
  width: 100%;
`;

const SRTooltip = styled.p`
  visibility: hidden;
  width: 0;
  height: 0;
  margin: 0;
  overflow: hidden;
`;

export default ToolTipIcon;
