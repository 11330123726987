import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Container, LabelLarge, inputStyles, Paragraph } from '../elements';
import { setIndustryName, setIndustryId, setAkHash, clearIndustryState } from '../actions/application';
import { selectApplicationId, selectIndustryState } from '../selectors/application';
import { selectIndustryName, selectDataIds } from '../selectors/index';
import { reportToSentry, TAGS } from '../helpers/sentry';
import { convertNumberToString } from '../helpers/valueConversion';
import { IndustrySearch } from '@coterieinsure/ux-components';
import styled from 'styled-components';
import IndustrySelect from '../Components/IndustrySelect';
import LoadingSpinner from '../Components/Shared/LoadingSpinnerDots';

interface IndustryProps {
  industryId: string | undefined;
  akHash?: string;
  industryName?: string;
  dataIds: string[];
  setIndustryName: Function;
  setIndustryId: Function;
  setAkHash: Function;
  clearIndustryState: Function;
}

const Industry = ({
  industryId,
  akHash,
  industryName,
  dataIds,
  setIndustryName,
  setIndustryId,
  setAkHash,
  clearIndustryState,
}: IndustryProps) => {
  useEffect(() => {
    document.title = 'Coterie - Industry';
  }, []);

  const selectIndustry = (selectedIndustryId: number, newIndustryName: string, newAkHash: string) => {
    const newIndustryId = convertNumberToString(selectedIndustryId);
    const prevIndustryId = industryId;
    const prevIndustryName = industryName;
    const prevAkHash = akHash;

    if ((newIndustryId === prevIndustryId || newAkHash === prevAkHash) && newIndustryName === prevIndustryName) {
      clearIndustryState();
    } else {
      setIndustryName(newIndustryName);
      setIndustryId(newIndustryId || 'INVALID_ID');
      setAkHash(newAkHash);
    }
  };

  return (
    <Container>
      <div>
        <LabelLarge display="inline" htmlFor="industry-search-input">
          What type of business are you in?
        </LabelLarge>
        <Paragraph>Select the option that best describes your business</Paragraph>
      </div>
      {!dataIds.length ? (
        <StyledIndustrySearch
          selectIndustry={selectIndustry}
          industryId={Number(industryId)}
          industryName={industryName}
          reportError={(error: any) => reportToSentry(error, undefined, TAGS.INDUSTRY_SEARCH)}
          loadingElement={<LoadingSpinner height={50} width={100} />}
        />
      ) : (
        <IndustrySelect selectedIndustries={dataIds} selectIndustry={selectIndustry} selectedId={industryId || ''} />
      )}
    </Container>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  applicationId: selectApplicationId(state),
  industryId: convertNumberToString(selectIndustryState(state)) || undefined,
  industryName: selectIndustryName(state),
  dataIds: selectDataIds(state),
});

const mapDispatchToProps = {
  setIndustryName,
  setIndustryId,
  setAkHash,
  clearIndustryState,
};

const StyledIndustrySearch = styled(IndustrySearch)`
  display: flex;
  flex-direction: column;
  position: relative;

  > div {
    z-index: 2;
  }

  p.header {
    font-family: ${(props) => props.theme.font.typeface.secondary};
    padding-bottom: 6px;
    margin-bottom: 0px;
    letter-spacing: 1px;
    font-size: 11px;
    margin-left: 10px;
  }

  img {
    max-width: 40px;
    position: absolute;
    right: 0;
    top: 16px;
  }

  input {
    ${inputStyles}
  }

  ul {
    margin: 8px 0;
    padding: 12px 0 0 0;
    list-style: none;
    max-height: 300px;
    overflow: auto;
    max-width: 560px;
    border: 1px solid #d4d4d9;
    border-radius: 4px;

    li {
      background-color: ${(props) => props.theme.formElements.dropdown.background};
      border: 1px solid transparent;
      padding: 10px 23px;
      :hover {
        cursor: pointer;
        background-color: ${(props) => props.theme.formElements.dropdown.backgroundSelected};
      }

      &.active {
        background-color: ${(props) => props.theme.formElements.dropdown.backgroundSelected};
        border-radius: 2px;
      }

      h3 {
        font-family: ${(props) => props.theme.font.typeface.secondary};
        font-size: 15px;
        margin: 0;
        font-weight: normal;
      }
    }
  }

  button {
    color: ${({ theme }) => theme.formElements.dropdown.linkTextColor};
    font-family: ${({ theme }) => theme.font.typeface.primary};
    font-size: ${({ theme }) => theme.formElements.input.fontSize};
    font-weight: bold;
    background-color: ${({ theme }) => theme.formElements.input.background};
    border: none;
    cursor: pointer;
    padding: 10px 23px 20px;
  }
`;

export default connect(mapStateToProps, mapDispatchToProps)(Industry);
