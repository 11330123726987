import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import StartDate from '../StartDate';
import { setPaymentInterval } from '../../../actions/quote';
import { showNextPage, didViewSummaryPage } from '../../../actions/application';
import handleFormSubmit from '../../../actions/handleFormSubmit';
import { getCurrentPage, getPaymentInterval } from '../../../selectors/routing';
import {
  selectLoadingState,
  selectReasonabilityErrors,
  selectPurchasedPolicies,
  selectPurchasedPolicy,
  selectCombinedPartnerInfo,
  selectIsPremierPlatformPartner,
  selectLocationType,
} from '../../../selectors/index';
import {
  selectQuote,
  selectPremium,
  selectMonthlyPremium,
  selectHasFees,
  selectFeesSavings,
  selectMonth1Fees,
  selectMonth1Owed,
  selectTotalYearlyOwed,
  selectPolicyFees,
  selectInstallmentFees,
  selectHasFirstMonthWaived,
  selectMonthlyOwed,
  selectLossControlFees,
  selectMonthlyFees,
  selectCyberFee,
} from '../../../selectors/quote';
import {
  selectApplication,
  selectPolicyTypes,
  selectLossControl,
  selectApplicationId,
} from '../../../selectors/application';
import { isPageComplete } from '../../../selectors/pageComplete';
import { PAGES } from '../../../constants';
import EndDate from '../../Summary/EndDate';
import { PrimaryButton } from '../../../elements';
import isApplicationBindable from '../../../helpers/isApplicationBindable';
import {
  Sidebar,
  QuoteCont,
  PremiumHeader,
  PriceCont,
  ToggleButtonCont,
  ToggleButton,
  BuyButtonCont,
  DiscountApplied,
  discountButtonCSS,
  PartnerName,
  SavingsNotice,
  FeesContainer,
  FeeAppliedContainer,
  FeeAppliedText,
  PriceRow,
  BreakdownRow,
  BreakdownLabel,
  BreakdownValue,
  TotalDueText,
  TotalDueButton,
  DownloadQuoteButton,
} from './styles';
import RenderPremium, { feeNotice } from './RenderPremium';
import PriceDrawer from './PriceDrawer';
import discount_applied_badge from '../../../icons/discount_applied_badge.svg';
import right_arrow_button from '../../../icons/right_arrow_button.svg';
import right_arrow_button_hover from '../../../icons/right_arrow_button_hover.svg';
import ToolTipIcon from '.././ToolTipIcon';
import toolTipContent from '../../../helpers/toolTipContent';
import { formatCurrency } from '../../../helpers/formatNumber';
import { PolicyType } from '../../../types/enums';
import { isProd, isSandbox } from '../../../helpers/environment';
import { selectIsAgentMDA } from '../../../selectors/agent';

const dashboardURLdomain =
  isProd() || isSandbox()
    ? 'https://dashboard.coterieinsurance.com/quoteproposal'
    : 'https://dashboard-test.coterieinsurance.com/quoteproposal';

interface InfoSidebarProps {
  loading: boolean;
  isSummaryPage: boolean;
  isPaymentPage: boolean;
  page: string;
  paymentInterval: string;
  setPaymentInterval: () => void;
  premium: number;
  monthlyPremium: number;
  hasSprinklers?: boolean;
  isPageComplete: boolean;
  isBindable: boolean;
  premierPlatform: boolean;
  shouldShowQuote: boolean;
  hasReasonabilityErrors: boolean;
  handleFormSubmit: () => void;
  policies: PolicyData[];
  policy: PolicyData;
  partnerInfo: PartnerInfo;
  showFees: boolean;
  feesSavings: number;
  month1Fees: number;
  month1Owed: number;
  totalYearlyOwed: number;
  policyFees: number;
  installmentFees: number;
  hasFirstMonthWaived: boolean;
  monthlyOwed: number;
  lossControl: string | undefined;
  lossControlFees: number;
  monthlyFees: number;
  hasBOPorGL: boolean;
  cyberFee?: Fee;
  applicationId?: string;
  isMDA?: boolean;
}

interface PremiumBreakdown {
  label: string;
  value?: any;
  rowStyle?: any;
  labelStyle?: any;
  valueStyle?: any;
  isHidden?: boolean;
}

const InfoSidebar = ({
  loading,
  isSummaryPage,
  page,
  paymentInterval,
  setPaymentInterval,
  premium,
  monthlyPremium,
  isPageComplete,
  isBindable,
  premierPlatform,
  shouldShowQuote,
  hasReasonabilityErrors,
  handleFormSubmit,
  policies,
  policy,
  partnerInfo,
  showFees,
  feesSavings,
  month1Fees,
  month1Owed,
  totalYearlyOwed,
  policyFees,
  installmentFees,
  hasFirstMonthWaived,
  monthlyOwed,
  lossControl,
  lossControlFees,
  monthlyFees,
  hasBOPorGL,
  cyberFee,
  applicationId,
  isMDA,
}: InfoSidebarProps) => {
  const handleSubmit = (e: React.MouseEvent | React.KeyboardEvent) => {
    e.preventDefault();
    handleFormSubmit();
  };

  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1024 });

  const [showPriceDrawer, setShowPriceDrawer] = useState(false);
  const [showPriceDrawerClosing, setShowPriceDrawerClosing] = useState(false);

  const handlePriceDrawerClose = () => {
    setShowPriceDrawer(false);
    setShowPriceDrawerClosing(true);
    setTimeout(() => {
      setShowPriceDrawerClosing(false);
    }, 400);
  };

  const quoteInterval = paymentInterval === 'None' ? 'year' : 'month';
  const hideToggleButtons = isTablet && page === PAGES.PAYMENT;
  const isPaymentPage = page === PAGES.PAYMENT;
  const showLoadingSpinner = shouldShowQuote && loading;
  const RATE_PAGES = [
    PAGES.GENERAL_LIABILITY,
    PAGES.PROFESSIONAL_LIABILITY,
    PAGES.LOCATIONS,
    PAGES.ADDITIONAL_INSUREDS,
    PAGES.CONTACT_INFORMATION,
    PAGES.ADD_ONS,
    PAGES.SUMMARY,
  ];
  const hasPurchasedPolicy = page === PAGES.PAYMENT && Boolean(policies?.length > 0);

  const renderedFeesSavings = formatCurrency(feesSavings);
  const yearlySavingsCopy = `You are saving ${renderedFeesSavings} in fees by paying in full`;
  const monthlySavingsCopy = `Save ${renderedFeesSavings} in fees by paying in full`;
  const savingsNotice = paymentInterval === 'None' ? yearlySavingsCopy : monthlySavingsCopy;
  const totalDueAmount = paymentInterval === 'None' ? formatCurrency(totalYearlyOwed) : formatCurrency(month1Owed);

  const premiumPaid = policies?.reduce((acc, curr) => acc + curr?.premiumPaid, 0);
  const feesPaid = paymentInterval === 'None' ? policy.totalYearlyFees : policy.month1Fees;

  const breakdownStyles = {
    headerRow: {
      'text-transform': 'uppercase',
      'font-size': '12px',
      'font-weight': '600',
      'letter-spacing': '1px',
      'align-items': 'baseline',
      'margin-bottom': '8px',
      color: '#0D0D46',
    },
    totalRow: { 'font-size': '16px', 'font-weight': '600', 'line-height': '21px', color: '#0D0D46' },
    breakdownValues: {
      'font-family': 'Questrial',
      'font-size': '15px',
      'font-weight': '400',
      'line-height': '24px',
      color: '#4A4A4D',
    },
  };

  const paymentPageBreakdown: PremiumBreakdown[] = [
    {
      label: 'Selected Billing Plan',
      value: paymentInterval === 'None' ? 'YEARLY' : 'MONTHLY',
      rowStyle: breakdownStyles.headerRow,
      valueStyle: { background: '#F2F5FA', 'border-radius': '4px', padding: '4px 6px' },
    },
    {
      label: 'Total Due',
      value: paymentInterval === 'None' ? formatCurrency(totalYearlyOwed) : formatCurrency(month1Owed),
      rowStyle: breakdownStyles.totalRow,
    },
    {
      label: 'Premium',
      value: paymentInterval === 'None' ? formatCurrency(premium) : formatCurrency(monthlyPremium),
      rowStyle: breakdownStyles.breakdownValues,
    },
    {
      label: 'Policy Fee',
      value: formatCurrency(policyFees),
      isHidden: !Boolean(policyFees),
      rowStyle: breakdownStyles.breakdownValues,
    },
    {
      label: 'Installment Fee',
      value: formatCurrency(installmentFees),
      isHidden: hasFirstMonthWaived,
      rowStyle: breakdownStyles.breakdownValues,
    },
    {
      label: 'Manage My Risk Program',
      value: paymentInterval === 'None' ? formatCurrency(lossControlFees * 12) : formatCurrency(lossControlFees),
      isHidden: !Boolean(lossControl === 'OptIn'),
      rowStyle: breakdownStyles.breakdownValues,
    },
    {
      label: 'Cyber Claim and Risk Management Fee',
      value: formatCurrency(cyberFee?.amount),
      isHidden: !Boolean(cyberFee?.amount),
      rowStyle: breakdownStyles.breakdownValues,
    },
    {
      label: 'Future Monthly Payments',
      value: formatCurrency(monthlyOwed),
      rowStyle: { 'letter-spacing': '1px', 'line-height': '32px' },
      labelStyle: { 'text-transform': 'uppercase', 'font-size': '12px', 'font-weight': '600', color: '#0D0D46' },
      valueStyle: { 'font-family': 'Questrial', 'font-size': '15px', 'font-weight': '400', color: '#4A4A4D' },
      isHidden: paymentInterval === 'None',
    },
  ];

  const paymentConfirmedPageBreakdown: PremiumBreakdown[] = [
    {
      label: 'Payment Summary',
      rowStyle: breakdownStyles.headerRow,
    },
    {
      label: 'Total Amount Paid Today',
      value: formatCurrency(premiumPaid + feesPaid),
      rowStyle: breakdownStyles.totalRow,
    },
    {
      label: 'Premium Paid',
      value: formatCurrency(premiumPaid),
      rowStyle: breakdownStyles.breakdownValues,
    },
    {
      label: 'Fees Paid',
      value: formatCurrency(feesPaid),
      rowStyle: breakdownStyles.breakdownValues,
      isHidden: !Boolean(feesPaid),
    },
  ];

  const renderPaymentPageBreakdown = hasPurchasedPolicy ? paymentConfirmedPageBreakdown : paymentPageBreakdown;

  return (
    <Sidebar>
      {isPaymentPage && (
        <QuoteCont data-cy="payment-breakdown-container" isPaymentPage={isPaymentPage}>
          <PriceCont data-cy="payment-breakdown-box" isPaymentPage={isPaymentPage}>
            {renderPaymentPageBreakdown
              .filter((e) => !e?.isHidden)
              .map((breakdown) => (
                <BreakdownRow key={breakdown.label} style={breakdown?.rowStyle}>
                  <BreakdownLabel style={breakdown?.labelStyle}>{breakdown.label}</BreakdownLabel>
                  {breakdown?.value && (
                    <BreakdownValue style={breakdown?.valueStyle} data-testid={`${breakdown.label}-value`}>
                      {breakdown.value}
                    </BreakdownValue>
                  )}
                </BreakdownRow>
              ))}
          </PriceCont>
        </QuoteCont>
      )}
      {RATE_PAGES.includes(page) && (
        <QuoteCont data-cy="rc1-container">
          {isBindable && <PremiumHeader>Your premium</PremiumHeader>}
          <PriceCont data-cy="quote-box" isSummaryPage={isSummaryPage}>
            <PriceRow>
              {premierPlatform && (
                <PartnerName>
                  with <span>{partnerInfo.name}</span>
                </PartnerName>
              )}
              {isBindable && !hasPurchasedPolicy && !hideToggleButtons && (
                <ToggleButtonCont>
                  <ToggleButton
                    disabled={loading || paymentInterval === 'Monthly'}
                    selected={paymentInterval === 'Monthly'}
                    onClick={() => setPaymentInterval()}
                  >
                    Monthly
                  </ToggleButton>
                  <ToggleButton
                    disabled={loading || paymentInterval === 'None'}
                    selected={paymentInterval === 'None'}
                    onClick={() => setPaymentInterval()}
                  >
                    Yearly
                  </ToggleButton>
                </ToggleButtonCont>
              )}
              <RenderPremium
                showLoadingSpinner={showLoadingSpinner}
                isSummaryPage={isSummaryPage}
                isBindable={isBindable}
                paymentInterval={paymentInterval}
                page={page}
                shouldShowQuote={shouldShowQuote}
                monthlyPremium={monthlyPremium}
                premium={premium}
                showFees={showFees}
                savingsNotice={savingsNotice}
                totalYearlyOwed={totalYearlyOwed}
                month1Owed={month1Owed}
                setShowPriceDrawer={setShowPriceDrawer}
              />
            </PriceRow>
            {isTablet && isSummaryPage && (
              <TotalDueText>
                Total due today: {totalDueAmount}
                <TotalDueButton onClick={() => setShowPriceDrawer(true)}>
                  <img src={right_arrow_button} alt="show total due details" />
                  <img src={right_arrow_button_hover} alt="show total due details" />
                </TotalDueButton>
              </TotalDueText>
            )}
            {isTablet && isSummaryPage && showFees && (
              <SavingsNotice interval={quoteInterval}>{savingsNotice}</SavingsNotice>
            )}
            {premierPlatform && (
              <ToolTipIcon
                data-cy="discount-applied"
                content={toolTipContent['DISCOUNT_APPLIED']}
                toolTipName="Discount_Applied"
                override={() => <DiscountApplied src={discount_applied_badge} alt="5% discount applied" />}
                css={discountButtonCSS}
              />
            )}
          </PriceCont>
          {showFees && isSummaryPage && (
            <FeesContainer>
              {lossControl === 'OptIn' && Boolean(lossControlFees) && (
                <FeeAppliedContainer>
                  <FeeAppliedText data-testid="loss-control-notice">
                    Manage My Risk added for an additional{' '}
                    {paymentInterval === 'None'
                      ? formatCurrency(lossControlFees * 12)
                      : `${formatCurrency(lossControlFees)}/mo`}
                  </FeeAppliedText>
                </FeeAppliedContainer>
              )}
              <FeeAppliedContainer>
                {
                  <ToolTipIcon
                    content={
                      typeof toolTipContent['FEES_APPLIED'] === 'function' &&
                      toolTipContent['FEES_APPLIED'](month1Fees, paymentInterval, feesSavings)
                    }
                    toolTipName="Fees_Applied"
                  />
                }
                <FeeAppliedText>{feeNotice}</FeeAppliedText>
              </FeeAppliedContainer>
            </FeesContainer>
          )}
          {isSummaryPage && (
            <div>
              <StartDate fullWidth disabled={loading} />
              <EndDate disabled={loading} />
            </div>
          )}
          {isBindable && !hasPurchasedPolicy && (
            <BuyButtonCont>
              <PrimaryButton
                onClick={handleSubmit}
                data-cy="go-to-payment"
                disabled={loading || hasReasonabilityErrors || !isPageComplete}
                full
              >
                Pay
              </PrimaryButton>
            </BuyButtonCont>
          )}
          {isBindable && applicationId && isMDA && (
            <div>
              <DownloadQuoteButton as="a" target="_blank" href={`${dashboardURLdomain}/${applicationId}`}>
                {/* inlining PDF svg icon so fill color can be changed on hover */}
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M5.6 7.7L5.6 18.9H16.8V20.5H5.6C4.72 20.5 4 19.78 4 18.9L4 7.7H5.6ZM18.4 4.5C19.28 4.5 20 5.22 20 6.1V15.7C20 16.58 19.28 17.3 18.4 17.3H8.8C7.92 17.3 7.2 16.58 7.2 15.7L7.2 6.1C7.2 5.22 7.92 4.5 8.8 4.5L18.4 4.5ZM18.4 6.1L8.8 6.1L8.8 15.7L18.4 15.7V6.1ZM14.2004 7.8529C14.3454 8.3319 14.1534 9.0929 14.0324 9.5939C14.2844 10.2559 14.6414 10.7059 15.1754 11.0329C15.7164 10.9649 16.7984 10.8619 17.1774 11.2349C17.5034 11.5609 17.4264 12.4369 16.6334 12.4369C16.1734 12.4369 15.4874 12.2259 14.8994 11.9089C14.2274 12.0209 13.4694 12.3039 12.7734 12.5339C11.2164 15.2199 10.3054 13.9949 10.4084 13.5039C10.5324 12.8819 11.3714 12.3879 11.9934 12.0679C12.3194 11.4959 12.7824 10.4979 13.0934 9.7549C12.8634 8.8659 12.7394 8.1699 12.8754 7.6699C13.0254 7.1189 14.0694 7.0389 14.2004 7.8529ZM11.9654 12.5649C11.7824 12.7359 11.1794 13.2269 11.0204 13.6529C11.0204 13.6749 11.3754 13.5069 11.9654 12.5649ZM13.5654 10.2739C13.3574 10.8239 13.0994 11.4489 12.7984 11.9739C13.2914 11.7849 13.8544 11.5119 14.5014 11.3779C14.1534 11.1199 13.8244 10.7439 13.5654 10.2739ZM15.7294 11.5769C16.7334 12.0059 16.8884 11.8189 16.8884 11.8189C17.0004 11.7449 16.8204 11.4959 15.7294 11.5769ZM13.5564 7.7289C13.4784 7.7289 13.4754 8.5649 13.6124 8.9969C13.7644 8.7259 13.7864 7.7289 13.5564 7.7289Z"
                  />
                </svg>
                Download Quote
              </DownloadQuoteButton>
            </div>
          )}
        </QuoteCont>
      )}
      {(showPriceDrawer || showPriceDrawerClosing) && (
        <PriceDrawer
          showPriceDrawer={showPriceDrawer}
          onClose={handlePriceDrawerClose}
          isMonthly={paymentInterval !== 'None'}
          monthlyPremium={monthlyPremium}
          premium={premium}
          policyFees={policyFees}
          installmentFees={installmentFees}
          hasFirstMonthWaived={hasFirstMonthWaived}
          hasLossControl={Boolean(lossControl === 'OptIn')}
          lossControlFees={lossControlFees}
          month1Owed={month1Owed}
          totalYearlyOwed={totalYearlyOwed}
          monthlyFees={monthlyFees}
          monthlyOwed={monthlyOwed}
          hasBOPorGL={hasBOPorGL}
          cyberFee={cyberFee}
        />
      )}
    </Sidebar>
  );
};

const mapStateToProps = (state: ReduxState) => {
  const page = getCurrentPage(state);
  const isSummaryPage = page === PAGES.SUMMARY;
  const isPaymentPage = page === PAGES.PAYMENT;
  const reasonabilityErrors = selectReasonabilityErrors(state) || [];

  const policyTypes = selectPolicyTypes(state);
  const hasReasonabilityErrors = reasonabilityErrors.length > 0;
  const hasSelectedBOP = policyTypes.includes(PolicyType.BOP);
  const isOnLocationsPage = selectLocationType(state) === PAGES.LOCATIONS;
  const quote = selectQuote(state);
  const shouldShowQuote = !hasReasonabilityErrors && quote?.quotes?.length !== 0;

  return {
    premium: selectPremium(state),
    monthlyPremium: selectMonthlyPremium(state),
    loading: selectLoadingState(state),
    paymentInterval: getPaymentInterval(state),
    page,
    isSummaryPage,
    isPaymentPage,
    reasonabilityErrors: selectReasonabilityErrors(state),
    policies: selectPurchasedPolicies(state),
    policy: selectPurchasedPolicy(state),
    isBindable: isApplicationBindable(selectApplication(state)) && (isSummaryPage || isPaymentPage),
    partnerInfo: selectCombinedPartnerInfo(state),
    premierPlatform: selectIsPremierPlatformPartner(state),
    shouldShowQuote,
    isPageComplete: isPageComplete(state),
    hasReasonabilityErrors,
    showFees: selectHasFees(state),
    feesSavings: selectFeesSavings(state),
    month1Fees: selectMonth1Fees(state),
    month1Owed: selectMonth1Owed(state),
    totalYearlyOwed: selectTotalYearlyOwed(state),
    policyFees: selectPolicyFees(state),
    installmentFees: selectInstallmentFees(state),
    hasFirstMonthWaived: selectHasFirstMonthWaived(state),
    monthlyOwed: selectMonthlyOwed(state),
    lossControl: selectLossControl(state),
    lossControlFees: selectLossControlFees(state),
    monthlyFees: selectMonthlyFees(state),
    hasBOPorGL: policyTypes?.includes(PolicyType.BOP) || policyTypes?.includes(PolicyType.GL),
    cyberFee: selectCyberFee(state),
    applicationId: selectApplicationId(state),
    isMDA: selectIsAgentMDA(state),
  };
};

// TODO: Had to change this back to non object shorthand for mapDispatchToProps
// this was because jest.spyOn is not working with object shorthand
const mapDispatchToProps = (dispatch: ThunkDispatch<ReduxState, unknown, AnyAction>) => ({
  didViewSummaryPage: (didView: boolean) => dispatch(didViewSummaryPage(didView)),
  showNextPage: () => dispatch(showNextPage()),
  setPaymentInterval: () => dispatch(setPaymentInterval()),
  handleFormSubmit: () => dispatch(handleFormSubmit()),
});

export default connect(mapStateToProps, mapDispatchToProps)(InfoSidebar);
