import { SET_AGENT, SET_IS_MDA, SET_AGENT_PRODUCER_EXTERNAL_ID, SET_MDA_LEAD_EMAIL_SENT } from './../actions/agent';

const initialState = { isMDA: false, mdaLeadEmailSent: false };

export function agent(state: Agent = initialState, action: Action) {
  switch (action.type) {
    case SET_AGENT:
      return Object.assign({}, state, action.data);
    case SET_IS_MDA:
      return Object.assign({}, state, { isMDA: true });
    case SET_AGENT_PRODUCER_EXTERNAL_ID:
      return Object.assign({}, state, { producerExternalID: action.data });
    case SET_MDA_LEAD_EMAIL_SENT:
      return Object.assign({}, state, { mdaLeadEmailSent: action.data });
    default:
      return state;
  }
}
