import { useMutation } from 'react-query';
import { bindQuote } from '../api';

interface PostBindPayload {
  quoteId: string;
  paymentInterval?: string;
  useStripeCheckout?: boolean;
  agencyExternalId?: string;
  producerExternalId?: string;
}

interface PostBindPayloadStripe extends PostBindPayload {
  tokenId: string;
}

interface PostBindPayloadAuthorize extends PostBindPayload {
  authorizeNetPaymentNonce: string;
  carrierAuthorizeNetPaymentNonces?: any;
}

export const usePostBindQuote = (options?: any) => {
  return useMutation((payload: PostBindPayloadStripe | PostBindPayloadAuthorize) => bindQuote(payload), options);
};
