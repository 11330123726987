import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { getQuoteAmount } from '../../selectors/quote';
import { getCurrentPage } from '../../selectors/routing';
import { PageTitle, Container } from '../../elements';
import InfoSidebar from './InfoSidebar';
import { Tablet } from '../../helpers/responsive';
import { PAGES } from '../../constants';
import { selectPurchasedPolicies } from '../../selectors';

interface PageTitles {
  [key: string]: string;
}

interface PageHeaderProps {
  currentPage: string;
  isPolicyPurchased: boolean;
}

const PageHeader = ({ currentPage, isPolicyPurchased }: PageHeaderProps) => {
  const pageTitles: PageTitles = {
    INDUSTRY: 'Industry',
    BUSINESS_INFORMATION: 'Business Information',
    POLICIES: 'Policies',
    FINANCE_BASICS: 'Finance Basics',
    GENERAL_LIABILITY: 'General Liability',
    PROFESSIONAL_LIABILITY: 'Professional Liability',
    LOCATIONS: 'Locations',
    ADDITIONAL_INSUREDS: 'Additional Insureds',
    CONTACT_INFORMATION: 'Contact Information',
    ADD_ONS: 'Add Ons',
    SUMMARY: 'Policy Summary',
    PAYMENT: isPolicyPurchased ? "Congrats, you're insured!" : 'Payment',
  };

  const hideSidebar = ['SUMMARY', 'PAYMENT'];

  return (
    <Container>
      <HeaderContainer>
        <PageTitle>{pageTitles[currentPage]}</PageTitle>
        <Tablet>{!hideSidebar.find((page) => page === currentPage) && <InfoSidebar />}</Tablet>
      </HeaderContainer>
    </Container>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  quoteAmount: getQuoteAmount(state),
  currentPage: getCurrentPage(state),
  isPolicyPurchased: Boolean(getCurrentPage(state) === PAGES.PAYMENT && selectPurchasedPolicies(state)?.length),
});

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 32px 0;
`;

export default connect(mapStateToProps)(PageHeader);
