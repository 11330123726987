import React from 'react';
import { useThemeContext } from '../../../elements/theme/CustomThemeProvider';
import LoadingSpinner from '../../Shared/LoadingSpinnerDots';
import { CenteredLoadingCont } from '../styles/addons';
import styled from 'styled-components';

interface CenteredLoadingSpinnerCompProps {
  useAltColor?: boolean;
  showCheckMark?: boolean;
  position?: 'absolute' | 'relative';
}

const CenteredLoadingSpinner = ({
  useAltColor = false,
  showCheckMark,
  position = 'absolute',
}: CenteredLoadingSpinnerCompProps) => {
  const { theme } = useThemeContext();
  return (
    <CenteredLoadingCont data-testid="loading-spinner" position={position}>
      {showCheckMark ? (
        <EligibleStatusMessage>
          <img src={theme.icons.checkMark} alt="checkmark" height="15px" />
          Based on your answers, you're eligible for this coverage
        </EligibleStatusMessage>
      ) : (
        <LoadingSpinner height={50} width={50} useAltColor={useAltColor} />
      )}
    </CenteredLoadingCont>
  );
};

const EligibleStatusMessage = styled.p`
  display: flex;
  align-items: center;
  color: #4223ff;
  font-weight: 600;

  img {
    margin-right: 8px;
  }
`;

export default CenteredLoadingSpinner;
