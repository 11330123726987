import styled from 'styled-components';

export const PolicyRow = styled.div`
  display: flex;
  margin: 0;
  padding: 20px;
  :hover {
    cursor: pointer;
  }
`;

interface PolicyContProps {
  isSelected: boolean;
  policyTypeUnavailable?: boolean;
  theme: ThemeInterface;
}

export const PolicyCont = styled.section`
  margin: ${(props: PolicyContProps) => (props.policyTypeUnavailable ? '10px 0 0' : '10px 0')};
  position: relative;
  border: ${(props: PolicyContProps) =>
    props.isSelected ? props.theme.formElements.visualCheckbox.borderOnActive : '1px solid transparent'};
  border-radius: 4px;
  background-color: ${(props) => props.theme.formElements.visualCheckbox.background};
`;

export const PolicyTitle = styled.p`
  font-family: ${(props) => props.theme.font.typeface.primary};
  font-size: 20px;
  line-height: 26px;
  font-weight: 600;
  color: ${(props) => props.theme.pageComponents.policySelection.policyTitle.textColor};
  margin: 0 0 5px 0;

  @media (max-width: 767px) {
    padding: 0 16px 0 0;
  }
`;

export const PolicyDesc = styled.div`
  font-family: ${(props) => props.theme.font.typeface.secondary};
  font-size: 15px;
  line-height: 23px;
  color: ${(props) => props.theme.pageComponents.policySelection.policyDesc.textColor};
  margin: 0;
`;

export const PolicyDescCont = styled.div`
  margin: 0 0 0 74px;
  padding: 5px 20px;
  @media (max-width: 767px) {
    margin: 0;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;
export const LearnMoreBox = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  padding: 10px 0;
  border-top: 1px solid ${(props) => props.theme.pageComponents.globals.background};
  :hover {
    cursor: pointer;
  }
`;

export const MobileTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px 20px 0;
`;
