import React from 'react';
import styled from 'styled-components';
import { DesktopAndTablet } from '../../helpers/responsive';

const FinePrint = () => (
  <div>
    <MobileRow>
      <FinePrintLink href={`https://${process.env.REACT_APP_DOMAIN}/privacy-policy`} target="_blank" rel="noopener">
        Privacy Policy
      </FinePrintLink>
      <FinePrintLink href={`https://${process.env.REACT_APP_DOMAIN}/terms-conditions`} target="_blank" rel="noopener">
        Terms & Conditions
      </FinePrintLink>
    </MobileRow>
    <FinePrintText>
      © {new Date().getFullYear()} Coterie Applications Inc.
      <DesktopAndTablet>
        <br />
      </DesktopAndTablet>{' '}
      All rights reserved.
    </FinePrintText>
  </div>
);

const MobileRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  @media (min-width: 768px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`;

const FinePrintLink = styled.a`
  color: ${(props) => props.theme.pageComponents.applicationProgress.finePrintLink.textColor};
  font-family: ${(props) => props.theme.font.typeface.primary};
  font-size: 11px;
  font-weight: 600;
  line-height: 13px;
  text-decoration: none;
  margin-bottom: 8px;
  display: block;
`;

const FinePrintText = styled.p`
  color: ${(props) => props.theme.pageComponents.applicationProgress.finePrintText.textColor};
  font-family: ${(props) => props.theme.font.typeface.secondary};
  font-size: 11px;
  letter-spacing: 0;
  line-height: 17px;
  margin: 0;
  display: block;
  text-align: center;

  @media (min-width: 768px) {
    text-align: left;
  }
`;

export default FinePrint;
