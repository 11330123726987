import { APPLICATION_ERROR } from '../actions/quote';
import { Middleware } from 'redux';
import { fatalError } from '../actions/application';
import { ERRORS } from '../constants';

/**
 *  * Make sure to update this list in cypress fixtures *
 *  * as we cannot import from the project files into cypress*
 *  */
export const CAT_1_REASONABILITY_ERRORS = [
  'An error occurred while rating request ID',
  'Failed to lookup CGL/BOP class codes for AK Hash',
];

const errorInterceptor: Middleware<{}, ReduxState> = (store) => (next) => (action) => {
  const { dispatch } = store;
  const { type, data } = action;

  if (type === APPLICATION_ERROR && data.length !== 0) {
    let cat1Errors = 0;
    let errorsChecked = 0;
    while (errorsChecked < data.length) {
      for (let errorMsg of data) {
        for (let subString of CAT_1_REASONABILITY_ERRORS) {
          if (typeof errorMsg === 'string') {
            if (errorMsg.includes(subString)) {
              cat1Errors++;
            }
          }
        }
        errorsChecked++;
      }
    }

    if (cat1Errors === data.length) {
      dispatch(fatalError(ERRORS.CAT_1.UNACTIONABLE_REASONABILITY_ERROR));
      return;
    }
  }

  return next(action);
};

export default errorInterceptor;
