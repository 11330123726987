import { Dictionary } from 'lodash';
import styled from 'styled-components';
import {
  setLiquorLiabilityCoverage,
  setRestaurantCoverage,
  setEquipmentBreakdownCoverage,
  setHiredAndNonOwnedAuto,
  setLimitDamagePremRented,
  setContractorToolsCoverage,
  setCyberCoverage,
  setWorkplaceViolenceCoverage,
} from '../../actions/application';
import { formatCurrency } from '../../helpers/formatNumber';

type InputBoundaries = Dictionary<number>;

export type EndorsementUnderwritingQuestions = {
  [key: string]: {
    QUESTION_COPY: string;
    ERROR_COPY: string;
    validateInput: (value: number | string, boundaries: InputBoundaries) => boolean;
    options?: { label: string; value: string | number }[];
  };
};

export type EndorsementValidationQuestion = {
  QUESTION_COPY: string;
};

export type EndorsementValidation = {
  validationQuestions: EndorsementValidationQuestion[];
  validationFunc: (...args: any[]) => boolean;
};

export interface EndorsementSubQuestions {
  underwritingQuestions?: EndorsementUnderwritingQuestions;
  validation?: EndorsementValidation;
}

interface EndorsementObj {
  LABEL: string;
  QUESTION_COPY: string | JSX.Element;
  action: (value: any) => Action;
  subQuestions?: EndorsementSubQuestions;
  coverageInfo: (data: { [key: string]: number | string }) => CoverageInfo[];
  // Showing an endorsement as recommended is hardcoded below.  If we end up having to update these recommended endorsements again we should consider using a more dynamic solution like launch darkly to store the recommended endorsements.
  recommended: boolean;
}

export type CoverageInfo = {
  coverage: string;
  limit: string;
};

const BoldText = styled.p`
  font-weight: 600;
`;

export const endorsementsMap: Dictionary<EndorsementObj> = {
  liquorLiabilityEndorsement: {
    LABEL: 'Liquor Liability Coverage',
    QUESTION_COPY: 'Add this coverage if you sell, serve or furnish alcoholic beverages.',
    action: setLiquorLiabilityCoverage,
    subQuestions: {
      underwritingQuestions: {
        alcoholicBeverageSales: {
          QUESTION_COPY: 'What are your annual liquor sales?',
          ERROR_COPY: 'Annual liquor sales cannot exceed gross annual sales',
          validateInput: (value, { grossAnnualSales: top }) => top >= value,
        },
      },
    },
    coverageInfo: ({ glLimit, glAggregateLimit }) => [
      { coverage: 'Per Claim Limit', limit: formatCurrency(glLimit as number) },
      { coverage: 'Yearly Limit', limit: formatCurrency(glAggregateLimit as number) },
    ],
    // Showing an endorsement as recommended is hardcoded below.  If we end up having to update these recommended endorsements again we should consider using a more dynamic solution like launch darkly to store the recommended endorsements.
    recommended: false,
  },
  equipmentBreakdownEndorsement: {
    LABEL: 'Equipment Breakdown Coverage',
    QUESTION_COPY:
      'Add this coverage to protect your business equipment from unexpected events like power surges or outages.',
    action: setEquipmentBreakdownCoverage,
    coverageInfo: ({ buildingLimit, bppLimit }) => [
      {
        coverage: 'Equipment Breakdown Limit',
        limit: formatCurrency(Boolean(buildingLimit) ? (buildingLimit as number) : (bppLimit as number)),
      },
    ],
    // Showing an endorsement as recommended is hardcoded below.  If we end up having to update these recommended endorsements again we should consider using a more dynamic solution like launch darkly to store the recommended endorsements.
    recommended: true,
  },
  restaurantCoverage: {
    LABEL: 'Restaurant Coverage',
    QUESTION_COPY:
      'Add this coverage to protect your restaurant against things like food spoilage and kitchen accidents. ',
    action: setRestaurantCoverage,
    coverageInfo: ({ glLimit }) => [
      { coverage: 'Food Contamination', limit: '$10,000' },
      { coverage: 'Additional Advertising Expenses', limit: '$3,000' },
      { coverage: 'Spoilage', limit: '$10,000' },
      { coverage: 'Reward Payment', limit: '$5,000' },
      { coverage: 'Brands and Labels', limit: formatCurrency(glLimit as number) },
      { coverage: 'Ordinance and Law', limit: formatCurrency(glLimit as number) },
      { coverage: 'Lock Replacement', limit: '$1,000' },
      { coverage: 'Delivery Errors and Omissions', limit: '$10,000' },
      { coverage: 'Merchandise withdrawal Expense', limit: '$25,000' },
    ],
    // Showing an endorsement as recommended is hardcoded below.  If we end up having to update these recommended endorsements again we should consider using a more dynamic solution like launch darkly to store the recommended endorsements.
    recommended: true,
  },
  hiredAutoAndNonOwnedAuto: {
    LABEL: 'Hired and Non-Owned Auto Coverage',
    QUESTION_COPY: 'Add this coverage if your business uses personal autos for work-related reasons.',
    action: setHiredAndNonOwnedAuto,
    subQuestions: {
      validation: {
        validationFunc: (answers) => !Object.values(answers).includes(true),
        validationQuestions: [
          {
            QUESTION_COPY: 'Does your business own any autos?',
          },
          {
            QUESTION_COPY: 'Does your business ever use autos to transport passengers?',
          },
          {
            QUESTION_COPY: 'Does your business ever operate your customer’s autos?',
          },
        ],
      },
    },
    coverageInfo: ({ glLimit, glAggregateLimit }) => [
      { coverage: 'Per Claim Limit', limit: formatCurrency(glLimit as number) },
      { coverage: 'Yearly Limit', limit: formatCurrency(glAggregateLimit as number) },
    ],
    // Showing an endorsement as recommended is hardcoded below.  If we end up having to update these recommended endorsements again we should consider using a more dynamic solution like launch darkly to store the recommended endorsements.
    recommended: true,
  },
  cyberCoverage: {
    LABEL: 'Cyber Insurance',
    QUESTION_COPY: (
      <>
        <p>Provides coverage against losses resulting from a cyber attack, security breach, and/or data loss.</p>
        <BoldText>Per Event Deductible: $1,000</BoldText>
      </>
    ),
    action: setCyberCoverage,
    subQuestions: {
      underwritingQuestions: {
        limits: {
          QUESTION_COPY: 'Select your coverage limit:',
          ERROR_COPY: '',
          validateInput: (value) => value === '25000/50000' || value === '50000/100000',
          options: [
            {
              label: '$50,000 coverage form aggregate / $50,000 liability coverage / $25,000 first party coverage',
              value: '25000/50000',
            },
            {
              label: '$100,000 coverage form aggregate / $100,000 liability coverage / $50,000 first party coverage',
              value: '50000/100000',
            },
          ],
        },
      },
    },
    coverageInfo: ({ cyberLimits }) => [
      { coverage: 'Coverage Form Aggregate', limit: isCyber25_50(cyberLimits as string) ? '$50,000' : '$100,000' },
      { coverage: 'Liability Coverage Limit', limit: isCyber25_50(cyberLimits as string) ? '$50,000' : '$100,000' },
      { coverage: 'First Party Coverage Limit', limit: isCyber25_50(cyberLimits as string) ? '$25,000' : '$50,000' },
    ],
    // Showing an endorsement as recommended is hardcoded below.  If we end up having to update these recommended endorsements again we should consider using a more dynamic solution like launch darkly to store the recommended endorsements.
    recommended: true,
  },
  workplaceViolenceCoverage: {
    LABEL: 'Workplace Violence Coverage',
    QUESTION_COPY: (
      <>
        <p>
          Provides coverage for expenses and business interruption due to a violent event on or off premises as well as
          coverage for traveling employees.
        </p>
        <BoldText>Deductible: $0</BoldText>
      </>
    ),
    action: setWorkplaceViolenceCoverage,
    subQuestions: {
      underwritingQuestions: {
        limits: {
          QUESTION_COPY: 'Select your coverage limit:',
          ERROR_COPY: '',
          validateInput: (value) => value === '50000/50000' || value === '100000/100000',
          options: [
            {
              label: '$50,000 per event / $50,000 agg',
              value: '50000/50000',
            },
            {
              label: '$100,000 per event / $100,000 agg',
              value: '100000/100000',
            },
          ],
        },
      },
    },
    coverageInfo: ({ workplaceViolenceCoverage }) => [
      { coverage: 'Per Event', limit: workplaceViolenceCoverage === '50000/50000' ? '$50,000' : '$100,000' },
      { coverage: 'Aggregate', limit: workplaceViolenceCoverage === '50000/50000' ? '$50,000' : '$100,000' },
    ],
    // Showing an endorsement as recommended is hardcoded below.  If we end up having to update these recommended endorsements again we should consider using a more dynamic solution like launch darkly to store the recommended endorsements.
    recommended: true,
  },
  limitDamagePremRented: {
    LABEL: 'Damages to Premises Rented to You (Higher Limits)',
    QUESTION_COPY:
      'Your policy includes $50,000 coverage for premises rented. Do you want to increase your total damage protection?',
    action: setLimitDamagePremRented,
    subQuestions: {
      underwritingQuestions: {
        limitDamagePremRented: {
          QUESTION_COPY: 'Choose your total coverage amount up to $1,000,000',
          ERROR_COPY: 'An increased limit amount must be greater than $50,000 and less than $1,000,000',
          validateInput: (value) => (value as number) >= 50_000 && (value as number) <= 1_000_000,
        },
      },
    },
    coverageInfo: ({ limitDamagePremRented }) => [
      { coverage: 'Limit', limit: formatCurrency(limitDamagePremRented as number) },
    ],
    // Showing an endorsement as recommended is hardcoded below.  If we end up having to update these recommended endorsements again we should consider using a more dynamic solution like launch darkly to store the recommended endorsements.
    recommended: false,
  },
  contractorsInstallationAndTools: {
    LABEL: 'Contractor Tools Coverage',
    QUESTION_COPY: 'Select additional coverage for tools and installation. Your current coverage is $5,000.',
    action: setContractorToolsCoverage,
    subQuestions: {
      underwritingQuestions: {
        selectedContractorsToolsLimit: {
          QUESTION_COPY: 'Choose your total coverage amount:',
          ERROR_COPY: 'You must select a limit to add this coverage.',
          options: [
            { value: 10000, label: '$10,000' },
            { value: 15000, label: '$15,000' },
            { value: 20000, label: '$20,000' },
            { value: 25000, label: '$25,000' },
          ],
          validateInput: (value, { grossAnnualSales: top }) => top >= value,
        },
      },
    },
    coverageInfo: ({ contractorToolsLimit }) => [
      { coverage: 'Limit', limit: formatCurrency(contractorToolsLimit as number) },
    ],
    // Showing an endorsement as recommended is hardcoded below.  If we end up having to update these recommended endorsements again we should consider using a more dynamic solution like launch darkly to store the recommended endorsements.
    recommended: false,
  },
};

const isCyber25_50 = (limits: string) => {
  return limits === '25000/50000';
};
