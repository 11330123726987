import { Dictionary } from 'lodash';
import { CLEAR_ALL_DATA, CLEAR_APPLICATION, SET_AK_HASH, SET_APPLICATION_TYPES } from '../actions/application';
import { AVAILABLE_ENDORSEMENTS_ACTIONS } from '../actions/availableEndorsements';
import { formatKeysFromPascalCaseToCamelCase } from '../helpers/functionalHelpers';

const defaultState = {
  isLoading: false,
  endorsements: undefined, //undefined indicates data needs to be fetched; null indicates no endorsements due to BOP 1
};

const availableEndorsements = (state: Dictionary<any> = defaultState, action: Action) => {
  switch (action.type) {
    case AVAILABLE_ENDORSEMENTS_ACTIONS.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.data,
      };
    case AVAILABLE_ENDORSEMENTS_ACTIONS.SET_AVAILABLE_ENDORSEMENTS:
      return {
        ...state,
        endorsements: formatKeysFromPascalCaseToCamelCase(action.data),
      };
    case AVAILABLE_ENDORSEMENTS_ACTIONS.CLEAR_AVAILABLE_ENDORSEMENTS:
    case CLEAR_ALL_DATA:
    case CLEAR_APPLICATION:
    case SET_AK_HASH:
    case SET_APPLICATION_TYPES:
      return defaultState;
    default:
      return state;
  }
};

export default availableEndorsements;
