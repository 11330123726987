const renderPremium = (amount?: number | string) => {
  if (!amount) return '';
  if (typeof amount === 'string') {
    return parseFloat(amount.replace(',', ''))
      .toFixed(2)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  } else {
    return amount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
};

export default renderPremium;
