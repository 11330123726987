import SmoothCollapse from 'react-smooth-collapse';
import styled from 'styled-components';

export const WhiteHr = styled.hr<{ isHidden: boolean }>`
  height: 2px;
  width: 100%;
  border: none;
  background-color: ${({ theme, isHidden }) =>
    !isHidden
      ? theme.formElements.visualCheckbox.expandedSection.background
      : theme.formElements.visualCheckbox.expandedSection.borderBottom};
`;

export const BlendedSpacer = styled.div`
  height: 44px;
  width: 100%;
  background-color: ${({ theme }) => theme.formElements.visualCheckbox.background};
`;

export const PolicySummaryCont = styled.div`
  padding: 0 20px;
`;

export const StyledSmoothCollapse = styled(SmoothCollapse)`
  background-color: ${({ theme }) => theme.formElements.visualCheckbox.expandedSection.background};

  .PolicyItem:not(:last-child) {
    border-bottom: 2px solid ${({ theme }) => theme.formElements.visualCheckbox.background};
  }
`;

export const DrawerControls = styled.button`
  border: 0;
  padding: 0;
  height: 18px;
  background: none;
  align-self: center;
  color: ${({ theme }) => theme.formElements.visualCheckbox.textLinkColor};
  font-size: 13px;
  font-weight: 600;
  font-family: ${({ theme }) => theme.font.typeface.primary};
  line-height: 15px;
  margin-top: 8px;
  border-bottom: 2px solid ${({ theme }) => theme.formElements.visualCheckbox.background};

  :hover {
    cursor: pointer;
    border-bottom: 2px dotted ${({ theme }) => theme.formElements.visualCheckbox.textLinkColor};
  }
`;

export const ErrorPopUp = styled.div<{ showErrorMsg: boolean }>`
  border: 1px solid ${({ theme }) => theme.pageComponents.globals.errorBorder};
  border-radius: 3px;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
  width: 158px;
  min-height: 84px;
  position: absolute;
  left: -56px;
  top: 32px;
  box-sizing: border-box;
  font-family: ${({ theme }) => theme.font.typeface.secondary};
  font-size: 13px;
  letter-spacing: 0;
  line-height: 19px;
  padding: 16px;
  color: ${({ theme }) => theme.pageComponents.globals.errorTextColor};
  background-color: ${({ theme }) => theme.pageComponents.globals.errorBackground};
  display: flex;
  flex-direction: row;
  ${({ showErrorMsg }) =>
    showErrorMsg
      ? `opacity: 1;`
      : `animation-name: fadeOut;
      -webkit-animation-duration: 1s;animation-duration: 1s;
      -webkit-animation-fill-mode: both;animation-fill-mode: both;

      @keyframes fadeOut {
        0% {opacity: 1;}
        100% {opacity: 0;}
      }`}

  @media screen and (max-width: 768px) {
    right: 0;
  }
`;

export const ExclamationMark = styled.img`
  margin: auto 16px auto 0;
`;

export const SectionCont = styled.div<{ isSelected: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: ${({ theme }) => theme.formElements.visualCheckbox.background};
  border: ${({ isSelected, theme }) =>
    isSelected ? theme.formElements.visualCheckbox.borderOnActive : theme.formElements.visualCheckbox.background};
  border-radius: 4px;
  padding: 16px 16px 12px;
  width: 100%;
  box-sizing: border-box;

  @media (min-width: 1025px) {
    min-width: 600px;
  }
`;

export const HeaderSubCont = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

export const PolicyTitle = styled.h3`
  font-size: 20px;
  font-weight: 600;
  font-family: ${({ theme }) => theme.font.typeface.primary};
  color: ${({ theme }) => theme.pageComponents.summary.policies.policyTitle.textColor};
  text-align: left;
  margin: 0;
`;

export const PolicyPrice = styled.p`
  font-weight: 600;
`;

export const ExclusionsHeader = styled.p`
  font-family: ${({ theme }) => theme.pageComponents.summary.exclusions.header.fontFamily};
  font-size: ${({ theme }) => theme.pageComponents.summary.exclusions.header.fontSize};
  color: ${({ theme }) => theme.pageComponents.summary.exclusions.header.color};
  font-weight: 600;
`;

export const ExclusionsList = styled.ul`
  margin-block-start: 0px;
  margin-block-end: 0px;
  padding-inline-start: 24px;
`;

export const ExclusionsItem = styled.li`
  font-family: ${({ theme }) => theme.pageComponents.summary.exclusions.listItem.fontFamily};
  font-size: ${({ theme }) => theme.pageComponents.summary.exclusions.listItem.fontSize};
  color: ${({ theme }) => theme.pageComponents.summary.exclusions.listItem.color};
  line-height: 23px;
  margin-bottom: 10px;
  list-style-type: none;
  position: relative;
  ::before {
    content: '⏺';
    position: absolute;
    left: -24px;
    top: 1px;
    font-size: 12px;
    color: ${({ theme }) => theme.pageComponents.summary.exclusions.listItem.liColor};
  }
`;
