import React from 'react';
import styled, { css } from 'styled-components';
import { connect } from 'react-redux';
import PreviousPageButton from './PreviousPageButton';
import SaveApplicationButton from './SaveApplicationButton';
import { PrimaryButton, LoadingSpinner } from '../../elements';

const ButtonCont = styled.div`
  z-index: 5;
  padding-top: 0px;
  margin: 20px auto;
  width: 80vw;
  max-width: 100%;
  background-color: white;
  @media print {
    display: none;
  }
  ${(props: ButtonContProps) =>
    props.arrowLinks &&
    css`
      padding-top: 30px;
      margin: 0 auto;
    `}
`;

interface ButtonContProps {
  arrowLinks: boolean;
}

const LinkCont = styled.div`
  display: flex;
  margin: 24px 0;
  justify-content: space-between;
  @media (max-width: 768px) {
    margin-bottom: 0;
  }
`;

const SubmitButton = ({
  active,
  submit,
  children,
  isLoading,
  arrowLinks = true,
}: {
  active: boolean;
  submit?: ((event: React.MouseEvent) => void) | undefined;
  children?: any;
  isLoading?: boolean;
  arrowLinks?: boolean;
}) => (
  <ButtonCont arrowLinks={arrowLinks}>
    <PrimaryButton
      disabled={!active}
      data-cy="add-policy-modal-submit"
      onClick={active && submit ? submit : undefined}
      type="submit"
      full={true}
      aria-label="submit"
    >
      {isLoading ? <LoadingSpinner /> : <>{children}</>}
    </PrimaryButton>
    {arrowLinks && (
      <LinkCont>
        <PreviousPageButton />
        <SaveApplicationButton shouldUseAltColor={false} />
      </LinkCont>
    )}
  </ButtonCont>
);

const mapStateToProps = (state: ReduxState) => ({
  isLoading: state.applicationIsLoading,
});

export default connect(mapStateToProps)(SubmitButton);
