import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { getQuoteAmount, selectHasFees } from '../../selectors/quote';
import { getCurrentPage, getPaymentInterval } from '../../selectors/routing';
import { PAGES } from '../../constants';
import MobileLogo from '../WhiteLabel/Components/MobileLogo';
import { TextButton } from '../../elements';
import SmoothCollapse from 'react-smooth-collapse';
import ApplicationProgress from '../Global/ApplicationProgress';
import FinePrint from '../Global/FinePrint';
import CaretIcon from './CaretIcon';
import { selectAvailablePages } from '../../selectors/progress';
import { selectPurchasedPolicies } from '../../selectors';
import { useThemeContext } from '../../elements/theme/CustomThemeProvider';
import { feeNotice } from './InfoSidebar';

interface MobileHeaderProps {
  quoteAmount: string;
  currentPage: string;
  paymentInterval: string;
  availablePages: string[];
  isPolicyPurchased: boolean;
  showFees: boolean;
}

const MobileHeader = ({
  quoteAmount,
  currentPage,
  paymentInterval,
  availablePages,
  isPolicyPurchased,
  showFees,
}: MobileHeaderProps) => {
  const { theme } = useThemeContext();
  const [shouldShowQuote, setShouldShowQuote] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const isSummaryOrPaymentPage = currentPage === PAGES.SUMMARY || currentPage === PAGES.PAYMENT;

  useEffect(() => {
    setIsExpanded(false);
    switch (currentPage) {
      case PAGES.LOCATIONS:
      case PAGES.GENERAL_LIABILITY:
      case PAGES.PROFESSIONAL_LIABILITY:
      case PAGES.ADDITIONAL_INSUREDS:
      case PAGES.ADD_ONS:
      case PAGES.CONTACT_INFORMATION:
        setShouldShowQuote(true);
        break;
      default:
        setShouldShowQuote(false);
    }
  }, [currentPage]);

  useEffect(() => {
    const handleCleanup = () => {
      document.body.style.overflow = 'auto';
    };

    if (isExpanded) {
      document.body.style.overflow = 'hidden';
      window.scrollTo(0, 0);
    } else if (!isExpanded) {
      handleCleanup();
    }

    return handleCleanup;
  }, [isExpanded]);

  const formattedPageTitle = currentPage
    .replace('_', ' ')
    .split('')
    .map((char: string, idx: number, arr: string[]) =>
      idx === 0 || arr[idx - 1] === ' ' ? char.toUpperCase() : char.toLowerCase()
    )
    .join('');

  const handleOnClick = () => setIsExpanded(!isExpanded);

  return (
    <HeaderContainer>
      <ProgressContainer>
        <LogoContainer isExpanded={isExpanded}>
          <MobileLogo />
          <TextButtonCont>
            <StyledTextButton data-cy="mobile-nav-button" onClick={handleOnClick}>
              {formattedPageTitle}
            </StyledTextButton>
            <AppProgressCaret
              src={theme.icons.caret}
              alt="Application Progress Icon"
              expanded={isExpanded}
              onClick={handleOnClick}
            />
          </TextButtonCont>
        </LogoContainer>
        <SmoothCollapse expanded={isExpanded}>
          <ApplicationProgress shouldShowQuote={shouldShowQuote} />
        </SmoothCollapse>
        {shouldShowQuote && (
          <QuoteContainer>
            <TextContainer>
              <p>Estimated</p>
              <p>{paymentInterval === 'Monthly' ? 'monthly' : 'yearly'} payments</p>
            </TextContainer>
            <h3>${quoteAmount}</h3>
          </QuoteContainer>
        )}
      </ProgressContainer>
      {!isSummaryOrPaymentPage && showFees && <FeeNotice>{feeNotice}</FeeNotice>}
      {isExpanded ? (
        <FinePrintCont>
          <FinePrint />
        </FinePrintCont>
      ) : null}
      <ProgressBar>
        <progress
          aria-label="Application Progress"
          max={availablePages.length}
          value={isPolicyPurchased ? availablePages.length : availablePages.indexOf(currentPage)}
        />
      </ProgressBar>
    </HeaderContainer>
  );
};

const ProgressBar = styled.div`
  progress[value] {
    width: 100%;
    height: 8px;
    appearance: none;
    -webkit-appearance: none;
    background-color: ${({ theme }) => theme.pageComponents.shared.mobileHeader.progressBar.backgroundColor};
    ::-webkit-progress-bar {
      background-color: ${({ theme }) => theme.pageComponents.shared.mobileHeader.progressBar.backgroundColor};
    }
    ::-webkit-progress-value {
      background-color: ${({ theme }) => theme.pageComponents.shared.mobileHeader.progressBar.valueColor};
    }
    ::-moz-progress-bar {
      background-color: ${({ theme }) => theme.pageComponents.shared.mobileHeader.progressBar.valueColor};
    }
  }
`;

const AppProgressCaret = styled(CaretIcon)`
  margin-left: 8px;
`;

const TextButtonCont = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledTextButton = styled(TextButton)`
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 1px;
`;

const ProgressContainer = styled.div`
  padding: 13px 16px 0;
`;

const FinePrintCont = styled.div`
  border-top: 1px solid ${({ theme }) => theme.pageComponents.globals.background};
  margin-top: 16px;
  padding: 16px;
`;

const HeaderContainer = styled.div`
  font-family: ${({ theme }) => theme.font.typeface.primary};
  background-color: ${({ theme }) => theme.pageComponents.shared.mobileHeader.headerContainer.background};
  position: sticky;
  top: 0;
  z-index: 10;
`;

const LogoContainer = styled.div<{ isExpanded: boolean }>`
  width: 100%;
  margin-bottom: ${({ isExpanded }) => (isExpanded ? '32px' : '8px')};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const QuoteContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  h3 {
    margin: 0;
    font-size: 30px;
    color: ${({ theme }) => theme.pageComponents.shared.mobileHeader.quoteContainer.h3.textColor};
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;

  p {
    font-size: 12px;
    font-weight: 600;
    color: ${({ theme }) => theme.pageComponents.shared.mobileHeader.textContainer.p.textColor};
    margin: 0;
  }
`;

const FeeNotice = styled.p`
  margin: 8px 16px 0 0;
  font-size: 12px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.primary.zero};
  text-align: right;
`;

const mapStateToProps = (state: ReduxState) => ({
  quoteAmount: getQuoteAmount(state),
  currentPage: getCurrentPage(state),
  paymentInterval: getPaymentInterval(state),
  availablePages: selectAvailablePages(state),
  isPolicyPurchased: selectPurchasedPolicies(state).length > 0,
  showFees: selectHasFees(state),
});

export default connect(mapStateToProps)(MobileHeader);
