import React, { Component } from 'react';
import Media from 'react-media';
import styled, { css } from 'styled-components';
import { IncrementButton, Value, ButtonRow, Option, OptionRow } from './MultiButton';
import { getCurrentPage } from '../../selectors/routing';
import { formatCurrency, formatString } from '../../helpers/formatNumber';
import { PAGES } from '../../constants';
import { connect } from 'react-redux';

interface PropTypes {
  value?: number;
  options: number[];
  updateValue: (option: number, name?: string) => void;
  label?: string;
  summary?: boolean;
  propertyName?: string;
  money?: boolean;
  days?: boolean;
  disabledOptions?: any[];
}

class MultiRow extends Component<PropTypes> {
  incrementSelection = () => {
    const { value, options } = this.props;
    const currentIndex = options.findIndex((option: number) => option === value);

    if (currentIndex === options.length - 1) {
      return;
    }
    this.props.updateValue(options[currentIndex + 1]);
  };

  decrementSelection = () => {
    const { value, options } = this.props;
    const currentIndex = options.findIndex((option) => option === value);

    if (currentIndex === 0) {
      return;
    }
    this.props.updateValue(options[currentIndex - 1]);
  };

  componentDidMount() {
    if (window.innerWidth < 767) {
      // TODO: Use ReactMedia for this
      // TODO: This breaks locations input on mobile - an existing location with no buildingLimit will be auto set and jump ahead without user setting it
      if (!this.props.value) {
        this.props.updateValue(this.props.options[0]);
      }
    }
  }

  render() {
    const { updateValue, value, options, label, summary, propertyName, money, days, disabledOptions = [] } = this.props;

    const optionDataCy = (option: number) =>
      `multi-button-${option}${summary && propertyName ? `-${propertyName.replace(/ /g, '_')}` : ``}`;

    const availableOptions = options.filter(function (obj) {
      return disabledOptions.indexOf(obj) === -1;
    });
    const isMinValue = value === availableOptions[0];
    const isMaxValue = value === availableOptions[availableOptions.length - 1];

    return (
      <OptionRow>
        <Media query="(max-width: 850px)">
          {(matches) =>
            matches ? (
              <ButtonRow>
                <IncrementButton
                  onClick={this.decrementSelection}
                  type="button"
                  disabled={isMinValue}
                >
                  -
                </IncrementButton>
                <Value>{money ? formatCurrency(value ?? 0) : value}</Value>
                <IncrementButton
                  onClick={this.incrementSelection}
                  type="button"
                  disabled={isMaxValue}
                >
                  +
                </IncrementButton>
              </ButtonRow>
            ) : (
              options.map((option) => (
                <StyledOption
                  onClick={() => updateValue(option, propertyName)}
                  key={option}
                  data-cy={optionDataCy(option)}
                  aria-label={`${label}: ${option}`}
                  aria-pressed={option === value}
                  type="button"
                  items={options.length}
                  active={option === value}
                  disabled={disabledOptions && disabledOptions.includes(option)}
                >
                  {money ? formatCurrency(option) : formatString(option, days)}
                </StyledOption>
              ))
            )
          }
        </Media>
      </OptionRow>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  isSummaryPage: getCurrentPage(state) === PAGES.SUMMARY,
});

const StyledOption = styled(Option)`
  ${(props) =>
    props.active &&
    css`
      color: ${(props) => props.theme.selectors.dynamicSelectors.textColorSelected};
      background-color: ${(props) => props.theme.selectors.dynamicSelectors.backgroundSelected};
      height: 48px;
      border-radius: 24px;
      margin: 0 -10px;
      font-size: 16px;
      z-index: 2;
      position: relative;
      border: none;
    `}

  &:disabled {
    color: ${(props) => props.theme.selectors.dynamicSelectors.textColorDisabled};
    background-color: ${(props) => props.theme.selectors.dynamicSelectors.backgroundDisabled};
    cursor: not-allowed;
  }
`;

export default connect(mapStateToProps)(MultiRow);
