import { PAGES } from '../constants';
import { selectLocationType, selectReasonabilityErrors } from '.';
import {
  isIndustryComplete,
  isBusinessInfoComplete,
  isPoliciesComplete,
  isFinanceBasicsComplete,
  isLocationsComplete,
  isGLComplete,
  isPLComplete,
  isAdditionalInsuredsComplete,
  isContactInfoComplete,
  isAddOnsComplete,
} from './progress';
import { selectUserHasCompletedCheckoutForm } from './application';

export const isPageComplete = (state: ReduxState) => {
  const locationType = selectLocationType(state);
  if (selectReasonabilityErrors(state)?.length) {
    return false;
  }

  switch (locationType) {
    case PAGES.INDUSTRY:
      return isIndustryComplete(state);
    case PAGES.BUSINESS_INFORMATION:
      return isBusinessInfoComplete(state);
    case PAGES.POLICIES:
      return isPoliciesComplete(state);
    case PAGES.FINANCE_BASICS:
      return isFinanceBasicsComplete(state);
    case PAGES.LOCATIONS:
      return isLocationsComplete(state);
    case PAGES.GENERAL_LIABILITY:
      return isGLComplete(state);
    case PAGES.PROFESSIONAL_LIABILITY:
      return isPLComplete(state);
    case PAGES.ADDITIONAL_INSUREDS:
      return isAdditionalInsuredsComplete(state);
    case PAGES.CONTACT_INFORMATION:
      return isContactInfoComplete(state);
    case PAGES.ADD_ONS:
      return isAddOnsComplete(state);
    case PAGES.SUMMARY:
      return true;
    case PAGES.PAYMENT:
      return selectUserHasCompletedCheckoutForm(state);
    default:
      return false;
  }
};
