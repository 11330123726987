import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { LabelLarge, Input, Form as FormElement, PrimaryButton, InputErrorMessage } from '../../elements';
import { addAdditionalInsured } from '../../actions/application';
import AddressInput from './AddressInput';
import { validateEmailRegex } from '../../helpers/inputValidation/validateEmail';
import { INPUT_ERROR_MSG } from '../../constants';

const StyledPrimaryButton = styled(PrimaryButton)`
  margin: 24px 0 0;
`;
interface PropTypes {
  addAdditionalInsured: (name: AdditionalInsured) => void;
}

const AdditionalInsuredsForm = ({ addAdditionalInsured }: PropTypes) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [addInsAddress, setAddInsAddress] = useState({ street: '', city: '', zip: '', state: '' });

  const handleEmailOnBlur = () => setIsEmailValid(validateEmailRegex(email));

  const submitAdditionalInsured = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const { street, city, zip, state } = addInsAddress;
    addAdditionalInsured({ name, street, city, zip, state, email });
    setName('');
    setEmail('');
    setAddInsAddress({ street: '', city: '', zip: '', state: '' });
  };

  return (
    <>
      <Form>
        <LabelLarge>Full Name of Individual or Legal Entity</LabelLarge>
        <Input
          width="full"
          value={name}
          onChange={(e) => setName(e.target.value)}
          data-cy="add-ins-name"
          aria-label="Name of additional insured"
          marginBottom="smaller"
        />

        <LabelLarge>Email</LabelLarge>
        <StyledInput
          width="full"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onBlur={handleEmailOnBlur}
          hasError={!isEmailValid}
          data-cy="add-ins-email"
          aria-label="Email of additional insured"
          marginBottom="smaller"
        />
        {!isEmailValid && (
          <StyledInputErrorMessage>{!isEmailValid && INPUT_ERROR_MSG.INVALID_EMAIL}</StyledInputErrorMessage>
        )}
        <AddressInput addInsAddress={addInsAddress} setAddInsAddress={setAddInsAddress} />
        <StyledPrimaryButton
          type="button"
          onClick={submitAdditionalInsured}
          disabled={!Boolean(addInsAddress.street && name && validateEmailRegex(email))}
          data-cy="add-addl-ins"
          aria-label="Add additional insured"
          small
        >
          Add person
        </StyledPrimaryButton>
      </Form>
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  addAdditionalInsured: (data: AdditionalInsured) => dispatch(addAdditionalInsured(data)),
});

const Form = styled(FormElement)`
  margin: 0;
  display: inherit;
  margin-bottom: 25px;
`;

export default connect(null, mapDispatchToProps)(AdditionalInsuredsForm);

const StyledInput = styled(Input)`
  margin-bottom: 16px;
`;

const StyledInputErrorMessage = styled(InputErrorMessage)`
  height: 16px;
  margin: 0 0 24px;
`;
