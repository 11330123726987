export const isApplicationQuotable = (application: Application) => {
  const hasMinimumRequirements = Boolean(
    application.applicationId &&
      application.applicationTypes?.length &&
      application.grossAnnualSales &&
      application.annualPayroll
  );
  const hasBop = application.applicationTypes?.includes('BOP');

  if (hasBop) {
    return (
      hasMinimumRequirements &&
      Boolean(
        application.locations?.length &&
          application.locations[0].hasSprinklers != null &&
          application.locations[0].bppLimit
      )
    );
  }

  return hasMinimumRequirements;
};
