import { createSelector } from 'reselect';
import { selectLocationState } from './application';
import { isProd } from '../helpers/environment';

export const selectPartnerInfo = (state: ReduxState) => state.partner;

export const selectChannelPartnerGuid = (state: ReduxState) => selectPartnerInfo(state)?.id;

export const defaultAgencyExternalId = isProd()
  ? process.env.REACT_APP_COTERIE_AGENCY_EXTERNAL_ID_PROD
  : process.env.REACT_APP_COTERIE_AGENCY_EXTERNAL_ID_TEST;
export const selectPartnerAgencyExternalId = (state: ReduxState) =>
  selectPartnerInfo(state)?.agencyExternalId ?? defaultAgencyExternalId;

export const selectPremierPlatform = (state: ReduxState) => selectPartnerInfo(state)?.premierPlatform;
export const getPartnerFromApplication = (state: ReduxState) => state.partnerFromApplication || {};
export const getPartnerIdFromApplication = (state: ReduxState) => getPartnerFromApplication(state)?.id;

export const selectCombinedPartnerInfo = createSelector(
  selectPartnerInfo,
  getPartnerFromApplication,
  (partnerFromPartnerInbound, partnerFromApplicationInbound) => {
    return partnerFromPartnerInbound?.id ? partnerFromPartnerInbound : partnerFromApplicationInbound;
  }
);

export const selectIsPremierPlatformPartner = createSelector(
  selectCombinedPartnerInfo,
  selectLocationState,
  (partnerInfo, locationState) => {
    const unavailableStateForPremierPlatform = ['TN', 'AK'];
    return Boolean(
      partnerInfo.premierPlatform && locationState && !unavailableStateForPremierPlatform.includes(locationState)
    );
  }
);
