import { useRef, useEffect } from 'react';

/**
 * Custom React Hook that creates a reference to previous values.
 * Allows for comparing current state/props to previous state/props in
 * other hooks.
 * @param value
 */
export const usePrevious = (value: any): any | undefined => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
};
