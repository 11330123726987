import { PolicyType } from '../types/enums';
import pl_svg from '../icons/PLicon.svg';
import gl_svg from '../icons/GLicon.svg';
import bop_svg from '../icons/BOPicon.svg';
import coterieTheme from '../elements/theme';

const getPolicyAssets = (policy: PolicyType, theme: ThemeInterface = coterieTheme): PolicyAssets | undefined => {
  if (policy === PolicyType.PL) {
    return {
      icon: pl_svg,
      title: 'Professional Liability',
      color: theme.pageComponents.products.pl.color,
      background: theme.pageComponents.products.pl.background,
    };
  } else if (policy === PolicyType.GL) {
    return {
      icon: gl_svg,
      title: 'General Liability',
      color: theme.pageComponents.products.gl.color,
      background: theme.pageComponents.products.gl.background,
    };
  } else if (policy === PolicyType.BOP) {
    return {
      icon: bop_svg,
      title: "Business Owner's",
      color: theme.pageComponents.products.bop.color,
      background: theme.pageComponents.products.bop.background,
    };
  }
};

export default getPolicyAssets;
