import React from 'react';
import SmoothCollapse from 'react-smooth-collapse';
import {
  Property,
  MobilePropertyValue,
  Row,
  MobileContainer,
  CollapseRow,
  PropertyName,
  CaretButton,
  StyledCaret,
  PropertySubtitle,
} from '../styles';
import { formatCurrency } from '../../../helpers/formatNumber';
import { DesktopAndTablet, Mobile } from '../../../helpers/responsive';
import toolTipContent from '../../../helpers/toolTipContent';
import { useThemeContext } from '../../../elements/theme/CustomThemeProvider';
import { POLICY_OPTIONS, PROPERTY_LABELS } from '../../../constants';

interface PolicyItemProps {
  propertyName: string;
  propertyValue?: number;
  isReadOnly?: boolean;
  toolTipContentKey?: string;
  expanded: boolean;
  setExpanded: (...args: any[]) => any;
  testId?: string;
  customColors?: Record<string, any>;
  customDefaultBg?: string;
  children: JSX.Element;
  disableSubmit?: boolean;
  labelSubtitle?: string;
  isFlorida?: boolean;
  policyType?: string;
}

const PolicyItem = ({
  propertyName,
  propertyValue,
  isReadOnly = false,
  toolTipContentKey = '',
  children,
  expanded,
  setExpanded,
  testId,
  customColors,
  customDefaultBg,
  disableSubmit = false,
  labelSubtitle,
  isFlorida = false,
  policyType,
}: PolicyItemProps) => {
  const { theme } = useThemeContext();
  const showToolTip = Boolean(toolTipContentKey);
  const showPropertyValue = typeof propertyValue === 'number' || typeof propertyValue === 'string';

  const defaultBg = customDefaultBg ?? '';

  const renderPropertyValue = (value?: number | string) => {
    if (typeof value === 'string') {
      return value;
    }
    return formatCurrency(value);
  };

  const showLabelSubtitle = isFlorida && labelSubtitle;

  const label = (propertyName: string) => {
    if (isFlorida && policyType === POLICY_OPTIONS.PROPERTY_CLAIMS_DEDUCTIBLE) {
      return PROPERTY_LABELS['PROPERTY_CLAIMS_DEDUCTIBLE_FL'];
    }
    return propertyName;
  };

  return (
    <div className="PolicyItem">
      <Row
        onClick={() => (!isReadOnly || showToolTip) && setExpanded(expanded ? '' : propertyName)}
        data-cy={`summary-${propertyName.replace(/ /g, '_')}-expand`}
        data-testid={testId || propertyName}
        borderColor={customColors?.borderColor}
        backgroundColor={expanded ? customColors?.backgroundColor : defaultBg}
      >
        <DesktopAndTablet>
          <Property isReadOnly={isReadOnly && !showToolTip}>
            <PropertyName>
              {label(propertyName)}
              {showLabelSubtitle && <PropertySubtitle> - {labelSubtitle}</PropertySubtitle>}
            </PropertyName>
          </Property>
          <Property isReadOnly={isReadOnly && !showToolTip}>
            {showPropertyValue && renderPropertyValue(propertyValue)}
            {(!isReadOnly || showToolTip) && (
              <CaretButton disabled={disableSubmit}>
                <StyledCaret src={theme.icons.caret} expanded={expanded} />
              </CaretButton>
            )}
          </Property>
        </DesktopAndTablet>
        <Mobile>
          <MobileContainer>
            <Row>
              <Property isReadOnly={isReadOnly && !showToolTip}>
                {label(propertyName)} {showLabelSubtitle && `- ${labelSubtitle}`}
              </Property>
              {(!isReadOnly || showToolTip) && (
                <CaretButton disabled={disableSubmit}>
                  <StyledCaret src={theme.icons.caret} expanded={expanded} />
                </CaretButton>
              )}
            </Row>
            <Row>
              {!expanded && showPropertyValue && (
                <MobilePropertyValue>{renderPropertyValue(propertyValue)}</MobilePropertyValue>
              )}
            </Row>
          </MobileContainer>
        </Mobile>
      </Row>
      <SmoothCollapse expanded={expanded} allowOverflowWhenOpen>
        <CollapseRow>
          {showToolTip && toolTipContent[toolTipContentKey]}
          {children}
        </CollapseRow>
      </SmoothCollapse>
    </div>
  );
};

export default PolicyItem;
