import { POLICY_DEFAULTS } from '../constants';
import { PolicyType } from '../types/enums';
import { setLossControlValueHelper } from './lossControl';

const setValueHelper = (value: number | undefined, defaultValue: number) => (value == null ? defaultValue : value);

const addPolicyDefaults = (application: Application) => {
  const { applicationTypes } = application;

  if (!applicationTypes?.length) {
    return application;
  }

  const isPL = applicationTypes.includes(PolicyType.PL);
  const isBOP = applicationTypes.includes(PolicyType.BOP);
  const isGL = applicationTypes.includes(PolicyType.GL);

  const GL_BOP_DEFAULTS =
    isGL || isBOP
      ? {
          propertyDamageLiabilityDeductible: setValueHelper(
            application.propertyDamageLiabilityDeductible,
            POLICY_DEFAULTS.GL_BOP.propertyDamageLiabilityDeductible
          ),
          lossControl: setLossControlValueHelper(application.lossControl, POLICY_DEFAULTS.GL_BOP.lossControl),
        }
      : {};

  const PL_DEFAULTS = isPL
    ? {
        professionalLiability: {
          ...application.professionalLiability,
          deductibleAmount: setValueHelper(
            application.professionalLiability?.deductibleAmount,
            POLICY_DEFAULTS.PL.professionalLiability.deductibleAmount
          ),
          occurrenceLimit: setValueHelper(
            application.professionalLiability?.occurrenceLimit,
            POLICY_DEFAULTS.PL.professionalLiability.occurrenceLimit
          ),
        },
      }
    : {};

  return {
    ...application,
    ...GL_BOP_DEFAULTS,
    ...PL_DEFAULTS,
  };
};

export default addPolicyDefaults;
