import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { LabelLarge, Paragraph } from '../../elements';
import format from 'date-fns/format';
import BOPIcon from '../../icons/BOPicon.svg';
import GLIcon from '../../icons/GLicon.svg';
import PLIcon from '../../icons/PLicon.svg';
import silver_icon from '../../icons/Packages/silver.svg';
import gold_icon from '../../icons/Packages/gold.svg';
import platinum_icon from '../../icons/Packages/platinum.svg';
import { selectEndorsementPackage } from '../../selectors/application';
import { selectPurchasedPolicies } from '../../selectors';
import { EndorsementOptions as options, POLICY_ENUMS } from '../../constants';
import { useThemeContext } from '../../elements/theme/CustomThemeProvider';
import { MobileAndTablet } from '../../helpers/responsive';
import InfoSidebar from '../../Components/Shared/InfoSidebar';

interface PolicyPurchasedProps {
  policies: PolicyData[];
  selectedPackage?: string;
  shouldRenderIcon: boolean;
}

interface PolicyObject {
  [key: string]: string;
}

const policyString: PolicyObject = {
  GL: 'General Liability',
  BOP: "Business Owner's Policy",
  PL: 'Professional Liability',
};

const policyIcon: PolicyObject = {
  GL: GLIcon,
  BOP: BOPIcon,
  PL: PLIcon,
};

const iconSrc = {
  [options.SILVER]: silver_icon,
  [options.GOLD]: gold_icon,
  [options.PLATINUM]: platinum_icon,
};

const PolicyPurchased = ({ policies, selectedPackage }: PolicyPurchasedProps) => {
  const { shouldRenderIcon } = useThemeContext();

  return (
    <Container data-testid="purchasedPolicy">
      <MobileAndTablet>
        <InfoSidebar />
      </MobileAndTablet>
      {policies.map((policy: PolicyData) => (
        <PolicyRow key={policy.policyId}>
          <LabelText>included coverage{policies.length > 1 ? 's' : ''}</LabelText>
          <Row>
            {shouldRenderIcon ? <Icon src={policyIcon[policy.policyType]} alt="policy icon" /> : null}
            <LabelLarge data-cy="policyInfo">{policyString[policy.policyType]}</LabelLarge>
          </Row>
          {selectedPackage !== 'None' && policy.policyType === POLICY_ENUMS.BOP && (
            <Row>
              {shouldRenderIcon && selectedPackage ? (
                <CoverageIcon src={iconSrc[selectedPackage]} alt="coverage icons" />
              ) : null}
              <LabelLarge>{selectedPackage} Coverage Package</LabelLarge>
            </Row>
          )}
          <LabelText>policy number</LabelText>
          <LabelLarge>{policy.policyNumber}</LabelLarge>
          {policy.nextPaymentDate && (
            <React.Fragment>
              <LabelText>next payment date</LabelText>
              <LabelLarge>{format(new Date(policy.nextPaymentDate), 'MMMM d, yyyy')}</LabelLarge>
            </React.Fragment>
          )}
        </PolicyRow>
      ))}
      <Paragraph>
        Thank you so much! You should receive your policy documents via email shortly. Let us know if you have any
        questions by contacting our support team at{' '}
        <EmailLink href="mailto: support@coterieinsurance.com">support@coterieinsurance.com</EmailLink>.
      </Paragraph>
    </Container>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  selectedPackage: selectEndorsementPackage(state),
  policies: selectPurchasedPolicies(state) ?? [],
});

const Container = styled.div`
  max-width: 600px;
`;
const PolicyRow = styled.div`
  padding: 0 29px 24px;
  border-radius: 4px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 24px;
`;

const LabelText = styled.p`
  color: ${(props) => props.theme.colors.neutral.medium};
  font-family: ${(props) => props.theme.font.typeface.primary};
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.75px;
  padding: 24px 0 8px;
  margin: 0;
`;

const EmailLink = styled.a`
  text-decoration: none;
`;

const Icon = styled.img`
  width: 24px;
  height: 17px;
  margin-right: 8px;
`;

const CoverageIcon = styled.img`
  width: 21px;
  height: 24px;
  margin-right: 8px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 8px;
`;

export default connect(mapStateToProps)(PolicyPurchased);
