import { Dictionary } from 'lodash';
import { cres_partner_keys } from '../constants';
import { isProd } from './environment';

interface PartnerPayload {
  id: string;
  userId?: string;
}

interface PartnerData {
  partnerToken: string;
  primaryColor: string;
  secondaryColor: string;
  fromPartnerLink: boolean;
  showEndDateInput: boolean;
  name: string;
  userId?: string;
  id?: string;
}

export const partnerData: Partial<PartnerData>[] = [
  {
    partnerToken: '673cd2a8-8bf5-4d16-b1ba-523320d6e47c',
    primaryColor: 'red',
    secondaryColor: 'green',
    fromPartnerLink: true,
    showEndDateInput: true,
  },
  {
    partnerToken: 'd3b7bc16-dc6c-416e-bb87-5facf195cb08',
    primaryColor: 'red',
    secondaryColor: 'green',
    fromPartnerLink: true,
  },
  {
    partnerToken: '2d748523-3b7d-49e7-bafa-3515d74483c3',
    primaryColor: 'red',
    secondaryColor: 'green',
    fromPartnerLink: true,
  },
  {
    partnerToken: '263a765d-3d2b-4b46-be4c-7bf92715c927',
    primaryColor: 'red',
    secondaryColor: 'green',
    fromPartnerLink: true,
    showEndDateInput: true,
  },
  {
    partnerToken: '42ebdc9f-4fdc-4a6a-b9d6-41f400e079e9',
    primaryColor: 'blue',
    secondaryColor: 'yellow',
    fromPartnerLink: true,
    showEndDateInput: true,
  },
  {
    partnerToken: '8b0ae0cd-87ed-44ae-a4eb-c17b1ef2d0f7',
    primaryColor: 'blue',
    secondaryColor: 'yellow',
    fromPartnerLink: true,
    showEndDateInput: true,
    name: 'another name',
  },
  {
    partnerToken: '3304fd7e-5cb9-4578-8c5b-c855d3aff28a',
    fromPartnerLink: true,
  },
];

export const getPartnerInfo = (partnerPayload: PartnerPayload) => {
  const partnerInfo = partnerData.find((partner) => partner.partnerToken === partnerPayload.id);

  if (!partnerInfo) return { partnerToken: partnerPayload.id, ...partnerPayload };

  if (partnerPayload.userId) {
    partnerInfo.userId = partnerPayload.userId;
  }

  return { ...partnerInfo, ...partnerPayload };
};

export const getAdditionalInsured = (partnerId: string) => {
  switch (partnerId) {
    case '42ebdc9f-4fdc-4a6a-b9d6-41f400e079e9':
    case '8b0ae0cd-87ed-44ae-a4eb-c17b1ef2d0f7':
      return {
        name:
          'Innovative Employee Solutions, Inc. and their respective directors, officers and employees. Coverage is primary and non-contributory in regard to any insurance carried by Innovative Employee Solutions or Leidos',
        street: '9665 Granite Ridge Drive, #420',
        city: 'San Diego',
        zip: '92123',
        state: 'CA',
        email: 'hrservicerep@innovative-es.com',
      };
    case '31f2d341-982d-456e-b600-5f75f5573fc9':
    case 'e0e75e36-dfac-4465-8606-b26ec771bd24':
      return {
        name:
          'Innovative Employee Solutions, Inc. and Leidos, Inc. and their respective directors, officers and employees. Coverage is primary and non-contributory in regard to any insurance carried by Innovative Employee Solutions or Leidos',
        street: '11955 Freedom Drive',
        city: 'Reston',
        zip: '20190',
        state: 'VA',
        email: 'hrservicerep@innovative-es.com',
      };
    default:
      return {};
  }
};

export const white_label_partners: Dictionary<string> = {
  CRES: isProd() ? cres_partner_keys.PROD : cres_partner_keys.TEST,
};
