import React, { useState, useEffect } from 'react';
import { eachYearOfInterval, eachMonthOfInterval } from 'date-fns';
import { StyledSelect, CustomHeaderContainer, selectStyles } from '../styles';

interface HeaderProps {
  changeMonth: (month: number) => void;
  changeYear: (year: number) => void;
  minDate: Date;
  maxDate: Date;
  selectedDate: Date | null;
}

interface OptionType {
  value: number;
  label: string;
}

const DatePickerHeader = ({ changeMonth, changeYear, minDate, maxDate, selectedDate }: HeaderProps) => {
  const [selectedMonth, setSelectedMonth] = useState<string | number>();
  const [selectedYear, setSelectedYear] = useState<string | number>();

  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const monthOptions = eachMonthOfInterval({
    start: minDate,
    end: maxDate,
  })
    .map((m) => m.getMonth())
    .slice(0, 12)
    .map((m) => ({ value: m, label: monthNames[m] }));

  const yearOptions: OptionType[] = eachYearOfInterval({
    start: minDate,
    end: maxDate,
  })
    .map((y) => y.getFullYear())
    .map((y) => ({ value: y, label: y.toString() }));

  useEffect(() => {
    if (selectedDate) {
      setSelectedMonth(selectedDate.getMonth());
      setSelectedYear(selectedDate.getFullYear());
    }
  }, [selectedDate]);

  return (
    <CustomHeaderContainer>
      <StyledSelect
        options={monthOptions}
        styles={selectStyles}
        value={monthOptions.find((option) => option.value === selectedMonth)}
        isSearchable={false}
        aria-label="Month"
        isMonth
        onChange={(option: OptionType) => {
          changeMonth(option.value);
          setSelectedMonth(option.value);
        }}
        components={{
          IndicatorSeparator: () => null,
        }}
      ></StyledSelect>
      <StyledSelect
        options={yearOptions}
        styles={selectStyles}
        value={yearOptions.find((option) => option.value === selectedYear)}
        isSearchable={false}
        aria-label="Year"
        onChange={(option: OptionType) => {
          changeYear(option.value);
          setSelectedYear(option.value);
        }}
        components={{
          IndicatorSeparator: () => null,
        }}
      ></StyledSelect>
    </CustomHeaderContainer>
  );
};

export default DatePickerHeader;
