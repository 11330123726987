import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { messageRemapperUtilitySelector } from '../../selectors/errors';
import Modal from '../../elements/Modal';
import { Dispatch } from 'redux';
import { ErrorTheme } from '../../helpers/errorMessageRemapper';
import { selectDeclinationMessages } from '../../selectors';

const SubHeading = styled.h2`
  font: ${({ theme }) => theme.font.typeface.secondary};
  font-size: 15px;
  font-weight: normal;
  margin: 0 0 18px;
`;

const ErrorsCont = styled.div<{ useAltStyle: boolean }>`
  background-color: ${({ useAltStyle, theme }) =>
    useAltStyle ? theme.pageComponents.errorModal.backgroundAlt : theme.pageComponents.errorModal.background};
  color: ${({ useAltStyle, theme }) =>
    useAltStyle ? theme.pageComponents.errorModal.textColorAlt : theme.pageComponents.errorModal.textColor};
  font-size: 15px;
  font-weight: 600;
  padding: 6px 20px;
  border-radius: 4px;
`;

export const ErrorModal = ({
  errorMessages,
  errorCallbacks,
  errorModalTheme,
  hasErrors,
  dispatch,
  declinationMessages = [],
}: {
  errorMessages: JSX.Element[];
  declinationMessages: JSX.Element[];
  errorCallbacks: Function[];
  errorModalTheme: ErrorTheme;
  hasErrors: boolean;
  dispatch: Dispatch;
}) => {
  // If there are declinations messages, we want to show the Quote Help Screen instead of the Error Modal so that a user must reset the application
  const hasDeclinationMessages = declinationMessages.length > 0;
  const { headerCopy, ctaCopy, showSubheader } = errorModalTheme;
  const [isOpen, setIsOpen] = useState(hasErrors && !hasDeclinationMessages);

  useEffect(() => {
    setIsOpen(hasErrors && !hasDeclinationMessages);
  }, [hasErrors, hasDeclinationMessages]);

  const closeModal = () => {
    setIsOpen(false);
    /**
     * If the error requires an action to be fired, it will pass that action and paylaod back so it can be dispatched.
     * An exmaple of this is to reset policyTypes to null if they are invalid, allowing the app to update with valid Policy Types
     * for the user's industry and to deselect the invalid policy.
     * */
    errorCallbacks &&
      errorCallbacks.forEach((callback) => {
        const { action, payload, redirectTo } = callback();
        dispatch(action(payload));
        redirectTo && dispatch({ type: redirectTo });
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      label="Error"
      title={headerCopy}
      buttonOnClick={closeModal}
      buttonTestId="modal-button"
      buttonCyLabel="error-modal-go-back-and-fix"
      buttonText={ctaCopy}
      id="error-modal"
    >
      {showSubheader && <SubHeading>While processing your application, we found the following error(s):</SubHeading>}
      <ErrorsCont useAltStyle={errorModalTheme.useAltStyle}>{errorMessages}</ErrorsCont>
    </Modal>
  );
};

const mapStateToProps = (state: ReduxState) => {
  const { errorMessages, errorCallbacks, errorModalTheme } = messageRemapperUtilitySelector(state);
  const hasErrors = errorMessages.length > 0;

  return {
    hasErrors,
    errorMessages,
    errorCallbacks,
    errorModalTheme,
    declinationMessages: selectDeclinationMessages(state),
  };
};

export default connect(mapStateToProps)(ErrorModal);
