import handlePartnerInbound from '../actions/handlePartnerInbound';
import handleAgencyInbound from '../actions/handleAgencyInbound';
import { PAGES } from '../constants';
import handleWhiteLabelDev from '../actions/handleWhiteLabelDev';
import handleMDAInbound from '../actions/handleMDAInbound';

export const routePaths = {
  [PAGES.HOME]: '/',
  [PAGES.INDUSTRY]: '/getquote/industry',
  [PAGES.BUSINESS_INFORMATION]: '/getquote/businessinfo',
  [PAGES.POLICIES]: '/getquote/policyselection',
  [PAGES.FINANCE_BASICS]: '/getquote/financebasics',
  [PAGES.LOCATIONS]: '/getquote/locations',
  [PAGES.GENERAL_LIABILITY]: '/getquote/gl',
  [PAGES.PROFESSIONAL_LIABILITY]: '/getquote/pl',
  [PAGES.ADDITIONAL_INSUREDS]: '/getquote/additional-insureds',
  [PAGES.CONTACT_INFORMATION]: '/getquote/contact',
  [PAGES.ADD_ONS]: '/getquote/addons',
  [PAGES.SUMMARY]: '/getquote/summary',
  [PAGES.PAYMENT]: '/getquote/purchase',
  [PAGES.QUOTEHELP]: '/quotehelp',
  [PAGES.APPLICATION]: '/application/:id',
  [PAGES.PARTNER]: { path: '/partner/:id', thunk: handlePartnerInbound },
  [PAGES.AGENCY]: { path: '/agency/:agencyExternalId/:channelPartnerGUID', thunk: handleAgencyInbound },
  [PAGES.QUICKQUOTE]: { path: '/agency/:agencyExternalId/:channelPartnerGUID/:id', thunk: handleAgencyInbound },
  [PAGES.WHITE_LABEL_DEV]: { path: '/whiteLabel/:channelPartnerName', thunk: handleWhiteLabelDev },
  [PAGES.MDA_INBOUND]: { path: '/quote', thunk: handleMDAInbound },
};
