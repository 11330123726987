import React, { Component } from 'react';
import QuoteHelp from '../../Pages/QuoteHelp';
import { reportToSentry, TAGS } from '../../helpers/sentry';
import { isLocal } from '../../helpers/environment';

class ErrorBoundary extends Component {
  state = {
    hasError: null,
  };
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    if (isLocal()) {
      console.log(error, errorInfo);
    }
    this.setState({ hasError: true }, () => {
      reportToSentry(error, { name: 'error boundary', data: errorInfo }, TAGS.ERROR_BOUNDARY);
    });
  }

  render() {
    if (this.state.hasError) {
      return <QuoteHelp />;
    } else {
      return this.props.children;
    }
  }
}

export default ErrorBoundary;
