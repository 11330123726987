import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import CheckoutForm from '../Components/Summary/CheckoutForm';
import PolicyPurchased from '../Components/Summary/PolicyPurchased';
import { LabelLarge, Container } from '../elements';
import { PROP_TYPES } from '../constants';
import LoadingSpinner from '../Components/Shared/LoadingSpinnerDots';

class Payment extends Component {
  render() {
    const { purchasedPolicies, quoteBindPending, reasonabilityErrors, policyPurchaseErrors } = this.props;
    const showCheckoutForm =
      reasonabilityErrors.length === 0 &&
      policyPurchaseErrors.length === 0 &&
      !quoteBindPending &&
      purchasedPolicies.length === 0;

    return (
      <Container>
        {purchasedPolicies.length > 0 && <PolicyPurchased />}
        <Overlay active={quoteBindPending}>
          <LoadingSpinner />
          <StyledLabelLarge isDarkTheme>Purchasing Your Policy...</StyledLabelLarge>
        </Overlay>
        {(reasonabilityErrors.length !== 0 || policyPurchaseErrors.length !== 0) && (
          <BindableErrorCont data-cy="reasonability-error-cont">
            <BindableErrorText>
              There was a problem with your application. Please check that the information you entered is correct. If
              the problem persists use the chat button to contact support and tell the errors you received:
            </BindableErrorText>
            <ul>
              {reasonabilityErrors.map((error) => (
                <BindableError key={error}>{error}</BindableError>
              ))}
              {policyPurchaseErrors.map((error) => (
                <BindableError key={error}>{error}</BindableError>
              ))}
            </ul>
          </BindableErrorCont>
        )}
        {showCheckoutForm && (
          <>
            <CheckoutForm
              onCreditCardChange={this.onCreditCardChange}
              tokenReceived={this.tokenReceived}
              submitTest={this.setCollapseTimeout}
            />
          </>
        )}
      </Container>
    );
  }
}

Payment.propTypes = {
  purchasedPolicies: PropTypes.arrayOf(PropTypes.string),
  application: PROP_TYPES.APPLICATION,
  quoteId: PropTypes.string,
  quoteBindPending: PropTypes.bool,
  reasonabilityErrors: PropTypes.array,
  policyPurchaseErrors: PropTypes.array,
  industryName: PropTypes.string,
};

const mapStateToProps = (state) => ({
  purchasedPolicies: state.purchasedPolicy?.policies || [],
  application: state.application,
  quoteId: state.quote.quoteId,
  quoteBindPending: state.quoteBindPending,
  reasonabilityErrors: state.reasonabilityErrors,
  policyPurchaseErrors: state.policyPurchaseErrors,
  industryName: state.industryName,
});

const Overlay = styled.div`
  background-color: ${({ theme }) => theme.pageComponents.payment.overlay};
  opacity: 0.75;
  display: ${({ active }) => (active ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 12;
`;

const BindableErrorCont = styled.div`
  background-color: hsla(2, 100%, 66%, 1);
  padding: 20px;
  margin: 20px 0;
  border-radius: 4px;
`;
const BindableErrorText = styled.p`
  font-family: ${(props) => props.theme.font.typeface.secondary};
  color: white;
  font-size: 1.3em;
  margin: 0;
`;

const BindableError = styled.li`
  color: white;
  font-family: ${(props) => props.theme.font.typeface.secondary};
  font-size: 1.3em;
`;

const StyledLabelLarge = styled(LabelLarge)`
  margin-top: 24px;
  font-size: 24px;
`;

export default connect(mapStateToProps)(Payment);
