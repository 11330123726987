import React, { useEffect, useState } from 'react';
import { debounce, Dictionary } from 'lodash';
import { LabelLarge } from '../../elements';
import { EndorsementUnderwritingQuestions } from './addOnQuestionMap';
import {
  SubQuestionCont,
  UnderwritingQuestionCont,
  UnderWritingNumberInput,
  StyledInputErrorMessage,
  UnderwritingRadioBtn,
  UnderwritingRadioGroup,
} from './styles/addons';
import CenteredLoadingSpinner from '../AddOns/shared/CenteredLoadingSpinner';
import { NumberFormatValues } from 'react-number-format';
import styled from 'styled-components';

interface UnderWritingQuestionsProps {
  underwritingQuestions: EndorsementUnderwritingQuestions;
  value: Dictionary<number | string>;
  validationData: Dictionary<any>;
  action: (value: any) => void;
  isLoading: boolean;
  setExpanded: (value: boolean) => void;
}

interface UnderwritingRadioOption {
  label: string;
  value: number;
}

const UnderWritingQuestions = ({
  underwritingQuestions,
  value,
  validationData,
  action,
  isLoading,
  setExpanded,
}: UnderWritingQuestionsProps) => {
  const [invalidInputs, setInvalidInputs] = useState<{ [key: string]: boolean }>({});
  const [isSimulatedLoading, setIsSimulatedLoading] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [inputKey, setInputKey] = useState(0);

  useEffect(() => {
    setInputKey((i) => i + 1);
  }, [value]);

  useEffect(() => {
    const hasInvalidInput = Object.keys(invalidInputs).filter((input) => invalidInputs[input]).length === 0;
    const areAllSubQuestionsAnswered =
      value != null &&
      Object.keys(value).length &&
      Object.keys(value).reduce((accum, subQuestionValue) => {
        if (!accum) return false;
        return subQuestionValue != null;
      }, true);
    if (areAllSubQuestionsAnswered && hasInvalidInput) {
      setHasLoaded(false);
      setIsSimulatedLoading(true);

      const simulatedOnSuccess = setTimeout(() => {
        setHasLoaded(true);
        setTimeout(() => {
          setIsSimulatedLoading(false);
        }, 1000);
      }, Math.random() * 1000 + 1000);

      return () => {
        clearTimeout(simulatedOnSuccess);
        setIsSimulatedLoading(false);
        setHasLoaded(false);
      };
    }
  }, [value, invalidInputs, setExpanded]);

  return (
    <UnderWritingQuestionsContainer>
      {isSimulatedLoading && <CenteredLoadingSpinner useAltColor />}
      {Object.keys(underwritingQuestions).map((question, index) => {
        const { QUESTION_COPY, ERROR_COPY, validateInput, options } = underwritingQuestions[question];
        const isValid = value?.[question] != null ? validateInput(value[question], validationData) : true;
        const isLastQuestion = index === Object.keys(underwritingQuestions).length - 1;

        if (!isValid && !invalidInputs[question]) {
          setInvalidInputs({ ...invalidInputs, [question]: true });
        } else if (isValid && invalidInputs[question]) {
          setInvalidInputs({ ...invalidInputs, [question]: false });
        }

        const updateEndorsementValue = (value: number | string | undefined | null) => {
          const valueData = value || {};
          const isValuePrimitive = typeof valueData === 'string' || typeof valueData === 'number';
          const newEndorsementValue = isValuePrimitive ? { [question]: value } : { ...valueData, [question]: value };
          action(newEndorsementValue);
        };

        const handleOnChange = debounce((event: NumberFormatValues) => {
          updateEndorsementValue(event.floatValue || null);
        }, 1000);

        const handleSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
          updateEndorsementValue(event.target.value);
        };

        return (
          <SubQuestionCont key={question} isActive={!isSimulatedLoading} direction="column" addSpace={!isLastQuestion}>
            {!options || options.length === 0 ? (
              <UnderwritingQuestionCont>
                <StyledLabelLarge htmlFor={question}>{QUESTION_COPY}</StyledLabelLarge>
                <UnderWritingNumberInput
                  name={question}
                  key={inputKey}
                  value={value[question]}
                  onValueChange={handleOnChange}
                  customWidth="quarter"
                  marginBottom="none"
                  thousandSeparator={true}
                  prefix="$"
                  aria-label={QUESTION_COPY}
                  data-cy={question}
                  disabled={isLoading}
                  hasError={!isValid}
                  placeholder="e.g., $150,000"
                />
              </UnderwritingQuestionCont>
            ) : (
              <UnderwritingRadioGroup>
                <LabelLarge htmlFor={question}>{QUESTION_COPY}</LabelLarge>
                {options.map((option: { label: string; value: string | number }) => (
                  <UnderwritingRadioBtn
                    id={option.value}
                    key={option.value}
                    onChange={(e) => handleSelect(e)}
                    isChecked={value[question] === String(option.value)}
                    labelText={option.label}
                    dataCy={option.value as string}
                    name={option.label}
                  />
                ))}
              </UnderwritingRadioGroup>
            )}
            {!isValid && <StyledInputErrorMessage>{ERROR_COPY}</StyledInputErrorMessage>}
          </SubQuestionCont>
        );
      })}
    </UnderWritingQuestionsContainer>
  );
};

const StyledLabelLarge = styled(LabelLarge)`
  align-self: center;
`;

const UnderWritingQuestionsContainer = styled.div`
  position: relative;
`;

export default UnderWritingQuestions;
