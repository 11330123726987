import React, { useState } from 'react';
import policies from './policyInfo';
import SmoothCollapse from 'react-smooth-collapse';
import { SelectedCheck, UnSelectedCheck } from '../Summary/Packages/PackagesStyledComponents';
import {
  PolicyRow,
  PolicyCont,
  PolicyTitle,
  PolicyDesc,
  PolicyDescCont,
  Column,
  LearnMoreBox,
  MobileTitleContainer,
} from './styles';
import { PolicyIcon, TextButton, theme } from '../../elements';
import { Mobile, DesktopAndTablet } from '../../helpers/responsive';
import styled from 'styled-components';
import { useThemeContext } from '../../elements/theme/CustomThemeProvider';
import { useFeatureFlags } from '../../toggle_tools/featureFlagTools';
import PolicyItem from '../Summary/Policies/PolicyItem';
import { ExclusionsHeader, ExclusionsItem, ExclusionsList } from '../Summary/Policies/styles';
import { selectExclusions } from '../../selectors/quote';
import { connect } from 'react-redux';

const Policy = ({
  name,
  label,
  applicationTypes,
  expanded,
  updateSelectedPolicy,
  handleLearnMoreClick,
  policyTypeUnavailable,
  exclusions,
}: {
  name: string;
  label: string;
  applicationTypes: string[];
  expanded: string;
  updateSelectedPolicy: (name: string) => void;
  handleLearnMoreClick: (name: string) => void;
  policyTypeUnavailable: boolean;
  exclusions: string[];
}) => {
  const [exclusionExpanded, setExclusionExpanded] = useState('');
  const { shouldRenderIcon } = useThemeContext();
  const { showExclusions } = useFeatureFlags();

  const isSelected = applicationTypes.includes(name);

  const shouldRenderExclusions = (policyType: string) => {
    if (!showExclusions) return false;
    return policyType === 'BOP' || policyType === 'GL' ? Boolean(exclusions?.length) : false;
  };
  const getBackgroundColor = (policyType: string) => {
    if (policyType === 'GL') {
      return { borderColor: '#40FFB1', backgroundColor: '#CFFFEB' };
    } else if (policyType === 'BOP') {
      return { borderColor: '#FFDB43', backgroundColor: '#FFF4C6' };
    } else return;
  };

  return (
    <PolicyCont isSelected={isSelected} policyTypeUnavailable={policyTypeUnavailable}>
      {isSelected ? (
        <SelectedCheck onClick={() => updateSelectedPolicy(name)} />
      ) : (
        <UnSelectedCheck onClick={() => updateSelectedPolicy(name)} />
      )}
      <Mobile>
        <MobileTitleContainer>
          {shouldRenderIcon && <PolicyIcon icon={name} />}
          <PolicyTitle>{label}</PolicyTitle>
        </MobileTitleContainer>
      </Mobile>

      <PolicyRow onClick={() => updateSelectedPolicy(name)} data-cy={name} data-testid={name}>
        <DesktopAndTablet>{shouldRenderIcon && <PolicyIcon icon={name} />}</DesktopAndTablet>
        <Column>
          <DesktopAndTablet>
            <PolicyTitle>{label}</PolicyTitle>
          </DesktopAndTablet>
          <PolicyDesc>{policies[name].subtitle}</PolicyDesc>
        </Column>
      </PolicyRow>
      <SmoothCollapse expanded={expanded === name}>
        <PolicyDescCont>{policies[name].description}</PolicyDescCont>
        {shouldRenderExclusions(name) && (
          <PolicyItemContainer data-testId="PolicyItemContainerChecker">
            <PolicyItem
              disableSubmit={true}
              propertyName="Which operations are excluded?"
              expanded={exclusionExpanded === 'Which operations are excluded?'}
              setExpanded={setExclusionExpanded}
              testId="exclusions"
              customColors={getBackgroundColor(name)}
              customDefaultBg={theme.pageComponents.summary.exclusions.background.default}
            >
              <>
                <ExclusionsHeader>We exclude the following operations as part of this industry:</ExclusionsHeader>
                <ExclusionsList>
                  {exclusions.map((exclusion) => (
                    <ExclusionsItem key={exclusion}>{exclusion}</ExclusionsItem>
                  ))}
                </ExclusionsList>
              </>
            </PolicyItem>
          </PolicyItemContainer>
        )}
      </SmoothCollapse>
      <LearnMoreBox data-testId="learn-more-box" onClick={() => handleLearnMoreClick(name)}>
        <StyledTextButton
          type="button"
          fontSize="13px"
          aria-label={`Learn more about ${label}`}
          aria-pressed={expanded === name}
        >
          {expanded === name ? 'Close' : 'Learn more'}
        </StyledTextButton>
      </LearnMoreBox>
    </PolicyCont>
  );
};

const StyledTextButton = styled(TextButton)`
  color: ${({ theme }) => theme.pageComponents.policySelection.learnMoreButton.textColor};
  :hover {
    cursor: pointer;
    border-bottom: ${({ theme }) => theme.pageComponents.policySelection.learnMoreButton.borderOnHover} !important;
  }
`;

const PolicyItemContainer = styled.div`
  margin: 0 32px 20px 74px;
  padding: 0px 20px;
  background-color: ${({ theme }) => theme.pageComponents.summary.exclusions.background.default};
`;

const mapStateToProps = (state: ReduxState) => ({
  exclusions: selectExclusions(state),
});

export default connect(mapStateToProps)(Policy);
