import React from 'react';
import {
  LoaderCont,
  PriceText,
  Price,
  DollarCont,
  Row,
  FeeNotice,
  SavingsNotice,
  PremiumCont,
  TotalDueText,
  TotalDueButton,
} from './styles';
import LoadingSpinner from '.././LoadingSpinnerDots';
import { useMediaQuery } from 'react-responsive';
import { PAGES } from '../../../constants';
import { DesktopOrMobile } from '../../../helpers/responsive';
import { formatCurrency } from '../../../helpers/formatNumber';
import right_arrow_button from '../../../icons/right_arrow_button.svg';
import right_arrow_button_hover from '../../../icons/right_arrow_button_hover.svg';

export const feeNotice = 'Additional fees may apply';

interface RenderPremiumProps {
  showLoadingSpinner: boolean;
  isSummaryPage: boolean;
  isBindable: boolean;
  paymentInterval: string;
  page: string;
  shouldShowQuote: boolean;
  showFees: boolean;
  savingsNotice: string;
  premium: number;
  monthlyPremium: number;
  totalYearlyOwed: number;
  month1Owed: number;
  setShowPriceDrawer: (showPriceDrawer: boolean) => void;
}

const RenderPremium = ({
  showLoadingSpinner,
  isSummaryPage,
  isBindable,
  paymentInterval,
  page,
  shouldShowQuote,
  showFees,
  savingsNotice,
  premium,
  monthlyPremium,
  totalYearlyOwed,
  month1Owed,
  setShowPriceDrawer,
}: RenderPremiumProps) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1024 });

  const quoteInterval = paymentInterval === 'None' ? 'year' : 'month';
  const estPremiumVerbiage = isTablet ? `Estimated ${quoteInterval}ly premium` : 'Estimated premium';
  const quoteHeader = isBindable || page === PAGES.PAYMENT ? 'Your premium is' : estPremiumVerbiage;
  const premiumAmount = paymentInterval === 'None' ? premium : monthlyPremium;
  const renderedPremium = shouldShowQuote ? formatCurrency(premiumAmount) : '--';
  const totalDueAmount = paymentInterval === 'None' ? formatCurrency(totalYearlyOwed) : formatCurrency(month1Owed);

  if (!showLoadingSpinner) {
    return (
      <PremiumCont>
        <Row>
          {!isTablet && isSummaryPage && showFees && (
            <SavingsNotice interval={quoteInterval}>{savingsNotice}</SavingsNotice>
          )}
          {!isTablet && (
            <PriceText isBindable={isBindable} data-testid="quotePrefix">
              {quoteHeader}
            </PriceText>
          )}
          <DollarCont data-testid="premium-cont">
            <Price data-cy="premium">{renderedPremium}</Price>
          </DollarCont>
          <DesktopOrMobile>
            <PriceText>per {quoteInterval}</PriceText>
          </DesktopOrMobile>
          {!isTablet && isSummaryPage && (
            <TotalDueText>
              Total due today: {totalDueAmount}
              <TotalDueButton onClick={() => setShowPriceDrawer(true)}>
                <img src={right_arrow_button} alt="show total due details" />
                <img src={right_arrow_button_hover} alt="show total due details" />
              </TotalDueButton>
            </TotalDueText>
          )}
        </Row>
        {showFees && !isSummaryPage && <FeeNotice data-testid="fee-notice">{feeNotice}</FeeNotice>}
      </PremiumCont>
    );
  } else {
    return (
      <Row>
        <LoaderCont>
          <LoadingSpinner />
        </LoaderCont>
      </Row>
    );
  }
};

export default RenderPremium;
