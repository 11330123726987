import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { showNextPage } from '../actions/application';
import handleExistingApplication from '../actions/handleExistingApplication';
import LoadingSpinner from '../Components/Shared/LoadingSpinnerDots';
import { selectPayloadId } from '../selectors';

interface LoadingApplicationProps {
  existingApplicationLoaded?: string | null;
  hasStateHydrated: boolean;
  fetchExistingApplication: () => void;
  showNextPage: () => void;
  incomingApplicationId: string;
}

class LoadingApplication extends Component<LoadingApplicationProps> {
  componentDidMount() {
    const { existingApplicationLoaded, incomingApplicationId } = this.props;

    if (
      this.props.hasStateHydrated &&
      (!existingApplicationLoaded || incomingApplicationId !== existingApplicationLoaded)
    ) {
      this.props.fetchExistingApplication();
    } else {
      this.props.showNextPage();
    }
  }

  render() {
    return (
      <PageCont id="pagecont">
        <LoadingSpinner />
      </PageCont>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  existingApplicationLoaded: state.existingApplicationLoaded,
  hasStateHydrated: state._persist.rehydrated,
  incomingApplicationId: selectPayloadId(state),
});

const fetchExistingApplication = () => handleExistingApplication;

const mapDispatchToProps = {
  fetchExistingApplication,
  showNextPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoadingApplication);

const PageCont = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 768px) {
    top: 20%;
    position: absolute;
  }
`;
