import React, { useEffect, useReducer, useState } from 'react';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import PolicySummary from './PolicySummary';
import { PL_LIMIT_OCC_AGG, POLICY_ENUMS, POLICY_NAMES } from '../../../constants';
import {
  selectBPPDeductible,
  selectFirstLocation,
  selectGLDeductible,
  selectGLLimit,
  selectLocationBPPLimit,
  selectLocationBuildingLimit,
  selectPlDeductible,
  selectPlLimit,
  selectPolicyTypes,
  selectProfessionalLiability,
} from '../../../selectors/application';
import { getQuoteAmountByType, selectExclusions } from '../../../selectors/quote';
import { selectLoadingState } from '../../../selectors';
import {
  setBppDeductible,
  setGlLimit,
  updateProfessionalLiability,
  updateLocation,
  setApplicationTypes,
} from '../../../actions/application';
import handleUpdateApplication from '../../../actions/handleUpdateApplication';
import { SectionHeader, SectionHeaderCont } from '../styles';
import {
  WhiteHr,
  BlendedSpacer,
  PolicySummaryCont,
  StyledSmoothCollapse,
  DrawerControls,
  ErrorPopUp,
  ExclamationMark,
  SectionCont,
  HeaderSubCont,
  PolicyTitle,
  PolicyPrice,
} from './styles';
import coterie_bang from '../../../icons/coterie_bang.svg';
import { PolicyIcon } from '../../../elements';
import policyInfo from '../../PolicySelection/policyInfo';
import { summariesAttributesFactory } from './factories';
import { useThemeContext } from '../../../elements/theme/CustomThemeProvider';
import PolicyItem from './PolicyItem';
import { ExclusionsHeader, ExclusionsList, ExclusionsItem } from './styles/policySummaryCards';
import { useFeatureFlags } from '../../../toggle_tools/featureFlagTools';
import styled from 'styled-components';
import Checkbox from '../../../elements/Checkbox';

const ERROR_TYPE = {
  BPP_OVER_LIMIT: 'BPP_OVER_LIMIT',
  BPP_UNDER_LIMIT: 'BPP_UNDER_LIMIT',
  BUILDING_OVER_LIMIT: 'BUILDING_OVER_LIMIT',
};

type quote = { monthly: string; annual: string };
interface QuoteAmounts {
  [key: string]: quote;
}

interface PolicySummaryCardProps {
  isLoading: boolean;
  businessLocation: ApplicationLocation;
  applicationTypes: string[];
  buildingLimit?: number;
  bppLimit?: number;
  bppDeductible?: number;
  glLimit?: number;
  propertyDamageLiabilityDeductible?: number;
  plLimit: number | null;
  plDeductible: number | null;
  professionalLiability: Object;
  quoteAmountByType: QuoteAmounts;
  exclusions: string[];
  setBppDeductible: (num: number) => void;
  setGlLimit: (num: number) => void;
  updateProfessionalLiability: (val: any) => void;
  updateLocation: (val: any) => void;
  setApplicationTypes: (types: string[]) => void;
  handleUpdateApplication: () => void;
}

const PolicySummaryCards = ({
  isLoading,
  businessLocation,
  applicationTypes,
  bppLimit,
  buildingLimit,
  bppDeductible,
  glLimit,
  propertyDamageLiabilityDeductible,
  plLimit,
  plDeductible,
  professionalLiability,
  quoteAmountByType,
  exclusions,
  setBppDeductible,
  setGlLimit,
  updateProfessionalLiability,
  updateLocation,
  setApplicationTypes,
  handleUpdateApplication,
}: PolicySummaryCardProps) => {
  const { theme, shouldRenderIcon } = useThemeContext();
  const [expanded, setExpanded] = useState('');
  const [editExpanded, setEditExpanded] = useState('');
  const [showErrorMsg, setShowErrorMsg] = useState('');
  const [shouldShowErrorMsg, setShouldShowErrorMsg] = useState(false);
  const [bppLimitError, setBppLimitError] = useState<{ type: string; value: number } | null>(null);
  const [buildingLimitError, setBuildingLimitError] = useState<{ type: string; value: number } | null>(null);

  const { showExclusions, increaseFlPropertyMin } = useFeatureFlags();

  const renderExclusions = (policyType: string) => {
    if (!showExclusions) return false;
    return policyType === 'BOP' || policyType === 'GL' ? Boolean(exclusions?.length) : false;
  };

  const [cachedSelectedPolicies, dispatchHook] = useReducer(
    (state: any, action: any) => (action != null ? action : state),
    []
  );

  useEffect(() => {
    const hasNewPolicyAdded = applicationTypes.length > cachedSelectedPolicies.length;
    const hasUpgradedGLtoBOP =
      applicationTypes.includes(POLICY_ENUMS.BOP) && !cachedSelectedPolicies.includes(POLICY_ENUMS.BOP);
    if (hasNewPolicyAdded || hasUpgradedGLtoBOP) {
      dispatchHook(applicationTypes);
    }
  }, [applicationTypes, cachedSelectedPolicies]);

  const togglePolicyEdit = (section: string) => {
    if (editExpanded === section) {
      setEditExpanded('');
    } else {
      setEditExpanded(section);
    }
  };

  const updateLocationProperty = (update: { bppLimit: number } | { buildingLimit: number }) => {
    updateLocation({ ...businessLocation, ...update });
    handleUpdateApplication();
  };

  const updateBppLimit = debounce((event: { floatValue: number }) => {
    if (event.floatValue > 500_000) {
      setBppLimitError({ type: ERROR_TYPE.BPP_OVER_LIMIT, value: event.floatValue });
    } else if (event.floatValue < 1) {
      setBppLimitError({ type: ERROR_TYPE.BPP_UNDER_LIMIT, value: event.floatValue });
    } else if (event.floatValue != null) {
      setBppLimitError(null);
      updateLocationProperty({ bppLimit: event.floatValue });
    }
  }, 500);

  const updateBuildingLimit = debounce((event: any) => {
    if (event.floatValue > 1_000_000) {
      setBuildingLimitError({ type: ERROR_TYPE.BUILDING_OVER_LIMIT, value: event.floatValue });
    } else if (event.floatValue != null) {
      setBuildingLimitError(null);
      updateLocationProperty({ buildingLimit: event.floatValue });
    }
  }, 500);

  const updateBppDeductible = (value: number) => {
    setBppDeductible(value);
    handleUpdateApplication();
  };

  const updateGlLimit = (value: number) => {
    setGlLimit(value);
    handleUpdateApplication();
  };

  const updatePlLimit = (value: number) => {
    const updates = {
      occurrenceLimit: value,
      aggregateLimit: PL_LIMIT_OCC_AGG[value],
    };
    const updatedProfessionalLiability = Object.assign({}, professionalLiability, { ...updates });

    updateProfessionalLiability({ ...updatedProfessionalLiability });
    handleUpdateApplication();
  };

  const updatePlDeductible = (value: number) => {
    const updates = { deductibleAmount: value };
    const updatedProfessionalLiability = Object.assign({}, professionalLiability, { ...updates });

    updateProfessionalLiability({ ...updatedProfessionalLiability });
    handleUpdateApplication();
  };

  const handleDeselectPolicy = (policy: string) => {
    setEditExpanded('');
    const newPolicies = applicationTypes.includes(policy)
      ? applicationTypes.filter((p: string) => p !== policy)
      : [...applicationTypes, policy];

    setApplicationTypes(newPolicies);
  };

  const handleSelectPolicy = (policy: string) => {
    setEditExpanded('');
    setApplicationTypes([...applicationTypes, policy]);
  };

  const handlePolicyChange = (policy: string) => {
    if (applicationTypes.includes(policy)) {
      if (applicationTypes.length === 1) {
        setShowErrorMsg(policy);
        setShouldShowErrorMsg(true);
        setTimeout(() => setShowErrorMsg(''), 5000);
        setTimeout(() => setShouldShowErrorMsg(false), 6000);
      } else {
        handleDeselectPolicy(policy);
      }
    } else {
      handleSelectPolicy(policy);
    }
  };

  const updateHandlers = {
    updateGlLimit,
    updateBppDeductible,
    updatePlLimit,
    updatePlDeductible,
    updateBppLimit,
    updateBuildingLimit,
  };

  const summaryValues = {
    bppLimit,
    buildingLimit,
    bppDeductible,
    glLimit,
    propertyDamageLiabilityDeductible,
    plLimit,
    plDeductible,
    bppLimitError,
    buildingLimitError,
  };

  const SUMMARIES = summariesAttributesFactory(updateHandlers, summaryValues);

  const getBackgroundColor = (policyType: string) => {
    if (policyType === 'GL') {
      return { borderColor: '#40FFB1', backgroundColor: '#CFFFEB' };
    } else if (policyType === 'BOP') {
      return { borderColor: '#FFDB43', backgroundColor: '#FFF4C6' };
    } else return;
  };

  return (
    <>
      <SectionHeader>Your Policies</SectionHeader>
      {cachedSelectedPolicies.map((policyType: string, index: number) => (
        <FlexDiv key={policyType}>
          <SectionCont data-cy={`${policyType}-summary-card`} isSelected={applicationTypes.includes(policyType)}>
            <SectionHeaderCont>
              <Checkbox
                onChange={() => handlePolicyChange(policyType)}
                selected={applicationTypes.includes(policyType)}
                ariaLabel={`${applicationTypes.includes(policyType) ? 'deselect' : 'select'} ${
                  POLICY_NAMES[policyType]
                }`}
              />
              {shouldShowErrorMsg ? (
                <ErrorPopUp showErrorMsg={showErrorMsg === policyType}>
                  <ExclamationMark src={coterie_bang} alt="exclamation mark" />
                  <span>You must have at least one policy activated.</span>
                </ErrorPopUp>
              ) : null}
              <HeaderSubCont>
                {shouldRenderIcon && <PolicyIcon icon={policyType} onClick={() => togglePolicyEdit(policyType)} />}
              </HeaderSubCont>
              <PolicyTitle>{POLICY_NAMES[policyType]}</PolicyTitle>
            </SectionHeaderCont>
            <div>
              <p>{policyInfo[policyType].subtitle}</p>
              <PolicyPrice>${quoteAmountByType[policyType]?.monthly}/mo</PolicyPrice>
            </div>
            <StyledSmoothCollapse expanded={editExpanded === policyType} allowOverflowWhenOpen>
              <BlendedSpacer />
              <PolicySummaryCont>
                {SUMMARIES[policyType].map((summary: any) =>
                  summary.value != null ? (
                    <PolicySummary
                      key={summary.option}
                      policyType={summary.option}
                      policyValue={summary.value}
                      inputValue={summary.inputValue}
                      selectedPolicies={applicationTypes}
                      expanded={expanded}
                      setExpanded={setExpanded}
                      updateValue={summary.updateHandler}
                      inputType={summary.inputType}
                      isDisabled={isLoading}
                      isReadOnly={summary.isReadOnly}
                      errorMessage={summary.hasError}
                      isFlorida={increaseFlPropertyMin && businessLocation.state === 'FL'}
                    />
                  ) : null
                )}
                {renderExclusions(policyType) && (
                  <PolicyItem
                    propertyName="Which operations are excluded?"
                    expanded={expanded === 'Which operations are excluded?'}
                    setExpanded={setExpanded}
                    testId="exclusions"
                    customColors={getBackgroundColor(policyType)}
                  >
                    <>
                      <ExclusionsHeader>We exclude the following operations as part of this industry:</ExclusionsHeader>
                      <ExclusionsList>
                        {exclusions.map((exclusion) => (
                          <ExclusionsItem key={exclusion}>{exclusion}</ExclusionsItem>
                        ))}
                      </ExclusionsList>
                    </>
                  </PolicyItem>
                )}
              </PolicySummaryCont>
            </StyledSmoothCollapse>
            <WhiteHr isHidden={!applicationTypes.includes(policyType) || editExpanded === policyType} />
            <DrawerControls
              data-cy={`${policyType}-edit-drop-down`}
              data-testid={`${policyType}-edit-drop-down`}
              onClick={() => togglePolicyEdit(policyType)}
            >
              {applicationTypes.includes(policyType) ? (editExpanded === policyType ? 'Close' : 'Edit Details') : ''}
            </DrawerControls>
          </SectionCont>
        </FlexDiv>
      ))}
    </>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  isLoading: selectLoadingState(state),
  businessLocation: selectFirstLocation(state),
  glLimit: selectGLLimit(state),
  bppDeductible: selectBPPDeductible(state),
  propertyDamageLiabilityDeductible: selectGLDeductible(state),
  buildingLimit: selectLocationBuildingLimit(state),
  bppLimit: selectLocationBPPLimit(state),
  plLimit: selectPlLimit(state) || null,
  plDeductible: selectPlDeductible(state) || null,
  professionalLiability: selectProfessionalLiability(state),
  applicationTypes: selectPolicyTypes(state),
  quoteAmountByType: getQuoteAmountByType(state),
  exclusions: selectExclusions(state),
});

const mapDispatchToProps = {
  setBppDeductible,
  setGlLimit,
  updateProfessionalLiability,
  updateLocation,
  setApplicationTypes,
  handleUpdateApplication,
};

const FlexDiv = styled.div`
  display: flex;
  margin-bottom: 16px;
`;

export default connect(mapStateToProps, mapDispatchToProps)(PolicySummaryCards);
