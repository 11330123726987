import styled, { css } from 'styled-components';
import { SecondaryButton } from '../../../elements';

export const Sidebar = styled.div`
  z-index: 10;
  @media (max-width: 1024px) {
    position: initial;
    display: inline-block;
    width: 100%;
    margin-bottom: 16px;
  }
  @media (min-width: 1025px) {
    position: sticky;
    top: 0;
  }
`;

export const QuoteCont = styled.div<{ isPaymentPage?: boolean }>`
  position: relative;
  max-width: ${(props) => (props.isPaymentPage ? '324px' : '225px')};
  ${(props) => props.isPaymentPage && 'min-width: 324px;'}
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-right: 60px;
  margin-top: 55px;
  float: right;

  & > * {
    flex: 1 100%;
  }

  @media (max-width: 767px) {
    max-width: 100%;
    margin: 0;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    max-width: 100%;
    margin: 0;
  }
`;

export const LoaderCont = styled.div`
  width: 100%;
  height: 100%;
  margin: auto;
`;

export const PremiumHeader = styled.h2`
  font-family: ${({ theme }) => theme.font.typeface.primary};
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.pageComponents.shared.infoSideBar.premiumHeader.textColor};
  line-height: 22px;
  @media (min-width: 1025px) {
    margin: auto;
  }
  @media (max-width: 1024px) {
    margin: 0;
  }
`;

interface PriceContProps {
  isSummaryPage?: boolean;
  isPaymentPage?: boolean;
}

export const PriceCont = styled.div<PriceContProps>`
  position: relative;
  background-color: ${({ theme }) => theme.pageComponents.globals.background};
  border: 1px solid ${({ theme }) => theme.pageComponents.shared.infoSideBar.priceCont.border};
  border-radius: 4px;
  padding: ${(props) => (props.isPaymentPage ? '16px 24px' : '12px 0 16px')};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 225px;
  margin: 8px auto 0;
  flex-direction: column;
  box-sizing: border-box;
  ${(props) => props.isPaymentPage && 'row-gap: 8px;'}
  @media (min-width: 768px) and (max-width: 1024px) {
    margin: ${(props) => (props.isSummaryPage ? '8px auto 0' : '0')};
    padding: 0.7em 1em;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
    align-items: start;
  }
`;

interface PriceTextProps {
  isBindable?: boolean;
}

export const PriceText = styled.p<PriceTextProps>`
  color: ${({ theme }) => theme.pageComponents.shared.infoSideBar.priceText.textColor};
  font-family: ${({ theme }) => theme.font.typeface.primary};
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  margin: 0;
  @media (min-width: 768px) and (max-width: 1024px) {
    max-width: ${(props) => (props.isBindable ? '100%' : '50px')};
    margin-right: 2em;
    text-align: left;
  }
`;

export const Price = styled.h3`
  color: ${({ theme }) => theme.pageComponents.shared.infoSideBar.price.textColor};
  font-weight: 600;
  font-family: ${({ theme }) => theme.font.typeface.primary};
  font-size: 48px;
  display: inline;
  padding: 0;
  margin: 0;
  letter-spacing: 1.5px;
  @media (min-width: 768px) and (max-width: 1024px) {
    font-size: 30px;
  }
`;

export const DollarCont = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const ToggleButtonCont = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  margin: 8px 0 12px;
  width: 160px;
`;

interface ToggleButtonProps {
  selected: boolean;
}

export const ToggleButton = styled.button<ToggleButtonProps>`
  margin: 0;
  height: 35px;
  width: 50%;
  border: none;
  color: ${(props) =>
    props.selected
      ? props.theme.pageComponents.shared.infoSideBar.toggleButton.colorSelected
      : props.theme.pageComponents.shared.infoSideBar.toggleButton.colorUnselected};
  font-family: ${(props) => props.theme.font.typeface.primary};
  font-size: 13px;
  background-color: ${(props) =>
    props.selected
      ? props.theme.pageComponents.shared.infoSideBar.toggleButton.backgroundSelected
      : props.theme.pageComponents.shared.infoSideBar.toggleButton.backgroundUnselected};
  font-weight: 600;

  :hover {
    cursor: pointer;
  }
  :first-child {
    border-radius: 20px 0 0 20px;
  }
  :last-child {
    border-radius: 0 20px 20px 0;
  }
  :disabled {
    cursor: default;
  }
`;

export const Row = styled.div`
  margin: 0 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 91px;
  @media (min-width: 768px) and (max-width: 1024px) {
    min-height: 40px;
    margin: 0;
    flex-direction: row;
  }
`;

export const BuyButtonCont = styled.div`
  @media (max-width: 1024px) {
    display: none;
  }
`;

export const DownloadQuoteButton = styled(SecondaryButton)`
  margin-top: 18px;
  padding: 0px 40px 0 16px;

  svg {
    margin-right: auto;
    fill: ${({ theme }) => theme.colors.primary.zero};
  }

  &:hover svg {
    fill: #fff;
  }

  @media (max-width: 1024px) {
    padding: 8px 0;
    height: auto;
    display: inline-block;
    margin: 0;
    display: flex;

    svg {
      margin-right: 0px;
    }
  }
`;

export const DiscountApplied = styled.img`
  width: 70px;
  position: relative;
`;

export const discountButtonCSS = css`
  position: absolute;
  left: -14px;
  bottom: -20px;
  width: 70px !important;
  height: 70px !important;
`;

export const PartnerName = styled.p`
  margin: 0;
  font-weight: 600;
  span {
    text-transform: uppercase;
    font-weight: bold;
  }
`;

export const FeeNotice = styled.p`
  margin: 9px 0 0;
  font-size: 12px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.primary.zero};
  text-align: center;
`;

export const SavingsNotice = styled.p<{ interval: string }>`
  margin: 0 0 12px;
  font-size: 12px;
  font-weight: 600;
  color: ${({ theme, interval }) => (interval === 'month' ? theme.colors.alert.zero : theme.colors.primary.zero)};
  text-align: center;

  @media (min-width: 768px) and (max-width: 1024px) {
    margin: 0.5em 0;
  }
`;

export const FeesContainer = styled.div`
  margin-bottom: 16px;
`;

export const FeeAppliedContainer = styled.div`
  display: flex;
  padding: 12px;
  background-color: ${({ theme }) => theme.colors.neutral.special};
  margin: 8px 0px 4px 0px;
  border-radius: 4px;

  span {
    position: relative;
    top: -1px;
  }

  @media (max-width: 1024px) {
    align-items: center;
    width: 90%;
  }
  @media (max-width: 767px) {
    width: 96%;
  }
`;

export const FeeAppliedText = styled.p`
  margin: 0 0 0 8px;
  color: ${({ theme }) => theme.colors.primary.zero};
  font-size: 14px;
  font-weight: 600;
`;

export const PremiumCont = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const PriceRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media (min-width: 768px) and (max-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const BreakdownRow = styled.div<{ style: any }>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  ${(props) =>
    props.style &&
    css`
      ${props.style}
    `}
`;

export const BreakdownLabel = styled.div<{ style: any }>`
  ${(props) =>
    props.style &&
    css`
      ${props.style}
    `}
`;

export const BreakdownValue = styled.div<{ style: any }>`
  ${(props) =>
    props.style &&
    css`
      ${props.style}
    `}
`;

export const TotalDueText = styled.div`
  font-family: ${({ theme }) => theme.font.typeface.secondary};
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.tertiary.zero};
  margin-top: 16px;
  display: flex;
  column-gap: 8px;
  align-items: center;
  @media (min-width: 768px) and (max-width: 1024px) {
    align-self: end;
  }
`;

export const TotalDueButton = styled.button`
  height: 24px;
  padding: 0;
  border: none;
  background-color: transparent;
  img:last-child {
    display: none;
  }
  &:hover {
    cursor: pointer;
  }
  &:hover img:last-child {
    display: block;
  }
  &:hover img:first-child {
    display: none;
  }
`;
