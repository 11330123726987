import styled from 'styled-components';
import { InputErrorMessage, NumberInput } from '../../../../elements';

export const SummaryNumberInput = styled(NumberInput)`
  margin-left: 20px;

  @media screen and (max-width: 768px) {
    width: calc(100% - 20px);
    margin-left: 10px;
    margin-right: 10px;
  }
`;

export const SummaryInputErrorMsg = styled(InputErrorMessage)`
  margin-left: 20px;
  margin-bottom: 18px;

  @media screen and (max-width: 768px) {
    width: calc(100% - 20px);
    margin-left: 10px;
    margin-right: 10px;
  }
`;
