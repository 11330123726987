import styled from 'styled-components';
import RadioBtn from '../../elements/RadioBtn';
import {
  selectAreAnyBlanketAIItemsChecked,
  selectHasBlanketAIConstructionContractRequirementEndorsementChecked,
  selectHasBlanketAILessorOfLeasedEquipmentEndorsementChecked,
  selectHasBlanketAIManagersOrLessorsPremisesEndorsementChecked,
  selectHasBlanketAIPrimaryAndNonContributoryChecked,
  selectHasBlanketAIWaiverOfTransferChecked,
} from '../../selectors/application';
import {
  removeBlanketAdditionalInsureds,
  setBlanketAdditionalInsureds,
  toggleBlanketAIItem,
} from '../../actions/application';
import { connect } from 'react-redux';
import Checkbox, { CheckboxAndLabel, CheckboxLabel } from '../../elements/Checkbox';
import SmoothCollapse from 'react-smooth-collapse';
import { useState } from 'react';
import { BlanketAIItem } from '../../types/enums';
import { RadioContainer, RadioText } from '../../Pages/AdditionalInsureds';

const managerOrLessorDefaultFormText =
  'Any person or organization with whom you agree in a written agreement for lease or rental of premises or building, prior to loss, to name as an additional insured in this Businessowners Coverage.';
const lessorOfLeasedDefaultFormText =
  'Any person or organization with whom you agree in a written agreement for lease or rental of equipment, prior to loss, to name as an additional insured in this Businessowners Coverage.';

export const blanketOptions = [
  {
    question: 'Managers or Lessors Premises Additional Insured',
    label: 'additionalInsuredsManagersOrLessorsPremisesEndorsement',
    description: managerOrLessorDefaultFormText,
    id: BlanketAIItem.managersOrLessorsPremises,
  },
  {
    question: 'Lessor of Leased Equipment Additional Insured',
    label: 'additionalInsuredsLessorOfLeasedEquipmentEndorsement',
    description: lessorOfLeasedDefaultFormText,
    id: BlanketAIItem.lessorOfLeasedEquipment,
  },
  {
    question: 'Construction Contract Requirement Additional Insured',
    label: 'additionalInsuredsConstructionContractRequirementEndorsement',
    id: BlanketAIItem.constructionContractRequirement,
  },
  {
    question: 'Primary and Non-Contributory',
    label: 'additionalInsuredsPrimaryAndNonContributory',
    id: BlanketAIItem.primaryAndNonNonContributory,
  },
  {
    question: 'Waiver of Subrogation',
    label: 'additionalInsuredsWaiverOfTransfer',
    id: BlanketAIItem.waiverOfTransfer,
  },
];

interface BlanketAdditionalInsuredsProps {
  toggleBlanketAIItem: (arg0: BlanketAIItem) => void;
  removeBlanketAdditionalInsureds: () => void;
  setBlanketAdditionalInsureds: () => void;
  hasBlanketAIPrimaryAndNonContributoryChecked: boolean | undefined;
  hasBlanketAIWaiverOfTransferChecked: boolean | undefined;
  hasBlanketAIConstructionContractRequirementEndorsementChecked: boolean | undefined;
  hasBlanketAIManagersOrLessorsPremisesEndorsementChecked: boolean | undefined;
  hasBlanketAILessorOfLeasedEquipmentEndorsementChecked: boolean | undefined;
  areAnyBlanketAIItemsChecked: boolean;
}

const BlanketAdditionalInsureds = ({
  toggleBlanketAIItem,
  hasBlanketAIPrimaryAndNonContributoryChecked,
  hasBlanketAIWaiverOfTransferChecked,
  hasBlanketAIConstructionContractRequirementEndorsementChecked,
  hasBlanketAIManagersOrLessorsPremisesEndorsementChecked,
  hasBlanketAILessorOfLeasedEquipmentEndorsementChecked,
  removeBlanketAdditionalInsureds,
  setBlanketAdditionalInsureds,
  areAnyBlanketAIItemsChecked,
}: BlanketAdditionalInsuredsProps) => {
  const [blanketAISelected, setBlanketAISelected] = useState(areAnyBlanketAIItemsChecked);
  const showAdditionalInsuredsForm = () => {
    setBlanketAISelected(true);
    setBlanketAdditionalInsureds();
  };

  const hideAdditionalInsuredsForm = () => {
    setBlanketAISelected(false);
    removeBlanketAdditionalInsureds();
  };

  const blanketAIItemsSelected = {
    [BlanketAIItem.primaryAndNonNonContributory]: hasBlanketAIPrimaryAndNonContributoryChecked,
    [BlanketAIItem.waiverOfTransfer]: hasBlanketAIWaiverOfTransferChecked,
    [BlanketAIItem.constructionContractRequirement]: hasBlanketAIConstructionContractRequirementEndorsementChecked,
    [BlanketAIItem.managersOrLessorsPremises]: hasBlanketAIManagersOrLessorsPremisesEndorsementChecked,
    [BlanketAIItem.lessorOfLeasedEquipment]: hasBlanketAILessorOfLeasedEquipmentEndorsementChecked,
  };

  return (
    <>
      <RadioContainer data-cy="blanked-additional-insureds">
        <RadioText>Include blanket additional insured packages to this policy?</RadioText>

        <RadioBtn
          name="blanket-additional-insured"
          id="yes-blanket"
          labelText="Yes"
          isChecked={blanketAISelected}
          onChange={showAdditionalInsuredsForm}
        />
        <RadioBtn
          name="blanket-additional-insured"
          id="no-blanket"
          labelText="No"
          isChecked={!blanketAISelected}
          onChange={hideAdditionalInsuredsForm}
        />
      </RadioContainer>
      <SmoothCollapse expanded={blanketAISelected} allowOverflowWhenOpen={true}>
        <CheckboxContainer>
          <legend>
            These bundles include commonly-requested forms with blanket language to comply with written contractual
            agreements.
          </legend>

          {blanketOptions.map((option) => (
            <span key={option.id}>
              <Checkbox
                onChange={() => toggleBlanketAIItem(option.id)}
                selected={Boolean(blanketAIItemsSelected[option.id])}
                label={option.question}
                id={option.id}
              />
              {option?.description && (
                <BlanketDescription>
                  Blanket wording: <br /> {option.description}
                </BlanketDescription>
              )}
            </span>
          ))}
        </CheckboxContainer>
      </SmoothCollapse>
    </>
  );
};

const CheckboxContainer = styled.fieldset`
  border: none;
  margin: 0;
  margin-top: 8px;
  padding: 0;
  margin-bottom: 80px;

  legend {
    ${({ theme }) => theme.colors.neutral.dark}
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  ${CheckboxLabel} {
    ${({ theme }) => theme.colors.neutral.dark}
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    margin-left: 8px;
  }

  ${CheckboxAndLabel} {
    margin-top: 16px;
    margin-bottom: 0px;
  }
`;

const BlanketDescription = styled.p`
  line-height: 20px;
  color: #4a4a4d;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  font-family: ${(props) => props.theme.font.typeface.secondary};
  margin-top: 0;
  margin-left: 40px;
`;

const mapStateToProps = (state: ReduxState) => ({
  hasBlanketAIPrimaryAndNonContributoryChecked: selectHasBlanketAIPrimaryAndNonContributoryChecked(state),
  hasBlanketAIWaiverOfTransferChecked: selectHasBlanketAIWaiverOfTransferChecked(state),
  hasBlanketAIConstructionContractRequirementEndorsementChecked:
    selectHasBlanketAIConstructionContractRequirementEndorsementChecked(state),
  hasBlanketAIManagersOrLessorsPremisesEndorsementChecked:
    selectHasBlanketAIManagersOrLessorsPremisesEndorsementChecked(state),
  hasBlanketAILessorOfLeasedEquipmentEndorsementChecked:
    selectHasBlanketAILessorOfLeasedEquipmentEndorsementChecked(state),
  areAnyBlanketAIItemsChecked: selectAreAnyBlanketAIItemsChecked(state),
});

const mapDispatchToProps = {
  toggleBlanketAIItem,
  removeBlanketAdditionalInsureds,
  setBlanketAdditionalInsureds,
};

export default connect(mapStateToProps, mapDispatchToProps)(BlanketAdditionalInsureds);
