import { nextPageToShow } from '../selectors/routing';
import {
  UPDATE_APPLICATION_LOCALLY,
  UPDATE_APPLICATION_FROM_SERVER,
  INITIAL_APPLICATION_LOAD,
  CLEAR_APPLICATION,
  CLEAR_ALL_DATA,
  SHOW_NEXT_PAGE,
  FATAL_ERROR,
  QUOTEHELP,
} from '../actions/application';
import { PAGES } from '../constants';

const navigationMiddleware = (store) => (next) => (action) => {
  const result = next(action);
  const { location } = store.getState();
  const isSummaryPage = location.type === PAGES.SUMMARY;

  switch (action.type) {
    case UPDATE_APPLICATION_FROM_SERVER:
      if (!isSummaryPage) {
        store.dispatch({ type: nextPageToShow(store.getState()) });
      }
      return result;
    case FATAL_ERROR:
      store.dispatch({ type: QUOTEHELP });
      return result;
    case UPDATE_APPLICATION_LOCALLY:
    case INITIAL_APPLICATION_LOAD:
    case CLEAR_APPLICATION:
    case CLEAR_ALL_DATA:
    case SHOW_NEXT_PAGE:
      store.dispatch({ type: nextPageToShow(store.getState()) });
      return result;
    default:
      return result;
  }
};

export default navigationMiddleware;
