import { Middleware } from 'redux';
import { AVAILABLE_ENDORSEMENTS_ACTIONS } from '../actions/availableEndorsements';
import { selectEndorsements } from '../selectors/application';
import { getAvailableEndorsements } from '../selectors/availableEndorsements';
import {
  setCyberCoverage,
  setEndorsementPackage,
  setEquipmentBreakdownCoverage,
  setRestaurantCoverage,
  setWorkplaceViolenceCoverage,
} from '../actions/application';
import { EndorsementOptions } from '../constants';

const setEndorsementDefaults: Middleware<{}, ReduxState> = (store) => (next) => (action) => {
  const result = next(action);
  const { type } = action;

  if (type === AVAILABLE_ENDORSEMENTS_ACTIONS.SET_AVAILABLE_ENDORSEMENTS) {
    const state = store.getState();
    const endorsements = selectEndorsements(state);
    const availableEndorsements = getAvailableEndorsements(state);
    const currentCyberCoverage = endorsements?.cyberCoverage;
    const currentWorkplaceViolenceCoverage = endorsements?.workplaceViolenceCoverage;
    const isEquipmentBreakdownAvailable = Boolean(
      availableEndorsements.equipmentBreakdownEndorsement &&
        JSON.parse(availableEndorsements.equipmentBreakdownEndorsement)
    );
    const isRestaurantCoverageAvailable = Boolean(
      availableEndorsements.restaurantCoverage && JSON.parse(availableEndorsements.restaurantCoverage)
    );
    const isGoldPackageAvailable = availableEndorsements.endorsementPackage?.includes(EndorsementOptions.GOLD);

    // this condition should be true only if the user hasn't already deselected cyber coverage
    if (availableEndorsements.cyberCoverage && currentCyberCoverage === undefined) {
      store.dispatch(setCyberCoverage(JSON.parse(availableEndorsements.cyberCoverage)));
    }

    // this condition should be true only if the user hasn't already deselected WPV coverage
    if (availableEndorsements.workplaceViolenceCoverage && currentWorkplaceViolenceCoverage === undefined) {
      store.dispatch(setWorkplaceViolenceCoverage(JSON.parse(availableEndorsements.workplaceViolenceCoverage)));
    }

    if (isEquipmentBreakdownAvailable) {
      store.dispatch(setEquipmentBreakdownCoverage(true));
    }

    if (isRestaurantCoverageAvailable) {
      store.dispatch(setRestaurantCoverage(true));
    }

    if (isGoldPackageAvailable) {
      store.dispatch(setEndorsementPackage(EndorsementOptions.GOLD));
    }
  }

  return result;
};

export default setEndorsementDefaults;
