export const DATA_IDS_FROM_DROPIN = 'DATA_IDS_FROM_DROPIN';
export const SET_DROPIN = 'SET_DROPIN';
export const SET_CHANNEL_PARTNER_GUID = 'SET_CHANNEL_PARTNER_GUID';
export const SET_CUSTOM_STYLES_FROM_DROPIN = 'SET_CUSTOM_STYLES_FROM_DROPIN';

export const setDataIdsFromDropin = (data: string[]) => ({
  type: DATA_IDS_FROM_DROPIN,
  data,
});

export const setDropin = () => ({
  type: SET_DROPIN,
});

export const setChannelPartnerGuidDropIn = (data: string | null) => ({
  type: SET_CHANNEL_PARTNER_GUID,
  data,
});

export const setCustomStylesFromDropin = (data: CustomStyles) => ({
  type: SET_CUSTOM_STYLES_FROM_DROPIN,
  data,
});
