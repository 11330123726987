import styled from 'styled-components';

const CaretIcon = styled.img<{ expanded: boolean }>`
  color: ${({ theme }) => theme.pageComponents.summary.styles.styledCaret.textColor};
  width: 10px;
  margin-left: 10px;
  vertical-align: middle;
  transform: ${({ expanded }) => (expanded ? `rotate(180deg)` : `rotate(0)`)};
  transition: all 0.4s;
  cursor: pointer;
`;

export default CaretIcon;
