export const convertNumberToString = (value?: string | number) => {
  switch (typeof value) {
    case 'number':
      return value.toString();
    case 'string':
      return value;
    default:
      return null;
  }
};

export const convertStringToNumber = (value?: string | number) => {
  switch (typeof value) {
    case 'string':
      return parseInt(value) || null;
    case 'number':
      return value;
    default:
      return null;
  }
};
