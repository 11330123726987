import merge from 'lodash/merge';
import { getRequestContext, getResponseContext, reportToSentry } from '../helpers/sentry';
import {
  fatalError,
  setAvailablePolicyTypes,
  setAcknowledgements,
  updateApplicationLocally,
  setApplicationIsLoading,
  setPartnerIntroViewed,
} from './application';
import { handleGetAvailablePolicyTypes } from './handleGetAvailablePolicyTypes';
import { fetchAcknowledgements, failFastCheck } from '../api';
import { ERRORS } from '../constants';
import { applicationError, setExclusions } from './quote';
import { addSource } from '../helpers/addSource';
import { getIsDropin } from '../selectors/routing';
import { selectApplication } from '../selectors/application';
import { selectPartnerAgencyExternalId, selectAgentProducerExternalId } from '../selectors';
import { getHasInvalidPolicyError } from '../helpers/getHasInvalidPolicyError';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { PolicyType } from '../types/enums';
import { selectIsAgentMDA } from '../selectors/agent';

const handleFailFastCheck = (data?: Application) => {
  return (dispatch: ThunkDispatch<ReduxState, unknown, AnyAction>, getState: () => ReduxState) => {
    dispatch(setApplicationIsLoading(true));
    const sourceData = addSource({ isDropin: getIsDropin(getState()), isMDA: selectIsAgentMDA(getState()) });

    const producerExternalId = selectAgentProducerExternalId(getState());
    const agencyExternalId = selectPartnerAgencyExternalId(getState());
    const addProducerOrAgencyExternalId = producerExternalId ? { producerExternalId } : { agencyExternalId };

    const application = merge(selectApplication(getState()), data, sourceData, addProducerOrAgencyExternalId);
    return failFastCheck(application)
      .then(async (res) => {
        const { availablePolicyTypes: availableTypes } = getState();

        if (res.data.errors) {
          const hasInvalidPolicyError = getHasInvalidPolicyError(res.data.errors);
          const needsAvailablePolicyTypes = availableTypes?.length === 3;

          /**
           * On a prefilled application without an industry ID, the backend will send all policies as availableTypes
           * Neither the updateApplication call nor fail fast receives the available types when backend sends an error obj
           * In order to display a helpful error message, we need to ask the server for available types by sending a post
           * request without a policy type set so it doesn't error out.
           *
           * TODO update the backend to send available policy types when an invalid policy error is detected so the front end
           * doesn't have to make an extra call
           */
          if (hasInvalidPolicyError && needsAvailablePolicyTypes) {
            dispatch(handleGetAvailablePolicyTypes(res.data.errors));
          } else {
            dispatch(applicationError(res.data.errors));
          }
          const hasZipCodeError = res.data.errors.reduce((result: Error, error: Error) => {
            return result || error.message.includes('E0027');
          }, false);
          if (hasZipCodeError) {
            reportToSentry('Fail fast error', getResponseContext(res));
          }

          return;
        } else if (res.data.availablePolicyTypes.length === 0) {
          dispatch(fatalError(ERRORS.CAT_1.ZIP_CODE_OUTSIDE_BOUNDS));
          return;
        }

        let availablePolicyTypes = res.data.availablePolicyTypes;

        if (availablePolicyTypes.includes('PL')) {
          try {
            const acknowledgements = await fetchAcknowledgements(res.data.application.industryId);
            dispatch(setAcknowledgements(acknowledgements.data));
          } catch (error) {
            availablePolicyTypes = availablePolicyTypes.filter((policy: PolicyType) => policy !== PolicyType.PL);
            reportToSentry(error as any, getResponseContext(res));
          }
        }
        if (res.data.exclusions && res.data.exclusions.length > 0) {
          dispatch(setExclusions(res.data.exclusions));
        }
        dispatch(setPartnerIntroViewed(true));
        dispatch(setAvailablePolicyTypes(availablePolicyTypes));
        dispatch(updateApplicationLocally(res.data.application));
        dispatch(setApplicationIsLoading(false));
      })

      .catch((error) => {
        reportToSentry(error, getRequestContext({ application }));
        dispatch(fatalError(error));
        dispatch(setApplicationIsLoading(false));
      });
  };
};

export default handleFailFastCheck;
