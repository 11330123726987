import { quoteReceived, fatalError } from './application';
import { rateCallOne } from '../api';
import { applicationError, clearApplicationError, setDeclinationMessages, setQuoteIsLoading } from './quote';
import { getRequestContext, reportToSentry } from '../helpers/sentry';
import { Dispatch } from 'redux';
import { addSource } from '../helpers/addSource';
import { getIsDropin } from '../selectors/routing';
import { selectIsAgentMDA } from '../selectors/agent';
import { ERRORS } from '../constants';

const handleRateCallOne = (application: Application) => {
  return (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(setQuoteIsLoading(true));

    const sourceData = addSource({ isDropin: getIsDropin(getState()), isMDA: selectIsAgentMDA(getState()) });

    const requestData = {
      ...application,
      ...sourceData,
    };

    return rateCallOne(requestData)
      .then((res) => {
        if (res.data?.underwritingInformation?.declinations?.length > 0) {
          // combine all declinations from each policy object into a single array
          const declinations = res.data.underwritingInformation.declinations.reduce((acc: string[], item: any) => {
            const declination = Array.isArray(item.declination) ? item.declination[0] : item.declination;
            return acc.concat(declination);
          }, []);

          dispatch(setDeclinationMessages(declinations));
          dispatch(fatalError(ERRORS.CAT_1.DECLINATION_ERROR));
        }

        dispatch(setQuoteIsLoading(false));
        dispatch(clearApplicationError());
        if (!res.data.isSuccess && res.data.errors.length > 0) {
          dispatch(applicationError(res.data.errors));
        }
        dispatch(quoteReceived(res.data.quote));
      })
      .catch((error) => {
        dispatch(setQuoteIsLoading(false));
        dispatch(fatalError(error));
        reportToSentry(error, getRequestContext({ requestData }));
      });
  };
};

export default handleRateCallOne;
