import { setApiToken, fetchPartnerInfo, verifyMatchingAgencyChannelPartner } from '../api';
import { clearApplication, setPartnerInfo, fatalError } from './application';
import { reportToSentry } from '../helpers/sentry';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import loadExistingApplication from './loadExistingApplication';

const handleAgencyInbound = async (
  dispatch: ThunkDispatch<ReduxState, unknown, AnyAction>,
  getState: () => ReduxState
) => {
  try {
    const { agencyExternalId, channelPartnerGUID, id: incomingApplicationId } = getState().location.payload;
    const { data } = await fetchPartnerInfo(channelPartnerGUID);

    await verifyMatchingAgencyChannelPartner(agencyExternalId, channelPartnerGUID);

    dispatch(clearApplication());
    setApiToken(channelPartnerGUID);

    if (agencyExternalId) {
      dispatch(setPartnerInfo({ ...data, agencyExternalId, partnerToken: channelPartnerGUID, id: channelPartnerGUID }));
    } else {
      dispatch(setPartnerInfo(data));
    }
    if (incomingApplicationId) {
      loadExistingApplication(dispatch, getState, incomingApplicationId);
    }
  } catch (error) {
    reportToSentry(error as any);
    dispatch(fatalError(error));
  }
};

export default handleAgencyInbound;
