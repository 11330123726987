import { Middleware } from 'redux';
import { setPartnerInfo, clearApplication, CLEAR_ALL_DATA, INITIAL_APPLICATION_LOAD } from '../actions/application';
import { resetApiTokens, setApiToken } from '../api';
import { PAGES } from '../constants';

const tokenMiddleware: Middleware<{}, ReduxState> = (store) => (next) => (action) => {
  const result = next(action);

  switch (action.type) {
    case INITIAL_APPLICATION_LOAD:
      const metadataFromStore = store.getState().partner;
      const metadataFromApplicationString = store.getState().application.metadata || '';
      const metadataFromApplication = metadataFromApplicationString?.length
        ? JSON.parse(metadataFromApplicationString)
        : {};

      const tokenFromApplication = metadataFromApplication.partnerToken;
      const tokenFromStore = metadataFromStore?.partnerToken;

      const { fromPartnerLink } = store.getState().partner as PartnerInfo;
      const { type } = store.getState().location.prev as LocationState;
      const fromPartnerInbound = !fromPartnerLink && type !== PAGES.PARTNER;

      if (tokenFromApplication && fromPartnerInbound) {
        store.dispatch(setPartnerInfo({ ...metadataFromApplication }));
        setApiToken(tokenFromApplication);
      } else if (tokenFromStore) {
        store.dispatch(clearApplication());
        store.dispatch(setPartnerInfo({ ...metadataFromStore }));
        setApiToken(tokenFromStore);
      }
      break;
    case CLEAR_ALL_DATA:
      resetApiTokens();
      break;
    default:
      break;
  }

  return result;
};

export default tokenMiddleware;
