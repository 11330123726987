import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addDays } from 'date-fns';
import styled from 'styled-components';
import { updateStartDate } from '../../actions/application';
import { Dispatch } from 'redux';

import DatePicker from '../../elements/DatePicker';

import { LabelLarge } from '../../elements';
import { selectPolicyStartDate } from '../../selectors/application';

type DatePickerDate = Date | [Date | null, Date | null] | null;

interface StartDateProps {
  fullWidth: boolean;
  startDate: DatePickerDate;
  disabled?: boolean;
  updateStartDate: (date: DatePickerDate) => void;
}

class StartDate extends Component<StartDateProps> {
  setStartDate = (date: DatePickerDate) => this.props.updateStartDate(date);
  render() {
    const { startDate, fullWidth = false, disabled = false } = this.props;

    return (
      <Container>
        <LabelLarge>Policy start date</LabelLarge>
        <DatePicker
          fullWidth={fullWidth}
          date={startDate as Date}
          disabled={disabled}
          setDate={this.setStartDate}
          minDate={new Date()}
          maxDate={addDays(new Date(), 60)}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  startDate: selectPolicyStartDate(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateStartDate: (date: DatePickerDate) => dispatch(updateStartDate({ policyStartDate: date })),
});

export default connect(mapStateToProps, mapDispatchToProps)(StartDate);

const Container = styled.div`
  z-index: 5;
  @media (min-width: 768px) and (max-width: 1024px) {
    max-width: 50%;
  }
`;
