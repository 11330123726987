import { AnyAction, Middleware } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import handleFetchEndorsements from '../actions/handleFetchEndorsements';
import { getShouldFetchAvailableEndorsements } from '../selectors/derivedSelectors';

const availableEndorsements: Middleware<{}, ReduxState> = (store) => (next) => (action) => {
  const result = next(action);
  const state = store.getState();
  const shouldFetchAvailableEndorsements = getShouldFetchAvailableEndorsements(state);

  if (shouldFetchAvailableEndorsements) {
    (store.dispatch as ThunkDispatch<ReduxState, void, AnyAction>)(handleFetchEndorsements());
  }

  return result;
};

export default availableEndorsements;
