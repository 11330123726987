import React from 'react';
import { connect } from 'react-redux';
import coterie_black_logo from '../../icons/coterie_logo_black.svg';
import quickbooks_logo from '../../icons/quickbooks_logo.png';
import {
  selectContactFirstName,
  selectContactLastName,
  selectContactPhone,
  selectContactEmail,
  selectLocationState,
  selectPolicyTypes,
} from '../../selectors/application';
import { selectPartnerInfo, selectIsPremierPlatformPartner } from '../../selectors';
import { getPartnerFromApplication } from '../../selectors/routing';
import Modal, { PrefilledAppDetailsBar } from '../../elements/Modal';
import styled from 'styled-components';
import { setPartnerIntroViewed } from '../../actions/application';
import {
  PREFILLED_INTRO_TEXT,
  QUICKBOOKS_PARTNER_IDS,
  PREFILLED_INTRO_NO_DATA_TEXT,
  PREMIER_PLATFORM_TEXT,
  COTERIE_PARTNER_ID,
} from '../../constants';
import { getEnvironment } from '../../helpers/getEnvironment';
import formatPhone from '../../helpers/formatPhone';
import five_percent_badge from '../../icons/five_percent_badge.svg';
import { DesktopAndTablet, Mobile } from '../../helpers/responsive';
import { selectReferralPartnerId } from '../../selectors/referralPartner';

const Field = styled.p`
  color: ${(props) => props.theme.pageComponents.shared.prefilledSummary.field.textColor};
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  margin: 0px 0px 4px 0px;
  word-wrap: break-word;
  font-family: ${(props) => props.theme.font.typeface.primary};
`;
const Value = styled.p`
  color: ${(props) => props.theme.pageComponents.shared.prefilledSummary.value.textColor};
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: 0;
  font-family: ${(props) => props.theme.font.typeface.secondary};
`;
const FieldContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  @media (max-width: 767px) {
    grid-template-columns: none;
  }
`;

const LogoHeader = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: space-evenly;
`;

const QuickBooksLogo = styled.img`
  height: 30px;
  @media screen and (max-width: 768px) {
    height: 24px;
  }
`;
const CoterieLogo = styled.img`
  height: 30px;
  @media screen and (max-width: 768px) {
    height: 24px;
  }
`;
const Plus = styled.span`
  font-size: 25px;
  margin: 0 8px;
`;

const P = styled.p`
  color: ${(props) => props.theme.pageComponents.shared.prefilledSummary.p.textColor};
  font-family: ${(props) => props.theme.font.typeface.secondary};
  font-size: 15px;
  letter-spacing: 0;
  line-height: 24px;
`;

const DiscountContainer = styled.div`
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
`;

const SubHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const Badge = styled.img`
  margin-right: 32px;
  width: 96px;
  @media (max-width: 767px) {
    margin-right: 16px;
    width: 48px;
  }
`;

const PartnerName = styled.span`
  font-weight: bold;
  font-size: 2rem;
  @media screen and (max-width: 768px) {
    font-size: 24px;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
  border-top: ${({ theme }) => theme.pageComponents.shared.prefilledSummary.row.borderTop};
`;

interface PrefilledSummaryProps {
  partnerInfo: {
    introViewed: boolean;
    id: string;
    name: string;
  };
  setPartnerIntroViewed: (val: boolean) => void;
  firstName?: string;
  lastName?: string;
  phone?: string;
  email?: string;
  state?: string;
  policyType?: string[];
  isPremierPlatform: boolean;
  referralPartnerId?: string;
}

const PrefilledSummary = ({
  partnerInfo,
  setPartnerIntroViewed,
  firstName,
  lastName,
  phone,
  email,
  state,
  policyType = [],
  isPremierPlatform,
  referralPartnerId,
}: PrefilledSummaryProps) => {
  const { id, name, introViewed } = partnerInfo;
  const closeModal = () => {
    setPartnerIntroViewed(true);
  };

  const applicationValues = [
    { title: 'First Name', value: firstName },
    { title: 'Last Name', value: lastName },
    { title: 'Phone', value: phone && formatPhone(phone) },
    { title: 'State', value: state },
    { title: 'Policy Type', value: policyType.length > 0 && policyType },
    { title: 'Email', value: email },
  ];

  const isIntuit =
    QUICKBOOKS_PARTNER_IDS[getEnvironment()] === id || QUICKBOOKS_PARTNER_IDS[getEnvironment()] === referralPartnerId;
  const isCoterie = COTERIE_PARTNER_ID === id;
  const anyApplicationValues = applicationValues
    .filter((values) => values.title !== 'State')
    .some((field) => field.value);
  const isOpen = Boolean(id && !isCoterie && !introViewed && (isIntuit || anyApplicationValues));

  const RenderLogoHeader = () => (
    <LogoHeader>
      {isIntuit ? <QuickBooksLogo src={quickbooks_logo} alt="Quickbooks logo" /> : <PartnerName>{name}</PartnerName>}
      <Plus>+</Plus>
      <CoterieLogo src={coterie_black_logo} alt="Coterie Logo" />
    </LogoHeader>
  );

  const RenderSubHeader = () => (
    <SubHeaderContainer>
      <DesktopAndTablet>
        <Badge src={five_percent_badge} />
      </DesktopAndTablet>
      <DiscountContainer>
        <DesktopAndTablet>
          <RenderLogoHeader />
        </DesktopAndTablet>
        <Mobile>
          <Row>
            <Badge src={five_percent_badge} />
            <RenderLogoHeader />
          </Row>
        </Mobile>
        <P>{PREMIER_PLATFORM_TEXT(name)}</P>
      </DiscountContainer>
    </SubHeaderContainer>
  );

  const detailsBar = (
    <PrefilledAppDetailsBar data-cy="prefilled-summary-modal" premierPlatform={isPremierPlatform}>
      {isPremierPlatform ? <RenderSubHeader /> : <RenderLogoHeader />}
    </PrefilledAppDetailsBar>
  );

  return (
    <Modal
      title={isPremierPlatform ? 'Customer Discount' : 'Welcome'}
      isOpen={isOpen}
      prefilledAppDetailsBar={detailsBar}
      onRequestClose={closeModal}
      label="Prefilled application modal"
      buttonOnClick={closeModal}
      buttonCyLabel="prefilled-summary-submit-button"
      buttonText="Continue"
    >
      {anyApplicationValues ? (
        <>
          <P>{PREFILLED_INTRO_TEXT(name)}</P>
          <FieldContainer>
            {applicationValues.map(
              (item, i) =>
                item.value && (
                  <div key={i}>
                    <Field>{item.title}</Field>
                    <Value>{item.value}</Value>
                  </div>
                )
            )}
          </FieldContainer>
        </>
      ) : (
        <P>{PREFILLED_INTRO_NO_DATA_TEXT(name)}</P>
      )}
    </Modal>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  partnerInfo: getPartnerFromApplication(state),
  firstName: selectContactFirstName(state),
  lastName: selectContactLastName(state),
  phone: selectContactPhone(state),
  email: selectContactEmail(state),
  state: selectLocationState(state),
  policyType: selectPolicyTypes(state),
  partner: selectPartnerInfo(state),
  isPremierPlatform: selectIsPremierPlatformPartner(state),
  referralPartnerId: selectReferralPartnerId(state),
});

const mapDispatchToProps = {
  setPartnerIntroViewed,
};

export default connect(mapStateToProps, mapDispatchToProps)(PrefilledSummary);
