import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import BackButton from './PreviousPageButton';
import SaveApplicationButton from './SaveApplicationButton';
import { PrimaryButtonDarkTheme, LoadingSpinner } from '../../elements';
import { isPageComplete } from '../../selectors/pageComplete';
import { getCurrentPage, getPaymentInterval } from '../../selectors/routing';
import { selectMonth1Owed, selectTotalYearlyOwed } from '../../selectors/quote';
import {
  getPartnerIdFromApplication,
  selectChannelPartnerGuid,
  selectLoadingState,
  selectPurchasedPolicies,
} from '../../selectors';
import handleFormSubmit from '../../actions/handleFormSubmit';
import { clearApplication } from '../../actions/application';
import { PAGES } from '../../constants';
import { DesktopAndTablet, Mobile } from '../../helpers/responsive';
import { formatCurrency } from '../../helpers/formatNumber';
import { useFeatureFlags } from '../../toggle_tools/featureFlagTools';
import PoweredByLogo from '../WhiteLabel/Components/PoweredByLogo';

const CallToActionBar = ({
  handleFormSubmit,
  clearApplication,
  isLoading,
  isPageComplete,
  isFirstPage,
  isSummaryPage,
  isPaymentPage,
  paymentInterval,
  isPolicyPurchased,
  partnerId,
  month1Owed,
  totalYearlyOwed,
}: {
  handleFormSubmit: () => void;
  clearApplication: () => void;
  isLoading: boolean;
  isPageComplete: boolean;
  isFirstPage: boolean;
  isSummaryPage: boolean;
  isPaymentPage: boolean;
  paymentInterval: string;
  isPolicyPurchased: boolean;
  partnerId: string | undefined;
  month1Owed: number;
  totalYearlyOwed: number;
}) => {
  const { hideSaveForLaterAndStartOverButtons } = useFeatureFlags();

  const handleSubmit = () => {
    handleFormSubmit();
  };

  const handleStartOver = () => {
    clearApplication();
  };

  const totalDue = paymentInterval === 'None' ? formatCurrency(totalYearlyOwed) : formatCurrency(month1Owed);

  const NextButtonText = () => {
    if (isLoading) {
      return <LoadingSpinner />;
    } else if (isSummaryPage) {
      return <>Buy policy</>;
    } else if (isPaymentPage) {
      return <>Pay {totalDue}</>;
    } else {
      return <>Next</>;
    }
  };
  const partnerWantsHidden = Boolean(
    partnerId && hideSaveForLaterAndStartOverButtons.channel_partner_ids.includes(partnerId)
  );
  const isDisabled = !isPageComplete || isLoading;

  return (
    <BarContainer>
      <PoweredByLogo />
      <ButtonCont justifyContent={isPolicyPurchased ? 'center' : 'space-between'}>
        {!partnerWantsHidden && isPolicyPurchased ? (
          <NextButton disabled={false} data-cy="submit" onClick={handleStartOver} aria-label="start over">
            Start over
          </NextButton>
        ) : !isPolicyPurchased ? (
          <>
            <BackButton disabled={isFirstPage} />
            {!partnerWantsHidden ? (
              <DesktopAndTablet>
                <SaveApplicationButton shouldUseAltColor={true} />
              </DesktopAndTablet>
            ) : null}
            <NextButton disabled={isDisabled} data-cy="submit" onClick={handleSubmit} aria-label="next page">
              <NextButtonText />
            </NextButton>
          </>
        ) : null}
      </ButtonCont>
      <Mobile>
        <ButtonCont justifyContent="center">
          <SaveApplicationButton shouldUseAltColor={true} />
        </ButtonCont>
      </Mobile>
    </BarContainer>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  isLoading: selectLoadingState(state),
  isPageComplete: isPageComplete(state),
  isFirstPage: getCurrentPage(state) === PAGES.INDUSTRY,
  isSummaryPage: getCurrentPage(state) === PAGES.SUMMARY,
  isPaymentPage: getCurrentPage(state) === PAGES.PAYMENT,
  paymentInterval: getPaymentInterval(state),
  partnerId: selectChannelPartnerGuid(state) ?? getPartnerIdFromApplication(state),
  isPolicyPurchased: Boolean(getCurrentPage(state) === PAGES.PAYMENT && selectPurchasedPolicies(state)?.length),
  month1Owed: selectMonth1Owed(state),
  totalYearlyOwed: selectTotalYearlyOwed(state),
});

const ButtonCont = styled.div<{ justifyContent: string }>`
  box-sizing: border-box;
  margin: 0 0 0 425px;
  width: 64vw;
  max-width: 600px;
  min-height: 46px;
  padding: 0 24px;
  display: flex;
  flex-direction: row;
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: center;
  @media (max-width: 1324px) {
    margin: 0;
    width: 100%;
    justify-content: ${({ justifyContent }) => (justifyContent === 'center' ? 'center' : 'space-around')};
  }
  @media print {
    display: none;
  }
`;

const BarContainer = styled.div`
  display: flex;
  @media (max-width: 1324px) {
    width: 100%;
    justify-content: center;
    flex-direction: row;
  }
  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

const NextButton = styled(PrimaryButtonDarkTheme)`
  margin: 0;
`;

export default connect(mapStateToProps, { handleFormSubmit, clearApplication })(CallToActionBar);
