import { setAcknowledgements, setAvailablePolicyTypes } from './application';
import { fetchAcknowledgements } from '../api';
import { getRequestContext, reportToSentry } from '../helpers/sentry';
import { Dispatch } from 'redux';
import { PolicyType } from '../types/enums';

const handleGetAcknowledgements = () => {
  return async (dispatch: Dispatch, getState: () => ReduxState) => {
    const industryId = getState().application.industryId;
    const availablePolicyTypes = getState().availablePolicyTypes;
    try {
      const acknowledgements = await fetchAcknowledgements(industryId);
      dispatch(setAcknowledgements(acknowledgements.data));
    } catch (error) {
      dispatch(setAvailablePolicyTypes(availablePolicyTypes?.filter((policy: PolicyType) => policy !== PolicyType.PL)));
      reportToSentry(error as any, getRequestContext({ industryId }));
    }
  };
};

export default handleGetAcknowledgements;
