import { endorsementsMap } from '../Components/AddOns/addOnQuestionMap';
import { selectEndorsements, selectGrossAnnualSales } from '../selectors/application';
import { indexableObj } from './defaultValues';
import { isObjectLiteral } from './functionalHelpers';

export const validateAddons = (state: ReduxState) => {
  const appEndorsements = selectEndorsements(state) || indexableObj;
  const grossAnnualSales = selectGrossAnnualSales(state) || Infinity;

  const result = Object.keys(appEndorsements).reduce((accum, endorsementKey) => {
    if (!accum) return false;
    if (isObjectLiteral(appEndorsements[endorsementKey])) {
      return Object.keys(appEndorsements[endorsementKey]).reduce((accum, subQuestionKey) => {
        if (!accum) return false;
        const validateInputFunc =
          endorsementsMap[endorsementKey]?.subQuestions?.underwritingQuestions?.[subQuestionKey]?.validateInput;
        const value = appEndorsements[endorsementKey][subQuestionKey];
        const boundaries = { grossAnnualSales };

        // We return true by default for sub-questions we don't have a validation check for
        return validateInputFunc ? validateInputFunc(value, boundaries) : true;
      }, true);
    } else {
      return accum;
    }
  }, true);

  return result;
};
