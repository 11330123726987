import { EndorsementOptions as options } from '../../../constants';

export const packages = [
  {
    option: options.SILVER,
    flat_premium_charge: '$175',
    description: 'Covers basic needs for some of the more common occurrences you may face in your business.',
  },
  {
    option: options.GOLD,
    flat_premium_charge: '$250',
    description: 'Adds coverages from "Silver" package, as well as other possible occurrences that you might need.',
  },
  {
    option: options.PLATINUM,
    flat_premium_charge: '$325',
    description: 'The most comprehensive package, including coverage for your property off-premise.',
  },
];

export const coverages = {
  'Blanket Limit': {
    Silver: '$150k',
    Gold: '$350k',
    Platinum: '$500k',
  },
  'Accounts Receivable': {
    Silver: 'Included',
    Gold: 'Included',
    Platinum: 'Included',
  },
  'Computers and Media': {
    Silver: 'Included',
    Gold: 'Included',
    Platinum: 'Included',
  },
  'Debris Removal': {
    Silver: 'Included',
    Gold: 'Included',
    Platinum: 'Included',
  },
  'Personal Property of Others': {
    Silver: 'Included',
    Gold: 'Included',
    Platinum: 'Included',
  },
  'Valuable Papers and Records': {
    Silver: 'Included',
    Gold: 'Included',
    Platinum: 'Included',
  },
  'Brands and Labels': {
    Silver: 'No sublimit',
    Gold: 'No sublimit',
    Platinum: 'No sublimit',
  },
  'Claim Expenses': {
    Silver: '$10k',
    Gold: '$10k',
    Platinum: '$10k',
  },
  'Computer Fraud': {
    Silver: '$5k',
    Gold: '$5k',
    Platinum: '$5k',
  },
  'Contract Penalty': {
    Gold: '$1k',
    Platinum: '$1k',
  },
  'Employee Dishonesty (including ERISA)': {
    Silver: '$25k',
    Gold: '$25k',
    Platinum: '$25k',
  },
  'Fine Arts': {
    Silver: '$25k',
    Gold: '$50k',
    Platinum: '$75k',
  },
  Forgery: {
    Silver: '$25k',
    Gold: '$25k',
    Platinum: '$35k',
  },
  'Laptop Computers - Worldwide Coverage': {
    Silver: '$10k',
    Gold: '$10k',
    Platinum: '$15k',
  },
  'Off-Premises Utility Services - Direct Damage': {
    Silver: '$25k',
    Gold: '$25k',
    Platinum: '$25k',
  },
  'Ordinance or Law': {
    Gold: '$50k/$25k',
    Platinum: '$50k/$25k',
  },
  'Outdoor Signs': {
    Silver: 'No sublimit',
    Gold: 'No sublimit',
    Platinum: 'No sublimit',
  },
  'Pairs or Sets': {
    Silver: 'No sublimit',
    Gold: 'No sublimit',
    Platinum: 'No sublimit',
  },
  'Property at Other Premises': {
    Silver: '$25k',
    Gold: '$50k',
    Platinum: '$50k',
  },
  'Salespersons Samples': {
    Silver: '$5k',
    Gold: '$25k',
    Platinum: '$25k',
  },
  'Sewer and Drain Back Up': {
    Silver: 'No sublimit',
    Gold: 'No sublimit',
    Platinum: 'No sublimit',
  },
  'Sump Overflow or Sump Pump Failure': {
    Silver: '$50k',
    Gold: '$50k',
    Platinum: '$50k',
  },
  'Tenant Building and Business Personal Property Coverage - Required by Lease': {
    Silver: '$20k',
    Gold: '$20k',
    Platinum: '$20k',
  },
  'Tenant Glass': {
    Gold: 'No sublimit',
    Platinum: 'No sublimit',
  },
  'Transit Property in the Care of Carriers for Hire': {
    Silver: '$10k',
    Gold: '$25k',
    Platinum: '$25k',
  },
  'Unauthorized Business Card Use': {
    Silver: '$5k',
    Gold: '$5k',
    Platinum: '$5k',
  },
  'Newly Acquired or Constructed Property (Building)': {
    Silver: 'From $250k to $1M',
    Gold: 'From $250k to $1M',
    Platinum: 'From $250k to $1M',
  },
  'Newly Acquired or Constructed Property (BPP)': {
    Silver: 'From $100k to $500k',
    Gold: 'From $100k to $500k',
    Platinum: 'From $100k to $500k',
  },
  'Newly Acquired or Constructed Property (BI)': {
    Silver: '60 days',
    Gold: '120 days',
    Platinum: '180 days',
  },
  'Outdoor Property': {
    Silver: '$25k',
    Gold: '$25k',
    Platinum: '$25k',
  },
  'Personal Effects': {
    Silver: 'From $2.5k to $25k',
    Gold: 'From $2.5k to $60k',
    Platinum: 'From $2.5k to $60k',
  },
  'Property Off-Premises': {
    Silver: 'From $10k to $25k',
    Gold: 'From $10k to $25k',
    Platinum: 'From $10k to $25k',
  },
  'Business Income Daily Limit Options': {
    Silver: '$500 Daily limit or Avg Daily Limit',
    Gold: '$500 Daily limit or Avg Daily Limit',
    Platinum: '$500 Daily limit or Avg Daily Limit',
  },
  'Business Income Extension for Off-Premises Utility Services': {
    Silver: '$50k',
    Gold: '$50k',
    Platinum: '$50k',
  },
  'Business Income Extension for Web Sites': {
    Silver: '$50k',
    Gold: '$50k',
    Platinum: '$50k',
  },
  'Business Income from Dependent Properties': {
    Silver: '$50k',
    Gold: '$50k',
    Platinum: '$50k',
  },
  'Extended Business Income': {
    Silver: '90 days',
    Gold: '12 months',
    Platinum: '12 months',
  },
  'Jewelry and watches': {
    Silver: '$5k',
    Gold: '$5k',
    Platinum: '$5k',
  },
  'Bullion, gold, silver, platinum': {
    Silver: '$25k',
    Gold: 'Specific Limit',
    Platinum: 'Specific Limit',
  },
  'Patterns, Dies, Molds and Forms Theft Payment Changes': {
    Gold: 'Specific Limit',
    Platinum: 'Specific Limit',
  },
  'Commodity Stock': {
    Silver: 'Yes',
    Gold: 'Yes',
    Platinum: 'Yes',
  },
  'Finished Stock': {
    Silver: 'Yes',
    Gold: 'Yes',
    Platinum: 'Yes',
  },
  'Mercantile Stock - Sold': {
    Silver: 'Yes',
    Gold: 'Yes',
    Platinum: 'Yes',
  },
};

export const transformCoveragesForDetails = (coverages, option) => {
  return Object.keys(coverages)
    .filter((coverage) => coverages[coverage][option])
    .map((coverage) => ({
      coverage: coverage,
      limit: coverages[coverage][option],
      package: option,
      coverageToolTip: coverages[coverage].toolTip,
    }));
};
