import { createSelector } from 'reselect';
import { pageOrder, PAGES } from '../constants';

import {
  selectIndustryState,
  selectNumEmployees,
  selectFailFastZip,
  selectPreviousLosses,
  selectLegalBusinessName,
  selectBusinessAgeInMonths,
  selectPolicyTypes,
  selectAnnualPayroll,
  selectGrossAnnualSales,
  selectGLLimit,
  selectMailingAddressStreet,
  selectMailingAddressCity,
  selectMailingAddressState,
  selectMailingAddressZip,
  selectCertificationsRequired,
  selectCertificationsMaintained,
  selectPeriodLoading,
  selectYearsOfPriorActs,
  selectYearsOfProfessionalExperience,
  selectContactEmail,
  selectContactPhone,
  selectContactFirstName,
  selectContactLastName,
  selectLocations,
  selectHiredCoverage,
  selectNonOwnedCoverage,
  selectUserHasViewedSummaryPage,
  selectUserHasViewedGLPage,
  selectUserHasViewedAdditionalInsuredsPage,
  selectUserHasViewedAddOnsPage,
} from '../selectors/application';
import { selectPurchasedPolicies } from '../selectors/index';
import { getAvailableEndorsements } from './availableEndorsements';

const inputCompleted = (value) => value !== undefined && value !== null;

export const getPaymentInterval = (state) => state.paymentInterval;
export const getPartnerFromApplication = (state) => state.partnerFromApplication || {};

export const getIsDropin = (state) => state.dropin.isDropin;

const industryPageComplete = createSelector(
  selectIndustryState,
  (industryState) => industryState !== 0 && inputCompleted(industryState)
);

const businessInfoPageComplete = createSelector(
  selectNumEmployees,
  selectFailFastZip,
  selectPreviousLosses,
  selectLegalBusinessName, //  TODO determine if we need business name and age since we're not using them in the func below
  selectBusinessAgeInMonths,
  (numEmployees, failFastZip, previousLosses) =>
    inputCompleted(numEmployees) && inputCompleted(failFastZip) && failFastZip !== '' && Array.isArray(previousLosses)
);

const policySelectionPageComplete = createSelector(selectPolicyTypes, (applicationTypes) => {
  return applicationTypes && applicationTypes.length > 0;
});

const financeBasicsPageComplete = createSelector(selectAnnualPayroll, selectGrossAnnualSales, (...args) =>
  args.every((value) => inputCompleted(value))
);

export const glValuesComplete = ({ applicationTypes = [], glLimit }) => {
  if (applicationTypes.length === 0) return false;

  if (!applicationTypes.includes('GL') && !applicationTypes.includes('BOP')) {
    return true;
  }

  return Boolean(glLimit);
};

const generalLiabilityPageComplete = createSelector(
  selectPolicyTypes,
  selectGLLimit,
  selectUserHasViewedGLPage,
  // selectBuildingDeductible,
  (
    applicationTypes,
    glLimit,
    glScreenViewed
    // SINGLE_LOC: buildingDeductible removed from BOP array push below
    // buildingDeductible
  ) => {
    if (!applicationTypes) return false;

    if (!applicationTypes.includes('GL') && !applicationTypes.includes('BOP')) {
      return true;
    }

    return glScreenViewed && glValuesComplete({ applicationTypes, glLimit });
  }
);

const mailingAddressComplete = createSelector(
  selectMailingAddressStreet,
  selectMailingAddressCity,
  selectMailingAddressState,
  selectMailingAddressZip,
  (...args) => args.every((value) => inputCompleted(value) && value !== '')
);

const locationsPageComplete = createSelector(selectLocations, selectPolicyTypes, (locations, applicationTypes) => {
  const typesWithoutBuildingLimit = ['CommercialCondoLeased', 'Home', 'BuildingLeased', 'Kiosk', 'Other'];

  if (!applicationTypes) return false;
  if (!locations || !locations.length) return false;

  let complete = true;
  locations.forEach((loc) => {
    if (!loc.street || !loc.city || !loc.state || !loc.zip) {
      complete = false;
    }
    if (
      applicationTypes.includes('BOP') &&
      (!loc.locationType ||
        !loc.bppLimit ||
        (typesWithoutBuildingLimit.includes(loc.locationType) ? false : loc.buildingLimit == null))
    ) {
      complete = false;
    }
  });
  return complete;
});

const professionalLiabilityPageComplete = createSelector(
  selectPolicyTypes,
  selectCertificationsRequired,
  selectCertificationsMaintained,
  selectPeriodLoading,
  selectYearsOfPriorActs,
  selectYearsOfProfessionalExperience,
  (policyTypes, ...args) => {
    if (policyTypes.includes('PL')) {
      return args.every((value) => inputCompleted(value));
    } else {
      return true;
    }
  }
);

// usesAutosQuestionsComplete is not being used but future work could include this so keeping this selector in the codebase
// eslint-disable-next-line
const usesAutosQuestionsComplete = createSelector(
  selectPolicyTypes,
  selectHiredCoverage,
  selectNonOwnedCoverage,
  (policyTypes, ...args) => {
    if (policyTypes.includes('BOP')) {
      return args.every((value) => inputCompleted(value));
    } else {
      return true;
    }
  }
);

const additionalInsuredsPageComplete = createSelector(
  selectUserHasViewedAdditionalInsuredsPage,
  (additionalInsuredsViewed) => additionalInsuredsViewed
);

const contactPageComplete = createSelector(
  mailingAddressComplete,
  selectContactEmail,
  selectContactPhone,
  selectContactFirstName,
  selectContactLastName,
  (mailingAddress, ...args) => mailingAddress && args.every((value) => inputCompleted(value) && value !== '')
);

const addonsPageComplete = createSelector(
  getAvailableEndorsements,
  selectUserHasViewedAddOnsPage,
  (availableEndorsements, hasViewedAddonsPage) => {
    if (availableEndorsements != null && !hasViewedAddonsPage) {
      return false;
    } else {
      return true;
    }
  }
);

const summaryPageComplete = createSelector(selectUserHasViewedSummaryPage, (summaryPageViewed) => summaryPageViewed);

const paymentPageComplete = createSelector(selectPurchasedPolicies, (purchasedPolicy) => false);

export const nextPageToShow = createSelector(
  selectPolicyTypes,
  industryPageComplete,
  businessInfoPageComplete,
  policySelectionPageComplete,
  financeBasicsPageComplete,
  locationsPageComplete,
  generalLiabilityPageComplete,
  professionalLiabilityPageComplete,
  additionalInsuredsPageComplete,
  contactPageComplete,
  addonsPageComplete,
  summaryPageComplete,
  paymentPageComplete,
  (applicationTypes, ...pages) => {
    const pageIndex = pages.findIndex((page) => page !== true);
    const nextPage = pageOrder[pageIndex];
    // TODO why are we checking this:
    if (applicationTypes && !applicationTypes.includes('BOP') && nextPage === PAGES.SUMMARY) {
      return PAGES.SUMMARY;
    }

    if (!nextPage) {
      return PAGES.QUOTEHELP;
    }
    return nextPage;
  }
);

export const selectCompletedPages = createSelector(nextPageToShow, (nextPage) => {
  const furthestIndex = pageOrder.indexOf(nextPage);

  const completedPageArray = pageOrder.filter((page, index) => index <= furthestIndex);
  return completedPageArray;
});

export const pagesWithUserInfo = createSelector(nextPageToShow, (nextPage) => {
  const furthestIndex = pageOrder.indexOf(nextPage);

  const pagesWithUserInfoArray = pageOrder.filter((page, index) => index < furthestIndex);
  return pagesWithUserInfoArray;
});

export const getLocation = (state) => {
  return state?.location;
};

export const getCurrentPage = createSelector(getLocation, (location) => location?.type);
