import { Dictionary } from 'lodash';

export const AVAILABLE_ENDORSEMENTS_ACTIONS = {
  SET_IS_LOADING: 'SET_IS_LOADING',
  SET_AVAILABLE_ENDORSEMENTS: 'SET_AVAILABLE_ENDORSEMENTS',
  CLEAR_AVAILABLE_ENDORSEMENTS: 'CLEAR_AVAILABLE_ENDORSEMENTS',
};

export const setIsLoading = (value: boolean) => ({
  type: AVAILABLE_ENDORSEMENTS_ACTIONS.SET_IS_LOADING,
  data: value,
});

export const setAvailableEndorsements = (endorsements: Dictionary<any> | null) => ({
  type: AVAILABLE_ENDORSEMENTS_ACTIONS.SET_AVAILABLE_ENDORSEMENTS,
  data: endorsements,
});

export const clearAvailableEndorsements = () => ({
  type: AVAILABLE_ENDORSEMENTS_ACTIONS.CLEAR_AVAILABLE_ENDORSEMENTS,
});
