import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { NumericFormatProps } from 'react-number-format';
import { useMediaQuery } from 'react-responsive';
import PolicyItem from './PolicyItem';
import { SummaryInputErrorMsg, SummaryNumberInput } from './styles';
import MultiRow from '../../Shared/MultiRow';
import Spacer from '../../Shared/Spacer';
import {
  POLICY_OPTIONS,
  PROPERTY_LABELS,
  TOOL_TIP_CONTENT_KEY,
  VALUE_OPTIONS,
  POLICY_ARIA_LABELS,
  INPUT_ERROR_MSG,
  PROPERTY_LABEL_SUBTITLES,
} from '../../../constants';
import { selectLiquorLiability, selectTwoFourLimitsAvailable } from '../../../selectors/application';

const PolicySummary = ({
  policyType,
  policyValue,
  inputValue,
  selectedPolicies = [],
  expanded,
  setExpanded,
  updateValue,
  inputType,
  isDisabled,
  errorMessage,
  isReadOnly = false,
  isFlorida = false,
  hasLiquorLiability,
  twoFourLimitsAvailable,
}: {
  policyType: string;
  policyValue: number;
  inputValue: number;
  selectedPolicies: string[];
  expanded: string;
  setExpanded: (...args: any[]) => any;
  updateValue: (option: NumericFormatProps) => void;
  inputType: string;
  isDisabled: boolean;
  errorMessage?: { type: string };
  isReadOnly?: boolean;
  isFlorida?: boolean;
  hasLiquorLiability: boolean;
  twoFourLimitsAvailable?: boolean;
}) => {
  const isMobile = useMediaQuery({ maxWidth: 850 });

  const BOPselected = selectedPolicies.includes('BOP');
  const GLselected = selectedPolicies.includes('GL');
  const PLselected = selectedPolicies.includes('PL');

  const shouldRender = () => {
    switch (policyType) {
      case POLICY_OPTIONS.BUSINESS_PERSONAL_PROPERTY_LIMIT:
      case POLICY_OPTIONS.BUILDING_LIMIT:
      case POLICY_OPTIONS.PROPERTY_CLAIMS_DEDUCTIBLE:
      case POLICY_OPTIONS.GENERAL_LIABILITY_LIMIT:
      case POLICY_OPTIONS.PROPERTY_DAMAGE_DEDUCTIBLE:
        return BOPselected || GLselected;
      case POLICY_OPTIONS.WIND_HAIL_DEDUCTIBLE:
        return BOPselected && isFlorida;
      case POLICY_OPTIONS.PROFESSIONAL_LIABILITY_LIMIT:
      case POLICY_OPTIONS.DEDUCTIBLE:
        return PLselected;
      default:
        return false;
    }
  };

  const RenderErrorMessage = () =>
    errorMessage ? (
      <SummaryInputErrorMsg>{INPUT_ERROR_MSG[errorMessage.type]}</SummaryInputErrorMsg>
    ) : (
      <Spacer height={32} />
    );

  const valueOptions = (policyType: string) => {
    if (policyType === POLICY_OPTIONS.PROPERTY_CLAIMS_DEDUCTIBLE && isFlorida) {
      return VALUE_OPTIONS['PROPERTY_CLAIMS_DEDUCTIBLE_FL'];
    }
    return VALUE_OPTIONS[policyType];
  };

  const policyTypeIsGLLimit = policyType === POLICY_OPTIONS.GENERAL_LIABILITY_LIMIT;
  const isTwoFourLimitDisabled = !twoFourLimitsAvailable || hasLiquorLiability;

  const disabledOptions = (policyType: string) => {
    if (policyType === POLICY_OPTIONS.GENERAL_LIABILITY_LIMIT && isTwoFourLimitDisabled) {
      return [2_000_000];
    }
    return [];
  }

  let twoFourUnavailableText;
  if (policyTypeIsGLLimit) {
    if (!twoFourLimitsAvailable) {
      twoFourUnavailableText = isMobile
        ? '$2M limit is unavailable for selected industry and location'
        : 'Unavailable for selected industry and location';
    } else if (hasLiquorLiability) {
      twoFourUnavailableText = isMobile
        ? '$2M limit is unavailable when Liquor Liability is added'
        : 'Unavailable with Liquor Liability';
    }
  }

  return shouldRender() ? (
    <PolicyItem
      policyType={policyType}
      propertyName={PROPERTY_LABELS[policyType]}
      propertyValue={policyValue}
      isReadOnly={isReadOnly}
      toolTipContentKey={TOOL_TIP_CONTENT_KEY[policyType]}
      expanded={expanded === PROPERTY_LABELS[policyType]}
      setExpanded={setExpanded}
      testId={policyType}
      labelSubtitle={PROPERTY_LABEL_SUBTITLES[policyType]}
      isFlorida={isFlorida}
    >
      {(() => {
        if (isReadOnly) return <></>;

        if (inputType === 'text') {
          return (
            <>
              <SummaryNumberInput
                value={inputValue != null ? inputValue : policyValue}
                onValueChange={updateValue}
                customWidth="quarter"
                marginBottom="none"
                thousandSeparator={true}
                prefix='$'
                aria-label={POLICY_ARIA_LABELS[policyType]}
                data-cy={POLICY_ARIA_LABELS[policyType]}
                disabled={isDisabled}
                hasError={Boolean(errorMessage)}
              />
              <RenderErrorMessage />
            </>
          );
        }
        return (
          <>
            <MultiRow
              propertyName={PROPERTY_LABELS[policyType]}
              money={true}
              options={valueOptions(policyType)}
              disabledOptions={disabledOptions(policyType)}
              value={policyValue}
              updateValue={updateValue}
              label={POLICY_ARIA_LABELS[policyType]}
              summary={true}
            />
            {policyTypeIsGLLimit && twoFourUnavailableText && <Message>{twoFourUnavailableText}</Message>}
          </>
        );
      })()}
    </PolicyItem>
  ) : null;
};

const Message = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #626266;
  align-self: flex-end;
  text-align: center;
  padding: 0 25px 0 0 !important;
  margin: -8px 0 16px;
  max-width: 136px;
  @media (max-width: 850px) {
    min-width: 100%;
    padding: 0 !important;
    margin: -12px 0 16px;
  }
`;

const mapStateToProps = (state: ReduxState) => ({
  hasLiquorLiability: Boolean(selectLiquorLiability(state)),
  twoFourLimitsAvailable: selectTwoFourLimitsAvailable(state),
});

export default connect(mapStateToProps)(PolicySummary);
