import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { white_label_partners } from '../helpers/whitelabel';
import { isProd } from '../helpers/environment';
import { setChannelPartnerGuidDropIn } from './dropIn';

const handleWhiteLabelDev = async (
  dispatch: ThunkDispatch<ReduxState, unknown, AnyAction>,
  getState: () => ReduxState
) => {
  const { channelPartnerName } = getState().location.payload;
  const channelPartnerGUID = white_label_partners[channelPartnerName];
  const hasMatchingGUID = typeof channelPartnerGUID === 'string' && channelPartnerName != null;

  if (hasMatchingGUID && !isProd()) {
    dispatch(setChannelPartnerGuidDropIn(channelPartnerGUID));
  } else {
    dispatch(setChannelPartnerGuidDropIn(null));
  }
};

export default handleWhiteLabelDev;
