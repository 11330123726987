import styled from 'styled-components';
import Select, { GroupBase, StylesConfig } from 'react-select';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { TextButton } from '../..';
import theme from '../../theme';

interface ExtendedDatePickerProps extends ReactDatePickerProps {
  fullWidth: boolean;
}

interface StyleTypes {
  isFocused: boolean;
  isSelected: boolean;
}

export const StyledSelect = styled(Select)<{ onChange: any; isMonth?: boolean }>`
  width: 100%;
  border-radius: 4px;
  margin-bottom: 18px;
`;

export const DatePickerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 8px 0 22px;

  @media (max-width: 1024px) {
    justify-content: start;
  }

  .react-datepicker {
    display: flex;
    flex-direction: column;
    width: 290px;
    border: ${(props) => props.theme.selectors.datePicker.border};
  }

  .react-datepicker-popper {
    z-index: 4;
  }

  .react-datepicker__month-container {
    padding: 0 1em 1em 1em;
  }

  .react-datepicker__week {
    display: flex;
  }
  .react-datepicker__month,
  .react-datepicker__day,
  .react-datepicker__day-name,
  .react-datepicker__day-name {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .react-datepicker__day,
  .react-datepicker__day-name {
    margin: 4px;
    width: 30px;
    height: 30px;
    font-size: 16px;
    font-weight: 500;
  }

  .react-datepicker__day:hover {
    background-color: ${(props) => props.theme.selectors.datePicker.backgroundOnHover};
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--selected:hover {
    background-color: ${(props) => props.theme.selectors.datePicker.selectionBackgroundOnActive};
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--keyboard-selected:hover,
  .react-datepicker__day-name {
    background-color: ${(props) => props.theme.selectors.datePicker.backgroundOnHover};
    color: ${(props) => props.theme.selectors.datePicker.textColor};
  }

  .react-datepicker__header {
    background: transparent;
    border: none;
  }

  .react-datepicker__day-names {
    display: flex;
    justify-content: center;
  }

  .react-datepicker__day-name {
    font-size: 16px;
    border-radius: 5px;
  }
  .react-datepicker__triangle {
    display: none;
  }
`;

export const StyledDatePicker = styled(ReactDatePicker)`
  background: ${(props) => props.theme.selectors.datePicker.background};
  border-radius: 4px;
  padding: 8px 12px;
  width: ${(props: ExtendedDatePickerProps) => (props.fullWidth ? '100%' : 'auto')};
  height: 46px;
  border: ${(props) => props.theme.formElements.input.border};
  box-sizing: border-box;
  padding-left: 50px;
  font-family: ${({ theme }) => theme.font.typeface.primary};
  font-size: ${({ theme }) => theme.formElements.input.fontSize};

  @media (max-width: 767px) {
    border: ${(props) => props.theme.formElements.input.border};
  }
`;

export const CalenderButton = styled.button`
  position: absolute;
  left: 10px;
  z-index: 2;
  display: flex;
  border: none;
  background: none;
  padding: 5px;
`;

export const CalenderIcon = styled.img`
  width: 17px;
  height: 16px;
`;

export const ChildrenContainer = styled.div`
  display: flex;
  padding: 0.5em 0.5em 2em 0.5em;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const CancelButton = styled(TextButton)`
  margin: 1em 0 0 0;
`;

export const CustomHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1em.5em;
`;

interface OptionType {
  value: string | number;
  label: string;
}

export const selectStyles: StylesConfig<OptionType | unknown, boolean, GroupBase<OptionType | unknown>> = {
  container: (styles: any) => {
    return {
      ...styles,
      margin: 0,
    };
  },
  control: (styles: any, state: any) => {
    const justifyContent = state.selectProps.isMonth ? 'flex-start' : 'flex-end';
    return {
      borderRadius: '4px',
      fontFamily: theme.font.typeface.primary,
      fontSize: '20px',
      letterSpacing: '0.2px',
      lineHeight: '15px',
      border: 'none',
      backgroundColor: 'transparent',
      display: 'flex',
      justifyContent: justifyContent,
      alignItems: 'center',
      color: state.isFocused ? theme.selectors.datePicker.textColorOnSelect : theme.selectors.datePicker.textColor,
      fontWeight: '600',

      ':hover': {
        ...styles[':hover'],
        borderColor: state.isFocused ? theme.formElements.input.borderOnHover : null,
      },
    };
  },
  input: (styles: any) => {
    return {
      ...styles,
      fontFamily: theme.font.typeface.secondary,
      fontSize: '14px',
    };
  },
  option: (styles: any, { isFocused, isSelected }: StyleTypes) => {
    return {
      ...styles,
      backgroundColor: isFocused ? theme.formElements.dropdown.backgroundSelected : null,
      color: isSelected ? theme.formElements.input.textColor : null,
      cursor: 'pointer',
      padding: '1em 1em 1em 1.5em;',
      textAlign: 'left',
      zIndex: 3,

      ':active': {
        ...styles[':active'],
        backgroundColor: theme.formElements.dropdown.backgroundSelected,
      },
    };
  },
  singleValue: () => {
    return {
      width: '100%',
      textAlign: 'right',
      textOverflow: 'none',
      marginRight: '0',
    };
  },
  indicatorsContainer: () => {
    return {
      //remove default styles
    };
  },
  dropdownIndicator: () => {
    return {
      color: theme.selectors.datePicker.textColorOnSelect,
      marginLeft: '.5em',
    };
  },
  valueContainer: () => {
    return {
      display: 'flex',
      alignItems: 'center',
    };
  },
};
