import React from 'react';
import styled from 'styled-components';
import { GhostButtonDarkTheme } from '../../elements';

const CtaStyledBackButton = styled(GhostButtonDarkTheme)`
  margin: 0;
`;

interface ButtonProps {
  disabled?: boolean;
}

const PreviousPageButton = ({ disabled }: ButtonProps) => {
  return (
    <CtaStyledBackButton
      data-cy="previous-button"
      onClick={() => window.history.back()}
      full={false}
      disabled={disabled}
    >
      Back
    </CtaStyledBackButton>
  );
};

export default PreviousPageButton;
