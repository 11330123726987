import React, { useState } from 'react';
import { connect } from 'react-redux';
import { emailApplicationLink } from '../../api';
import { Input, InputLabel, Form, InputBox, Paragraph, TextButton } from '../../elements';
import Modal, { SuccessMessage } from '../../elements/Modal';
import { selectContactEmail, selectContactFirstName } from '../../selectors/application';
import { selectMonthlyPremium } from '../../selectors/quote';
import { formatCurrency } from '../../helpers/formatNumber';
import styled from 'styled-components';
import GhostButton from './GhostButton';
import { selectChannelPartnerGuid, selectPartnerAgencyExternalId } from '../../selectors';
import { selectIsAgentMDA } from '../../selectors/agent';

export const buttonText = 'Save for later';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

const StyledInputBox = styled(InputBox)`
  max-width: 49%;

  @media (max-width: 767px) {
    max-width: 100%;
  }
`;

const StyledForm = styled(Form)`
  margin: 24px 0 0;
`;

const SaveButton = styled(TextButton)<{ shouldUseAltColor: boolean; theme?: ThemeInterface }>`
  color: ${({ theme, shouldUseAltColor }) =>
    shouldUseAltColor ? theme.buttons.textOnly.darkTheme.textColor : theme.buttons.textOnly.textColor} !important;
`;

interface SaveApplicationButtonProps {
  shouldUseAltColor: boolean;
  applicationUrl?: string;
  contactEmail?: string;
  contactFirstName?: string;
  monthlyPremiumQuote: number;
  agencyExternalId?: any;
  channelPartnerGuid?: string;
  isAgentMDA?: boolean;
}

const SaveApplicationButton = ({
  shouldUseAltColor,
  applicationUrl,
  contactEmail,
  contactFirstName,
  monthlyPremiumQuote,
  agencyExternalId,
  channelPartnerGuid,
  isAgentMDA,
}: SaveApplicationButtonProps) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isSuccess, setSuccess] = useState(false);

  const [userEmail, setUserEmail] = useState(contactEmail);
  const [userFirstName, setUserFirstName] = useState(contactFirstName);

  const [validForm, setValidForm] = useState(contactEmail);

  const handleEmailInputChange = (value: string) => {
    setUserEmail(value);
    setValidForm(value);
  };

  const quote = typeof monthlyPremiumQuote === 'number' ? formatCurrency(monthlyPremiumQuote) : null;

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const emailLink = async (e: React.FormEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);

    try {
      await emailApplicationLink({
        email: userEmail,
        contactFirstName: userFirstName,
        quote,
        applicationUrl,
        agencyExternalId,
        channelPartnerGuid,
      });
      setSuccess(true);
      setLoading(false);
      await new Promise((resolve) => setTimeout(resolve, 2500));
      setModalIsOpen(false);
      setSuccess(false);
    } catch (error) {
      console.log('Error sending application', error);
    }
  };

  if (!applicationUrl || isAgentMDA) return null;

  return (
    <>
      <SaveButton onClick={openModal} data-cy="app-save-button" shouldUseAltColor={shouldUseAltColor}>
        {buttonText}
      </SaveButton>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        title="Save for later"
        label="Save Application modal"
        id="app-save-modal"
        cancelButtonText="Cancel"
        buttonOnClick={emailLink}
        buttonCyLabel="app-save-modal-submit-button"
        buttonIsDisabled={!validForm}
        buttonText="Send email"
        buttonsAreHidden={isSuccess}
      >
        {isSuccess ? (
          <SuccessMessage>Application saved and email has been sent!</SuccessMessage>
        ) : (
          <Paragraph>
            Please enter your information and we will email you a link to the application so that you can come back to
            it anytime.
          </Paragraph>
        )}
        {!isSuccess && (
          <StyledForm onSubmit={emailLink}>
            <Row>
              <StyledInputBox>
                <InputLabel htmlFor="first-name">First name: </InputLabel>
                <Input
                  customWidth="full"
                  type="text"
                  name="first-name"
                  aria-label="first name"
                  id="first-name"
                  value={userFirstName}
                  disabled={isLoading}
                  data-cy="app-save-modal-first-name"
                  onChange={(e) => setUserFirstName(e.target.value)}
                />
              </StyledInputBox>
              <StyledInputBox>
                <InputLabel htmlFor="email">Email address: </InputLabel>
                <Input
                  customWidth="full"
                  type="email"
                  name="email"
                  aria-label="email"
                  id="email"
                  value={userEmail}
                  disabled={isLoading}
                  required
                  aria-required
                  data-cy="app-save-modal-email"
                  onChange={(e) => handleEmailInputChange(e.target.value)}
                />
              </StyledInputBox>
            </Row>
            <GhostButton isDisabled={!validForm} />
          </StyledForm>
        )}
      </Modal>
    </>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  applicationUrl: state?.application?.applicationUrl,
  contactEmail: selectContactEmail(state),
  contactFirstName: selectContactFirstName(state),
  monthlyPremiumQuote: selectMonthlyPremium(state),
  agencyExternalId: selectPartnerAgencyExternalId(state),
  channelPartnerGuid: selectChannelPartnerGuid(state),
  isAgentMDA: selectIsAgentMDA(state),
});

export default connect(mapStateToProps)(SaveApplicationButton);
