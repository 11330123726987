import React from 'react';
import styled from 'styled-components';
import Select, { GroupBase, StylesConfig } from 'react-select';
import { useThemeContext } from '../elements/theme/CustomThemeProvider';

interface PropTypes {
  onChange: (selectedOption: any) => void;
  options: OptionType[];
  value: string | number;
  full?: boolean;
  name?: string;
  ariaLabel?: string;
  menuHeight?: number;
  isDisabled?: boolean;
  hasError?: boolean;
}

interface StyleTypes {
  isFocused: boolean;
  isSelected?: boolean;
}

interface OptionType {
  value: string | number;
  label: string;
}

const Dropdown = ({
  onChange,
  options,
  value,
  full,
  name,
  ariaLabel,
  isDisabled,
  menuHeight = 175,
  hasError,
}: PropTypes) => {
  const { theme } = useThemeContext();
  const selected = options.find((option) => option.value === value);

  const colorStyles: StylesConfig<OptionType | unknown, boolean, GroupBase<OptionType | unknown>> = {
    container: (styles: any) => {
      return {
        ...styles,
        padding: '8px 0',
        margin: 0,
      };
    },
    control: (styles: any, { isFocused }: StyleTypes) => {
      return {
        ...styles,
        boxShadow: isFocused ? theme.formElements.input.boxShadowOnFocus : null,
        minHeight: '48px',
        backgroundColor: theme.formElements.input.background,
        border: hasError ? theme.formElements.input.borderOnError : theme.formElements.input.border,
        borderRadius: '4px',
        fontFamily: theme.font.typeface.secondary,
        fontSize: theme.formElements.input.fontSize,
        letterSpacing: '0.2px',
        lineHeight: '15px',

        ':hover': {
          ...styles[':hover'],
          borderColor: isFocused ? theme.formElements.input.borderOnHover : null,
        },
      };
    },
    input: (styles: any) => {
      return {
        ...styles,
        fontFamily: theme.font.typeface.secondary,
        fontSize: '14px',
      };
    },
    option: (styles: any, { isFocused, isSelected }: StyleTypes) => {
      return {
        ...styles,
        backgroundColor: isFocused ? theme.formElements.dropdown.backgroundSelected : null,
        color: isSelected ? theme.formElements.input.textColor : null,
        cursor: 'pointer',
        minHeight: '46px',
        zIndex: 3,

        ':active': {
          ...styles[':active'],
          backgroundColor: theme.formElements.dropdown.backgroundSelected,
        },
      };
    },
    dropdownIndicator: (styles: any) => {
      return { ...styles, color: theme.formElements.dropdown.dropdownIndicator };
    },
  };

  return (
    <StyledSelect
      onChange={onChange}
      options={options}
      styles={colorStyles}
      value={selected}
      full={full}
      name={name}
      aria-label={ariaLabel}
      maxMenuHeight={menuHeight}
      className={`${ariaLabel}-dropdown`}
      isDisabled={isDisabled}
      components={{
        IndicatorSeparator: () => null,
      }}
    />
  );
};

export const StyledSelect = styled(Select)<{ full?: boolean }>`
  width: ${(props) => (props.full ? '100%' : '264px')};
  border-radius: 4px;
  margin-bottom: 18px;
  @media (min-width: 768px) and (max-width: 1024px) {
    width: 100%;
  }
`;

export default Dropdown;
