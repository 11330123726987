import React from 'react';
import styled from 'styled-components';
import { LabelLarge, PrimaryButton } from '../elements';
import { connect } from 'react-redux';
import { clearAllData } from '../actions/application';
import { quoteHelpCopy } from '../helpers/quoteHelpCopy';
import { isLocal } from '../helpers/environment';
import { selectDeclinationMessages } from '../selectors';
import { ReactComponent as AlertIcon } from '../icons/alert.svg';

interface QuoteHelpProps {
  error?: string;
  clearAllData: () => void;
  declinationMessages: string[];
}

const QuoteHelp = ({ error, clearAllData, declinationMessages }: QuoteHelpProps) => {
  if (isLocal()) {
    console.log('error:', error);
  }

  return (
    <HelpCont className="CONT">
      <WarningIcon />
      <Title>{quoteHelpCopy(error)}</Title>
      {declinationMessages.length > 0 && declinationMessages.map((d) => <DeclinationMessage>{d}</DeclinationMessage>)}
      <MoreInfo center>
        For more information about our appetite and coverages, please visit{' '}
        <a href="https://coterieinsurance.com/producer-resources/">Agent Resources</a>.
      </MoreInfo>
      <StyledButton onClick={() => clearAllData()}>Reset application</StyledButton>
      <CenteredLabel>You may need to refresh the page after hitting the reset button.</CenteredLabel>
    </HelpCont>
  );
};

const HelpCont = styled.div`
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
`;

const Title = styled.h1`
  color: ${({ theme }) => theme.pageComponents.quoteHelp.title.textColor};
  font-family: ${({ theme }) => theme.font.typeface.primary};
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 30px;
  text-align: center;
`;

const CenteredLabel = styled.p`
  color: ${({ theme }) => theme.pageComponents.quoteHelp.centeredLabel.textColor};
  font-family: ${({ theme }) => theme.font.typeface.secondary};
  font-size: ${({ theme }) => theme.pageComponents.quoteHelp.centeredLabel.fontSize};
  letter-spacing: 0.27px;
  line-height: 20px;
  text-align: center;
  margin: 30px 0;
`;

const MoreInfo = styled(LabelLarge)`
  color: ${({ theme }) => theme.pageComponents.quoteHelp.phoneNumber.textColor};
  font-size: 18px;
  margin: 40px 0 40px 0;
`;

const StyledButton = styled(PrimaryButton)`
  margin: 10px auto;
`;

const DeclinationMessage = styled.p`
  margin-left: auto;
  margin-right: auto;
  background-color: ${({ theme }) => theme.pageComponents.errorModal.background};
  color: ${({ theme }) => theme.pageComponents.errorModal.textColor};
  font-size: 15px;
  font-weight: 600;
  padding: 6px 20px;
  border-radius: 4px;
  width: fit-content;
`;

const WarningIcon = styled(AlertIcon)`
  margin-top: 100px;
  width: 48px;
  height: 48px;
  margin-bottom: 10px;

  g {
    fill: ${(props) => props.theme.pageComponents.errorModal.textColor};
  }
`;

const mapStateToProps = (state: ReduxState) => ({
  error: state.fatalError,
  declinationMessages: selectDeclinationMessages(state),
});

const mapDispatchToProps = { clearAllData };

export default connect(mapStateToProps, mapDispatchToProps)(QuoteHelp);
