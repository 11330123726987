import React, { Fragment, useState } from 'react';
import styled, { css } from 'styled-components';
import CreateRow from '../CreateRow';
import { LinkButton as Button, Table } from '../../../elements';

interface MoreDetailsTableProps {
  rows: PackageDetails[];
  multiple?: boolean;
  hideTableHeader?: boolean;
}

interface PageIndicatorProps {
  current: boolean;
}

interface PageIndicatorsProps {
  onSecondPage: boolean;
}

interface PackageDetails {
  coverage: string;
  coverageToolTip?: string;
  limit: string;
  package: string;
  deductible?: string;
}

const PageIndicators = ({ onSecondPage }: PageIndicatorsProps) => (
  <PageIndicatorCont>
    <PageIndicator current={!onSecondPage} />
    <PageIndicator current={onSecondPage} />
  </PageIndicatorCont>
);

const MoreDetailsTable = ({ rows, multiple = false, hideTableHeader = false }: MoreDetailsTableProps) => {
  const firstPageRows = multiple ? rows.slice(0, 9) : rows;
  const secondPageRows = rows.slice(9);

  const hasSecondPage = multiple && secondPageRows.length > 0;
  const [onSecondPage, setOnSecondPage] = useState(false);

  const goToSecondPage = () => {
    setOnSecondPage(true);
  };

  const goToFirstPage = () => {
    setOnSecondPage(false);
  };

  return (
    <Fragment>
      <Table>
        {!hideTableHeader && (
          <thead>
            <tr>
              <th>COVERAGE</th>
              {rows.some((r) => r.limit) && <th>LIMIT</th>}
              {rows.some((r) => r.deductible) && <th>DEDUCTIBLE</th>}
            </tr>
          </thead>
        )}
        <tbody>
          {!onSecondPage && firstPageRows.map((items) => <CreateRow key={items.coverage} {...items} />)}
          {hasSecondPage &&
            onSecondPage &&
            secondPageRows.map((items) => <CreateRow key={items.coverage} {...items} />)}
        </tbody>
      </Table>
      {hasSecondPage && (
        <PaginationContainer>
          <ButtonCont>
            {onSecondPage && (
              <Button onClick={goToFirstPage} data-cy="more-details-prev-page">
                &#8592; Back
              </Button>
            )}
          </ButtonCont>
          <PageIndicators onSecondPage={onSecondPage} />
          <ButtonCont>
            {!onSecondPage && (
              <Button onClick={goToSecondPage} data-cy="more-details-next-page">
                Next &#8594;
              </Button>
            )}
          </ButtonCont>
        </PaginationContainer>
      )}
    </Fragment>
  );
};

export default MoreDetailsTable;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 15px 10px;
`;

const ButtonCont = styled.div`
  width: 60px;
`;

const PageIndicatorCont = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PageIndicator = styled.div<PageIndicatorProps>`
  margin: 0 5px;
  border-radius: 50px;
  ${(props) =>
    props.current
      ? css`
          box-sizing: border-box;
          height: 12px;
          width: 12px;
          border: 1px solid ${({ theme }) => theme.PageIndicator.current.border};
          background-color: ${({ theme }) => theme.PageIndicator.current.background};
        `
      : css`
          height: 8px;
          width: 8px;
          background-color: ${({ theme }) => theme.PageIndicator.notCurrent.background};
          align-self: center;
        `}
`;
