import { connect } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import React, { createContext, useState, useContext, useCallback } from 'react';
import { selectCustomStyles, selectDropInChannelPartnerGUID } from '../../selectors';
import createDefaultWhiteLabelTheme from './defaultWhiteLabel';
import { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { themeDetails } from './whiteLabelConstants';
import { ThemeState, CustomThemeProviderProps } from './types';
import { white_label_partners } from '../../helpers/whitelabel';

const defaultState = themeDetails['coterie'];

export const ThemeContext = createContext<ThemeState>(defaultState);
export const useThemeContext = () => useContext(ThemeContext);

const CustomThemeProvider = ({ children, customStyles, dropInChannelPartnerGUID }: CustomThemeProviderProps) => {
  const [state, setState] = useState<ThemeState>(defaultState);

  const mapCustomColors = useCallback(() => {
    return {
      primary: {
        zero: customStyles.primary,
        twentyFive: customStyles.primaryOne,
      },
      secondary: {
        zero: customStyles.secondary,
      },
    };
  }, [customStyles]);

  const updatedTheme = useCallback(
    (state) => {
      const hasWhiteLabelTheme = Object.values(white_label_partners).includes(dropInChannelPartnerGUID);
      const updatedTheme =
        hasWhiteLabelTheme && state.updateTheme
          ? state.updateTheme(mapCustomColors())
          : createDefaultWhiteLabelTheme(mapCustomColors());

      return { theme: updatedTheme };
    },
    [dropInChannelPartnerGUID, mapCustomColors]
  );

  const updatedLogo = useCallback(() => {
    if (customStyles.logoSrc) {
      return { logo: { ...themeDetails['defaultWhiteLabel'].logo, imgSrc: customStyles.logoSrc } };
    }
  }, [customStyles]);

  const activeThemeContext = useCallback(() => {
    if (dropInChannelPartnerGUID && themeDetails[dropInChannelPartnerGUID]) {
      return themeDetails[dropInChannelPartnerGUID];
    } else if (!dropInChannelPartnerGUID && !isEmpty(customStyles)) {
      return themeDetails['defaultWhiteLabel'];
    } else {
      return themeDetails['coterie'];
    }
  }, [customStyles, dropInChannelPartnerGUID]);

  const updateCustomTheme = useCallback(() => {
    const updatedActiveThemeContext = activeThemeContext();
    setState((state) => ({
      ...updatedActiveThemeContext,
      ...updatedTheme(state),
      ...updatedLogo(),
    }));
  }, [activeThemeContext, updatedTheme, updatedLogo]);

  useEffect(() => {
    setState(activeThemeContext());
  }, [dropInChannelPartnerGUID, activeThemeContext]);

  useEffect(() => {
    if (!isEmpty(customStyles)) {
      updateCustomTheme();
    }
  }, [customStyles, updateCustomTheme]);

  return (
    <ThemeContext.Provider
      value={{
        theme: state.theme,
        shouldRenderIcon: state.shouldRenderIcon,
        shouldRenderHubSpotWidget: state.shouldRenderHubSpotWidget,
        logo: state.logo,
        coveragePackageIcons: state.coveragePackageIcons,
      }}
    >
      <ThemeProvider theme={state.theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  customStyles: selectCustomStyles(state),
  dropInChannelPartnerGUID: selectDropInChannelPartnerGUID(state),
});

export default connect(mapStateToProps)(CustomThemeProvider);
