const inputCompleted = (value) => value !== undefined && value !== null && value !== '';

// Takes in the application and it only returns completed locations
const locationsCheck = (application) => {
  const { locations } = application;

  if (locations && locations.length) {
    const completeLocations = locations.filter(({ street, city, state, zip }) =>
      [street, city, state, zip].every((value) => inputCompleted(value))
    );
    return completeLocations;
  }
  return locations;
};

export default locationsCheck;
