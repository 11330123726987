import styled from 'styled-components';

interface PropTypes {
  height?: number;
}

const Spacer = styled.div<PropTypes>`
  height: ${(props) => `${props.height || 50}px`};
  width: 100%;
`;

export default Spacer;
