import React from 'react';
import { Dictionary } from 'lodash';
import { connect } from 'react-redux';
import { selectDropInChannelPartnerGUID } from '../selectors';

type testFunc = (toggleDict?: Dictionary<any>) => string | null | undefined;

/**
 *
 * @param {string | function} test - toggle string index or function that accepts the toggle dictionary as it's argument and returns the string index used for the component map.
 * @param map - map of components to be rendered.
 * @param FallBackComponent - fallback component that is rendered if the the test argument is nullish or returns a nullish value.
 * @returns React Component
 */
export const ComponentMap = (
  test: testFunc | string,
  map: { [key: string]: React.ComponentType<any> },
  FallBackComponent: React.ComponentType<any> | null
) => {
  const mapStateToProps = (state: ReduxState) => ({
    channelPartnerGUID: selectDropInChannelPartnerGUID(state),
  });

  const WithGuidComponent = (props: any) => {
    const testCase = typeof test === 'function' ? test(props) : props?.[test];
    const fallBack = FallBackComponent ? <FallBackComponent {...props} /> : null;
    const Component = map[testCase];

    if (testCase == null || Component == null) return fallBack;

    return <Component {...props} />;
  };

  return connect(mapStateToProps)(WithGuidComponent);
};
