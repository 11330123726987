import { Middleware } from 'redux';
import { setMDALeadEmailSent } from '../actions/agent';
import { BINDABLE_QUOTE_RECEIVED } from '../actions/application';
import { mdaLead } from '../api';
import { selectIsAgentMDA, selectMDALeadEmailSent } from '../selectors/agent';
import { selectIsEstimate, selectQuoteId } from '../selectors/quote';

// The sendMDALeadEmail middleware is responsible for calling the POST mda/lead endpoint
// this endpoint should only get called initially when a user is from MDA and the quote becomes bindable
// from that point forward, the endpoint should only ever get called again if the user updates their contact email on the contact page
// (https://coterieinsurance.atlassian.net/browse/QV2-399)
const sendMDALeadEmail: Middleware<{}, ReduxState> = (store) => (next) => (action) => {
  const result = next(action);
  const state = store.getState();
  const quoteExternalId = selectQuoteId(state);
  const isMDA = selectIsAgentMDA(state);
  const isEstimate = selectIsEstimate(state);

  // don't call the send MDA lead endpoint, if quote is not bindable or not a my digital agency user
  if (isEstimate || !isMDA || !quoteExternalId) return result;

  const hasMDALeadEmailSent = selectMDALeadEmailSent(state);

  // if mda lead email has not been sent already call send mda lead endpoint on application update.
  // the mda lead email will also get triggered when a contact email is updated
  if (action.type === BINDABLE_QUOTE_RECEIVED && !hasMDALeadEmailSent) {
    mdaLead(quoteExternalId).then((res) => {
      store.dispatch(setMDALeadEmailSent(true));
    });
  }

  return result;
};

export default sendMDALeadEmail;
