import React from 'react';
import styled from 'styled-components';
import { useThemeContext } from '../../elements/theme/CustomThemeProvider';

function getDetails(type: string, theme: ThemeInterface): any {
  switch (type) {
    case 'delete':
      return {
        ariaLabel: 'Remove',
        imageSrc: theme.icons.trashCan,
        alt: 'trash can',
      };
    case 'update':
      return {
        ariaLabel: 'Edit',
        imageSrc: theme.icons.editIcon,
        alt: 'edit pencil',
      };
    default:
      return;
  }
}

interface DeleteUpdateBtnProps {
  handleClick: () => void;
  type: string;
  name?: string;
  size?: string;
}

const DeleteOrUpdateButton = ({ handleClick, type, name = '', size }: DeleteUpdateBtnProps) => {
  const { theme } = useThemeContext();
  const { ariaLabel, imageSrc, alt } = getDetails(type, theme);

  return (
    <Button onClick={handleClick} data-cy={`${type}-${name}`} aria-label={`${ariaLabel} ${name}`} size={size}>
      <Image src={imageSrc} role="img" alt={`${name} ${alt}`} />
    </Button>
  );
};

export const Button = styled.button<{ size?: string }>`
  border: none;
  background: transparent;
  height: 16px;
  width: 16px;
  align-self: center;
  display: flex;
  justify-content: center;
  padding: 0;

  :hover {
    background-color: ${(props) => props.theme.pageComponents.globals.background};
    cursor: pointer;
    border-radius: 50%;
  }
`;

const Image = styled.img`
  width: 15px;
  min-width: 15px;
`;

export default DeleteOrUpdateButton;
