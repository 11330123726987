import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  setLocations,
  setNumEmployees,
  setLegalBusinessName,
  setDBA,
  setDBAIsValid,
  setBusinessAgeInMonths,
} from '../actions/application';
import ToolTipIcon from '../Components/Shared/ToolTipIcon';
import SectionDivider from '../Components/Shared/SectionDivider';
import MultiButton from '../Components/Shared/MultiButton';
import PreviousLosses from '../Components/BusinessInfo/PreviousLosses';
import { STATE_ZIP_VERIFICATION_WARNING, stateAbbrevOptions } from '../constants';
import {
  selectNumEmployees,
  selectFailFastZip,
  selectIndustryState,
  selectLegalBusinessName,
  selectDBA,
  selectBusinessAgeInMonths,
  selectFirstLocation,
  selectLocationState,
} from '../selectors/application';
import {
  FormSection,
  LabelLarge,
  Input,
  Container,
  SelectButton,
  ButtonContainer,
  ToolTipHeader,
  ContainerRow,
  LocationQuestionContainer,
  InputWithToolTip,
  NumberInput,
} from '../elements';
import Dropdown from '../elements/Dropdown';
import GhostButton from '../Components/Shared/GhostButton';
import handleFormSubmit from '../actions/handleFormSubmit';
import { DesktopAndTablet, Desktop, MobileAndTablet } from '../helpers/responsive';
import { NumberFormatValues } from 'react-number-format';
import styled from 'styled-components';
import toolTipContent from '../helpers/toolTipContent';
import Checkbox from '../elements/Checkbox';
import { verifyAddressStateAndZip } from '../api';
import { throttle } from 'lodash';
import { setZipStateVerified } from '../actions/businessInfo';

interface BusinessInfoProps {
  legalBusinessName?: string;
  dba?: string;
  businessAgeInMonths?: number;
  numEmployees?: number;
  businessZip: string;
  businessState?: string;
  industryId?: number;
  location?: ApplicationLocation;
  setLocations: (location: Array<object>) => void;
  setNumEmployees: (num: number) => void;
  setLegalBusinessName: (name: string) => void;
  setDBA: (name: string) => void;
  setDBAIsValid: (isValid: boolean) => void;
  setBusinessAgeInMonths: (age: number) => void;
  setZipStateVerified: (isVerified: boolean) => void;
}

const BusinessInfo = ({
  legalBusinessName,
  dba,
  businessAgeInMonths,
  numEmployees,
  businessZip,
  businessState,
  industryId,
  location,
  setLocations,
  setNumEmployees,
  setLegalBusinessName,
  setDBA,
  setDBAIsValid,
  setBusinessAgeInMonths,
  setZipStateVerified,
}: BusinessInfoProps) => {
  useEffect(() => {
    document.title = `Coterie - Business Basics`;
    window.scrollTo(0, 0);
  }, []);

  const [hasDBA, setHasDBA] = useState(Boolean(dba));
  const [showZipStateError, setShowZipStateError] = useState(false);

  const throttledVerifyAddressStateAndZip = useCallback(
    throttle((businessState, businessZip) => {
      // only call zip state verification endpoint if zip code is entered fully
      if (!businessState || !businessZip || businessZip.length < 5) return;

      verifyAddressStateAndZip(businessState, businessZip)
        .then((res) => {
          setShowZipStateError(false);
          setZipStateVerified(true);
        })
        .catch((error) => {
          const hasMismatchingState = error?.response?.data?.includes(STATE_ZIP_VERIFICATION_WARNING.stateErrorText);
          const hasMismatchingZip = error?.response?.data?.includes(STATE_ZIP_VERIFICATION_WARNING.zipErrorText);

          if (hasMismatchingState || hasMismatchingZip) {
            setShowZipStateError(true);
            setZipStateVerified(false);
          }
        });
    }, 1500),
    []
  );

  useEffect(() => {
    throttledVerifyAddressStateAndZip(businessState, businessZip);
  }, [businessState, businessZip, throttledVerifyAddressStateAndZip]);

  const handleDbaCheck = () => {
    setHasDBA((p) => {
      if (p) {
        setDBA('');
        setDBAIsValid(true);
      } else {
        setDBAIsValid(false);
      }
      p = !hasDBA;
      return p;
    });
  };

  const validDba = hasDBA ? Boolean(dba) : true;
  const validZip = Boolean(businessZip?.length === 5 || businessZip?.length === 10);

  const updateBusinessZip = (e: { target: HTMLInputElement }) => {
    const input = e.target.value;
    const hasNonNumberValues = input.match(/[^0-9]/g);
    if (input.length <= 5 && !hasNonNumberValues) {
      setLocations([{ ...location, zip: input }]);
    }
  };

  const updateBusinessState = (selection: { value: string; label: string }) => {
    setLocations([{ ...location, state: selection.value }]);
  };

  const adjustNumEmployees = (numEmployees: number) => {
    setNumEmployees(numEmployees);
  };

  const updateLegalBusinessName = (e: { target: HTMLInputElement }) => {
    setLegalBusinessName(e.target.value);
  };

  const updateDBA = (e: { target: HTMLInputElement }) => {
    setDBA(e.target.value);
    setDBAIsValid(true);
  };

  const updateBusinessAge = (age: number) => {
    setBusinessAgeInMonths(age);
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    handleFormSubmit();
  };

  return (
    <Container data-cy="business-info">
      <form onSubmit={handleSubmit}>
        <ToolTipHeader>
          <LabelLarge display="inline">Legal Business Name</LabelLarge>
          <DesktopAndTablet>
            <ToolTipIcon content={toolTipContent['LEGAL_BUSINESS_NAME']} toolTipName="Legal_Business_Name" />
          </DesktopAndTablet>
        </ToolTipHeader>
        <InputWithToolTip
          toolTipContentKey="LEGAL_BUSINESS_NAME"
          toolTipName="Legal_Business_Name"
          labelElementId="legalBusinessNameInput"
          marginSmall={true}
        >
          <Input
            value={legalBusinessName}
            onChange={updateLegalBusinessName}
            data-cy="legal-business-name"
            aria-label="Enter the legal name of your business"
            placeholder="Enter your legal business name"
            id="legalBusinessNameInput"
          />
        </InputWithToolTip>

        <DbaContainer>
          <Checkbox
            onChange={handleDbaCheck}
            selected={hasDBA}
            label="My business uses a separate Doing Business As or operating name"
            id="dba-checkbox"
          />

          {hasDBA && (
            <>
              <ToolTipHeader>
                <LabelLarge display="inline">Doing Business As</LabelLarge>
                <DesktopAndTablet>
                  <ToolTipIcon content={toolTipContent['DBA']} toolTipName="DBA" />
                </DesktopAndTablet>
              </ToolTipHeader>
              <InputWithToolTip toolTipContentKey="DBA" toolTipName="DBA" labelElementId="dbaInput">
                <Input
                  value={dba}
                  onChange={updateDBA}
                  data-cy="dba"
                  aria-label="Enter the DBA of your business"
                  placeholder="Enter your DBA"
                  id="dbaInput"
                />
              </InputWithToolTip>
            </>
          )}
        </DbaContainer>

        <LabelLarge>Number of years in operation</LabelLarge>
        <ButtonContainer>
          <StyledSelectButton
            active={businessAgeInMonths !== undefined && businessAgeInMonths <= 36}
            onClick={() => updateBusinessAge(18)}
            aria-label="Business is 0 - 3 years old"
            aria-pressed={businessAgeInMonths !== undefined && businessAgeInMonths <= 36}
            type="button"
          >
            0-3 years
          </StyledSelectButton>
          <StyledSelectButton
            active={businessAgeInMonths !== undefined && businessAgeInMonths > 36 && businessAgeInMonths < 60}
            onClick={() => updateBusinessAge(48)}
            data-cy="biz-age-middle"
            aria-label="Business is 3-5 years old"
            aria-pressed={businessAgeInMonths !== undefined && businessAgeInMonths > 36 && businessAgeInMonths < 60}
            type="button"
          >
            3-5 years
          </StyledSelectButton>
          <StyledSelectButton
            active={businessAgeInMonths !== undefined && businessAgeInMonths >= 60}
            onClick={() => updateBusinessAge(60)}
            aria-label="Business is over 5 years old"
            aria-pressed={businessAgeInMonths !== undefined && businessAgeInMonths >= 60}
            type="button"
          >
            over 5 years
          </StyledSelectButton>
        </ButtonContainer>
        <FormSection available={industryId !== undefined}>
          <ToolTipHeader>
            <LabelLarge display="inline">How many employees does your business have?</LabelLarge>
            <DesktopAndTablet>
              <ToolTipIcon content={toolTipContent['NUMBER_EMPLOYEES']} toolTipName="Number_Employees" />
            </DesktopAndTablet>
          </ToolTipHeader>
          <Desktop>
            <MultiButton
              available={true}
              value={numEmployees}
              updateValue={adjustNumEmployees}
              directLabel="Enter the total number of employees"
              options={[
                {
                  title: '10 or fewer',
                  values: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                },
                {
                  title: '11-20',
                  values: [11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
                },
                {
                  title: 'More than 20',
                  customInput: '>30', // shouldn't be boolean, should be value to use at end of values array

                  values: [21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
                  placeholder: 'Number of employees',
                },
              ]}
            />
          </Desktop>
          <MobileAndTablet>
            <LocationQuestionContainer>
              <InputWithToolTip
                toolTipContentKey="NUMBER_EMPLOYEES"
                toolTipName="Number_Employees"
                labelElementId="numEmployeesInput"
              >
                <NumberInput
                  value={numEmployees}
                  onValueChange={(values: NumberFormatValues) => {
                    if (values.floatValue) adjustNumEmployees(values.floatValue);
                  }}
                  customWidth="half"
                  aria-label="Number of employees"
                  placeholder="eg., 10"
                  id="numEmployeesInput"
                />
              </InputWithToolTip>
            </LocationQuestionContainer>
          </MobileAndTablet>
        </FormSection>
        <FormSection available={Boolean(legalBusinessName && businessAgeInMonths && numEmployees)}>
          <ContainerRow>
            <LocationQuestionContainer>
              <LabelLarge>Business State</LabelLarge>
              <Dropdown
                onChange={updateBusinessState}
                options={stateAbbrevOptions}
                value={businessState || ''}
                name="state"
                ariaLabel="State"
                isDisabled={!Boolean(legalBusinessName && businessAgeInMonths && numEmployees)}
                hasError={showZipStateError}
              />
            </LocationQuestionContainer>
            <LocationQuestionContainer>
              <LabelLarge>Business Zip Code</LabelLarge>
              <Input
                data-cy="zip-input"
                value={businessZip}
                onChange={updateBusinessZip}
                width="half"
                disabled={!Boolean(legalBusinessName && businessAgeInMonths && numEmployees)}
                aria-label="Zip Code"
                required
                aria-required
                hasError={showZipStateError}
                marginBottom="none"
              />
            </LocationQuestionContainer>
          </ContainerRow>
          {showZipStateError && <ZipStateWarning>{STATE_ZIP_VERIFICATION_WARNING.text}</ZipStateWarning>}
        </FormSection>
        <SectionDivider />
        <PreviousLosses
          disabled={!(industryId && numEmployees && legalBusinessName && businessAgeInMonths && validZip && validDba)}
        />
        <GhostButton />
      </form>
    </Container>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  numEmployees: selectNumEmployees(state) || 0,
  businessZip: selectFailFastZip(state) || '',
  industryId: selectIndustryState(state),
  legalBusinessName: selectLegalBusinessName(state) || '',
  dba: selectDBA(state) || '',
  businessAgeInMonths: selectBusinessAgeInMonths(state),
  location: selectFirstLocation(state),
  businessState: selectLocationState(state),
});

const mapDispatchToProps = {
  setLocations,
  setNumEmployees,
  setLegalBusinessName,
  setDBA,
  setDBAIsValid,
  setBusinessAgeInMonths,
  setZipStateVerified,
};

const StyledSelectButton = styled(SelectButton)`
  @media (min-width: 768px) and (max-width: 1024px) {
    min-width: 100px;
  }
`;

const DbaContainer = styled.div`
  margin-bottom: 32px;
`;

const ZipStateWarning = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: ${({ theme }) => theme.formElements.input.errorTextColor};
  margin-top: 8px;
`;

export default connect(mapStateToProps, mapDispatchToProps)(BusinessInfo);
