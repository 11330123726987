import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Policy from '../Components/PolicySelection/Policy';
import { Container, LabelLarge } from '../elements';
import { quoteHelp, setApplicationTypes } from '../actions/application';
import handleFormSubmit from '../actions/handleFormSubmit';
import { policiesWithLabels, getPartnerIdFromApplication, selectChannelPartnerGuid } from '../selectors';
import { selectPolicyTypes } from '../selectors/application';
import GhostButton from '../Components/Shared/GhostButton';
import { useFeatureFlags } from '../toggle_tools/featureFlagTools';
import warning_svg from '../icons/warning.svg';

type AvailablePolicyTypes = {
  label: string;
  name: string;
  unavailableMessage?: string;
};

interface PolicySelectionProps {
  applicationTypes: string[];
  availablePolicyTypes: Array<AvailablePolicyTypes>;
  quoteHelp: () => void;
  setApplicationTypes: (applicationTypes: Array<string | undefined>) => void;
  handleFormSubmit: () => void;
  partnerId: string | undefined;
}

const PolicySelection = ({
  applicationTypes,
  availablePolicyTypes,
  quoteHelp,
  setApplicationTypes,
  handleFormSubmit,
  partnerId,
}: PolicySelectionProps) => {
  const { hidePlByChannelPartner } = useFeatureFlags();

  const [userSelectedTypes, setUserSelectedTypes] = useState(applicationTypes || []);
  const [policyTypeUnavailable, setPolicyTypeUnavailable] = useState('');

  const [expanded, setExpanded] = useState('');

  const partnerWantsHidden = Boolean(partnerId && hidePlByChannelPartner?.channel_partner_ids.includes(partnerId));
  const availablePolicyTypesByChannelPartner = partnerWantsHidden
    ? availablePolicyTypes.filter((e) => e.name !== 'PL')
    : availablePolicyTypes;

  useEffect(() => {
    if (!availablePolicyTypesByChannelPartner.length) {
      quoteHelp();
    }
    document.title = `Coterie - Policies`;
    window.scrollTo(0, 0);
    // TODO this error scenario should be caught in the API response, not on this page
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!userSelectedTypes.includes('BOP') || !userSelectedTypes.includes('GL')) {
      setPolicyTypeUnavailable('');
    }
    if (userSelectedTypes.includes('BOP')) {
      setPolicyTypeUnavailable('GL');
    }
    if (userSelectedTypes.includes('GL')) {
      setPolicyTypeUnavailable('BOP');
    }
  }, [userSelectedTypes]);

  const updateSelectedPolicy = (policy: string) => {
    if (!userSelectedTypes.includes(policy)) {
      let selection = userSelectedTypes;
      if (policy === 'BOP' && userSelectedTypes.includes('GL')) {
        selection = selection.filter((e) => e !== 'GL');
      }
      if (policy === 'GL' && userSelectedTypes.includes('BOP')) {
        selection = selection.filter((e) => e !== 'BOP');
      }
      setUserSelectedTypes([...selection, policy]);
      setApplicationTypes([...selection, policy]);
    } else {
      const filteredApplicationTypes = userSelectedTypes.filter((item) => item !== policy);
      setUserSelectedTypes(filteredApplicationTypes);
      setApplicationTypes(filteredApplicationTypes);
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    handleFormSubmit();
  };

  const handleLearnMoreClick = (policyName: string) => {
    if (expanded === policyName) {
      setExpanded('');
    } else {
      setExpanded(policyName);
    }
  };

  return (
    <Container>
      <FormBody onSubmit={handleSubmit}>
        <LabelLarge display="inline">
          Which insurance policies does your business need? Select all that apply.
        </LabelLarge>
        <PolicyCol>
          {availablePolicyTypesByChannelPartner?.map((policy) => (
            <>
              <Policy
                key={policy.name}
                name={policy.name}
                label={policy.label}
                applicationTypes={userSelectedTypes}
                expanded={expanded}
                updateSelectedPolicy={updateSelectedPolicy}
                handleLearnMoreClick={handleLearnMoreClick}
                policyTypeUnavailable={policyTypeUnavailable === policy.name}
              />
              {policyTypeUnavailable === policy.name && (
                <UnavailableMessageCont data-testid={`${policy.name}-unavailable-message`}>
                  <img src={warning_svg} alt="warning icon" />
                  <UnavailableMessage>{policy?.unavailableMessage}</UnavailableMessage>
                </UnavailableMessageCont>
              )}
            </>
          ))}
        </PolicyCol>
        <GhostButton />
      </FormBody>
    </Container>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  availablePolicyTypes: policiesWithLabels(state),
  applicationTypes: selectPolicyTypes(state),
  partnerId: selectChannelPartnerGuid(state) ?? getPartnerIdFromApplication(state),
});

const mapDispatchToProps = {
  setApplicationTypes,
  quoteHelp,
  handleFormSubmit,
};

const FormBody = styled.form`
  flex: 3;
`;
const PolicyCol = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  margin: 10px 0;
  position: relative;
`;

const UnavailableMessageCont = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 4px;
`;

const UnavailableMessage = styled.p`
  margin: 5px 0px 8px;
  color: ${({ theme }) => theme.colors.tertiary.sixty};
  letter-spacing: 1px;
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
`;

export default connect(mapStateToProps, mapDispatchToProps)(PolicySelection);
