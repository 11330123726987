import React, { useState, useEffect, FunctionComponent } from 'react';
import { connect } from 'react-redux';
import LocationEditor from '../../Components/Locations/LocationEditor';
import LocationInput from './LocationInput';
import styled, { StyledComponent } from 'styled-components';
import locationsCheck from '../../helpers/locationsCheck';
import {
  addLocation,
  deleteLocation,
  updateLocation,
  setExistingMailingAddress,
  addPotentialAddress,
  setBppDeductible,
} from '../../actions/application';
import handleFormSubmit from '../../actions/handleFormSubmit';
import {
  selectApplication,
  selectMailingAddressStreet,
  selectPolicyTypes,
  selectBPPDeductible,
  selectLocationBPPLimit,
  selectHasSprinklers,
} from '../../selectors/application';
import { selectLoadingState, selectPotentialMailingAddresses } from '../../selectors/index';
import { LabelLarge } from '../../elements';
import { usePrevious } from '../../helpers/usePrevious';
import GhostButton from './GhostButton';
import SubmitButton from './SubmitButton';
import { getCurrentPage } from '../../selectors/routing';
import { isPageComplete } from '../../selectors/pageComplete';
import { PAGES, QUOTE_COVERAGE_DEFAULTS } from '../../constants';
import { isLocationsComplete } from '../../selectors/progress';
import { useThemeContext } from '../../elements/theme/CustomThemeProvider';

const uuidv1 = require('uuid/v1');

const AddressButtonCont = styled.div`
  display: flex;
  justify-content: flex-start;
`;

interface TrashIconProps {
  shouldDisplay: boolean;
}

const TrashIcon = styled.button`
  background: none;
  border: none;

  img {
    width: 15px;
    display: ${({ shouldDisplay }: TrashIconProps) => (!shouldDisplay ? 'none' : '')};
    :hover {
      cursor: pointer;
    }
  }
`;

const ExistingAddressButton: StyledComponent<'div', any, { active: boolean }, never> = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: none;
  padding-left: 16px;
  height: 48px;
  border-radius: 4px;
  margin-top: 10px;
  color: ${({ theme }) => theme.charcoalGrey};
  font-family: ${({ theme }) => theme.font.typeface.secondary};
  font-size: 15px;
  width: 100%;
  border: ${({ active, theme }) =>
    active
      ? theme.pageComponents.shared.locations.existingAddressButton.borderOnActive
      : theme.pageComponents.shared.locations.existingAddressButton.border};
  background-color: ${({ theme }) => theme.pageComponents.shared.locations.existingAddressButton.background};
  box-shadow: ${({ active }) => (active ? '0 0 10px 1px rgba(0, 0, 0, 0.1)' : '')};
`;

type mailingAddress = {
  mailingAddressCity: string;
  mailingAddressState: string;
  mailingAddressZip: string;
  mailingAddressStreet: string;
};

interface LocationsProps {
  locations: Location[];
  addLocation: (location: ApplicationLocation) => void;
  deleteLocation: (street: string) => void;
  setExistingMailingAddress: (mailingAddress: mailingAddress) => void;
  updateLocation: (address: Location) => void;

  mailingAddressStreet: string | undefined;
  applicationTypes: string[];
  onBundleSubmit?: () => void;
  handleFormSubmit: () => void;
  bppLimit: number | undefined;
  hasSprinklers: boolean | undefined;
  bppDeductible: number | undefined;
  setBppDeductible: (bppDeductible: number) => void;
  isPageComplete: boolean;
  isSummaryPage: boolean;
  isLoading: boolean;
}

export const Locations: FunctionComponent<LocationsProps> = ({
  locations,
  addLocation,
  deleteLocation,
  setExistingMailingAddress,
  updateLocation,

  mailingAddressStreet,
  applicationTypes,
  onBundleSubmit,
  handleFormSubmit,
  bppLimit,
  hasSprinklers,
  bppDeductible,
  setBppDeductible,
  isPageComplete,
  isSummaryPage,
  isLoading,
}) => {
  const { theme } = useThemeContext();
  const [expandedLocation, setExpandedLocation] = useState('');
  const [showInputBox, setShowInputBox] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = 'Coterie - Locations';
    if (applicationTypes.includes('BOP') && !Boolean(bppDeductible)) {
      setBppDeductible(QUOTE_COVERAGE_DEFAULTS.BPP_DEDUCTIBLE);
    }

    if (!locations.length) {
      setShowInputBox(true);
    } else {
      setExpandedLocation(locations[0].street);
    }
  }, [applicationTypes, bppDeductible, setBppDeductible, locations]);

  const prevLocations = usePrevious(locations);

  useEffect(() => {
    if (!locations.length && prevLocations?.length) {
      setShowInputBox(true);
      setExpandedLocation('');
    }

    if (prevLocations?.length < locations.length) {
      const newStreet = locations[locations.length - 1].street;
      setExpandedLocation(newStreet);
    }

    if (prevLocations?.length > locations.length && locations.length) {
      setExpandedLocation(locations[0].street);
    }
  }, [locations, prevLocations]);

  const handleAddLocation = (location: Address) => {
    setShowInputBox(false);
    // default fire suppression system to false when location is added
    addLocation({ ...location, hasSprinklers: false });
    addPotentialAddress(location);
    setExistingAddressAsMailingAddress(location);
  };

  const handleUpdateLocation = (updates: Partial<Location>) => {
    const location = locations.find((location) => location.street === expandedLocation);

    const updatedLocation = Object.assign({}, location, { ...updates });
    updateLocation(updatedLocation);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onBundleSubmit ? onBundleSubmit() : handleFormSubmit();
  };

  const setExistingAddressAsMailingAddress = (address: Address) => {
    if (address.street === mailingAddressStreet && !applicationTypes.includes('BOP')) {
      setExistingMailingAddress({
        mailingAddressCity: '',
        mailingAddressState: '',
        mailingAddressZip: '',
        mailingAddressStreet: '',
      });
    } else {
      setExistingMailingAddress({
        mailingAddressCity: address.city,
        mailingAddressState: address.state,
        mailingAddressZip: address.zip,
        mailingAddressStreet: address.street,
      });
    }
  };

  const expandedLocationData = locations.find((bizLocation) => bizLocation.street === expandedLocation);

  return (
    <form onSubmit={handleSubmit}>
      <LabelLarge display="inline">Where is your business located?</LabelLarge>
      <AddressButtonCont>
        {locations.map((location) => (
          <ExistingAddressButton
            key={uuidv1()}
            active={expandedLocation === location.street}
            data-active={expandedLocation === location.street}
            data-cy={`location-button-${location.street?.replace(/\s/g, '')}`}
            onClick={() => setExpandedLocation(location.street)}
          >
            {location.street}, {location.city} {location.state} {location.zip}
            <TrashIcon
              type="button"
              onClick={() => deleteLocation(location.street)}
              aria-label={`Remove ${location.street} business location`}
              data-cy={`delete-location-${location.street?.replace(/\s/g, '')}`}
              shouldDisplay={true}
            >
              <img src={theme.icons.trashCan} alt="" aria-hidden="true" />
            </TrashIcon>
          </ExistingAddressButton>
        ))}
      </AddressButtonCont>
      {(applicationTypes.includes('BOP') || isSummaryPage) && (
        <LocationEditor
          updateLocation={handleUpdateLocation}
          updateBPPDeductible={setBppDeductible}
          {...expandedLocationData}
          expanded={expandedLocationData}
          bppDeductible={bppDeductible}
          bppLimit={bppLimit}
          hasSprinklers={hasSprinklers}
          isLoading={isLoading}
          locationState={locations[0]?.state}
        />
      )}
      <LocationInput addLocation={handleAddLocation} showInputBox={showInputBox} />

      {isSummaryPage ? (
        <>
          <SubmitButton active={isPageComplete} arrowLinks={false}>
            Add Policy
          </SubmitButton>
        </>
      ) : (
        <GhostButton />
      )}
    </form>
  );
};

const mapStateToProps = (state: ReduxState) => {
  const isSummaryPage = getCurrentPage(state) === PAGES.SUMMARY;

  return {
    locations: locationsCheck(selectApplication(state)) || [],
    potentialMailingAddresses: selectPotentialMailingAddresses(state),
    mailingAddressStreet: selectMailingAddressStreet(state),
    applicationTypes: selectPolicyTypes(state),
    bppDeductible: selectBPPDeductible(state),
    bppLimit: selectLocationBPPLimit(state),
    hasSprinklers: selectHasSprinklers(state),
    isSummaryPage,
    isPageComplete: isSummaryPage ? isLocationsComplete(state) : isPageComplete(state),
    isLoading: selectLoadingState(state),
  };
};

const mapDispatchToProps = {
  addPotentialAddress,
  addLocation,
  deleteLocation,
  setExistingMailingAddress,
  updateLocation,

  setBppDeductible,
  handleFormSubmit,
};

export default connect(mapStateToProps, mapDispatchToProps)(Locations);
