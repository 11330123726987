import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import handleFormSubmit from '../../actions/handleFormSubmit';
import { isPageComplete } from '../../selectors/pageComplete';

const HiddenButton = styled.input`
  visibility: hidden;
  height: 0;
  width: 0;
  padding: 0;
  border: 0;
`;

const GhostButton = ({
  isDisabled,
  handleFormSubmit,
  ariaLabel = 'next page',
}: {
  isDisabled: boolean;
  handleFormSubmit: () => void;
  ariaLabel?: string;
}) => (
  <HiddenButton
    disabled={isDisabled}
    aria-label={ariaLabel}
    onClick={() => handleFormSubmit()}
    type="submit"
    value="submit"
  />
);

const mapStateToProps = (state: ReduxState, { isDisabled }: { isDisabled?: boolean }) => ({
  isDisabled: isDisabled !== undefined ? isDisabled : !isPageComplete(state),
});

const mapDispatchToProps = {
  handleFormSubmit,
};

export default connect(mapStateToProps, mapDispatchToProps)(GhostButton);
