import throttle from 'lodash/throttle';
import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { LabelLarge, Input, TextButton } from '../../elements';
import { fetchTypeAheadEntries, typeAhead, verifyAddress, verifyAddressStateAndZip } from '../../api';
import { Desktop, MobileAndTablet } from '../../helpers/responsive';
import { reportToSentry } from '../../helpers/sentry';
import { useOnClickOutside } from '../../helpers/useOnOutsideClick';
import { STATE_ZIP_VERIFICATION_WARNING } from '../../constants';

export const ADDRESS_VERIFY_NO_MATCH = 'No matches for that address.';
const ADDRESS_VERIFY_NO_SECONDARY = "That's a valid address, but you're missing a suite/unit/apartment number.";
export const ADDRESS_VERIFY_ONLY_PO =
  'PO Boxes are not a valid business address. Please provide a physical street address to ensure proper coverage';

interface PropTypes {
  addLocation: (address: Address) => void;
  showInputBox: boolean;
  existing?: boolean;
  existingStreet?: string;
  existingCity?: string;
  existingState?: string;
  isMailingAddress?: boolean;
}

interface Suggestion {
  street: string;
  city: string;
  state: string;
  zipCode: string;
  secondary?: string;
  entries?: string;
}

export const getFullAddress = (s: Suggestion) => {
  const hasEntries = Number(s.entries) > 1;
  const hasOneEntry = Number(s.entries) === 1;

  if (hasEntries) {
    return `${s.street} ${s.secondary} (${s.entries} more entries), ${s.city} ${s.state} ${s.zipCode}`;
  } else if (hasOneEntry) {
    return `${s.street} ${s.secondary}, ${s.city} ${s.state} ${s.zipCode}`;
  } else return `${s.street}, ${s.city} ${s.state} ${s.zipCode}`;
};

const LocationInputContainer = ({
  addLocation,
  showInputBox,
  existing,
  existingStreet,
  existingCity,
  existingState,
  isMailingAddress = false,
}: PropTypes) => {
  const [address, setAddress] = useState('');
  const [manualStreet, setManualStreet] = useState('');
  const [manualCity, setManualCity] = useState('');
  const [manualState, setManualState] = useState('');
  const [manualZip, setManualZip] = useState('');
  const [statusMessage, setStatusMessage] = useState('');
  const [addressSuggestions, setAddressSuggestions] = useState<Address[]>([]);
  const [inputTouched, setInputTouched] = useState(false);
  const [showResultsBox, setShowResultsBox] = useState<boolean>(false);
  const [useManualAddressEntry, setUseManualAddressEntry] = useState(false);
  const addressInputContRef = React.useRef<HTMLDivElement>(null);
  const [showZipStateError, setShowZipStateError] = useState(false);

  const switchToManualAddressEntry = () => setUseManualAddressEntry(true);

  const updateAddress = (e: { target: HTMLInputElement }) => {
    let value = e.target.value;
    setAddress(value);
    setStatusMessage('');
    setInputTouched(true);
    if (value.length > 0) {
      debounceChangeAddress(value);
    } else {
      setAddressSuggestions([]);
    }
  };

  const showAddressWithEntries = (address: any) => {};

  const changeAddress = async (address: string) => {
    setStatusMessage('');
    return typeAhead(address)
      .then((suggestions) => {
        setAddressSuggestions(suggestions || []);
      })
      .catch((err) => reportToSentry(err));
  };

  /**
   * TODO look into other solutions for debouncing inputs that
   * might better satisfy eslint warnings and avoid potential bugs
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceChangeAddress = useCallback(
    debounce((address) => changeAddress(address), 500),
    []
  );

  const updateManualStreet = (e: { target: HTMLInputElement }) => {
    setManualStreet(e.target.value);
    setStatusMessage('');
  };

  const updateManualCity = (e: { target: HTMLInputElement }) => {
    setManualCity(e.target.value);
    setStatusMessage('');
  };

  const updateManualState = (e: { target: HTMLInputElement }) => {
    setManualState(e.target.value.toUpperCase());
    setStatusMessage('');
  };

  const updateManualZip = (e: { target: HTMLInputElement }) => {
    const value = e.target.value;

    if (value === '') setManualZip('');
    //@ts-ignore (2345) isNaN expects a number can can accept a string and attempt to cast it to a num
    if (typeof value === 'string' && !isNaN(value) && !isNaN(parseFloat(value))) {
      setManualZip(value);
    }
    setStatusMessage('');
  };

  const handleVerifyAddress = async (suggestion: Suggestion, cb: (arg0: VerifiedAddressResponse) => void) => {
    try {
      const address = await verifyAddress(suggestion);

      const hasNoMatch = !address || !address.analysis;
      const hasNoSecondary = address?.analysis?.dpv_match_code === 'D';
      const isOnlyPOBox = address?.metadata?.record_type === 'P' && !isMailingAddress;
      setStatusMessage(ADDRESS_VERIFY_NO_MATCH);
      if (hasNoMatch) {
      } else if (hasNoSecondary) {
        setStatusMessage(ADDRESS_VERIFY_NO_SECONDARY);
      } else if (isOnlyPOBox) {
        setStatusMessage(ADDRESS_VERIFY_ONLY_PO);
      } else {
        cb(address);
      }
    } catch (error) {
      reportToSentry(error as any);
    }
  };

  const handleCheckAddress = async (suggestion: Suggestion) => {
    if (Number(suggestion?.entries) > 1) {
      const addressEntries = await fetchTypeAheadEntries(suggestion);
      setAddressSuggestions(addressEntries);
      return;
    }

    const handleOnVerifySuccess = (address: any) => {
      const { street_name, street_suffix, city_name, zipCode, state_abbreviation, primary_number } = address.components;

      const street = `${primary_number || ''} ${street_name || ''} ${street_suffix || ''}${
        suggestion.secondary ? ` ${suggestion.secondary}` : ''
      }`;

      const location = {
        street,
        city: city_name,
        zip: zipCode,
        state: state_abbreviation,
      };

      setAddress('');
      setStatusMessage('');
      setAddressSuggestions([]);
      addLocation(location);
      setInputTouched(false);
    };

    await handleVerifyAddress(suggestion, handleOnVerifySuccess);
  };

  const handleManualAddressSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const address = {
      street: manualStreet,
      city: manualCity,
      state: manualState,
      zipCode: manualZip,
    };

    const handleOnVerifySuccess = () => {
      addLocation({
        street: manualStreet,
        city: manualCity,
        state: manualState,
        zip: manualZip,
      });
      setUseManualAddressEntry(false);
      setInputTouched(false);
      setAddress('');
    };

    await handleVerifyAddress(address, handleOnVerifySuccess);
  };

  const isAddressComplete = Boolean(
    manualStreet && manualCity && manualState && manualState.length === 2 && manualZip && manualZip.length >= 5
  );

  const handleAddressListKeyPress = (e: React.KeyboardEvent, address: Suggestion) => {
    if (e.key === 'Escape') {
      setShowResultsBox(false);
    } else if (e.key === 'Enter' || e.key === ' ') {
      handleCheckAddress(address);
    }
  };

  const throttledVerifyAddressStateAndZip = useCallback(
    throttle((businessState, businessZip) => {
      // only call zip state verification endpoint if zip code is entered fully
      if (!businessState || !businessZip || businessZip.length < 5) return;

      verifyAddressStateAndZip(businessState, businessZip)
        .then((res) => {
          setShowZipStateError(false);
        })
        .catch((error) => {
          const hasMismatchingState = error?.response?.data?.includes(STATE_ZIP_VERIFICATION_WARNING.stateErrorText);
          const hasMismatchingZip = error?.response?.data?.includes(STATE_ZIP_VERIFICATION_WARNING.zipErrorText);

          if (hasMismatchingState || hasMismatchingZip) {
            setShowZipStateError(true);
          }
        });
    }, 1500),
    []
  );

  useEffect(() => {
    throttledVerifyAddressStateAndZip(manualState, manualZip);
  }, [manualState, manualZip, throttledVerifyAddressStateAndZip]);

  const renderAddressEntry = () => (
    <>
      {useManualAddressEntry ? (
        <>
          <InputCont>
            <LabelLarge>Street address</LabelLarge>
            <Input
              onChange={updateManualStreet}
              value={manualStreet}
              placeholder="Enter street address"
              data-cy="manual-street-input"
              aria-label="Manual Street Address"
              marginBottom="smaller"
            />
            <MobileAndTablet>
              <LabelLarge>City</LabelLarge>
              <Input
                onChange={updateManualCity}
                value={manualCity}
                placeholder="Enter city"
                data-cy="manual-city-input"
                aria-label="Manual City"
                marginBottom="smaller"
              />
            </MobileAndTablet>
            <Row>
              <Desktop>
                <Column>
                  <LabelLarge>City</LabelLarge>
                  <HalfInput
                    onChange={updateManualCity}
                    value={manualCity}
                    placeholder="Enter city"
                    data-cy="manual-city-input"
                    aria-label="Manual City"
                    marginBottom="none"
                  />
                </Column>
              </Desktop>
              <ThirdColumn>
                <LabelLarge>State</LabelLarge>
                <ThirdInput
                  onChange={updateManualState}
                  value={manualState}
                  placeholder="Enter state"
                  data-cy="manual-state-input"
                  aria-label="Manual State"
                  marginBottom="none"
                  maxLength={2}
                  hasError={showZipStateError}
                />
              </ThirdColumn>
              <ThirdColumn>
                <LabelLarge>Zipcode</LabelLarge>
                <ThirdInput
                  onChange={updateManualZip}
                  value={manualZip}
                  placeholder="Enter zip"
                  data-cy="manual-zip-input"
                  aria-label="Manual Zip"
                  marginBottom="none"
                  maxLength={5}
                  hasError={showZipStateError}
                />
              </ThirdColumn>
            </Row>
            {showZipStateError && <ZipStateWarning>{STATE_ZIP_VERIFICATION_WARNING.text}</ZipStateWarning>}
          </InputCont>
          {statusMessage && <StatusMessage aria-live="polite">{statusMessage}</StatusMessage>}
          <TextButton
            type="button"
            disabled={!isAddressComplete || showZipStateError || statusMessage !== ''}
            onClick={handleManualAddressSubmit}
            aria-label="Save address"
            data-cy="save-manual-address"
          >
            Save address
          </TextButton>
        </>
      ) : (
        <AddressContainer ref={addressInputContRef}>
          <Input
            onChange={updateAddress}
            onFocus={() => setShowResultsBox(true)}
            value={address}
            placeholder="Enter address"
            data-cy="location-input"
            aria-label="Address"
            marginBottom={statusMessage ? 'none' : 'smallest'}
          />
          {statusMessage && <StatusMessage aria-live="polite">{statusMessage}</StatusMessage>}
          {showInputBox && showResultsBox && inputTouched && Boolean(addressSuggestions.length) && (
            <AddressSelectionDropdown>
              {addressSuggestions.map((address: any) => (
                <AddressSuggestion
                  key={getFullAddress(address)}
                  onClick={() => handleCheckAddress(address)}
                  onKeyDown={(e: React.KeyboardEvent) => {
                    handleAddressListKeyPress(e, address);
                  }}
                  data-cy="address-suggestion"
                  tabIndex={0}
                >
                  {getFullAddress(address)}
                </AddressSuggestion>
              ))}
            </AddressSelectionDropdown>
          )}
          <TextButton
            type="button"
            onClick={switchToManualAddressEntry}
            aria-label="Enter address manually"
            data-cy="toggle-manual-address"
          >
            Enter address manually
          </TextButton>
        </AddressContainer>
      )}
    </>
  );

  useOnClickOutside(addressInputContRef, () => setShowResultsBox(false));

  return (
    <>
      {existing ? (
        <LabelLarge center>{`${existingStreet}, ${existingCity}, ${existingState}`}</LabelLarge>
      ) : (
        <>
          {showInputBox
            ? renderAddressEntry()
            : // TODO: Re-enable multi-location support
              null}
        </>
      )}
    </>
  );
};

const StatusMessage = styled.p`
  font-family: ${(props) => props.theme.font.typeface.secondary};
  font-size: 14px;
  font-weight: bold;
  color: ${({ theme }) => theme.formElements.input.errorTextColor};
  line-height: 1.25;
`;

const AddressSelectionDropdown = styled.ul`
  padding: 11px 0;
  border: ${(props) => props.theme.formElements.input.border};
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-top: 10px;
  position: absolute;
  width: 100%;
  background: ${({ theme }) => theme.pageComponents.globals.background};
  z-index: 3;
  padding-left: 0;
  max-height: 300px;
  overflow: scroll;
`;

const AddressSuggestion = styled.li`
  padding: 10px 20px;
  margin: 0;
  font-family: ${(props) => props.theme.font.typeface.secondary};
  font-size: 15px;
  list-style: none;
  :hover,
  :focus {
    cursor: pointer;
    background-color: ${(props) => props.theme.pageComponents.shared.locationInput.addressSuggestion.backgroundOnHover};
  }
`;

const InputCont = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;

const AddressContainer = styled.div`
  position: relative;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const HalfInput = styled(Input)`
  width: 292px;
`;

const ThirdInput = styled(Input)`
  width: 138px;
  @media (max-width: 767px) {
    width: 50%;
  }
`;

const ThirdColumn = styled(Column)`
  margin-left: 16px;
  @media (max-width: 1024px) {
    &:first-child {
      margin-left: 0;
    }
  }
  @media (max-width: 767px) {
    margin-left: 0;
  }
`;

const ZipStateWarning = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: ${({ theme }) => theme.formElements.input.errorTextColor};
  margin-top: 12px;
`;

export default LocationInputContainer;
