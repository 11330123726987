import styled from 'styled-components';
import { ModalDetailBar } from '../../../elements/Modal';

export const IconContainer = styled.div`
  height: 40px;
  width: 88px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 767px) {
    height: 24px;
    width: 56px;
    margin-right: 8px;
  }
`;

export const PlusSymbol = styled.span`
  color: ${({ theme }) => theme.pageComponents.summary.plusSymbol.textColor};
  font-size: 24px;
  font-weight: bolder;
  padding-left: 6px;
  @media (max-width: 767px) {
    font-size: 12px;
    padding-left: 3px;
  }
`;

export const FirstImg = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 36px;
  @media (max-width: 767px) {
    width: 24px;
  }
`;

export const SecondImg = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 36px;
  @media (max-width: 767px) {
    width: 24px;
  }
`;

export const Container = styled.div<{ policy?: PolicyAssets }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
  border: 2px solid ${({ policy }) => policy?.color};
  border-radius: 4px;
  background-color: ${({ policy }) => policy?.background};
  padding: 12px 16px;
  margin: 12px 0;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

export const ContentContainer = styled.div`
  margin-left: 20px;
  @media (max-width: 767px) {
    margin: 0;
  }
`;

export const Title = styled.h4`
  color: ${({ theme }) => theme.pageComponents.summary.title.textColor};
  font-family: ${({ theme }) => theme.font.typeface.primary};
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  margin: 0;
`;

export const Content = styled.p`
  color: ${({ theme }) => theme.pageComponents.summary.content.textColor};
  font-family: ${({ theme }) => theme.font.typeface.secondary};
  font-size: 13px;
  line-height: 20px;
  margin: 0;
  @media (max-width: 767px) {
    margin-top: 8px;
  }
`;

export const Link = styled.a`
  color: ${({ theme }) => theme.pageComponents.summary.link.textColor};
  display: flex;
  align-items: center;
  font-family: ${({ theme }) => theme.font.typeface.primary};
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
  text-decoration: none;
  @media (max-width: 767px) {
    margin-top: 8px;
  }
`;

export const ModalIcon = styled.img`
  margin: 0 20px 0 10px;
`;

export const AddPolicyDetailsBar = styled(ModalDetailBar)<{ policy?: PolicyAssets }>`
  border-top: 2px solid ${({ policy }) => policy?.color};
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;
