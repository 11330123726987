import { getIndustryName } from '../api';
import { setIndustryName, fatalError } from './application';
import { getRequestContext, reportToSentry } from '../helpers/sentry';
import { Dispatch } from 'redux';

const handleGetIndustryName = (industryId: string) => {
  if (!industryId) {
    return;
  }
  return (dispatch: Dispatch) => {
    return getIndustryName(industryId)
      .then((res) => {
        if (!res.data.name) {
          reportToSentry(`Industry not found: ${industryId}`, { name: 'industry not found', data: res });
        }
        dispatch(setIndustryName(res.data.name));
      })
      .catch((error) => {
        reportToSentry(error, getRequestContext({ industryId }));
        dispatch(fatalError(error));
      });
  };
};

export default handleGetIndustryName;
