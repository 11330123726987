import { Dictionary } from 'lodash';
import { ENVIRONMENT_URL } from '../constants';
import { selectQuoteId, selectQuote } from '../selectors/quote';
import { getPaymentInterval } from '../selectors/routing';
import { handlePurchasePolicy, quotePurchasePending } from './quote';
import { selectChannelPartnerGuid, selectPartnerAgencyExternalId, selectAgentProducerExternalId } from '../selectors';

export const PAYMENT_TRANSACTION_FAILED = 'PAYMENT_TRANSACTION_FAILED';
export const CLEAR_PAYMENT_TRANSACTION_ERRORS = 'CLEAR_PAYMENT_TRANSACTION_ERRORS';

interface CardData {
  cardNumber: string;
  month: string;
  year: string;
  cardCode: string;
}

export const clientKey: Dictionary<Dictionary<string>> = {
  benchmark: {
    prod: '526aUcSPzgq3egP445EftcSW9HvM53EtWN6w8uewTYHtZ8dasphyX688hj5Das7X',
    test: '2aLLXCZ2k26a6ezNzGAg5puJgm97MkYzFgtUGpv8P5W36Pq3t9UsgC7YBsqV4MsK',
  },
  clearspring: {
    prod: '9qNz9P2hgVzTpcNMk5PS5n44mNPq9TvH5ggW7Wtg4468rb9s6Q8YpT5enjBGwjLw',
    test: '7s2cXk9nT47z67EZRc7Wk9e8eQ4pYW6PRcy95eL9xS6e74Z3dDhKUwNCwYCN5jHf',
  },
  spinnaker: {
    prod: '5537SvP47S8dT4UnG7z45XXLscCpkfV9c2T3xF3TB9nRaqBBvrL595V2cjXUu8g3',
    test: '7555gMz7sLkANntG4JNuYv4PV7K8yFDjFPjC6kPA67vyTDYqtdK666qSX9m44Ps2',
  },
};

export const apiLoginId: Dictionary<Dictionary<string>> = {
  benchmark: {
    prod: '5ws49L8UP',
    test: '9rWk7QmN8bNs',
  },
  clearspring: {
    prod: '4eL3PSs23',
    test: '6F3zAS5w82',
  },
  spinnaker: {
    prod: '7ZH39wTWw4',
    test: '66nQaE3d6',
  },
};

const buildAuthData = (insuranceCarrier: string = 'benchmark') => {
  const isProd = window.location.hostname === ENVIRONMENT_URL.PRODUCTION;
  const env = isProd ? 'prod' : 'test';

  return {
    clientKey: clientKey[insuranceCarrier.toLocaleLowerCase()][env],
    apiLoginID: apiLoginId[insuranceCarrier.toLocaleLowerCase()][env],
  };
};

export const authorizeCard = ({ quotes, cardData, paymentInterval, errorCB, successCB }: any) => {
  const authData = buildAuthData(quotes[0].insuranceCarrier);
  const request = { authData, cardData };

  window.Accept.dispatchData(request, (res: any) => {
    if (res.messages.resultCode === 'Error') {
      errorCB(res);
    } else {
      const firstQuote = {
        authorizeNetPaymentNonce: res.opaqueData.dataValue,
        paymentInterval,
        carrierAuthorizeNetPaymentNonces: [
          { carrier: quotes[0].insuranceCarrier, authorizeNetPaymentNonce: res.opaqueData.dataValue },
        ],
      };

      if (quotes.length === 2) {
        const authData = buildAuthData(quotes[1].insuranceCarrier);
        const newRequest = { authData, cardData };
        window.Accept.dispatchData(newRequest, (res: any) => {
          if (res.messages.resultCode === 'Error') {
            errorCB(res);
          } else {
            const secondAndFinalQuote = {
              ...firstQuote,
              // combine carriers and nonces into an array of objects in a field to be specified by the api
              carrierAuthorizeNetPaymentNonces: [
                { carrier: quotes[0].insuranceCarrier, authorizeNetPaymentNonce: firstQuote.authorizeNetPaymentNonce },
                { carrier: quotes[1].insuranceCarrier, authorizeNetPaymentNonce: res.opaqueData.dataValue },
              ],
            };
            successCB(secondAndFinalQuote);
          }
        });
      } else {
        successCB(firstQuote);
      }
    }
  });
};

/**
 * Async action to handle payment transactions for the Accept.js API for Authorize.net
 * Accept.js API documentation: https://developer.authorize.net/api/reference/features/acceptjs.html#
 * @param {string} cardExp - users credit card expiration in 'mm/yy' format
 * @param {string} cardNumber - users credit card number
 * @param {string} cardCode - users credit card security code number
 */
export const handlePaymentTransaction = (cardExp: string, cardNumber: string, cardCode: string) => (
  dispatch: Function,
  getState: Function
) => {
  dispatch(quotePurchasePending());

  const cardArray = cardExp.split('/');
  /**
   * The credit card security code input field expects a 4 digit format, so 3 digit inputs
   * will have an extra whitespace that needs to be trimmed. We need to keep the 4 digit format
   * for AMEX credit cards, but also support 3 digit for Visa and Mastercard
   */
  const cardData: CardData = {
    cardNumber: cardNumber.split(' ').join(''),
    month: cardArray[0],
    year: cardArray[1],
    cardCode: cardCode.trim(),
  };

  const quoteId = selectQuoteId(getState());
  const quote = selectQuote(getState());

  const producerExternalId = selectAgentProducerExternalId(getState());
  const agencyExternalId = selectPartnerAgencyExternalId(getState());
  const addProducerOrAgencyExternalId = producerExternalId ? producerExternalId : agencyExternalId;

  const channelPartnerGuid = selectChannelPartnerGuid(getState());

  const onError = (res: any) => {
    dispatch(quotePurchasePending());
    dispatch(paymentTransactionFailed(res.messages.message));
  };

  const onSuccess = (quoteToBind: any) => {
    dispatch(handlePurchasePolicy({ ...quoteToBind, quoteId, addProducerOrAgencyExternalId, channelPartnerGuid }));
  };

  authorizeCard({
    quotes: quote.quotes,
    cardData,
    paymentInterval: getPaymentInterval(getState()),
    errorCB: onError,
    successCB: onSuccess,
  });
};

export const paymentTransactionFailed = (errors: any) => ({
  type: PAYMENT_TRANSACTION_FAILED,
  data: errors,
});

export const clearPaymentTransactionErrors = () => ({
  type: CLEAR_PAYMENT_TRANSACTION_ERRORS,
});
