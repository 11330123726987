import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { setUserHasInvalidatedAddon } from '../../actions/application';
import { selectEndorsements, selectUserHasInvalidatedAddOns } from '../../selectors/application';
import YesNoQuestion from '../Shared/YesNoQuestion';
import { EndorsementValidation, EndorsementValidationQuestion } from './addOnQuestionMap';
import { SubQuestionCont } from './styles/addons';
import CenteredLoadingSpinner from '../AddOns/shared/CenteredLoadingSpinner';

interface ValidationQuestionsProps {
  validation: EndorsementValidation;
  endorsement: string;
  action: (value: boolean) => Action;
  setExpanded: (value: boolean) => void;
}

interface ConnectedValidationQuestionProps extends ValidationQuestionsProps {
  setUserHasInvalidatedAddon: (value: string) => Action;
  isAddonInvalid: boolean;
  hasAddOnBeenAdded: boolean;
}

const ValidationQuestions = ({
  validation,
  endorsement,
  action,
  setUserHasInvalidatedAddon,
  isAddonInvalid,
  hasAddOnBeenAdded,
  setExpanded,
}: ConnectedValidationQuestionProps) => {
  const { validationQuestions, validationFunc } = validation;
  const [answers, setAnswers] = useState<{ [key: number]: boolean }>({});
  const [isSimulatedLoading, setIsSimulatedLoading] = useState(false);
  const [renderCheckMark, setRenderCheckMark] = useState(false);
  const isDesktop = useMediaQuery({ minWidth: 768 });

  useEffect(() => {
    const areSubQuestionsComplete = Object.values(answers).length === validationQuestions.length;

    if (areSubQuestionsComplete && !isAddonInvalid && !hasAddOnBeenAdded) {
      setRenderCheckMark(false);
      setIsSimulatedLoading(true);
      const isValid = validationFunc(answers);
      const simulatedLoading = setTimeout(() => {
        setExpanded(false);
        if (isValid) {
          action(true);
          setAnswers({});
        } else {
          action(false);
          setUserHasInvalidatedAddon(endorsement);
        }
      }, 5000);
      const simulatedOnSuccess = setTimeout(() => {
        isValid && setRenderCheckMark(true);
      }, Math.random() * 1000 + 1000);

      return () => {
        clearTimeout(simulatedLoading);
        clearTimeout(simulatedOnSuccess);
        setIsSimulatedLoading(false);
        setRenderCheckMark(false);
      };
    }
  }, [
    answers,
    validationQuestions.length,
    isAddonInvalid,
    hasAddOnBeenAdded,
    validationFunc,
    action,
    setUserHasInvalidatedAddon,
    endorsement,
    setExpanded,
  ]);

  const handleYes = (index: number) => {
    setAnswers({
      ...answers,
      [index]: true,
    });
  };

  const handleNo = (index: number) => {
    setAnswers({
      ...answers,
      [index]: false,
    });
  };

  const RenderValidationQuestions = () => (
    <>
      {validationQuestions.map((validationQuestion: EndorsementValidationQuestion, index: number) => (
        <SubQuestionCont key={index} isActive={!isSimulatedLoading} direction="row">
          <YesNoQuestion
            question={validationQuestion.QUESTION_COPY}
            value={answers[index]}
            selectYes={() => handleYes(index)}
            selectNo={() => handleNo(index)}
            cyLabel={`${endorsement}-addOn-validation-question-${validationQuestion.QUESTION_COPY}`}
            dataTestId="AddOn-validation-question"
            small={isDesktop}
            backgroundColor="#fff"
          />
        </SubQuestionCont>
      ))}
      {isSimulatedLoading && <CenteredLoadingSpinner showCheckMark={renderCheckMark} useAltColor position="relative" />}
    </>
  );

  return <RenderValidationQuestions />;
};

const mapStateToProps = (state: ReduxState, props: ValidationQuestionsProps) => {
  const invalidatedAddons = selectUserHasInvalidatedAddOns(state) || [];
  const endorsementState = selectEndorsements(state);
  const endorsementData = endorsementState?.[props.endorsement] || {};

  return {
    isAddonInvalid: invalidatedAddons.includes(props.endorsement),
    hasAddOnBeenAdded: Boolean(Object.keys(endorsementData).length),
  };
};

export default connect(mapStateToProps, { setUserHasInvalidatedAddon })(ValidationQuestions);
