import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import ProfessionalLiabilityQuestions from '../../Shared/ProfessionalLiability';
import GeneralLiabilityQuestions from '../../Shared/GeneralLiability';
import handleUpdateApplication from '../../../actions/handleUpdateApplication';
import {
  selectApplication,
  selectPolicyTypes,
  selectMailingAddressCity,
  selectMailingAddressStreet,
  selectMailingAddressState,
  selectMailingAddressZip,
} from '../../../selectors/application';
import {
  selectAvailablePolicyTypes,
  selectChannelPartnerGuid,
  getPartnerIdFromApplication,
  selectPartnerAgencyExternalId,
} from '../../../selectors';
import { getIsDropin } from '../../../selectors/routing';
import { addSource } from '../../../helpers/addSource';
import Locations from '../../Shared/Locations';
import {
  setExistingAddressAsLocation,
  didViewSummaryPage,
  setApplicationTypes,
  didViewAddOnsPage,
  setBppDeductible,
  setBppLimit,
} from '../../../actions/application';
import useGetQuoteDifference from './useGetQuoteDifference';
import shouldShowAddPolicy from './shouldShowAddPolicy';
import { formatCurrency } from '../../../helpers/formatNumber';
import Modal, { SuccessMessage } from '../../../elements/Modal';
import {
  Container,
  ContentContainer,
  Title,
  Content,
  Link,
  ModalIcon,
  AddPolicyDetailsBar,
  Row,
  IconContainer,
  PlusSymbol,
  FirstImg,
  SecondImg,
} from './styles';
import { DesktopAndTablet, Mobile } from '../../../helpers/responsive';
import getPolicyAssets from '../../../helpers/getPolicyAssets';
import { compose } from '../../../helpers/functionalHelpers';
import { PolicyType } from '../../../types/enums';
import { useThemeContext } from '../../../elements/theme/CustomThemeProvider';
import { useFeatureFlags } from '../../../toggle_tools/featureFlagTools';
import { QUOTE_COVERAGE_DEFAULTS } from '../../../constants';

interface AddPolicyProps {
  application: Application;
  currentQuoteAmount: string;
  handleUpdateApplication: () => void;
  policy: PolicyType;
  setExistingAddressAsLocation: () => void;
  setBppDeductible: (bppDeductible: number) => void;
  setBppLimit: (bppLimit: number) => void;
  didViewSummaryPage: (didView: boolean) => void;
  setApplicationTypes: (applicationTypes: PolicyType[]) => void;
  didViewAddOnsPage: () => void;
  city: string;
  street: string;
  state: string;
  zip: string;
  isDropin: boolean;
  partnerId: string | undefined;
  agencyExternalId: string;
}

const AddPolicy = ({
  currentQuoteAmount,
  application,
  handleUpdateApplication,
  policy,
  setExistingAddressAsLocation,
  setBppDeductible,
  setBppLimit,
  setApplicationTypes,
  didViewSummaryPage,
  didViewAddOnsPage,
  city,
  street,
  state,
  zip,
  isDropin,
  partnerId,
  agencyExternalId,
}: AddPolicyProps) => {
  const { theme, shouldRenderIcon } = useThemeContext();
  const sourceData = useMemo(() => addSource({ isDropin }), [isDropin]);
  const { difference, loading, quoteError } = useGetQuoteDifference(
    currentQuoteAmount,
    application,
    policy,
    city,
    street,
    state,
    zip,
    sourceData,
    agencyExternalId
  );
  const { hidePlByChannelPartner } = useFeatureFlags();
  const partnerWantsHidden = Boolean(partnerId && hidePlByChannelPartner?.channel_partner_ids.includes(partnerId));
  const [open, setModal] = useState(false);
  const [bopShowLocations, setBOPShowLocations] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const addingPL = policy === PolicyType.PL;
  const addingGL = policy === PolicyType.GL;
  const addingBOP = policy === PolicyType.BOP;
  const glToBOP = addingBOP && application.applicationTypes?.includes(PolicyType.GL);
  const onlyPLSelected =
    addingBOP && application.applicationTypes?.includes(PolicyType.PL) && application.applicationTypes?.length === 1;
  const policyAssets = getPolicyAssets(policy, theme);
  const handleSubmit = () => {
    const updateApplication = () => {
      setApplicationTypes([
        ...((addingBOP
          ? application?.applicationTypes?.filter((p) => p !== PolicyType.GL)
          : application.applicationTypes) || []),
        policy,
      ]);
      handleUpdateApplication();
    };
    didViewSummaryPage(false);
    if (addingBOP) {
      didViewAddOnsPage();
    }
    if (glToBOP) {
      setShowSuccess(true);
      setTimeout(() => {
        updateApplication();
        setModal(false);
        setShowSuccess(false);
      }, 2500);
    } else {
      updateApplication();
      setModal(false);
    }
  };

  const handleAddPolicyClick = () => {
    setModal(true);
    if (addingBOP) {
      setBppDeductible(QUOTE_COVERAGE_DEFAULTS.BPP_DEDUCTIBLE);
      setBppLimit(QUOTE_COVERAGE_DEFAULTS.BPP_LIMIT);
      setExistingAddressAsLocation();
    }
  };

  const RenderIcon = () =>
    shouldRenderIcon ? (
      <IconContainer>
        <FirstImg src={getPolicyAssets(application.applicationTypes?.[0], theme)?.icon} alt="Bundled policies" />
        <PlusSymbol>+</PlusSymbol>
        <SecondImg src={policyAssets?.icon} alt="Bundled policies" />
      </IconContainer>
    ) : null;

  const DetailsBar = (
    <AddPolicyDetailsBar policy={policyAssets}>
      <ModalIcon src={policyAssets?.icon} height="60px" width="50px" />
      <h3>{policyAssets?.title} Policy</h3>
    </AddPolicyDetailsBar>
  );

  return (
    <>
      {!loading && !quoteError && ((policy === PolicyType.PL && !partnerWantsHidden) || policy !== PolicyType.PL) && (
        <Container data-cy={`${policy.toLowerCase()}-add-policy`} policy={policyAssets}>
          <Row>
            <DesktopAndTablet>
              <RenderIcon />
            </DesktopAndTablet>
            <ContentContainer>
              <Row>
                <Mobile>
                  <RenderIcon />
                </Mobile>
                <Title>Add + Save!</Title>
              </Row>
              <Content data-cy="add-policy-content">
                {glToBOP
                  ? `Add property coverage for as little as ${formatCurrency(difference ?? 0)}/mo.`
                  : `Add a ${policyAssets?.title} Policy for as little as ${formatCurrency(difference ?? 0)}/mo.`}
              </Content>
            </ContentContainer>
          </Row>
          <Link href="#" onClick={handleAddPolicyClick}>
            + Add policy
          </Link>
        </Container>
      )}
      <Modal
        isOpen={open}
        onRequestClose={() => setModal(false)}
        label="Add Policy"
        title="Add Policy"
        detailsBar={DetailsBar}
      >
        {showSuccess ? (
          <SuccessMessage>
            Your GL has been upgraded to a BOP policy, you now have coverage for your property!
          </SuccessMessage>
        ) : (
          <>
            {addingPL && <ProfessionalLiabilityQuestions onBundleSubmit={handleSubmit} />}
            {(addingGL || (onlyPLSelected && !bopShowLocations)) && (
              <GeneralLiabilityQuestions
                onBundleSubmit={onlyPLSelected ? () => setBOPShowLocations(true) : handleSubmit}
              />
            )}
            {((!onlyPLSelected && addingBOP) || (onlyPLSelected && bopShowLocations)) && (
              <Locations onBundleSubmit={handleSubmit} />
            )}
          </>
        )}
      </Modal>
    </>
  );
};

const mapStateToProps = (state: ReduxState) => {
  const availablePolicyTypes = selectAvailablePolicyTypes(state);
  const applicationTypes = selectPolicyTypes(state);
  const availablePoliciesToAdd = availablePolicyTypes.filter(
    (policy: PolicyType) => !applicationTypes.includes(policy)
  );

  return {
    application: selectApplication(state),
    availablePoliciesToAdd,
    city: selectMailingAddressCity(state),
    street: selectMailingAddressStreet(state),
    state: selectMailingAddressState(state),
    zip: selectMailingAddressZip(state),
    isDropin: getIsDropin(state),
    partnerId: selectChannelPartnerGuid(state) ?? getPartnerIdFromApplication(state),
    agencyExternalId: selectPartnerAgencyExternalId(state),
  };
};

const mapDispatchToProps = {
  handleUpdateApplication,
  setExistingAddressAsLocation,
  didViewSummaryPage,
  didViewAddOnsPage,
  setApplicationTypes,
  setBppDeductible,
  setBppLimit,
};

const ConnectHoc = connect(mapStateToProps, mapDispatchToProps);

export default compose(ConnectHoc, shouldShowAddPolicy)(AddPolicy);
